import { FC } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import {
    AdminPanelSettings,
    DocumentScanner,
    Key,
    Settings,
    Summarize,
} from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'
import PanelTile from './components/PanelTile'
import { useQuery } from 'react-query'
import LoadingSpinner from 'components/LoadingSpinner'
import { getCompanyDetails } from 'api/evansAdmin'
import usePageTitle from 'hooks/usePageTitle'
import ErrorMessage from 'components/ErrorMessage'
import { AxiosError, AxiosResponse } from 'axios'

const iconStyle = {
    fontSize: '5rem',
}

const containerStyle = {
    padding: '1rem',
}

const EvansAdminCompanyPage: FC = () => {
    usePageTitle('Edit Company')
    const { companyId } = useParams()
    const navigate = useNavigate()

    const {
        data: response,
        isFetching,
        error,
    } = useQuery<AxiosResponse, AxiosError<{ message: string }>>(
        'getCompanyName',
        () => getCompanyDetails(companyId as string)
    )

    const { companyName } = response?.data || {}

    const { message: errorMsg } = error?.response?.data || {}

    function navigateToScreen(link: string) {
        navigate('/ride-to-work-apply/evans-admin/companies/' + link)
    }

    if (isFetching) {
        return <LoadingSpinner />
    }

    if (error) {
        return <ErrorMessage text={errorMsg as string} />
    }

    return (
        <Grid container spacing={5} sx={containerStyle}>
            <Grid item xs={12}>
                <Typography color="primary" variant="h4">
                    {companyName}
                </Typography>
            </Grid>
            <PanelTile
                title="Company Details"
                onClick={() => navigateToScreen(`${companyId}/details`)}
            >
                <DocumentScanner sx={iconStyle} />
            </PanelTile>
            <PanelTile
                title="Scheme Administrator"
                onClick={() => navigateToScreen(`${companyId}/admins`)}
            >
                <AdminPanelSettings sx={iconStyle} />
            </PanelTile>

            <PanelTile
                title="Company Documents"
                onClick={() => navigateToScreen(`${companyId}/documents`)}
            >
                <Summarize sx={iconStyle} />
            </PanelTile>

            <PanelTile
                title="Company Restrictions"
                onClick={() => navigateToScreen(`${companyId}/restrictions`)}
            >
                <Key sx={iconStyle} />
            </PanelTile>
            <PanelTile
                title="Company Settings"
                onClick={() => navigateToScreen(`${companyId}/settings`)}
            >
                <Settings sx={iconStyle} />
            </PanelTile>
        </Grid>
    )
}

export default EvansAdminCompanyPage
