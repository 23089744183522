import { List, ListItem, Typography, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { FC } from 'react'
import { IButtonProps } from 'Interfaces/CMS'

interface ButtonsListProps {
    buttons: IButtonProps[]
    onEditClick: (button: IButtonProps) => void
    onDeleteClick: (button: IButtonProps) => void
}

const ButtonsList: FC<ButtonsListProps> = ({
    buttons,
    onEditClick,
    onDeleteClick,
}) => {
    return (
        <List>
            {buttons.length === 0 && (
                <ListItem>
                    <Typography color="gray">No buttons</Typography>
                </ListItem>
            )}
            {buttons?.map((btn, i) => (
                <ListItem
                    key={`${btn.id}-${i}`}
                    secondaryAction={
                        <>
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => onEditClick(btn)}
                                sx={{ marginRight: 1 }}
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => onDeleteClick(btn)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </>
                    }
                >
                    <Typography>{btn.text}</Typography>
                </ListItem>
            ))}
        </List>
    )
}

export default ButtonsList
