import { MenuList } from '@mui/material'
import { forwardRef, OlHTMLAttributes, Ref } from 'react'
import { theme } from 'utils/Theme'

export const AddressSearchMenuList = forwardRef(
    (props: OlHTMLAttributes<HTMLUListElement>, ref: Ref<HTMLUListElement>) => {
        return (
            <MenuList
                sx={{
                    zIndex: theme.zIndex.tooltip,
                    background: theme.palette.common.white,
                    borderRadius: '5px',
                    boxShadow: theme.shadows[10],
                }}
                {...props}
                ref={ref}
            />
        )
    }
)
AddressSearchMenuList.displayName = 'AddressSearchMenuList'
