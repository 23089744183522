import { FC } from 'react'
import { DialogContentStyle } from 'components/Modal/ModalStyle'
import { Typography, Box } from '@mui/material'
import Button from 'components/Button'
import Input from 'components/Input'
import { useFormikContext } from 'formik'
const Step1: FC = () => {
    const formik = useFormikContext()

    return (
        <Box sx={DialogContentStyle}>
            <Typography variant="subtitle1">
                If you’ve forgotten your password or need to set up a new one,
                don’t worry - we’ll get you back up and cycling in no time.
            </Typography>
            <Typography>
                Enter your registered email address below and we’ll email you a
                link so you can create a new password - easy!
            </Typography>

            <Input
                label="Email address"
                name="email"
                apiErrors={formik.status}
            />

            <Button text="Reset Password" onClick={formik.handleSubmit} />
        </Box>
    )
}

export default Step1
