import React, { FC, useState } from 'react'
import { useQuery } from 'react-query'
import {
    confirmAdmin,
    deleteUnconfirmedAdmin,
    getUnconfirmedAdmins,
} from '../../../api/evansAdmin'
import { AxiosError, AxiosResponse } from 'axios'
import LoadingSpinner from '../../../components/LoadingSpinner'
import ErrorMessage from '../../../components/ErrorMessage'
import Button from '../../../components/Button'
import Table from '../../../components/Table'
import { Typography } from '@mui/material'
import { GridCellParams } from '@mui/x-data-grid'
import Modal from '../../../components/Modal/Modal'
import DeleteModal from '../../../components/DeleteModal'
import useStore from '../../../store/Store'

const UnconfirmedSchemeAdmins: FC = () => {
    const [
        isDeleteUnconfirmedAdminModalOpen,
        setIsDeleteUnconfirmedAdminModalOpen,
    ] = useState<string>()
    const [confirmingAdmin, setConfirmingAdmin] = useState<string | null>(null)
    const { openNotification } = useStore()

    const columns = [
        { field: 'id', flex: 0, headerName: 'Id' },
        { field: 'emailAddress', flex: 2, headerName: 'Email Address' },
        {
            field: 'confirm',
            headerName: 'Confirm',
            width: 100,
            renderCell: (params: GridCellParams) => (
                <Button
                    fullWidth
                    text="Confirm"
                    onClick={() => {
                        setConfirmingAdmin(params.row.emailAddress)
                        confirmAdmin(params.row.emailAddress)
                            .then(() => {
                                refetch()
                                openNotification(
                                    'Scheme admin has been confirmed',
                                    'success'
                                )
                            })
                            .finally(() => setConfirmingAdmin(null))
                    }}
                    disabled={
                        confirmingAdmin === params.row.emailAddress ||
                        isFetching
                    }
                    isLoading={confirmingAdmin === params.row.emailAddress}
                />
            ),
        },
        {
            field: 'delete',
            headerName: 'Delete',
            width: 100,
            renderCell: (params: GridCellParams) => (
                <Button
                    error
                    fullWidth
                    text="Delete"
                    onClick={() => {
                        setIsDeleteUnconfirmedAdminModalOpen(
                            params.row.emailAddress
                        )
                    }}
                />
            ),
        },
    ]

    const {
        isFetching,
        data: response,
        error,
        refetch,
    } = useQuery<AxiosResponse, AxiosError<{ message: string }>>(
        ['getUnconfirmedAdmins'],
        () => getUnconfirmedAdmins()
    )

    const { message: errorMsg } = error?.response?.data || {}

    if (isFetching) {
        return <LoadingSpinner />
    }

    if (error) {
        return <ErrorMessage text={errorMsg as string} />
    }

    return (
        <>
            <Typography mb={2} mt={1}>
                UNCONFIRMED SCHEME ADMINS:
            </Typography>
            <Table
                loading={isFetching}
                rows={response?.data}
                columns={columns}
            />

            <Modal
                title="Delete Unconfirmed Scheme Admin"
                isOpen={isDeleteUnconfirmedAdminModalOpen !== undefined}
                onClose={() => setIsDeleteUnconfirmedAdminModalOpen(undefined)}
            >
                {isDeleteUnconfirmedAdminModalOpen && (
                    <DeleteModal
                        successCallback={refetch}
                        closeModal={() =>
                            setIsDeleteUnconfirmedAdminModalOpen(undefined)
                        }
                        deleteFunction={() =>
                            deleteUnconfirmedAdmin(
                                isDeleteUnconfirmedAdminModalOpen
                            )
                        }
                        message={`Are you sure you want to remove unconfirmed admin with email: ${isDeleteUnconfirmedAdminModalOpen}?`}
                    />
                )}
            </Modal>
        </>
    )
}

export default UnconfirmedSchemeAdmins
