import Table from 'components/Table'
import { FC } from 'react'
import { getAllDocumentsForCompany, downloadDocument } from 'api/schemeAdmin'
import { useQuery, useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { GridCellParams } from '@mui/x-data-grid'
import Button from 'components/Button'
import useStore from 'store/Store'
import { AxiosError, AxiosResponse } from 'axios'
import fileDownload from 'js-file-download'
import ErrorMessage from 'components/ErrorMessage'
import { Divider, Typography } from '@mui/material'

const SchemeAdminDocuments: FC = () => {
    const { companyId } = useParams()
    const { openNotification } = useStore()

    const { mutate, variables, isLoading } = useMutation(
        (obj: { documentId: string; documentName: string }) =>
            downloadDocument(obj.documentId),
        {
            onSuccess: (response, { documentName }) => {
                fileDownload(response.data, documentName)
            },

            onError: (error: AxiosError<{ message: string }>) => {
                openNotification(
                    (error?.response?.data?.message as string) ||
                        'Something went wrong',
                    'error'
                )
            },
        }
    )

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
        },

        {
            field: 'category',
            headerName: 'Category',
            flex: 1,
        },
        {
            field: 'isGlobal',
            headerName: 'Is Global',
            flex: 1,
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1,
        },
        {
            field: 'download',
            headerName: 'Download',
            width: 130,
            renderCell: (params: GridCellParams) => (
                <Button
                    text="Download"
                    isLoading={isLoading && variables?.documentId === params.id}
                    onClick={() => {
                        mutate({
                            documentId: params.id as string,
                            documentName: params.row.name,
                        })
                    }}
                />
            ),
        },
    ]

    const {
        data: response,
        isFetching,
        error,
    } = useQuery<AxiosResponse, AxiosError<{ message: string }>>(
        ['getDocuments', companyId],
        () => getAllDocumentsForCompany(companyId as string)
    )

    const { message: errorMsg } = error?.response?.data || {}

    if (error) {
        return <ErrorMessage text={errorMsg as string} />
    }
    return (
        <>
            <Divider>
                <Typography
                    fontWeight="bold"
                    color="primary"
                    variant="h4"
                    mb={2}
                >
                    Manage company documents
                </Typography>
            </Divider>
            <Table
                disableSelect
                pageSize={12}
                loading={isFetching}
                columns={columns}
                rows={response?.data}
            />
        </>
    )
}

export default SchemeAdminDocuments
