import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getCertificateApplicantDetails } from 'api/evansAdmin'
import LoadingSpinner from 'components/LoadingSpinner'
import { Box, Grid, Tab, Tabs, Typography, useTheme } from '@mui/material'
import Tile from 'pages/SchemeAdmin/AdminPanelApplicantDetails/components/Tile'
import Button from 'components/Button'
import Modal from 'components/Modal/Modal'
import { AxiosError, AxiosResponse } from 'axios'
import ErrorMessage from 'components/ErrorMessage'
import { convertToPounds } from 'utils/convertToPounds'

const EvansAdminCertificateDetailsPage: FC = () => {
    const { certificateId } = useParams()
    const theme = useTheme()
    const [isAgreementModalOpen, setIsAgreementModalOpen] = useState(false)

    const {
        isFetching,
        data: response,
        error,
    } = useQuery<AxiosResponse, AxiosError<{ message: string }>>(
        ['getApplicantDetails', certificateId],
        () => getCertificateApplicantDetails(certificateId as string),
        {
            onSuccess: (response) => {
                if (!response.data.thirdStepAgreementURL) {
                    setCurrentAgreement('hireAgreement')
                }
            },
        }
    )

    const {
        status,
        applicationDate,
        approvalDate,
        certificateAmount,
        companyId,
        employee,
        hireAgreementURL,
        singleSalarySacrificeAmount,
        thirdStepAgreementURL,
        certificateNumber,
        purchaseOrder,
        salarySacrificeDeductionFrequency,
        salarySacrificeTermMonths,
    } = response?.data || {}

    const [currentAgreement, setCurrentAgreement] = useState('thirdStep')

    const areBothAgreements = Boolean(thirdStepAgreementURL && hireAgreementURL)

    const { message: errorMsg } = error?.response?.data || {}

    if (isFetching) {
        return <LoadingSpinner />
    }

    if (error) {
        return <ErrorMessage text={errorMsg as string} />
    }

    return (
        <>
            <Modal
                hideHeader
                maxWidth="md"
                isOpen={isAgreementModalOpen}
                onClose={() => setIsAgreementModalOpen(false)}
            >
                <Box sx={{ height: '600px' }}>
                    <Tabs
                        value={currentAgreement}
                        onChange={(event, value: string) =>
                            setCurrentAgreement(value)
                        }
                    >
                        {thirdStepAgreementURL && (
                            <Tab
                                value="thirdStep"
                                label={
                                    thirdStepAgreementURL.includes(
                                        'salary_sacrifice_agreement.pdf'
                                    )
                                        ? 'Salary Sacrifice Agreement'
                                        : 'Pre Contract Agreement'
                                }
                                disabled={!areBothAgreements}
                            />
                        )}
                        {hireAgreementURL && (
                            <Tab
                                value="hireAgreement"
                                label="Hire Agreement"
                                disabled={!areBothAgreements}
                            />
                        )}
                    </Tabs>

                    <embed
                        src={
                            currentAgreement === 'thirdStep' &&
                            thirdStepAgreementURL
                                ? thirdStepAgreementURL
                                : hireAgreementURL
                        }
                        width="100%"
                        height="100%"
                    />
                </Box>
            </Modal>

            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexWrap="wrap"
            >
                <Typography color="primary" variant="h4" mt={3}>
                    Certificate ID {certificateId}
                </Typography>

                <Button
                    disabled={!thirdStepAgreementURL && !hireAgreementURL}
                    text="Agreement Preview"
                    colorPrimary
                    onClick={() => setIsAgreementModalOpen(true)}
                />
            </Box>
            <Typography
                mt={5}
                color="primary"
                variant="h5"
                sx={{
                    borderBottom: '2px solid',
                    borderColor: theme.palette.secondary.main,
                    paddingBottom: '.3rem',
                }}
            >
                Certificate Details
            </Typography>
            <Grid container spacing={1}>
                <Tile label="ID" info={certificateId as string} />
                <Tile label="Status" info={status} />
                <Tile
                    label="Application Date"
                    info={applicationDate?.toString().replaceAll(',', '-')}
                />
                <Tile
                    label="Approval Date"
                    info={approvalDate?.toString().replaceAll(',', '-')}
                />
                <Tile
                    label="Amount"
                    info={convertToPounds(certificateAmount)}
                />
                <Tile
                    label="Salary Sacrifice Amount"
                    info={convertToPounds(singleSalarySacrificeAmount)}
                />
                <Tile label="Company ID" info={companyId} />
                <Tile label="Certificate Number" info={certificateNumber} />
                <Tile
                    label="Salary Sacrifice Deduction Frequency"
                    info={salarySacrificeDeductionFrequency}
                />
                <Tile
                    label="Salary Sacrifice Terms"
                    info={salarySacrificeTermMonths}
                />
                <Tile label="Purchase Order" info={purchaseOrder} />
            </Grid>
            <Typography
                color="primary"
                variant="h5"
                mt={5}
                sx={{
                    borderBottom: '2px solid',
                    borderColor: theme.palette.secondary.main,
                    paddingBottom: '.3rem',
                }}
            >
                Employee Details
            </Typography>
            <Grid container spacing={1}>
                <Tile label="Title" info={employee.title} />
                <Tile label="ID" info={employee.id} />
                <Tile label="First Name" info={employee.firstName} />
                <Tile label="Last Name" info={employee.lastName} />
                <Tile label="Work Email" info={employee.workEmail} />
                <Tile label="Personal Email" info={employee.personalEmail} />
                <Tile label="Landline" info={employee.landline} />
                <Tile label="Mobile Number" info={employee.mobileNumber} />
                <Tile
                    label="Address Line 1"
                    info={employee.address.addressLine1}
                />
                <Tile
                    label="Address Line 2"
                    info={employee.address.addressLine2}
                />
                <Tile label="Town" info={employee.address.town} />
                <Tile label="County" info={employee.address.town} />
                <Tile label="Postcode" info={employee.address.postcode} />
            </Grid>
        </>
    )
}

export default EvansAdminCertificateDetailsPage
