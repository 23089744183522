import React, { FC } from 'react'
import { Alert } from '@mui/material'

interface IProps {
    text: string
}

const SuccessMessage: FC<IProps> = ({ text }) => {
    return <Alert severity="success">{text}</Alert>
}

export default SuccessMessage
