import { FC } from 'react'
import { KeyboardArrowRight } from '@mui/icons-material'
import { Breadcrumbs, Link, Box } from '@mui/material'
import { useLocation, Link as RouterLink } from 'react-router-dom'
import { useTheme } from '@mui/system'

const Navigation: FC = () => {
    const location = useLocation()
    const isOnEvansAdminSite = location.pathname.includes('evans-admin')
    const isSchemeAdmin = location.pathname.includes('schemeadmin')
    const isSchemeAdminLogin = location.pathname.includes('schemeadmin/login')
    const isSavingsCalculator = location.pathname.includes('savings-calculator')
    const theme = useTheme()

    if (
        (isOnEvansAdminSite && location.pathname.includes('login')) ||
        isSavingsCalculator
    ) {
        return null
    }

    const linkStyle = {
        underline: 'none',
        textDecoration: 'none',
        color: theme.palette.primary.main,
        cursor: 'pointer',
    }

    const pathnames = location.pathname
        .split('/')
        .filter((element: string) => element)

    return (
        <Box padding="0 1rem" marginBottom="1rem">
            <Breadcrumbs separator={<KeyboardArrowRight />}>
                {isSchemeAdminLogin && (
                    <Link sx={linkStyle} href="https://www.evanscycles.com/">
                        Home
                    </Link>
                )}
                {isSchemeAdminLogin && (
                    <Link
                        sx={linkStyle}
                        href="https://www.evanscycles.com/b2b/ride-to-work"
                    >
                        Ride-to-Work
                    </Link>
                )}
                {pathnames.map((element: string, index: number) => {
                    if (
                        element === 'ride-to-work-apply' ||
                        element === 'evans-admin' ||
                        element === 'schemeadmin'
                    ) {
                        return
                    }

                    if (element === 'get-certificate') {
                        element = 'Apply'
                    }
                    if (isSchemeAdmin && element === 'companies') {
                        element = 'Home'
                    }

                    if (element === 'login') {
                        element = 'Scheme Administrator Login'
                    }
                    if (
                        isSchemeAdmin &&
                        element.match(/^\d+$/) &&
                        pathnames[index - 1] === 'companies'
                    ) {
                        element = 'Company'
                    }

                    if (
                        isSchemeAdmin &&
                        pathnames[index - 1] === 'certificates'
                    ) {
                        element = 'Applicant Details'
                    }

                    const firstLetterCapitalize = element
                        .charAt(0)
                        .toUpperCase()

                    element = firstLetterCapitalize + element.slice(1)

                    const link = pathnames.slice(0, index + 1).join('/')

                    return (
                        <RouterLink
                            key={element}
                            to={'/' + link}
                            style={linkStyle}
                        >
                            {element}
                        </RouterLink>
                    )
                })}
            </Breadcrumbs>
        </Box>
    )
}

export default Navigation
