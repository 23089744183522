import { ICertificateFiltersSchemeAdmin } from './../Interfaces/Certificate'
import {
    ICompanyRegistration,
    IUpdateCompanyDetails,
    ICompanyRestrictions,
} from './../Interfaces/Company'
import axios, { axiosBinary } from './axios'
import { IRegisterSchemeAdmin, ISchemeAdminDetails } from 'Interfaces/Admin'
import qs from 'qs'

export const rejectCertificateProcess = async (processIdList: string[]) => {
    return await axios.patch(
        `scheme-admin/certificates/statuses/reject`,
        processIdList
    )
}

export const acceptCertificateProcess = async (
    data: Array<{ certificateId: string; purchaseOrderNumber: string }>
) => {
    return await axios.patch(`scheme-admin/certificates/statuses/accept`, data)
}

export const registerSchemeAdmin = async (admin: IRegisterSchemeAdmin) => {
    return await axios.post('/scheme-admin/scheme-admins', admin)
}

export const getSchemeAdminDetails = async () => {
    return await axios.get(`scheme-admin/scheme-admins`)
}

export const updateSchemeAdminDetails = async (
    details: ISchemeAdminDetails
) => {
    return await axios.put(`scheme-admin/scheme-admins`, details)
}

export const getActiveAndPendingCompanies = async () => {
    return await axios.get(`scheme-admin/certificates/active-pending-companies`)
}

export const registerCompany = async (data: ICompanyRegistration) => {
    return await axios.post(`scheme-admin/companies`, data)
}

export const getCertificateListing = async (
    companyId: string,
    params: ICertificateFiltersSchemeAdmin
) => {
    return await axios.get(`scheme-admin/certificates/${companyId}`, {
        params,
        paramsSerializer: (params) =>
            qs.stringify(params, {
                arrayFormat: 'repeat',
                skipNulls: true,
            }),
    })
}

export const getCertificateErrors = async (processId: string) => {
    return await axios.get(`scheme-admin/certificates/errors/${processId}`)
}

export const getCertificateListingToCsv = async (
    companyId: string,
    params: ICertificateFiltersSchemeAdmin
) => {
    const { page, size, ...rest } = params
    return await axios.get(
        `scheme-admin/certificates/companies/${companyId}/csv`,
        {
            params: {
                ...rest,
                size: 9999,
            },
            paramsSerializer: (params) =>
                qs.stringify(params, {
                    arrayFormat: 'repeat',
                    skipNulls: true,
                }),
        }
    )
}

export const getCertificatesNumberForCompany = async (companyId: string) => {
    return await axios.get(
        `scheme-admin/certificates/companies/${companyId}/statuses`
    )
}

export const getCertificateDetails = async (processId: string) => {
    return await axios.get(`scheme-admin/certificates/processes/${processId}`)
}

export const getAllDocumentsForCompany = async (companyId: string) => {
    return await axios.get(`scheme-admin/documents/${companyId}`)
}

export const downloadDocument = async (documentId: string) => {
    return await axios.get(`scheme-admin/documents/${documentId}/document`, {
        responseType: 'blob',
    })
}

export const getAllGlobalsDocuments = async () => {
    return await axios.get(`scheme-admin/documents`)
}

export const updateEmailAddress = async (data: {
    emailAddress: string
    confirmEmailAddress: string
}) => {
    return await axios.patch(`scheme-admin/scheme-admins/emails`, data)
}
export const getCompanyDetails = async (companyId: string) => {
    return await axios.get(`scheme-admin/companies/${companyId}`)
}

export const updateCompanyDescription = async (
    companyId: string,
    descriptiveText: string
) => {
    return await axios.put(`scheme-admin/companies/${companyId}/description`, {
        descriptiveText,
    })
}

export const updateCompanyDetails = async (
    companyId: string,
    body: IUpdateCompanyDetails
) => {
    return await axios.put(`scheme-admin/companies/${companyId}/details`, body)
}
export const getCompanyRestrictions = async (companyId: string) => {
    return await axios.get(`scheme-admin/companies/${companyId}/restrictions`)
}

export const updateCompanyRestrictions = async (
    companyId: string,
    body: ICompanyRestrictions
) => {
    return await axios.patch(
        `scheme-admin/companies/${companyId}/restrictions`,
        body
    )
}

export const uploadNewCompanyLogo = async (
    companyId: string,
    image: File | string | Blob | FormData
) => {
    return await axiosBinary.post(`scheme-admin/companies/${companyId}/logo`, {
        image,
    })
}

export const removeCompanyLogo = async (companyId: string) => {
    return await axiosBinary.delete(`scheme-admin/companies/${companyId}/logo`)
}

export const getCompanyLogo = async (companyId: string) => {
    return await axios.get(`scheme-admin/companies/${companyId}/logo`)
}

export const getCertificatesDescription = async () => {
    return await axios.get('scheme-admin/certificates/statuses')
}

export const getTerms = async () => {
    return await axios.get('scheme-admin/companies/available-terms')
}
