import { FC } from 'react'
import Table from 'components/Table'
import { GridCellParams } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import Button from 'components/Button'
import { useQuery } from 'react-query'
import { getAllContentElements } from 'api/evansAdmin'
import usePageTitle from 'hooks/usePageTitle'
import { AxiosError, AxiosResponse } from 'axios'
import ErrorMessage from 'components/ErrorMessage'
import { Typography } from '@mui/material'

const EvansAdminCMSPage: FC = () => {
    usePageTitle('Content Management')
    const navigate = useNavigate()

    const columns = [
        { field: 'id', flex: 0, headerName: 'Id' },
        { field: 'name', flex: 2, headerName: 'Name' },
        { field: 'title', flex: 3, headerName: 'Title' },
        {
            field: 'edit',
            headerName: 'Edit',
            width: 80,
            renderCell: (params: GridCellParams) => (
                <Button
                    fullWidth
                    text="Edit"
                    onClick={() => {
                        navigate(
                            `/ride-to-work-apply/evans-admin/cms/${params.row.id}`
                        )
                    }}
                />
            ),
        },
    ]

    const {
        data: response,
        isFetching,
        error,
    } = useQuery<AxiosResponse, AxiosError<{ message: string }>>(
        ['getContentElements'],
        () => getAllContentElements()
    )

    const { message: errorMsg } = error?.response?.data || {}

    if (errorMsg) {
        return <ErrorMessage text={errorMsg} />
    }

    return (
        <>
            <Typography color="primary" variant="h4" mt={2}>
                Manage Content
            </Typography>
            <Table
                loading={isFetching}
                rows={response?.data}
                columns={columns}
            />
        </>
    )
}

export default EvansAdminCMSPage
