export const editorInitSettings = {
    plugins: ['lists', 'link', 'image', 'charmap', 'help'],
    toolbar:
        'undo redo | casechange blocks | bold italic forecolor | ' +
        'alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | removeformat help',
    color_map: [
        '000000',
        'Black',
        '005a54',
        'Evans green',
        '69bc90',
        'RTW green',
        'f9ad1d',
        'Evans yellow',
        'b11b36',
        'Error',
    ],
}
