export const CATEGORY_INPUT_OPTIONS = ['MARKETING', 'GUIDES', 'DOCUMENTS']

export const DOCUMENT_TYPE_OPTIONS = ['OTHER', 'PDF', 'JPG', 'PNG', 'DOC']

export const CERTIFICATE_APPLY_ACTIONS = {
    acceptThirdStep: 'ACCEPT_THIRD_STEP',
    acceptHire: 'ACCEPT_HIRE',
    cancel: 'CANCEL',
}

export const SALARY_SACRIFICE_MONTHS_OPTIONS = [12, 18, 24]

export const HIRE_AGREEMENT_MONTHS_OPTIONS = [
    12, 18, 24, 30, 36, 42, 48, 54, 60, 66, 72,
]

export const TITLE_OPTIONS = ['MR', 'MISS', 'DR', 'MRS', 'MS']

export const HOW_DID_YOU_HEAR_OPTIONS = [
    'Recommendation from a colleague or employee at my organisation',
    'Recommendation from other company',
    'Via Evans Cycles store',
    'Via the main Evans Cycles website',
    'Employee Benefits or HR trade publication',
    'Internet search',
    'Other',
]
export const HOW_DID_YOU_HEAR_OPTIONS_WITH_VALUES = [
    {
        value: 'RECOMMENDATION_FROM_COLLEAGUE',
        label: 'Recommendation from a colleague or employee at my organisation',
    },
    {
        value: 'RECOMMENDATION_FROM_OTHER_COMPANY',
        label: 'Recommendation from other company',
    },
    { value: 'VIA_EVANS_CYCLES_STORE', label: 'Via Evans Cycles store' },
    {
        value: 'VIA_MAIN_EVANS_CYCLES_WEBSITE',
        label: 'Via the main Evans Cycles website',
    },
    {
        value: 'EMPLOYEE_BENEFITS_OR_HR_TRADE_PUBLICATION',
        label: 'Employee Benefits or HR trade publication',
    },
    { value: 'INTERNET_SEARCH', label: 'Internet search' },
    { value: 'OTHER', label: 'Other' },
]

export const UPLOAD_DOCUMENT_FORMATS = ['pdf', 'jpg', 'docx', 'png']
export const CERTIFICATES_STATUSES_OPTIONS = [
    'NEW',
    'CREATED',
    'PENDING',
    'CANCELLED',
    'ACCEPTED',
    'DECLINED',
    'CERTIFICATE_ISSUED',
    'CERTIFICATE_NOTISSUED',
]

export const MIN_CERTIFICATE_AMOUNT =
    parseInt(process.env.REACT_APP_MIN_CERTIFICATE_AMOUNT as string) || 100

export const MAX_SALARY_SACRIFICE_CERTIFICATE_AMOUNT =
    parseInt(
        process.env.REACT_APP_MAX_SALARY_SACRIFICE_CERTIFICATE_AMOUNT as string
    ) || 15000

export const MAX_PRE_CONTRACT_CERTIFICATE_AMOUNT =
    parseInt(
        process.env.REACT_APP_MAX_PRE_CONTRACT_CERTIFICATE_AMOUNT as string
    ) || 1000

export const TRANSFER_OPTIONS_WITH_VALUES = [
    {
        value: 'MANAGED_BY_EVANS_CYCLES',
        label: 'Managed by Evans Cycles',
    },
    {
        value: 'BENEFIT_IN_KIND_P11D',
        label: 'Benefit in Kind (P11d)',
    },
    {
        value: 'FAIR_MARKET_VALUE_AT_END_OF_HIRE_TERM',
        label: 'Fair market value at end of hire term',
    },
    {
        value: 'EMPLOYER_EXTENDS_HIRE_TERM',
        label: 'Employer extends hire term',
    },
    {
        value: 'T4U',
        label: 'T4U',
    },
]

export const HIRE_AGREEMENT_OPTIONS = ['ONLINE', 'MANUAL']

export const SALARY_SACRIFICE_DEDUCTION_FREQUENCY = [
    'MONTHLY',
    'WEEKLY',
    'FORTNIGHTLY',
    'FOURWEEKLY',
]

export const NOTIFICATION_OPTIONS_WITH_VALUES = [
    {
        value: 'UPON_REQUEST',
        label: 'Upon Request',
    },
    {
        value: 'DAILY',
        label: 'Daily',
    },
    {
        value: 'WEEKLY',
        label: 'Weekly',
    },
    {
        value: 'MONTHLY',
        label: 'Monthly',
    },
]

export const MAX_ELIGIBLE_EMPLOYEE_NUMBER = 2147483647

export const MAX_LENGTH_DESCRIPTION_TEXT = 300

export const OLD_PATHS = [
    'get-certificate-step1',
    'get-certificate-step2',
    'get-certificate-step3',
    'get-certificate-step4',
    'get-certificate-step5',
]

export const MINIMUM_YEAR_OF_BIRTH = 1900
