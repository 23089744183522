import { FC } from 'react'
import {
    Box,
    IconButton,
    Container,
    List,
    useTheme,
    useMediaQuery,
    Button as MuiButton,
    Typography,
} from '@mui/material'
import logo from 'assets/Evans_logo.svg'
import { Menu } from '@mui/icons-material'
import SchemeAdminSidebar from './SchemeAdminSidebar'
import useStore from 'store/Store'
import useAuth from 'auth/useAuth'
import EvansNavLink from './EvansNavLink'

const hamburgerMenuStyle = {
    scale: '1.3',
    color: 'primary.contrastText',
}

const navbarInner = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '1rem',
}

const SchemeAdminHeader: FC = () => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('md'))
    const { isSchemeAdmin, handleSchemeAdminNavigationState } = useStore()
    const { logout } = useAuth()

    const evansAdminNavigationStyle = {
        backgroundColor: 'primary.main',
        height: '100px',
        color: 'secondary.main',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        left: 0,
        width: '100%',
        right: 0,
        zIndex: theme.zIndex.appBar,
        position: 'relative',
        padding: 0,
    }

    return (
        <>
            <Box sx={evansAdminNavigationStyle}>
                <Container maxWidth="lg" sx={navbarInner}>
                    <a href="https://evanscycles.com">
                        <img src={logo} height="33px" width="145px" />
                    </a>
                    {!matches && isSchemeAdmin && (
                        <List sx={navbarInner}>
                            <EvansNavLink
                                to="ride-to-work-apply/schemeadmin/companies"
                                text="Home"
                            />
                            <EvansNavLink
                                to="ride-to-work-apply/schemeadmin/settings"
                                text="Settings"
                            />

                            <li>
                                <MuiButton
                                    onClick={() => logout()}
                                    size="medium"
                                    variant="text"
                                    sx={{ margin: 0, padding: 0 }}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            color: 'primary.contrastText',
                                            textTransform: 'capitalize',
                                            fontSize: '1.2rem',
                                            borderRadius: 0,
                                            borderBottom:
                                                '4px solid transparent',
                                            padding: '0.2rem 0.3rem',
                                            margin: 0,
                                            transition: 'all 0.15s ease-in-out',
                                            ':hover': {
                                                color: 'secondary.main',
                                                borderBottom: `4px solid ${theme.palette.secondary.main}`,
                                            },
                                        }}
                                    >
                                        Logout
                                    </Typography>
                                </MuiButton>
                            </li>
                        </List>
                    )}

                    {matches && isSchemeAdmin && (
                        <IconButton
                            onClick={() =>
                                handleSchemeAdminNavigationState(true)
                            }
                            sx={hamburgerMenuStyle}
                        >
                            <Menu />
                        </IconButton>
                    )}
                </Container>
                {matches && <SchemeAdminSidebar />}
            </Box>
        </>
    )
}

export default SchemeAdminHeader
