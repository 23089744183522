export const DialogTitleStyle = {
    backgroundColor: 'primary.main',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'primary.contrastText',
}

export const DialogContentStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1rem',
}

export const dialogCloseIconStyle = {
    color: 'primary.contrastText',
}
