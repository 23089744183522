import { Typography, Box, Divider, Paper } from '@mui/material'
import { FC, useState } from 'react'
import { AddCircleOutline } from '@mui/icons-material'
import Modal from 'components/Modal/Modal'
import ButtonForm from './ButtonForm'
import ButtonsList from './ButtonsList'
import { IButtonProps } from 'Interfaces/CMS'

interface ButtonsSectionProps {
    buttons: IButtonProps[]
    onButtonsChange: React.Dispatch<React.SetStateAction<IButtonProps[]>>
}

const defaultButton = {
    name: '',
    color: 'PRIMARY',
    text: 'Click Me',
    link: 'https://evanscycles.com',
    width: '12',
}

const ButtonsSection: FC<ButtonsSectionProps> = ({
    buttons,
    onButtonsChange,
}) => {
    const [editButtonDetails, setEditButtonDetails] =
        useState<IButtonProps | null>(null)

    const handleSaveButton = (button: IButtonProps) => {
        if (button.id) {
            const newButtons = buttons?.map((b) =>
                b.id === button.id ? button : b
            )

            onButtonsChange(newButtons)
        } else {
            onButtonsChange([...buttons, button])
        }

        setEditButtonDetails(null)
    }

    const handleDeleteButton = (button: IButtonProps) => {
        onButtonsChange(buttons.filter((b) => b !== button))
    }

    return (
        <Paper>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding={2}
            >
                <Typography variant="h6" color="primary">
                    Buttons
                </Typography>
                <AddCircleOutline
                    color="primary"
                    sx={{ cursor: 'pointer', transform: 'scale(1.2)' }}
                    onClick={() => {
                        setEditButtonDetails(defaultButton)
                    }}
                />
            </Box>
            <Divider />
            <ButtonsList
                buttons={buttons}
                onEditClick={setEditButtonDetails}
                onDeleteClick={handleDeleteButton}
            />

            {editButtonDetails && (
                <Modal
                    isOpen
                    title={editButtonDetails.id ? 'Edit button' : 'Add button'}
                    onClose={() => setEditButtonDetails(null)}
                >
                    <ButtonForm
                        onSave={handleSaveButton}
                        initialValues={editButtonDetails}
                    />
                </Modal>
            )}
        </Paper>
    )
}
export default ButtonsSection
