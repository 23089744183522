import { FC, useEffect, useState } from 'react'
import Modal, { IProps as ModalProps } from 'components/Modal/Modal'
import { Box, Typography } from '@mui/material'
import useAuth from 'auth/useAuth'
import LoadingSpinner from 'components/LoadingSpinner'
import ErrorMessage from 'components/ErrorMessage'

interface IProps extends ModalProps {
    email: string
    setSuccessMessage: (message: string) => void
}

const ResetPasswordModal: FC<IProps> = ({
    email,
    setSuccessMessage,
    ...rest
}) => {
    const [isLoading, setIsLoading] = useState(true)

    const { resetPassword, resetPasswordError } = useAuth()

    useEffect(() => {
        if (rest.isOpen) {
            resetPassword(
                email,
                () => setIsLoading(false),
                () => setIsLoading(false)
            )
        }
    }, [rest.isOpen])

    return (
        <Modal {...rest}>
            <Box
                padding="1rem"
                paddingTop="2rem"
                display="flex"
                flexDirection="column"
                gap="1rem"
            >
                {isLoading && <LoadingSpinner />}

                {resetPasswordError && (
                    <ErrorMessage text={resetPasswordError} />
                )}

                {!isLoading && !resetPasswordError && (
                    <>
                        <Typography fontWeight="bold">
                            Welcome on the new version of Ride-to-Work. Before
                            you go further, you have to reset your password.
                        </Typography>
                        <Typography fontWeight="bold">
                            Now, check your email. Remember to look in your spam
                            folder, where automated messages sometimes filter.
                        </Typography>
                    </>
                )}
            </Box>
        </Modal>
    )
}

export default ResetPasswordModal
