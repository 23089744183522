/* eslint-disable @typescript-eslint/no-explicit-any */

type NoNulls<T> = {
    [key in keyof T]: T[key] extends null ? string : T[key]
}

function replaceNullWithEmptyString<T extends Record<string, any>>(
    obj: T
): NoNulls<T> {
    const newObj = {} as any

    for (const [key, value] of Object.entries(obj)) {
        if (value === null) {
            newObj[key as keyof T] = ''
        } else if (typeof value === 'object') {
            newObj[key as keyof T] = replaceNullWithEmptyString(value as any)
        } else {
            newObj[key as keyof T] = value
        }
    }

    return newObj
}

export default replaceNullWithEmptyString
