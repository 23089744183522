import { FC } from 'react'
import { FileDownload } from '@mui/icons-material'
import Modal from 'components/Modal/Modal'
import CompaniesFiltering from './components/CompaniesFiltering'
import Button from 'components/Button'
import useEvansAdminCompaniesPage from './useEvansAdminCompaniesPage'
import Table from 'components/Table'
import { Typography } from '@mui/material'
import usePageTitle from 'hooks/usePageTitle'
import LoadingSpinner from 'components/LoadingSpinner'
import replaceNullWithEmptyString from 'utils/replaceNullWithEmptyString'

const EvansAdminCompaniesPage: FC = () => {
    usePageTitle('Companies')
    const {
        columns,
        filters,
        isFilteringModalOpen,
        handleFilteringModalState,
        applyFilters,
        shouldDisableFilterButton,
        clearFilters,
        isFetching,
        data,
        convertCompanyData,
        handlePageChange,
        exportCompaniesToCsv,
        isLoading,
        isActionsLoading,
    } = useEvansAdminCompaniesPage()
    const { page, size, ...rest } = filters

    if (isLoading || isActionsLoading) {
        return <LoadingSpinner />
    }

    return (
        <div>
            <Typography color="primary" variant="h4" mt={2}>
                Manage Companies
            </Typography>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '1rem',
                    marginBottom: '.5rem',
                    marginTop: '1rem',
                }}
            >
                <div style={{ display: 'inherit', gap: 'inherit' }}>
                    <Button
                        text="Clear Filters"
                        onClick={clearFilters}
                        disabled={shouldDisableFilterButton()}
                        colorPrimary
                    />
                    <Button
                        text="Apply Filters"
                        onClick={handleFilteringModalState}
                    />
                </div>

                <FileDownload
                    onClick={() => {
                        exportCompaniesToCsv.mutate()
                    }}
                    color="primary"
                    sx={{ cursor: 'pointer', transform: 'scale(1.2)' }}
                />
            </div>

            <Modal
                title="Filter Companies"
                isOpen={isFilteringModalOpen}
                onClose={handleFilteringModalState}
            >
                <CompaniesFiltering
                    filteringValues={replaceNullWithEmptyString(rest)}
                    applyFilters={applyFilters}
                    closeModal={handleFilteringModalState}
                />
            </Modal>
            <Table
                pageSize={filters.size}
                onPageChange={handlePageChange}
                currentPage={filters.page + 1}
                rows={data ? convertCompanyData() : []}
                columns={columns}
                loading={isFetching}
                paginate
                totalElementsLength={data?.data.totalElements}
            />
        </div>
    )
}

export default EvansAdminCompaniesPage
