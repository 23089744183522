import {
    getSchemeAdminDetails,
    updateSchemeAdminDetails,
    updateEmailAddress,
} from 'api/schemeAdmin'
import { useQuery, useMutation } from 'react-query'
import { ISchemeAdminDetails } from 'Interfaces/Admin'
import { HOW_DID_YOU_HEAR_OPTIONS_WITH_VALUES } from 'utils/constants'
import useStore from 'store/Store'
import { AxiosError } from 'axios'
import { FormikProps } from 'formik'
import { RefObject } from 'react'

const useEvansAdminDetailsPage = (
    formikRef: RefObject<FormikProps<ISchemeAdminDetails>>,
    emailFormRef: RefObject<
        FormikProps<{ emailAddress: string; confirmEmailAddress: string }>
    >
) => {
    const {
        data: response,
        isFetching,
        error,
    } = useQuery('getSchemeAdminDetails', () => getSchemeAdminDetails())

    const { openNotification } = useStore()

    const initialValues = {
        ...response?.data,
        howDidYouHearOption: HOW_DID_YOU_HEAR_OPTIONS_WITH_VALUES.find(
            (element) => {
                if (response?.data.howDidYouHearOption === element.value) {
                    return element
                }
            }
        )?.label,
    }

    const emailFormValues = {
        emailAddress: response?.data.emailAddress,
        confirmEmailAddress: response?.data.emailAddress,
    }

    const mutation = useMutation(
        (data: ISchemeAdminDetails) => updateSchemeAdminDetails(data),
        {
            onSuccess: () => {
                openNotification('Your data has been updated', 'success')
            },
            onError: (
                error: AxiosError<{
                    message: string
                    fieldErrors: Record<string, string>
                }>
            ) => {
                openNotification(
                    error?.response?.data.message as string,
                    'error'
                )
                formikRef.current?.setStatus({
                    ...error.response?.data.fieldErrors,
                })
            },
        }
    )

    const updateEmailMutation = useMutation(
        (data: { emailAddress: string; confirmEmailAddress: string }) =>
            updateEmailAddress(data),
        {
            onSuccess: () => {
                openNotification('Your email has been updated', 'success')
            },
            onError: (
                error: AxiosError<{
                    message: string
                    fieldErrors: Record<string, string>
                }>
            ) => {
                openNotification(
                    error?.response?.data.message as string,
                    'error'
                )
                emailFormRef.current?.setStatus({
                    ...error.response?.data.fieldErrors,
                })
            },
        }
    )

    function submitHandler(values: ISchemeAdminDetails): void {
        mutation.mutate(values)
    }

    return {
        initialValues,
        submitHandler,
        response,
        isFetching,
        error,
        mutation,
        updateEmailMutation,
        emailFormValues,
    }
}

export default useEvansAdminDetailsPage
