import { FC, useState, useEffect } from 'react'
import { Box } from '@mui/system'
import { getCompanyNamesList } from 'api/public'
import Autocomplete from 'components/Autocomplete'
import { useQuery } from 'react-query'
import Button from 'components/Button'
import { AxiosResponse, AxiosError } from 'axios'
import { IOption } from 'Interfaces/Certificate'
import TagManager from 'react-gtm-module'

interface IProps {
    setIsOpen: () => void
    setNavisionNumber: (name: string) => void
}

const ForgotAccountNumberModal: FC<IProps> = ({
    setIsOpen,
    setNavisionNumber,
}) => {
    const [inputValue, setInputValue] = useState('')
    const [chosenOption, setChosenOption] = useState<IOption | null>(null)
    const [shouldRequest, setShouldRequest] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const { data: res } = useQuery<
        AxiosResponse,
        AxiosError<{ message: string }>
    >(
        ['getCompanyNamesList', chosenOption?.label],
        () => getCompanyNamesList(chosenOption?.label || inputValue),
        {
            enabled: shouldRequest,
            onError: (error) => {
                setErrorMessage(error.response?.data.message as string)
            },
        }
    )

    useEffect(() => {
        return () => setShouldRequest(false)
    })

    function handleInputChange(value: string) {
        setInputValue(value)
        setShouldRequest(true)
    }

    function handleValueChange(value: IOption | null) {
        setChosenOption(value)
        setErrorMessage('')
    }

    function pushDoNotKnowAccountNumberEvent() {
        if (chosenOption && chosenOption?.value !== '') {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'doNotknowAccountNumber',
                    title: 'Account Number',
                    employer: chosenOption?.additionalData.name,
                    employerType: chosenOption?.additionalData.type,
                    employerNumber: chosenOption?.value,
                },
            })
        }
    }

    return (
        <Box paddingTop="2rem" paddingBottom="1rem">
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    setNavisionNumber(chosenOption?.value || '')
                    pushDoNotKnowAccountNumberEvent()
                    setChosenOption(null)
                    setInputValue('')
                    setIsOpen()
                }}
            >
                <Autocomplete
                    error={errorMessage}
                    onInputChange={handleInputChange}
                    onValueChange={handleValueChange}
                    value={chosenOption}
                    inputValue={inputValue}
                    values={
                        res?.data.map(
                            (info: {
                                companyName: string
                                navisionNumber: string
                                applicationType: string
                            }) => ({
                                label: `${info.companyName} (${info.navisionNumber})`,
                                value: info.navisionNumber,
                                additionalData: {
                                    name: info.companyName,
                                    type: info.applicationType,
                                },
                            })
                        ) || []
                    }
                    width="100%"
                    label="Enter company name"
                />
                <Box marginTop="1rem">
                    <Button
                        submit
                        disabled={!inputValue}
                        text="Select"
                        fullWidth
                    />
                </Box>
            </form>
        </Box>
    )
}

export default ForgotAccountNumberModal
