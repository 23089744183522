import { FC, useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Formik, FormikProps } from 'formik'
import { Grid, Typography, Box } from '@mui/material'
import { loginSchemaValidator } from 'utils/ValidationSchema'
import { loginBoxStyle, loginBoxTitleStyle } from './AdminLoginPageStyles'
import ForgotPasswordModal from 'pages/SchemeAdmin/components/ForgotPassword/ForgotPasswordModal'
import Input from 'components/Input'
import Button from 'components/Button'
import useAuth from 'auth/useAuth'
import CustomContentBox from 'pages/EvansAdmin/EvansAdminCMSPage/components/CustomContentBox'
import { useQuery } from 'react-query'
import { getContentElement, confirmRegistration } from 'api/public'
import usePageTitle from 'hooks/usePageTitle'
import { useSearchParams } from 'react-router-dom'
import LoadingSpinner from 'components/LoadingSpinner'
import ErrorMessage from 'components/ErrorMessage'
import { AxiosError } from 'axios'
import ResetPasswordModal from './ResetPasswordModal'
import SuccessMessage from 'components/SuccessMessage'
import ChangePasswordModal from './ChangePasswordModal'

const signUpToRTWElementID = 3

const AdminLoginPage: FC = () => {
    usePageTitle('Scheme Administrator Login')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
        useState(false)
    const [resetPasswordSuccessMessage, setResetPasswordSuccessMessage] =
        useState('')
    const [isPasswordResetModalOpen, setIsPasswordResetModalOpen] =
        useState(false)
    const [resendConfirmationSuccess, setResendConfirmationSuccess] =
        useState(false)
    const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(false)
    const [isResendError, setIsResendError] = useState(false)
    const resetPasswordMessagePattern = /Password reset required for the user/i
    const changePasswordMessagePattern =
        /callback.newPasswordRequired is not a function/i
    const [
        confirmRegistrationSuccessMessage,
        setConfirmRegistrationSuccessMessage,
    ] = useState('')
    const {
        login,
        isLoading,
        loginSchemeAdminError,
        setLoginSchemeAdminError,
        userRoleError,
        resendConfirmationMail,
        isConfirmationResendLinkLoading,
    } = useAuth()

    const initialValues = {
        email: process.env.REACT_APP_SCHEME_ADMIN_EMAIL as string,
        password: process.env.REACT_APP_SCHEME_ADMIN_PASSWORD as string,
    }

    const formRef =
        useRef<FormikProps<{ email: string; password: string }>>(null)
    const [searchParams] = useSearchParams()
    const uid = searchParams.get('uid') as string
    const code = searchParams.get('code') as string

    const isAbleToConfirmRegistration =
        searchParams.get('uid') && searchParams.get('code')

    function submitHandler(values: { email: string; password: string }) {
        const { email, password } = values
        login('Scheme-admin', email, password, () => {
            setIsChangePasswordModalOpen(true)
        })
    }

    function openModalHandler() {
        setIsModalOpen((prevState: boolean) => !prevState)
    }

    useEffect(() => {
        if (
            loginSchemeAdminError &&
            loginSchemeAdminError.match(resetPasswordMessagePattern)
        ) {
            setIsPasswordResetModalOpen(true)
        }
    }, [loginSchemeAdminError])

    const {
        isLoading: isConfirmationResponseLoading,
        error: confirmationError,
    } = useQuery<unknown, AxiosError<{ message: string }>>(
        'confirmSchemeAdminAccount',
        () => confirmRegistration({ uid, code }),

        {
            enabled: Boolean(isAbleToConfirmRegistration),
            retry: false,
            onSuccess: () => {
                setConfirmRegistrationSuccessMessage(
                    'Your account has been confirmed. You can now login'
                )
            },
        }
    )

    const {
        data: signUpToRTWElementResponse,
        isFetching: signUpToRTWElementFetching,
    } = useQuery(['getSignUpToRTWElement'], () =>
        getContentElement(signUpToRTWElementID)
    )

    if (isConfirmationResponseLoading) {
        return <LoadingSpinner />
    }

    function resendConfirmationLink() {
        const mail = searchParams.get('mail') as string
        resendConfirmationMail(
            mail,
            () => {
                setResendConfirmationSuccess(true)
                setIsResendButtonDisabled(true)
                setIsResendError(false)
            },
            () => {
                setIsResendError(true)
            }
        )
            .then(() => {
                //do nothing, handled above
            })
            .catch(() => {
                //do nothing, handled above
            })
    }

    function displayConfirmationError() {
        let message = confirmationError?.response?.data.message as string
        const errorWords = message.split(' ')
        const isCodeExpired =
            errorWords.includes('Confirmation') &&
            errorWords.includes('code') &&
            errorWords.includes('expired')

        if (isCodeExpired)
            message =
                'Your confirmation link has expired, click the button below to resend a link.'

        return (
            <Box mb={2} display="flex" flexDirection="column" gap="1rem">
                <ErrorMessage text={message} />
                {isCodeExpired && (
                    <Button
                        isLoading={isConfirmationResendLinkLoading}
                        disabled={isResendButtonDisabled}
                        text="Resend confirmation link"
                        onClick={resendConfirmationLink}
                    />
                )}
            </Box>
        )
    }

    function displayConfirmationSuccess() {
        return (
            <Box mb={2}>
                <SuccessMessage text={confirmRegistrationSuccessMessage} />
            </Box>
        )
    }

    function displayResetPasswordSuccess() {
        return (
            <Box mb={2}>
                <SuccessMessage text={resetPasswordSuccessMessage} />
            </Box>
        )
    }

    function displayResendConfirmationSuccess() {
        return (
            <Box mb={2}>
                <SuccessMessage
                    text="We have resend you confirmation link, now check your email. Remember to look in your spam folder,
                where automated messages sometimes filter."
                />
            </Box>
        )
    }

    return (
        <>
            {isResendError && (
                <Box mb={2}>
                    <ErrorMessage text="Sending confirmation link failed, try again later." />
                </Box>
            )}
            {confirmRegistrationSuccessMessage && displayConfirmationSuccess()}
            {!resendConfirmationSuccess &&
                confirmationError &&
                displayConfirmationError()}
            {resetPasswordSuccessMessage && displayResetPasswordSuccess()}
            {resendConfirmationSuccess && displayResendConfirmationSuccess()}
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <Formik
                        innerRef={formRef}
                        onSubmit={(values) => submitHandler(values)}
                        initialValues={initialValues}
                        validationSchema={loginSchemaValidator}
                    >
                        {(formik) => (
                            <form onSubmit={formik.handleSubmit}>
                                <Box sx={loginBoxStyle}>
                                    <Typography
                                        sx={loginBoxTitleStyle}
                                        variant="h5"
                                        color="primary"
                                    >
                                        Existing Employer Login
                                    </Typography>
                                    {loginSchemeAdminError &&
                                        !loginSchemeAdminError.match(
                                            resetPasswordMessagePattern
                                        ) &&
                                        !loginSchemeAdminError.match(
                                            changePasswordMessagePattern
                                        ) && (
                                            <Typography color="error">
                                                {loginSchemeAdminError}
                                            </Typography>
                                        )}
                                    {userRoleError && (
                                        <Typography color="error">
                                            {userRoleError}
                                        </Typography>
                                    )}
                                    <Input name="email" label="Email" />
                                    <Input
                                        name="password"
                                        label="Password"
                                        type="password"
                                        passwordToggle
                                    />
                                    <Button
                                        isLoading={isLoading}
                                        text="Sign In"
                                        submit
                                    />
                                    <Typography>
                                        <Link onClick={openModalHandler} to="#">
                                            Forgotten password?
                                        </Link>
                                    </Typography>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Grid>
                <Grid
                    md={6}
                    item
                    display="flex"
                    flexDirection="column"
                    gap="1rem"
                    padding="1.3rem"
                >
                    {!signUpToRTWElementFetching &&
                        signUpToRTWElementResponse && (
                            <CustomContentBox
                                contentElement={signUpToRTWElementResponse.data}
                            />
                        )}
                </Grid>
            </Grid>
            {isModalOpen && (
                <ForgotPasswordModal
                    title="Forgot password"
                    isOpen={isModalOpen}
                    setIsOpen={openModalHandler}
                />
            )}

            <ResetPasswordModal
                setSuccessMessage={(message: string) =>
                    setResetPasswordSuccessMessage(message)
                }
                email={formRef.current?.values.email as string}
                title="Password Reset"
                isOpen={isPasswordResetModalOpen}
                onClose={() => {
                    setIsPasswordResetModalOpen(false)
                    setLoginSchemeAdminError('')
                }}
            />

            <ChangePasswordModal
                email={formRef?.current?.values.email as string}
                password={formRef?.current?.values.password as string}
                title="Change Password"
                isOpen={isChangePasswordModalOpen}
                onClose={() => {
                    setLoginSchemeAdminError('')
                    setIsChangePasswordModalOpen(false)
                }}
            />
        </>
    )
}

export default AdminLoginPage
