import { FC, useRef } from 'react'
import { Box, Grid, Typography, Divider } from '@mui/material'
import Input from 'components/Input'
import { Formik, FormikProps } from 'formik'
import { updateCompanyDetails } from 'api/evansAdmin'
import { useMutation } from 'react-query'
import LoadingSpinner from 'components/LoadingSpinner'
import { updateCompanyDetailsSchemaValidator } from 'utils/ValidationSchema'
import Button from 'components/Button'
import { ICompanyDetailsUpdate } from 'Interfaces/Company'
import useStore from 'store/Store'
import { useParams } from 'react-router-dom'
import LoqateSearch from 'components/Loqate/LoqateSearch'
import ErrorMessage from 'components/ErrorMessage'
import { AxiosError } from 'axios'
import replaceNullWithEmptyString from 'utils/replaceNullWithEmptyString'

interface IProps {
    data: ICompanyDetailsUpdate
    isFetching: boolean
}

const CompanyDetailsForm: FC<IProps> = ({ data, isFetching }) => {
    const { companyId } = useParams()
    const formRef = useRef<FormikProps<ICompanyDetailsUpdate>>(null)
    const { openNotification } = useStore()

    const mutation = useMutation(
        (data: ICompanyDetailsUpdate) =>
            updateCompanyDetails(companyId as string, data),
        {
            onSuccess: () => {
                openNotification('Company data has been saved', 'success')
            },

            onError: (
                error: AxiosError<{
                    message: string
                    fieldErrors: Record<string, string>
                }>
            ) => {
                formRef.current?.setStatus({
                    ...error.response?.data.fieldErrors,
                })
            },
        }
    )

    async function submitHandler(values: ICompanyDetailsUpdate) {
        mutation.mutate(values)
    }

    if (isFetching) {
        return <LoadingSpinner />
    }

    return (
        <Formik
            innerRef={formRef}
            initialValues={replaceNullWithEmptyString(data)}
            onSubmit={(values) => submitHandler(values)}
            validationSchema={updateCompanyDetailsSchemaValidator}
        >
            {(formik) => (
                <Grid
                    container
                    rowSpacing={4}
                    columnSpacing={2}
                    sx={{ marginTop: '.5rem' }}
                >
                    {mutation.isError && (
                        <Grid item xs={12}>
                            <ErrorMessage
                                text={
                                    mutation?.error?.response?.data
                                        .message as string
                                }
                            />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Typography
                            variant="h6"
                            color="primary"
                            fontWeight="bold"
                        >
                            Company Details
                        </Typography>
                        <Divider
                            sx={{
                                borderBottomWidth: '1.5px',
                                borderColor: 'primary.main',
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Input
                            apiErrors={formik.status}
                            fullWidth
                            name="navisionNumber"
                            label="Nav Company No"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            apiErrors={formik.status}
                            fullWidth
                            name="companyName"
                            label="Company Name"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            apiErrors={formik.status}
                            fullWidth
                            name="tradingName"
                            label="Trading Name"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            apiErrors={formik.status}
                            name="companyVatNumber"
                            label="Vat Number"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <LoqateSearch
                                label="Address Finder (required for identification)"
                                formik={formik}
                                addressFieldsNames={{
                                    addressLine1: 'address.addressLine1',
                                    addressLine2: 'address.addressLine2',
                                    town: 'address.town',
                                    county: 'address.county',
                                    postcode: 'address.postcode',
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            apiErrors={formik.status}
                            name="address.addressLine2"
                            fullWidth
                            label="Address Line 2"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            apiErrors={formik.status}
                            name="address.town"
                            label="Town"
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            name="address.county"
                            label="County"
                            fullWidth
                            apiErrors={formik.status}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            apiErrors={formik.status}
                            name="address.postcode"
                            label="Postcode"
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            apiErrors={formik.status}
                            name="eligibleEmployeesNumber"
                            label="Number of staff"
                            fullWidth
                        />
                    </Grid>
                    {formik.values.eligibleEmployeesNumber >= 200 && (
                        <Grid item xs={12} md={6}>
                            <Input
                                apiErrors={formik.status}
                                required
                                label="Registration Number"
                                name="companyRegistrationNumber"
                                fullWidth
                            />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Typography
                            variant="h6"
                            color="primary"
                            fontWeight="bold"
                        >
                            Company Descriptive Text
                        </Typography>
                        <Divider
                            sx={{
                                borderBottomWidth: '1.5px',
                                borderColor: 'primary.main',
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Input
                            apiErrors={formik.status}
                            textarea
                            name="descriptiveText"
                            fullWidth
                            rows={4}
                            width="100%"
                            lengthLimit={300}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider
                            sx={{
                                borderBottomWidth: '1.5px',
                                borderColor: 'secondary.main',
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            isLoading={mutation.isLoading}
                            text="Save"
                            grid
                            md={5}
                            fullWidth
                            onClick={formik.handleSubmit}
                        />
                    </Grid>
                </Grid>
            )}
        </Formik>
    )
}

export default CompanyDetailsForm
