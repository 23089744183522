import { FC, useState } from 'react'
import { Formik } from 'formik'
import {
    Paper,
    Box,
    InputLabel,
    Typography,
    Grid,
    Stack,
    Link,
} from '@mui/material'
import Button from 'components/Button'
import Input from 'components/Input'
import Modal from 'components/Modal/Modal'
import { theme } from 'utils/Theme'
import {
    Frequency,
    calculateGrossAndNet,
    calculateTotals,
    createCalculatorResults,
} from './calculate'
import { fields, getOutput } from './consts'
import EmailForm from './EmailForm'
import OutputRow from './OutputRow'
import { savingsCalculatorFormValidator } from 'utils/ValidationSchema'
import CustomTooltip from 'components/CustomTooltip'

export interface IOutput {
    label: string
    value?: number
    subheader?: boolean
    type?: 'currency' | 'percent'
}

export interface ICalculatorResults {
    fortnightlyGrossValue: string
    fourweeklyGrossValue: string
    monthlyGrossValue: string
    weeklyGrossValue: string
    fortnightlyNetValue: string
    fourweeklyNetValue: string
    monthlyNetValue: string
    weeklyNetValue: string
    totalCostOfHireValue: string
    totalSavingPercent: string
    totalSavingValue: string
}

const SavingsCalculatorPage: FC = () => {
    const [modalOpen, setModalOpen] = useState(false)
    const [calculatorResults, setCalculatorResults] = useState<
        ICalculatorResults | undefined
    >()

    return (
        <Paper>
            <Formik
                initialValues={{
                    annualSalary: 12501,
                    totalValue: 1000,
                    payFrequency: 'monthly' as Frequency,
                    term: 12,
                }}
                validationSchema={savingsCalculatorFormValidator}
                onSubmit={() => {
                    //do nothing, everything is happening onChange so far
                }}
            >
                {({ values }) => {
                    const calculatedGrossAndNet = calculateGrossAndNet(values)
                    const calculatedTotals = calculateTotals(
                        values.annualSalary,
                        values.totalValue
                    )

                    const output = getOutput(values.term, {
                        ...calculatedGrossAndNet,
                        ...calculatedTotals,
                    })
                    return (
                        <Grid container padding={2}>
                            <Grid item sm={12} md={5}>
                                {fields.map((field) => {
                                    const options = field.options?.map(
                                        (opt) => ({
                                            label: opt,
                                            value: opt
                                                .toString()
                                                .toLocaleLowerCase(),
                                        })
                                    )

                                    return (
                                        <Box key={field.name} marginBottom={1}>
                                            <InputLabel htmlFor={field.name}>
                                                <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    alignItems="center"
                                                >
                                                    <Typography>
                                                        {field.label}
                                                    </Typography>
                                                    <CustomTooltip
                                                        text={field.tooltip}
                                                    />
                                                </Box>
                                            </InputLabel>
                                            <Input
                                                width="100%"
                                                name={field.name}
                                                select={Boolean(field.options)}
                                                optionsWithValues={options}
                                                type={field.type || 'text'}
                                                thousandSeparator={
                                                    field.type === 'number'
                                                }
                                                prefix={
                                                    field.type === 'number'
                                                        ? '£'
                                                        : ''
                                                }
                                            />
                                        </Box>
                                    )
                                })}
                            </Grid>
                            <Grid
                                item
                                sm={12}
                                md={7}
                                sx={{
                                    paddingLeft: { xs: 0, md: 2 },
                                    paddingTop: { xs: 2, md: 0 },
                                }}
                                display="flex"
                                flexDirection="column"
                                justifyContent="space-between"
                            >
                                <Box
                                    border={`1px solid ${theme.palette.grey[300]}`}
                                >
                                    {output.map((row, i) => (
                                        <OutputRow
                                            key={row.label}
                                            output={row as IOutput}
                                            isLast={i === output.length - 1}
                                        />
                                    ))}
                                </Box>
                                <Stack spacing={1} marginTop={3}>
                                    <Button
                                        text="Apply for certificate"
                                        to="/ride-to-work-apply/certificate/get-certificate"
                                        rtwButton
                                    />
                                    <Grid container textAlign="center">
                                        <Grid item xs={6}>
                                            <Link
                                                component="button"
                                                onClick={() => {
                                                    const calculatedResults =
                                                        createCalculatorResults(
                                                            values
                                                        )
                                                    setCalculatorResults(
                                                        calculatedResults
                                                    )
                                                    setModalOpen(true)
                                                }}
                                            >
                                                <Typography>
                                                    Email me my savings
                                                </Typography>
                                            </Link>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography fontSize="1rem">
                                                <Link href="https://help.evanscycles.com/en/support/home#folder_80000443633">
                                                    Terms & conditions
                                                </Link>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Grid>
                        </Grid>
                    )
                }}
            </Formik>
            <Modal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                title="Contact details"
            >
                <EmailForm
                    calculatorResults={calculatorResults}
                    close={() => setModalOpen(false)}
                />
            </Modal>
        </Paper>
    )
}

export default SavingsCalculatorPage
