import { FC, useState, useRef, useEffect } from 'react'
import { Typography, Box } from '@mui/material'
import FormStepper from 'components/FormStepper'
import { Formik, FormikProps } from 'formik'
import Step1 from './Components/Step1'
import Step2 from './Components/Step2'
import Step3 from './Components/Step3'
import Step4 from './Components/Step4'
import Step5 from './Components/Step5'
import {
    rideToWorkCertificateValidator,
    t4uCertificateValidator,
} from 'utils/ValidationSchema'
import {
    getCompanyByNavisionNumber,
    getCurrentStepInCertificateProcess,
    applyT4UCertificate,
} from 'api/public'
import { ICompanyData } from 'Interfaces/Company'
import { IT4UCertificateData } from 'Interfaces/Certificate'
import {
    createCertificateProcess,
    updateCertificateProcessStatus,
    getT4UCertificate,
} from 'api/public'
import { useMutation, useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { CERTIFICATE_APPLY_ACTIONS } from 'utils/constants'
import usePageTitle from 'hooks/usePageTitle'
import { useSearchParams } from 'react-router-dom'
import LoadingSpinner from 'components/LoadingSpinner'
import { useNavigate } from 'react-router-dom'
import useStore from 'store/Store'
import ReCAPTCHA from 'react-google-recaptcha'
import { addYears, isAfter } from 'date-fns'
import TagManager from 'react-gtm-module'
import _ from 'lodash'

const RideToWorkCertificatePage: FC = () => {
    usePageTitle('Get Your Certificate')
    const [activeStep, setActiveStep] = useState(0)
    const [stepLabel, setStepLabel] = useState('')
    const [certificateId, setCertificateId] = useState('')
    const [salarySacrificeError, setSalarySacrificeError] = useState('')
    const [hireAgreementError, setHireAgreementError] = useState('')
    const [certificateApplyError, setCertificateApplyError] = useState('')
    const [searchParams] = useSearchParams()
    const formRef = useRef<FormikProps<IForm>>(null)
    const recaptchaRef = useRef<ReCAPTCHA>(null)
    const [addressErrorModal, setAddressErrorModal] = useState(false)

    function getUuid() {
        const uuid = searchParams.get('UUID') as string | null
        if (uuid?.includes('?')) {
            return uuid.substring(0, uuid.indexOf('?'))
        } else {
            return uuid as string
        }
    }

    const UUID = getUuid()
    const agreementId = searchParams.get('agreementId') as string
    const [isT4UCertificate, setIsT4UCertificate] = useState(
        false || Boolean(agreementId)
    )

    const [T4UCertificateData, setT4UCertificateData] =
        useState<IT4UCertificateData>()
    const navigate = useNavigate()
    const { openNotification } = useStore()

    const [isUnder18, setIsUnder18] = useState(false)
    const [companyData, setCompanyData] = useState<ICompanyData>({
        companyName: '',
        tradingName: '',
        companyVatNumber: 0,
        eligibleEmployeesNumber: 0,
        companyRegistrationNumber: '',
        ownershipOption: '',
        salarySacrificeTermInMonths: 0,
        hireAgreementTermInMonths: 0,
        minCertificateAmount: 0,
        maxCertificateAmount: 0,
        id: 0,
        navisionNumber: '',
        descriptiveText: '',
        isSalarySacrifice: true,
        requirePayrollNumber: false,
        applicationType: '',
        salarySacrificeDeductionFrequency: '',
        logoURL: null,
    })

    const initialValues = {
        employeeForm: {
            title: '',
            firstName: '',
            lastName: '',
            primaryEmailAddress: '',
            confirmPrimaryEmailAddress: '',
            secondaryEmailAddress: '',
            primaryTelephoneNumber: '',
            mobileNumber: '',
            addressLine1: '',
            addressLine2: '',
            companyTown: '',
            companyCounty: '',
            companyPostcode: '',
        },
        minCertificateAmount: 0,
        maxCertificateAmount: 0,
        certificateAmount: 0,
        dateOfBirth: '',
        newsletterSignUp: false,
        companyNavisionNumber: '',
        payrollEmployeeNumber: '',
        requiredPayroll: false,
        'g-recaptcha-response': '',
    }

    type IForm = typeof initialValues

    useEffect(() => {
        if (agreementId) {
            stepNavigator('STEP_2')
        }
    }, [])

    useEffect(() => {
        if (formRef.current) {
            setIsUnder18(
                isAfter(
                    addYears(new Date(formRef.current.values.dateOfBirth), 18),
                    new Date()
                )
            )
        }
    }, [formRef.current?.values.dateOfBirth])

    useEffect(() => {
        pushDataLayerEvent('pageView', CERTIFICATE_FORM_STEPS[activeStep])
    }, [activeStep])

    useEffect(() => {
        if (T4UCertificateData && isT4UCertificate) {
            formRef.current?.setFieldValue(
                'employeeForm.title',
                T4UCertificateData.employee.title
            )

            formRef.current?.setFieldValue(
                'employeeForm.firstName',
                T4UCertificateData.employee.firstName
            )

            formRef.current?.setFieldValue(
                'employeeForm.lastName',
                T4UCertificateData.employee.lastName
            )

            formRef.current?.setFieldValue(
                'employeeForm.primaryEmailAddress',
                T4UCertificateData.employee.workEmail
            )

            formRef.current?.setFieldValue(
                'employeeForm.mobileNumber',
                T4UCertificateData.employee.mobileNumber
            )

            formRef.current?.setFieldValue(
                'employeeForm.addressLine1',
                T4UCertificateData.employee.address.addressLine1
            )
            formRef.current?.setFieldValue(
                'employeeForm.companyPostcode',
                T4UCertificateData.employee.address.postcode
            )

            formRef.current?.setFieldValue(
                'employeeForm.addressLine2',
                T4UCertificateData.employee.address.addressLine2
            )

            formRef.current?.setFieldValue(
                'employeeForm.companyTown',
                T4UCertificateData.employee.address.town
            )

            formRef.current?.setFieldValue(
                'dateOfBirth',
                T4UCertificateData.employee.dateOfBirth
            )

            formRef.current?.setFieldValue(
                'employeeForm.companyCounty',
                T4UCertificateData.employee.address.county
            )
        }
    }, [T4UCertificateData])

    function pushDataLayerEvent(eventName: string, title: string) {
        const dataLayer = {
            event: eventName,
            title: title,
            employer: companyData.companyName,
            employerType: isUnder18 ? 'CHILD' : companyData.applicationType,
            employerNumber: companyData.navisionNumber,
        }
        TagManager.dataLayer({
            dataLayer: _.omitBy(dataLayer, (v) => _.isNil(v) || v === ''),
        })
    }

    function resetToStep0() {
        setActiveStep(0)
        formRef.current?.setValues({ ...initialValues })
        formRef.current?.setStatus({})
        setCertificateApplyError('')
        setCompanyData({
            companyName: '',
            tradingName: '',
            companyVatNumber: 0,
            eligibleEmployeesNumber: 0,
            companyRegistrationNumber: '',
            ownershipOption: '',
            salarySacrificeTermInMonths: 0,
            hireAgreementTermInMonths: 0,
            minCertificateAmount: 0,
            maxCertificateAmount: 0,
            id: 0,
            navisionNumber: '',
            descriptiveText: '',
            isSalarySacrifice: true,
            requirePayrollNumber: false,
            applicationType: '',
            salarySacrificeDeductionFrequency: '',
        })
    }

    const CERTIFICATE_FORM_STEPS = [
        'Account Number',
        'Personal Details',
        !companyData.isSalarySacrifice || isT4UCertificate
            ? 'Pre Contract'
            : 'Salary Sacrifice',
        'Hire Agreement',
        'Confirmation',
    ]

    function updateStep(step: string) {
        setStepLabel(step)
    }

    const getT4UAgreement = useQuery(
        'getT4UAgreement',
        () => getT4UCertificate((UUID as string) || agreementId),
        {
            enabled: isT4UCertificate && activeStep == 1,
            onSuccess: (response: AxiosResponse) => {
                setT4UCertificateData(response.data)
            },
            onError: (error: AxiosError<{ message: string }>) => {
                openNotification(
                    error?.response?.data.message || 'Something went wrong ',
                    'error'
                )
                navigate('ride-to-work-apply/certificate/get-certificate')
            },
        }
    )

    const companyNumberSearchMutation = useMutation(
        () =>
            getCompanyByNavisionNumber(
                formRef.current?.values.companyNavisionNumber.trim() as string
            ),
        {
            onSuccess: (response) => {
                setCompanyData(response.data)
                formRef.current?.setFieldValue(
                    'companyNavisionNumber',
                    formRef.current?.values.companyNavisionNumber.trim()
                )
                formRef.current?.setFieldValue(
                    'minCertificateAmount',
                    response.data.minCertificateAmount
                )

                formRef.current?.setFieldValue(
                    'requiredPayroll',
                    response.data.requirePayrollNumber
                )

                formRef.current?.setFieldValue(
                    'maxCertificateAmount',
                    response.data.maxCertificateAmount
                )
                setActiveStep(1)
                formRef?.current?.setTouched({})
            },
            onError: (error: AxiosError<{ message: string }>) => {
                formRef.current?.setFieldError(
                    'companyNavisionNumber',
                    error?.response?.data.message
                )
            },
        }
    )

    const getCurrentStepForm = useQuery(
        'getCurrentStep',
        () => getCurrentStepInCertificateProcess(UUID),
        {
            enabled: Boolean(UUID),
            onSuccess: (response: AxiosResponse) => {
                setIsT4UCertificate(response?.data.isT4uProcess)
                setCertificateId(UUID)
                stepNavigator(response?.data.nextStep)
                setCompanyData({
                    ...companyData,
                    companyName: response?.data.companyName,
                    navisionNumber: response?.data.navisionNumber,
                    applicationType: response?.data.applicationType,
                })
                setIsUnder18(
                    isAfter(
                        addYears(
                            new Date(response?.data.employeeDateOfBirth),
                            18
                        ),
                        new Date()
                    )
                )
            },
            onError: () => {
                setIsT4UCertificate(false)
                setCertificateId('')
                navigate('ride-to-work-apply/certificate/get-certificate')
            },
        }
    )

    const certificateApplyMutation = useMutation(
        () => {
            const {
                minCertificateAmount,
                maxCertificateAmount,
                requiredPayroll,
                ...values
            } = formRef.current?.values as IForm
            return createCertificateProcess(values)
        },
        {
            onSuccess: (response: AxiosResponse) => {
                setCertificateId(response.data?.processUUID)
                setCertificateApplyError('')
                stepNavigator(response.data?.nextStep)
            },
            onError: (
                error: AxiosError<{
                    message: string
                    fieldErrors: Record<string, unknown>
                }>
            ) => {
                setAddressErrorModal(false)
                recaptchaRef?.current?.reset()
                setCertificateApplyError(error.response?.data.message as string)
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                })
                formRef.current?.setErrors({
                    ...error.response?.data?.fieldErrors,
                })
            },
        }
    )

    const t4uApplyMutation = useMutation(
        () => {
            const {
                minCertificateAmount,
                maxCertificateAmount,
                certificateAmount,
                requiredPayroll,
                companyNavisionNumber,
                ...values
            } = formRef.current?.values as IForm
            return applyT4UCertificate({
                ...values,
                oldAgreementUUID: (UUID as string) || agreementId,
            })
        },
        {
            onSuccess: (response: AxiosResponse) => {
                setCertificateId(response.data?.processUUID)
                setCertificateApplyError('')
                stepNavigator(response.data?.nextStep)
            },
            onError: (
                error: AxiosError<{
                    message: string
                    fieldErrors: Record<string, unknown>
                }>
            ) => {
                setAddressErrorModal(false)
                recaptchaRef?.current?.reset()
                setCertificateApplyError(error.response?.data.message as string)
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                })
                formRef.current?.setErrors({
                    ...error.response?.data?.fieldErrors,
                })
            },
        }
    )

    const acceptThirdStepMutation = useMutation(
        () =>
            updateCertificateProcessStatus(
                certificateId,
                CERTIFICATE_APPLY_ACTIONS.acceptThirdStep
            ),
        {
            onSuccess: () => {
                stepNavigator(stepLabel)
            },
            onError: (error: AxiosError<{ message: string }>) =>
                setSalarySacrificeError(
                    error?.response?.data.message || 'Something went wrong'
                ),
        }
    )

    const acceptHireAgreementMutation = useMutation(
        () =>
            updateCertificateProcessStatus(
                certificateId,
                CERTIFICATE_APPLY_ACTIONS.acceptHire
            ),
        {
            onSuccess: () => {
                pushDataLayerEvent(
                    'applicationComplete',
                    CERTIFICATE_FORM_STEPS[4]
                )
                stepNavigator(stepLabel)
            },
            onError: (error: AxiosError<{ message: string }>) =>
                setHireAgreementError(
                    error?.response?.data.message || 'Something went wrong'
                ),
        }
    )

    const cancelCertificateApplicationMutation = useMutation(
        () =>
            updateCertificateProcessStatus(
                certificateId,
                CERTIFICATE_APPLY_ACTIONS.cancel
            ),
        {
            onSuccess: () => {
                navigate('/ride-to-work-apply/certificate/agreement-declined')
            },
            onError: (error: AxiosError<{ message: string }>) => {
                openNotification(
                    error?.response?.data.message || 'Something went wrong',
                    'error'
                )
            },
        }
    )

    function cancelCertificateApplicationHandler() {
        pushDataLayerEvent(
            'cancelAgreement',
            CERTIFICATE_FORM_STEPS[activeStep]
        )
        cancelCertificateApplicationMutation.mutate()
    }

    function submitHandler() {
        switch (activeStep) {
            case 0:
                companyNumberSearchMutation.mutate()
                break
            case 1:
                isT4UCertificate
                    ? t4uApplyMutation.mutate()
                    : certificateApplyMutation.mutate()
                break
            case 2:
                acceptThirdStepMutation.mutate()
                break
            case 3:
                acceptHireAgreementMutation.mutate()
        }
    }

    function stepNavigator(stepLabel: string) {
        switch (stepLabel) {
            case 'STEP_1':
                setActiveStep(0)
                break
            case 'STEP_2':
                setActiveStep(1)
                break
            case 'STEP_3':
                setActiveStep(2)
                break
            case 'STEP_4':
                setActiveStep(3)
                break
            case 'STEP_5':
                setActiveStep(4)
        }
    }

    const container = {
        padding: '3rem 0',
        display: 'grid',
        gap: '1rem',
    }

    if (getCurrentStepForm.isFetching || getT4UAgreement.isFetching) {
        return <LoadingSpinner />
    }

    return (
        <Box sx={container}>
            <Typography
                variant="h4"
                textAlign={'center'}
                color="primary"
                fontWeight={700}
                mb={3}
            >
                Apply for your Ride-to-Work certificate
            </Typography>

            <FormStepper
                activeStep={activeStep}
                options={CERTIFICATE_FORM_STEPS}
            />
            <Formik
                innerRef={formRef}
                validationSchema={
                    isT4UCertificate
                        ? t4uCertificateValidator
                        : rideToWorkCertificateValidator[activeStep]
                }
                onSubmit={submitHandler}
                initialValues={initialValues}
            >
                {() => (
                    <>
                        {activeStep === 0 && (
                            <Step1
                                isLoading={
                                    companyNumberSearchMutation.isLoading
                                }
                            />
                        )}
                        {activeStep === 1 && (
                            <Step2
                                setAddressErrorModal={setAddressErrorModal}
                                addressErrorModal={addressErrorModal}
                                isT4U={isT4UCertificate}
                                isLoading={
                                    certificateApplyMutation.isLoading ||
                                    t4uApplyMutation.isLoading
                                }
                                t4uIsDataFetching={getT4UAgreement.isFetching}
                                T4UCertificateData={
                                    T4UCertificateData as IT4UCertificateData
                                }
                                companyData={companyData}
                                resetToStep0={resetToStep0}
                                error={certificateApplyError}
                                recaptchaRef={recaptchaRef}
                                pushDataLayerEvent={pushDataLayerEvent}
                            />
                        )}
                        {activeStep === 2 && (
                            <Step3
                                updateStep={updateStep}
                                descriptiveText={companyData.descriptiveText}
                                isLoadingData={
                                    acceptThirdStepMutation.isLoading
                                }
                                id={certificateId}
                                error={salarySacrificeError}
                                cancelCertificateApplication={
                                    cancelCertificateApplicationHandler
                                }
                                isCancelAgreementButtonLoading={
                                    cancelCertificateApplicationMutation.isLoading
                                }
                            />
                        )}
                        {activeStep === 3 && (
                            <Step4
                                updateStep={updateStep}
                                descriptiveText={companyData.descriptiveText}
                                isLoadingData={
                                    acceptHireAgreementMutation.isLoading
                                }
                                isCancelAgreementButtonLoading={
                                    cancelCertificateApplicationMutation.isLoading
                                }
                                id={certificateId}
                                error={hireAgreementError}
                                cancelCertificateApplication={
                                    cancelCertificateApplicationHandler
                                }
                            />
                        )}
                        {activeStep === 4 && <Step5 />}
                    </>
                )}
            </Formik>
        </Box>
    )
}

export default RideToWorkCertificatePage
