import React, { FC } from 'react'
import {
    DataGrid,
    GridColDef,
    GridRowParams,
    GridRowsProp,
    GridSelectionModel,
} from '@mui/x-data-grid'
import { Pagination } from '@mui/material'

interface IProps {
    rows: GridRowsProp
    columns: GridColDef[]
    loading: boolean
    error?: boolean
    pageSize?: number
    numberOfPages?: number
    onPageChange?: (a: React.ChangeEvent<unknown>, b: number) => void
    currentPage?: number
    paginate?: boolean
    totalElementsLength?: number
    disableSelect?: boolean
    checkBox?: boolean
    onSelect?: (certificatesId: GridSelectionModel) => void
    columnsVisibility?: Record<string, boolean>
    height?: string
    isRowSelectable?: (params: GridRowParams) => boolean
}

const Table: FC<IProps> = ({
    rows,
    columns,
    loading,
    error,
    pageSize,
    numberOfPages,
    onPageChange,
    currentPage,
    paginate,
    totalElementsLength,
    checkBox,
    disableSelect,
    onSelect,
    columnsVisibility,
    height,
    isRowSelectable,
}) => {
    const pagination = () => (
        <Pagination
            page={currentPage}
            count={
                numberOfPages ||
                Math.ceil((totalElementsLength as number) / (pageSize || 10)) ||
                0
            }
            color="primary"
            onChange={onPageChange}
        />
    )

    return (
        <DataGrid
            rows={rows || []}
            columns={columns}
            disableSelectionOnClick={disableSelect}
            loading={loading}
            error={error}
            rowsPerPageOptions={[pageSize || 10]}
            pagination
            columnVisibilityModel={columnsVisibility}
            pageSize={pageSize || 10}
            disableColumnFilter
            sortingMode="server"
            isRowSelectable={isRowSelectable}
            onSelectionModelChange={onSelect}
            checkboxSelection={checkBox}
            components={
                paginate
                    ? {
                          Pagination: pagination,
                      }
                    : {}
            }
            sx={{
                height: height || '700px',
                marginTop: '1rem',
                '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText',
                },
            }}
        />
    )
}

export default Table
