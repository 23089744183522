import { FC, useState } from 'react'
import {
    Button,
    Typography,
    Box,
    Grid,
    Container,
    useMediaQuery,
    Theme,
} from '@mui/material'
import Input from 'components/Input'
import ForgotAccountNumberModal from 'Dialogs/ForgotAccountNumberModal'
import { useFormikContext } from 'formik'
import Modal from 'components/Modal/Modal'
import CustomTooltip from 'components/CustomTooltip'

interface IProps {
    isLoading: boolean
}

const Step1: FC<IProps> = ({ isLoading }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const formik = useFormikContext()
    const isMobileResolution = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    )

    function setNavisionNumber(name: string) {
        formik.setFieldValue('companyNavisionNumber', name)
    }

    function handleModalState() {
        setIsModalOpen((prevState: boolean) => !prevState)
    }

    return (
        <>
            <Container maxWidth="md" sx={{ marginTop: '3rem' }}>
                <Grid container>
                    <Grid item md={8} xs={12}>
                        <Input
                            maxWidth="800px"
                            fullWidth
                            name="companyNavisionNumber"
                            label="Account number"
                            required
                            attachedButton="right"
                        />
                    </Grid>

                    <Grid item md={4} xs={12}>
                        <Button
                            disableElevation
                            color="secondary"
                            variant="contained"
                            fullWidth
                            disabled={isLoading}
                            onClick={() => formik.handleSubmit()}
                            sx={{
                                marginTop: isMobileResolution ? '1rem' : '',
                                fontWeight: 'bold',
                                height: !isMobileResolution
                                    ? '3.5rem'
                                    : '2.6rem',
                                width: '100%',
                                borderRadius: `0 4px 4px 0`,
                            }}
                        >
                            {isLoading ? 'Loading...' : 'Apply now'}
                        </Button>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '1rem',
                        flexWrap: 'wrap',
                        gap: '1.5rem',
                    }}
                >
                    <Typography
                        color="primary"
                        fontWeight="bold"
                        sx={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleModalState()}
                    >
                        Don&apos;t know your account number?
                    </Typography>
                    <Box display="flex" gap=".2rem" alignItems="center">
                        <Typography variant="body2">
                            Where can I get one?{' '}
                        </Typography>
                        <CustomTooltip
                            text="You can get the account number by clicking 
                on the 'Don't know your account
                 number' or speak to your scheme administrator."
                        />
                    </Box>
                </Box>
            </Container>

            <Modal
                height="500px"
                isOpen={isModalOpen}
                onClose={handleModalState}
                title="Search for Navision Number"
            >
                <ForgotAccountNumberModal
                    setNavisionNumber={setNavisionNumber}
                    setIsOpen={handleModalState}
                />
            </Modal>
        </>
    )
}

export default Step1
