import { FC, useState } from 'react'
import {
    TextField,
    MenuItem,
    FormControlLabel,
    Checkbox,
    FormControl,
    FormLabel,
    Radio,
    RadioGroup,
    InputLabel,
    InputAdornment,
    IconButton,
    Box,
} from '@mui/material'
import { useField } from 'formik'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import NumericFormatCustom from './NumericFormatCustom'
import CustomTooltip from './CustomTooltip'
import { format } from 'date-fns'

interface IProps {
    name: string
    label?: string
    type?: string
    options?: Array<string | number>
    optionsWithValues?: Array<{
        label: string | number
        value: string | number
    }>
    select?: boolean
    maxWidth?: string
    fullWidth?: boolean
    required?: boolean
    width?: string
    apiErrors?: Record<string, string>
    checkBox?: boolean
    radio?: boolean
    radioOptions?: Array<{ label: string; value: string }>
    showLabelComponent?: boolean
    textarea?: boolean
    rows?: number
    placeholder?: string
    radioRow?: boolean
    lengthLimit?: number
    suffix?: string
    passwordToggle?: boolean
    disablePasting?: boolean
    thousandSeparator?: boolean
    prefix?: string
    attachedButton?: 'left' | 'right'
    tooltip?: string | JSX.Element
}

const Input: FC<IProps> = ({
    name,
    label,
    type,
    options,
    optionsWithValues,
    select,
    fullWidth,
    maxWidth,
    required,
    width,
    apiErrors,
    checkBox,
    radio,
    radioOptions,
    showLabelComponent,
    textarea,
    rows,
    placeholder,
    radioRow,
    lengthLimit,
    suffix,
    passwordToggle,
    disablePasting = false,
    thousandSeparator,
    prefix,
    attachedButton,
    tooltip,
}) => {
    const [field, meta] = useField(name)
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const maxWidthInput =
        type === 'number' ? '300px' : select ? '400px' : maxWidth || '600px'

    let apiErrorMessage = ''

    if (apiErrors && apiErrors[name]) {
        apiErrorMessage = apiErrors[name]
    }

    function removeApiError() {
        if (apiErrors && apiErrors[name]) {
            apiErrors[name] = ''
            apiErrorMessage = ''
        }
    }

    const error = (meta.touched && meta.error) || apiErrorMessage
    const roundedOnRight = `0 4px 4px 0`
    const roundedOnLeft = `4px 0 0 4px`

    function handleInputType() {
        if (passwordToggle) {
            return isPasswordVisible ? 'text' : 'password'
        }

        // handled with NumericFormatCustomComponent
        if (type === 'number') return 'text'

        return type || 'text'
    }

    const endAdornment =
        suffix || passwordToggle ? (
            <InputAdornment position="start">
                {passwordToggle && (
                    <IconButton
                        onClick={() =>
                            setIsPasswordVisible(
                                (prevState: boolean) => !prevState
                            )
                        }
                    >
                        {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                )}
                {suffix}
            </InputAdornment>
        ) : (
            <></>
        )

    const textField = (
        <TextField
            rows={rows}
            multiline={textarea}
            fullWidth={fullWidth}
            select={select}
            required={required}
            placeholder={placeholder}
            onPaste={(e) => {
                if (disablePasting) {
                    e.preventDefault()
                }
            }}
            InputLabelProps={{
                shrink: true,
                sx: {
                    color: 'black',
                    '& .MuiFormLabel-asterisk': {
                        color: '#FF0000',
                        fontSize: '1.2rem',
                    },
                },
            }}
            label={!showLabelComponent && label}
            error={Boolean(error)}
            helperText={
                error ||
                (lengthLimit && `${field.value?.length || 0}/${lengthLimit}`)
            }
            {...field}
            onKeyDown={() => removeApiError()}
            inputProps={{
                ...(prefix && { prefix }),
                ...(prefix === '£' && { decimals: true }),
                ...(thousandSeparator && { thousandSeparator }),
                ...(type === 'date' && {
                    max: format(new Date(), 'yyyy-MM-dd'),
                }),
            }}
            InputProps={{
                sx: {
                    backgroundColor: 'primary.contrastText',
                    borderRadius:
                        attachedButton === 'right'
                            ? roundedOnLeft
                            : attachedButton === 'left'
                            ? roundedOnRight
                            : '4px',
                },
                ...(type === 'number' && {
                    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
                    inputComponent: NumericFormatCustom as any,
                }),
                endAdornment,
            }}
            type={handleInputType()}
            sx={{
                maxWidth: maxWidth || maxWidthInput,
                width: width,
            }}
        >
            {(options || optionsWithValues) && (
                <MenuItem value="" key="null" sx={{ visibility: 'hidden' }} />
            )}
            {options?.map((element: string | number) => (
                <MenuItem value={element} key={element}>
                    {element}
                </MenuItem>
            ))}
            {optionsWithValues?.map(({ label, value }) => (
                <MenuItem value={value} key={value}>
                    {label}
                </MenuItem>
            ))}
        </TextField>
    )

    if (checkBox) {
        return (
            <FormControlLabel
                label={label}
                {...field}
                control={<Checkbox checked={Boolean(meta.value)} />}
            />
        )
    }

    if (radio) {
        const radioMeta = {
            value: meta.value,
            error: meta.error,
            initialtouched: meta.initialTouched.toString(),
            initialvalue: meta.initialValue,
            initialerror: meta.initialError,
            touched: meta.touched.toString(),
        }
        return (
            <FormControl>
                <FormLabel
                    id="demo-radio-buttons-group-label"
                    sx={{
                        marginBottom: '1rem',
                        color: 'primary.main',
                    }}
                >
                    {label}
                </FormLabel>
                <RadioGroup {...field} {...radioMeta} row={radioRow}>
                    {radioOptions?.map(
                        (element: { label: string; value: string }) => (
                            <FormControlLabel
                                sx={{ marginLeft: '1rem' }}
                                key={element.value}
                                control={
                                    <Radio
                                        value={element.value}
                                        checked={Boolean(
                                            meta.value === element.value
                                        )}
                                    />
                                }
                                label={element.label}
                            />
                        )
                    )}
                </RadioGroup>
            </FormControl>
        )
    }

    return (
        <>
            {showLabelComponent && (
                <InputLabel
                    sx={{
                        marginBottom: '.2rem',
                        color: 'black',
                        fontWeight: '500',
                    }}
                    color="primary"
                >
                    {label}
                </InputLabel>
            )}
            {textField}
            {tooltip && (
                <Box paddingLeft="1rem" paddingTop="16px">
                    <CustomTooltip text={tooltip} />
                </Box>
            )}
        </>
    )
}

export default Input
