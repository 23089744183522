export const loginBoxStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    border: '2px solid',
    padding: '4rem',
    background: '#efefef',
    borderColor: 'primary.main',
    textAlign: 'center',
}

export const loginBoxTitleStyle = {
    borderBottom: '3px solid',
    borderColor: 'secondary.main',
    paddingBottom: '.3rem',
}

export const loginBoxButtonStyle = {
    color: 'primary.contrastText',
    fontWeight: 'bold',
}

export const inputLoginBoxStyle = {
    backgroundColor: 'primary.contrastText',
}
