import { FC } from 'react'
import { Paper, Typography } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'

interface IProps {
    email: string | undefined
}

const Step2: FC<IProps> = ({ email }) => {
    return (
        <Paper
            elevation={1}
            sx={{
                display: 'grid',
                placeItems: 'center',
                padding: '2rem',
                gap: '1rem',
            }}
        >
            <CheckCircle color="success" sx={{ fontSize: '5rem' }} />
            <Typography variant="h4">Success</Typography>
            <Typography variant="h6">
                We have sent email to <strong>{email}</strong> to confirm the
                validity of your email address. <br /> After receiving the email
                follow the link provided to complete your registration.
            </Typography>
        </Paper>
    )
}

export default Step2
