import React, { FC } from 'react'
import { DateTimePicker } from '@mui/x-date-pickers'
import { TextField } from '@mui/material'
import { useField } from 'formik'

interface IProps {
    name: string
    label: string
    disabled?: boolean
    apiErrors?: Record<string, string>
    required?: boolean
    value: string
    onChange: (value: string | null) => void
    fullWidth?: boolean
    maxWidth?: string
    onBlur: () => void
}

const DateTimeInput: FC<IProps> = ({
    name,
    label,
    disabled,
    apiErrors,
    required,
    value,
    onChange,
    fullWidth,
    maxWidth,
    onBlur,
}) => {
    let apiErrorMessage = ''

    if (apiErrors && apiErrors[name]) {
        apiErrorMessage = apiErrors[name]
    }

    function removeApiError() {
        if (apiErrors && apiErrors[name]) {
            apiErrors[name] = ''
            apiErrorMessage = ''
        }
    }

    const [, field] = useField(name)

    const isError = Boolean((field.touched && field.error) || apiErrorMessage)

    return (
        <DateTimePicker
            value={value}
            inputFormat="yyyy-MM-dd HH:mm"
            onChange={(value) => {
                onChange(value)
                removeApiError()
            }}
            disabled={disabled}
            ampm={false}
            renderInput={(params) => (
                <TextField
                    {...params}
                    helperText={
                        field.touched && (field.error || apiErrorMessage)
                    }
                    label={label}
                    fullWidth={fullWidth}
                    onBlur={onBlur}
                    error={isError}
                    sx={{
                        maxWidth: maxWidth,
                    }}
                    required={required}
                    InputLabelProps={{
                        shrink: true,
                        sx: {
                            color: 'black',
                            '& .MuiFormLabel-asterisk': {
                                color: '#FF0000',
                                fontSize: '1.2rem',
                            },
                        },
                    }}
                />
            )}
        />
    )
}

export default DateTimeInput
