import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Divider } from '@mui/material'
import { useQuery } from 'react-query'
import { performCompanyAction } from 'api/evansAdmin'
import { getCompanyStatus } from 'api/evansAdmin'
import LoadingSpinner from 'components/LoadingSpinner'
import UpdateCompanyAction from './UpdateCompanyAction'
import AssignTemplate from './AssignTemplate'
import Modal from 'components/Modal/Modal'
import usePageTitle from 'hooks/usePageTitle'
import { useMutation } from 'react-query'
import useStore from 'store/Store'
import { AxiosError } from 'axios'

const CompanySettings: FC = () => {
    usePageTitle('Company Settings')
    const { openNotification } = useStore()

    const { companyId } = useParams()
    const [isAssignTemplateModalOpen, setIsAssignTemplateModalOpen] =
        useState(false)
    const {
        data: response,
        isFetching,
        refetch,
    } = useQuery('getCompanyStatus', () =>
        getCompanyStatus(companyId as string)
    )

    const { companyStatus } = response?.data || {}

    const isDisableButtonDisabled = companyStatus !== 'ACTIVE'

    const activateAction =
        companyStatus === 'DISABLED'
            ? 'RE_ACTIVATE'
            : companyStatus === 'APPROVED'
            ? 'ACTIVATE'
            : ''

    const responseHandler = {
        onSuccess: () => {
            openNotification('Company status has been changed', 'success')
            refetch()
        },
        onError: (error: AxiosError<{ message: string }>) => {
            openNotification(error.response?.data.message as string, 'error')
        },
    }

    const activateMutation = useMutation(
        () => performCompanyAction(companyId as string, activateAction),
        responseHandler
    )

    const disableMutation = useMutation(
        () => performCompanyAction(companyId as string, 'DISABLE'),
        responseHandler
    )

    if (isFetching) {
        return <LoadingSpinner />
    }

    return (
        <>
            <Modal
                title="Assign Template To Company"
                isOpen={isAssignTemplateModalOpen}
                onClose={() => setIsAssignTemplateModalOpen(false)}
            >
                <AssignTemplate
                    companyId={companyId as string}
                    successCallback={() => setIsAssignTemplateModalOpen(false)}
                />
            </Modal>
            <Box mt={4} display="flex" flexDirection="column" gap="2rem">
                <UpdateCompanyAction
                    title="Activate Company"
                    paragraph="Click here to activate your company."
                    buttonText="Activate"
                    isButtonDisabled={!activateAction}
                    mutation={activateMutation}
                />
                <Divider />

                <UpdateCompanyAction
                    buttonErrorColor
                    title="Disable Company"
                    paragraph="Click here to disable your company."
                    buttonText="Disable"
                    isButtonDisabled={isDisableButtonDisabled}
                    mutation={disableMutation}
                />
                <Divider />
                <UpdateCompanyAction
                    title="Assign Template"
                    paragraph="Here you can assign template to company."
                    buttonText="Assign Template"
                    onClick={() => setIsAssignTemplateModalOpen(true)}
                />
            </Box>
        </>
    )
}

export default CompanySettings
