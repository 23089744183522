interface IOutputValues {
    grossDeduction: number
    netDeduction: number
    totalSavingPercent: number
    totalSavingValue: number
    totalCostOfHire: number
}

export const getOutput = (term: number, outputValues: IOutputValues) => {
    return [
        {
            label: 'Total cost of hire through salary sacrifice',
            value: outputValues.totalCostOfHire,
            subheader: true,
            type: 'currency',
        },
        {
            label: 'Total savings £',
            value: outputValues.totalSavingValue,
            type: 'currency',
        },
        {
            label: 'Total savings %',
            value: outputValues.totalSavingPercent,
            type: 'percent',
        },
        {
            label: `Salary deduction over ${term} months`,
            subheader: true,
        },
        {
            label: 'Gross',
            value: outputValues.grossDeduction,
            type: 'currency',
        },
        { label: 'Net', value: outputValues.netDeduction, type: 'currency' },
    ]
}

export const fields = [
    {
        label: 'Your annual salary',
        tooltip: 'Enter your annual gross pre-tax salary',
        name: 'annualSalary',
        type: 'number',
    },
    {
        label: 'The total value of your bike and/or accessories',
        tooltip:
            'Enter the total value of your bike/accessories/components (you can buy just one of these or all 3)',
        name: 'totalValue',
        type: 'number',
    },
    {
        label: 'Pay Frequency',
        tooltip: 'Ride-to-Work pay frequency',
        name: 'payFrequency',
        options: ['Monthly', 'Weekly', 'Fortnightly', 'Fourweekly'],
    },
    {
        label: 'Salary sacrifice term (months)',
        tooltip:
            'Select the number of months you would like your repayment terms to be through your salary sacrifice. Please note that not all terms are applicable, please check with your Scheme Administrator on what terms you are signed up for',
        name: 'term',
        options: [12, 24, 36, 48, 60, 72],
    },
]
