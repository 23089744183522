import { FC, useState } from 'react'
import { Formik } from 'formik'
import useStore from 'store/Store'
import useAuth from 'auth/useAuth'
import { adminChangePasswordSchemaValidator } from 'utils/ValidationSchema'
import usePageTitle from 'hooks/usePageTitle'
import Button from 'components/Button'
import Input from 'components/Input'
import { Grid, Typography, Divider } from '@mui/material'
import ErrorMessage from 'components/ErrorMessage'

const EvansAdminSettingsPage: FC = () => {
    usePageTitle('Evans Admin Settings')
    const { changePassword } = useAuth()
    const { openNotification } = useStore()
    const [errorMessage, setErrorMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const initialValues = {
        currentPassword: '',
        newPassword: '',
        checkNewPassword: '',
    }

    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    function submitHandler(values: typeof initialValues, resetForm: any) {
        const { currentPassword, newPassword } = values
        setIsLoading(true)
        changePassword(currentPassword, newPassword, (err, success) => {
            if (err) {
                setErrorMessage(err.message)
            }

            if (success) {
                setErrorMessage('')
                openNotification(
                    'Password has been changed successfully',
                    'success'
                )
                resetForm()
            }

            setIsLoading(false)
        })
    }

    return (
        <>
            <Typography variant="h4" color="primary" mt={2} mb={2}>
                Account Details
            </Typography>

            <Typography
                variant="body2"
                sx={{ lineHeight: '1.8', fontWeight: '600' }}
                mt={1}
                mb={3}
            >
                You can change the password to your Ride-to-Work scheme below.
                Please remember passwords must be: 8 characters or more <br />{' '}
                and include 1 UPPERCASE character, 1 lowercase character, 1
                special character, 1 numeric character.
            </Typography>
            <Formik
                validationSchema={adminChangePasswordSchemaValidator}
                initialValues={initialValues}
                onSubmit={(values, { resetForm }) =>
                    submitHandler(values, resetForm)
                }
            >
                {(formik) => (
                    <Grid container gap={4}>
                        {errorMessage && (
                            <Grid item xs={12}>
                                <ErrorMessage text={errorMessage} />
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <Typography
                                color="primary"
                                variant="body1"
                                fontWeight="bold"
                            >
                                Enter your current password
                            </Typography>
                            <Divider
                                sx={{
                                    color: 'primary',
                                    borderBottomWidth: '2px',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                name="currentPassword"
                                label="Current Password"
                                type="password"
                                fullWidth
                                passwordToggle
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography
                                color="primary"
                                variant="body1"
                                fontWeight="bold"
                            >
                                Now enter your new password
                            </Typography>
                            <Divider
                                sx={{
                                    color: 'primary',
                                    borderBottomWidth: '2px',
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Input
                                name="newPassword"
                                label="New Password"
                                type="password"
                                passwordToggle
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                name="checkNewPassword"
                                label="Confirm New Password"
                                fullWidth
                                type="password"
                                passwordToggle
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                text="Save password"
                                onClick={formik.handleSubmit}
                                isLoading={isLoading}
                            />
                        </Grid>
                    </Grid>
                )}
            </Formik>
        </>
    )
}

export default EvansAdminSettingsPage
