import { FC } from 'react'
import { Lock } from '@mui/icons-material'
import {
    Divider,
    Typography,
    Box,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import { Container } from '@mui/system'
import { useLocation } from 'react-router-dom'

const styles = {
    textAlign: 'center',
    padding: '1rem',
    display: 'grid',
    gap: '1rem',
    minHeight: '30px',
}

const Footer: FC = () => {
    const location = useLocation()
    const theme = useTheme()
    const isMobileView = useMediaQuery(theme.breakpoints.down('md'))

    if (
        location.pathname.includes('evans-admin') ||
        location.pathname.includes('savings-calculator')
    ) {
        return null
    }

    if (location.pathname.includes('schemeadmin')) {
        return (
            <Box
                sx={{
                    backgroundColor: '#312828',
                    ...styles,
                    padding: '2rem',
                }}
            >
                <Container
                    maxWidth="lg"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: isMobileView ? 'column' : 'row',
                        justifyContent: isMobileView
                            ? 'center'
                            : 'space-between',
                        gap: '1rem',
                    }}
                >
                    <Typography color="primary.contrastText" variant="caption">
                        © 2023 Evans Cycles All Rights Reserved
                    </Typography>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '.5rem',
                        }}
                    >
                        <Lock
                            sx={{
                                color: 'primary.contrastText',
                                transform: 'scale(0.6)',
                            }}
                        />
                        <Typography
                            color="primary.contrastText"
                            variant="caption"
                        >
                            Evanscycles.com is secure and your details are
                            protected
                        </Typography>
                    </div>
                </Container>
            </Box>
        )
    }

    return (
        <Container maxWidth="lg" sx={styles}>
            <Typography variant="caption">
                Evans Cycles Limited is an Appointed Representative of Consumer
                Credit Compliance Limited who are authorised and regulated by
                the Financial Conduct Authority FRN 631736. The permissions of
                Consumer Credit Compliance Limited as a Principal firm allow
                Evans Cycles Limited to undertake Consumer Hire activities.
                Evans Cycles Limited can be viewed on the FCA Register here, our
                FRN is 845262.
            </Typography>
            <Divider />
            <Typography variant="caption">
                © 2020 Evans Cycles All Rights Reserved
            </Typography>
        </Container>
    )
}

export default Footer
