import { FC, ReactNode } from 'react'
import useStore from 'store/Store'
import { Navigate } from 'react-router-dom'

interface IProps {
    children: ReactNode
}

const CmsGuard: FC<IProps> = ({ children }) => {
    const { hasCmsPermission, isEvansAdmin } = useStore()

    if (hasCmsPermission && isEvansAdmin) {
        return <>{children}</>
    }

    return <Navigate to="/ride-to-work-apply/evans-admin/companies" />
}

export default CmsGuard
