import { FormikProps } from 'formik'
import { FC } from 'react'
import AddressSearch from 'react-loqate'
import { AddressSearchInput } from './AddressSearchInput'
import { AddressSearchMenuItem } from './AddressSearchMenuItem'
import { AddressSearchMenuList } from './AddressSearchMenuList'
import { LoqateContext } from './LoqateContext'

interface AddressFieldNames {
    addressLine1: string
    addressLine2: string
    town?: string
    county?: string
    postcode?: string
}

interface LoqateSearchProps {
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    formik: FormikProps<any>
    label: string
    addressFieldsNames: AddressFieldNames
}

const LoqateSearch: FC<LoqateSearchProps> = ({
    label,
    formik,
    addressFieldsNames,
}) => {
    const setAddressLines = (line1Value: string, line2Value: string) => {
        formik.setFieldValue(addressFieldsNames.addressLine1, line1Value)
        formik.setFieldValue(addressFieldsNames.addressLine2, line2Value)
    }
    const setValues = ({
        Line1,
        Line2,
        Line3,
        City,
        ProvinceName,
        PostalCode,
        Company,
    }: {
        Line1: string
        Line2: string
        Line3: string
        City: string
        ProvinceName: string
        PostalCode: string
        Company: string
    }) => {
        if (!Company && !Line3) {
            setAddressLines(Line1, Line2)
        } else if (Company && Line3) {
            setAddressLines(`${Company}, ${Line1}`, `${Line2}, ${Line3}`)
        } else if (!Company && Line3) {
            setAddressLines(`${Line1}, ${Line2}`, Line3)
        } else if (Company && !Line2) {
            setAddressLines(Company, Line1)
        } else {
            setAddressLines(`${Company}, ${Line1}`, Line2)
        }

        if (addressFieldsNames.town)
            formik.setFieldValue(addressFieldsNames.town, City ?? '')
        if (addressFieldsNames.county)
            formik.setFieldValue(addressFieldsNames.county, ProvinceName ?? '')
        if (addressFieldsNames.postcode)
            formik.setFieldValue(addressFieldsNames.postcode, PostalCode ?? '')
    }

    return (
        <LoqateContext.Provider
            value={{
                label,
                name: addressFieldsNames.addressLine1 ?? 'Address Line 1',
            }}
        >
            <AddressSearch
                locale="en_GB"
                apiKey={process.env.REACT_APP_LOQATE_KEY ?? ''}
                countries={['GB']}
                components={{
                    Input: AddressSearchInput,
                    List: AddressSearchMenuList,
                    ListItem: AddressSearchMenuItem,
                }}
                onSelect={setValues}
                inline
                debounce={100}
            />
        </LoqateContext.Provider>
    )
}
export default LoqateSearch
