import React from 'react'
import ReactDOM from 'react-dom/client'
import TagManager from 'react-gtm-module'

import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.css'

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID as string,
    events: {
        pageView: 'pageView',
    },
}

TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
