import { FC } from 'react'
import { CloseOutlined } from '@mui/icons-material'
interface IRemoveSorting {
    sortValue: string
    currentValue: string
    setValue: (value: string | null) => void
}

const RemoveSorting: FC<IRemoveSorting> = ({
    sortValue,
    currentValue,
    setValue,
}) => {
    const sortValueWithoutParameter = sortValue?.split(',')[0]

    const isVisible = sortValueWithoutParameter === currentValue

    if (!isVisible) return null

    return (
        <CloseOutlined
            sx={{ cursor: 'pointer', transform: 'scale(0.6)' }}
            onClick={() => setValue(null)}
        />
    )
}

export default RemoveSorting
