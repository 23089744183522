import { Divider, Link, Typography } from '@mui/material'
import { FC } from 'react'

const AgreementDeclinedPage: FC = () => {
    return (
        <>
            <Divider>
                <Typography variant="h4" color="primary" fontWeight="400">
                    Agreement cancelled
                </Typography>
            </Divider>
            <Typography mt="2rem">
                Your Ride-to-Work certificate applictaion has not been
                submitted. If you wish to re-apply please submit a new
                application by clicking <Link href="get-certificate">here</Link>
            </Typography>
        </>
    )
}
export default AgreementDeclinedPage
