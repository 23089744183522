import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import Button from 'components/Button'
import { deleteTemplate } from 'api/evansAdmin'
import { useMutation } from 'react-query'
import useStore from 'store/Store'
import { AxiosError } from 'axios'

interface IProps {
    templateId: string
    closeModal: () => void
    successCallback: () => void
}

const DeleteTemplate: FC<IProps> = ({
    templateId,
    closeModal,
    successCallback,
}) => {
    const { openNotification } = useStore()

    const { mutate, isLoading } = useMutation(
        () => deleteTemplate(templateId),
        {
            onSuccess: () => {
                openNotification('Template has been deleted', 'success')
                successCallback()
                closeModal()
            },

            onError: (error: AxiosError<{ message: string }>) => {
                openNotification(
                    error.response?.data.message as string,
                    'error'
                )
            },
        }
    )

    return (
        <Box padding="2rem 1rem">
            <Typography variant="h5" mb={1} color="primary">
                Are you sure you want to delete this template?
            </Typography>

            <Box
                display="flex"
                justifyContent="center"
                gap="1rem"
                marginTop="1rem"
            >
                <Button
                    text="No, Keep it."
                    outlined
                    colorPrimary
                    onClick={closeModal}
                />
                <Button
                    error
                    text="Yes, Delete"
                    isLoading={isLoading}
                    onClick={mutate}
                />
            </Box>
        </Box>
    )
}

export default DeleteTemplate
