import {
    getActiveAndPendingCompanies,
    getAllGlobalsDocuments,
    downloadDocument,
} from 'api/schemeAdmin'
import Button from 'components/Button'
import { useQuery, useMutation } from 'react-query'
import { GridCellParams } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import useStore from 'store/Store'
import { AxiosError } from 'axios'
import fileDownload from 'js-file-download'
import { getContentElement } from 'api/public'

const helloElementID = 4
const helloBoxElementID = 5

const useSchemeAdminCompanyPage = () => {
    const navigate = useNavigate()
    const { openNotification } = useStore()
    const { data: response, isLoading } = useQuery(
        'getCompanies',
        getActiveAndPendingCompanies
    )

    const { data: documentsResponse, isLoading: isDocumentsLoading } = useQuery(
        'getGlobalDocuments',
        getAllGlobalsDocuments
    )

    const { data: helloElementResponse } = useQuery(['getHelloElement'], () =>
        getContentElement(helloElementID)
    )
    const { data: helloBoxElementResponse } = useQuery(
        ['getHelloBoxElement'],
        () => getContentElement(helloBoxElementID)
    )

    const downloadDocumentMutation = useMutation(
        (obj: { documentId: string; documentName: string }) =>
            downloadDocument(obj.documentId),
        {
            onSuccess: (response, { documentName }) => {
                fileDownload(response.data, documentName)
            },

            onError: (error: AxiosError<{ message: string }>) => {
                openNotification(
                    error?.response?.data.message || 'Something went wrong ',
                    'error'
                )
            },
        }
    )

    const columns = [
        {
            field: 'navisionNumber',
            headerName: 'Account Number',
            flex: 1,
            sortable: false,
        },
        {
            field: 'companyName',
            headerName: 'Account Name',
            flex: 1,
            sortable: false,
        },
        {
            field: 'pendingCertificatesNumber',
            headerName: 'Pending Certificates',
            flex: 1,
            sortable: false,
        },

        {
            field: 'View',
            headerName: 'View',
            width: 100,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                return (
                    <Button
                        text="View"
                        fullWidth
                        onClick={() =>
                            navigate(
                                `/ride-to-work-apply/schemeadmin/companies/${params.id}`
                            )
                        }
                    />
                )
            },
        },
    ]

    return {
        columns,
        response,
        isLoading,
        documentsResponse,
        helloElementResponse,
        helloBoxElementResponse,
        isDocumentsLoading,
        downloadDocumentMutation,
    }
}

export default useSchemeAdminCompanyPage
