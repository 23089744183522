import { FC } from 'react'
import { AddCircleOutline } from '@mui/icons-material'
import { Typography, Box } from '@mui/material'
import Button from 'components/Button'
import Modal from 'components/Modal/Modal'
import Table from 'components/Table'
import EvansAdminFilterDocumentsModal from './components/EvansAdminFilterDocumentsModal'
import useEvansAdminDocumentsPage from './useEvansAdminDocumentsPage'
import DeleteModal from '../../../components/DeleteModal'
import EvansAdminCreateDocument from './components/EvansAdminCreateDocument'
import usePageTitle from 'hooks/usePageTitle'
import replaceNullWithEmptyString from 'utils/replaceNullWithEmptyString'
import DocumentPreviewModal from './components/DocumentPreviewModal'
import { deleteDocument } from '../../../api/evansAdmin'

const EvansAdminDocumentsPage: FC = () => {
    usePageTitle('Documents')
    const {
        columns,
        isFilteringModalOpen,
        shouldDisableClearButton,
        setIsFilteringModalOpen,
        clearFilters,
        isFetching,
        response,
        filters,
        convertResponseData,
        handlePageChange,
        applyFilters,
        isDeleteDocumentModalOpen,
        setIsDeleteDocumentModalOpen,
        removedDocumentId,
        refetch,
        isAddDocumentModalOpen,
        setIsAddDocumentModalOpen,
        isPreviewModalOpen,
        setIsPreviewModalOpen,
        documentToPreview,
    } = useEvansAdminDocumentsPage()

    const { page, size, ...restFilters } = filters

    return (
        <div>
            <Typography color="primary" variant="h4" mt={2} mb={2}>
                Manage Documents
            </Typography>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <section
                    style={{
                        display: 'inherit',
                        gap: '1rem',
                        flexWrap: 'wrap',
                    }}
                >
                    <Button
                        disabled={shouldDisableClearButton()}
                        text="Clear Filters"
                        colorPrimary
                        onClick={clearFilters}
                    />
                    <Button
                        text="Apply Filters"
                        onClick={() => setIsFilteringModalOpen(true)}
                    />
                </section>
                <AddCircleOutline
                    onClick={() => setIsAddDocumentModalOpen(true)}
                    color="primary"
                    sx={{ cursor: 'pointer', transform: 'scale(1.2)' }}
                />
            </Box>
            <Modal
                isOpen={isFilteringModalOpen}
                title="Filter Documents"
                onClose={() => setIsFilteringModalOpen(false)}
            >
                <EvansAdminFilterDocumentsModal
                    filters={replaceNullWithEmptyString(restFilters)}
                    applyFilters={applyFilters}
                    closeModal={() => setIsFilteringModalOpen(false)}
                />
            </Modal>

            <Modal
                title="Delete Confirmation"
                isOpen={isDeleteDocumentModalOpen}
                onClose={() => setIsDeleteDocumentModalOpen(false)}
            >
                <DeleteModal
                    successCallback={refetch}
                    closeModal={() => setIsDeleteDocumentModalOpen(false)}
                    deleteFunction={() => deleteDocument(removedDocumentId)}
                    message="Are you sure you want to delete this document?"
                />
            </Modal>

            <Modal
                title="Add New Document"
                isOpen={isAddDocumentModalOpen}
                onClose={() => setIsAddDocumentModalOpen(false)}
            >
                <EvansAdminCreateDocument
                    successCallback={() => {
                        setIsAddDocumentModalOpen(false)
                        refetch()
                    }}
                />
            </Modal>
            <DocumentPreviewModal
                isOpen={isPreviewModalOpen}
                setIsOpen={setIsPreviewModalOpen}
                document={documentToPreview}
            />

            <Table
                disableSelect
                currentPage={filters.page + 1}
                pageSize={filters.size}
                totalElementsLength={response?.data.totalElements}
                paginate
                columns={columns}
                loading={isFetching}
                onPageChange={handlePageChange}
                rows={convertResponseData() || []}
            />
        </div>
    )
}

export default EvansAdminDocumentsPage
