import { FC } from 'react'
import Button from 'components/Button'
import { createNewDocument } from 'api/evansAdmin'
import { Formik } from 'formik'
import { useMutation } from 'react-query'
import Input from 'components/Input'
import DragDropFile from 'components/DragDropFile'
import { Box, MenuItem, Typography } from '@mui/material'
import useStore from 'store/Store'
import { AxiosError } from 'axios'
import {
    CATEGORY_INPUT_OPTIONS,
    UPLOAD_DOCUMENT_FORMATS,
} from 'utils/constants'
import SelectInput from 'components/SelectInput'
import { createDocumentValidator } from 'utils/ValidationSchema'
import { ICreateDocument } from 'Interfaces/Documents'

interface IProps {
    successCallback: () => void
}

const EvansAdminCreateDocument: FC<IProps> = ({ successCallback }) => {
    const initialValues = {
        global: false,
        description: '',
        category: '',
        file: '',
    }

    type IFormValues = typeof initialValues
    const { openNotification } = useStore()

    const addDocumentMutation = useMutation(
        (obj: ICreateDocument) => createNewDocument(obj),
        {
            onSuccess: () => {
                openNotification('Document has been added', 'success')
                successCallback()
            },

            onError: (error: AxiosError<{ message: string }>) => {
                openNotification(
                    (error?.response?.data.message as string) ||
                        'Something went wrong',
                    'error'
                )
            },
        }
    )

    function submitHandler(values: IFormValues) {
        const formData = new FormData()

        const { file, ...rest } = values
        formData.append('file', file)

        const obj = {
            documentForm: JSON.stringify(rest),
            file: formData,
        }

        addDocumentMutation.mutate(obj)
    }

    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => submitHandler(values)}
                validationSchema={createDocumentValidator}
            >
                {(formik) => (
                    <Box
                        paddingTop="2rem"
                        display="flex"
                        flexDirection="column"
                        gap="1.5rem"
                    >
                        <SelectInput
                            options={() => {
                                return CATEGORY_INPUT_OPTIONS.map(
                                    (element: string) => (
                                        <MenuItem key={element} value={element}>
                                            {element}
                                        </MenuItem>
                                    )
                                )
                            }}
                            name="category"
                            required
                            label="Category"
                        />

                        <Input
                            name="description"
                            label="Description"
                            textarea
                            required
                            rows={4}
                        />

                        <DragDropFile
                            maxSize={
                                parseInt(
                                    process.env
                                        .REACT_APP_MAXIMUM_FILE_SIZE as string
                                ) || 10
                            }
                            label="Add Your Document"
                            fileTypes={UPLOAD_DOCUMENT_FORMATS}
                            isLoading={false}
                            name="file"
                            onChange={(file: File) =>
                                formik.setFieldValue('file', file)
                            }
                        />
                        <div>
                            <Typography variant="body1" fontWeight="bold">
                                Allowed Formats:{' '}
                                {UPLOAD_DOCUMENT_FORMATS.map(
                                    (element: string) => element.toUpperCase()
                                ).join(', ')}
                            </Typography>
                        </div>
                        <Input
                            checkBox
                            name="global"
                            label="Is Document Global"
                        />
                        <Button
                            disabled={!formik.values.file}
                            text="Submit"
                            onClick={formik.handleSubmit}
                            isLoading={addDocumentMutation.isLoading}
                        />
                    </Box>
                )}
            </Formik>
        </div>
    )
}

export default EvansAdminCreateDocument
