import { FC } from 'react'
import Button from 'components/Button'
import { Formik } from 'formik'
import Input from 'components/Input'
import { Box } from '@mui/material'
import { IDocumentsFiltersWithoutPaging } from 'Interfaces/Documents'
import { CATEGORY_INPUT_OPTIONS, DOCUMENT_TYPE_OPTIONS } from 'utils/constants'

interface IProps {
    filters: IDocumentsFiltersWithoutPaging
    applyFilters: (values: IDocumentsFiltersWithoutPaging) => void
    closeModal: () => void
}

const EvansAdminFilterDocumentsModal: FC<IProps> = ({
    filters,
    applyFilters,
    closeModal,
}) => {
    const initialValues = {
        ...filters,
    }

    function submitHandler(values: IDocumentsFiltersWithoutPaging) {
        applyFilters(values)
        closeModal()
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => submitHandler(values)}
        >
            {(formik) => (
                <Box
                    padding="2rem 0 0"
                    display="flex"
                    flexDirection="column"
                    gap="1rem"
                >
                    <Input
                        options={CATEGORY_INPUT_OPTIONS}
                        name="documentCategory"
                        label="Category"
                        select
                        fullWidth
                        maxWidth="100%"
                    />
                    <Input
                        options={DOCUMENT_TYPE_OPTIONS}
                        name="documentType"
                        label="Type"
                        select
                        fullWidth
                        maxWidth="100%"
                    />
                    <Input
                        checkBox
                        name="isGlobal"
                        label="Is Global"
                        fullWidth
                    />
                    <Button
                        text="Search"
                        onClick={formik.handleSubmit}
                        fullWidth
                    />
                </Box>
            )}
        </Formik>
    )
}

export default EvansAdminFilterDocumentsModal
