import { CognitoUserSession } from 'amazon-cognito-identity-js'
import axios, { AxiosRequestConfig } from 'axios'
import UserPool from 'auth/UserPool'

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL + '/api',
    headers: {
        'Content-Type': 'application/json',
    },
})

export const axiosBinary = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL + '/api',
    headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
    },
})

export const axiosInstanceLoqate = axios.create({
    baseURL:
        'https://api.addressy.com/Cleansing/International/Batch/v1.00/json4.ws',
    headers: {
        'Content-Type': 'application/json',
    },
})

axiosInstance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        const user = UserPool?.getCurrentUser()
        if (user) {
            user.getSession(
                (err: Error, session: CognitoUserSession | null) => {
                    if (err) return

                    const token = session?.getIdToken().getJwtToken()

                    config.headers = {
                        ...config.headers,
                        Authorization: `Bearer ${token}`,
                    }
                }
            )
        }

        return config
    },

    (error) => {
        return Promise.reject(error)
    }
)

axiosBinary.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        const user = UserPool?.getCurrentUser()
        if (user) {
            user.getSession(
                (err: Error, session: CognitoUserSession | null) => {
                    if (err) return

                    const token = session?.getIdToken().getJwtToken()

                    config.headers = {
                        ...config.headers,
                        Authorization: `Bearer ${token}`,
                    }
                }
            )
        }

        return config
    },

    (error) => {
        return Promise.reject(error)
    }
)

export default axiosInstance
