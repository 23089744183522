import { FC, useState } from 'react'
import { Typography, Tabs, Tab, Box } from '@mui/material'
import usePageTitle from 'hooks/usePageTitle'
import CompanyLogo from './CompanyLogo'
import CompanyDetailsForm from './CompanyDetailsForm'
import ErrorMessage from 'components/ErrorMessage'
import { getCompanyDetails } from 'api/evansAdmin'
import { useParams } from 'react-router-dom'
import { AxiosResponse, AxiosError } from 'axios'
import { useQuery } from 'react-query'
import LoadingSpinner from 'components/LoadingSpinner'

const CompanyDetails: FC = () => {
    usePageTitle('Company Details')
    const { companyId } = useParams()
    const [currentScreen, setCurrentScreen] = useState('details')
    const [errorMessage, setErrorMessage] = useState('')

    const { data, isFetching } = useQuery<
        AxiosResponse,
        AxiosError<{ message: string }>
    >(
        ['getCompanyDetails', companyId],
        () => getCompanyDetails(companyId as string),
        {
            onError: (error) => {
                setError(error.response?.data.message as string)
            },
        }
    )

    function setError(error: string) {
        setErrorMessage(error)
    }

    if (isFetching) {
        return <LoadingSpinner />
    }

    if (errorMessage) {
        return <ErrorMessage text={errorMessage} />
    }

    return (
        <Box paddingX="1rem">
            <Typography variant="h4" color="primary" mt={5}>
                Edit Company: {data?.data.companyName}
            </Typography>
            <Tabs
                sx={{ marginTop: '1rem' }}
                value={currentScreen}
                onChange={(event, value: string) => setCurrentScreen(value)}
            >
                <Tab value="details" label="Company Details" />
                <Tab value="logo" label="Company Logo" />
            </Tabs>

            {currentScreen === 'details' && (
                <CompanyDetailsForm data={data?.data} isFetching={isFetching} />
            )}
            {currentScreen === 'logo' && <CompanyLogo setError={setError} />}
        </Box>
    )
}

export default CompanyDetails
