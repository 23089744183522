import {
    IDocumentsFilters,
    IDocument,
    IDocumentsFiltersWithoutPaging,
} from 'Interfaces/Documents'
import { useState } from 'react'
import { getDocumentList } from 'api/evansAdmin'
import { useQuery } from 'react-query'
import { GridCellParams } from '@mui/x-data-grid'
import Button from 'components/Button'
import { downloadDocument } from 'api/evansAdmin'
import { useMutation } from 'react-query'
import useStore from 'store/Store'
import { AxiosError } from 'axios'
import fileDownload from 'js-file-download'
import { Box } from '@mui/material'
import SortingTableCell from 'components/sorting/SortingTableCell'
import _ from 'lodash'

const useEvansAdminDocumentsPage = () => {
    const [isFilteringModalOpen, setIsFilteringModalOpen] = useState(false)
    const [isAddDocumentModalOpen, setIsAddDocumentModalOpen] = useState(false)
    const [removedDocumentId, setRemovedDocumentId] = useState('')
    const [isDeleteDocumentModalOpen, setIsDeleteDocumentModalOpen] =
        useState(false)
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false)
    const [documentToPreview, setDocumentToPreview] = useState({
        url: '',
        type: '',
    })

    const [filters, setFilters] = useState<IDocumentsFilters>({
        page: 0,
        size: 12,
        sort: null,
        isGlobal: null,
        documentCategory: null,
        documentType: null,
    })

    const { sort } = filters

    const { openNotification } = useStore()

    function setSortingValue(value: string | null) {
        setFilters((prevState) => {
            return { ...prevState, sort: value }
        })
    }

    const mutation = useMutation(
        (obj: { documentId: string; documentName: string }) =>
            downloadDocument(obj.documentId),

        {
            onSuccess: (response, { documentName }) => {
                fileDownload(response.data, documentName)
            },
            onError: (error: AxiosError<{ message: string }>) => {
                openNotification(
                    (error.response?.data.message as string) ||
                        'Something went wrong',
                    'error'
                )
            },
        }
    )

    function clearFilters() {
        setFilters((prevState) => {
            return {
                ...prevState,
                sort: prevState.sort,
                isGlobal: null,
                documentCategory: null,
                documentType: null,
            }
        })
    }

    function applyFilters(values: IDocumentsFiltersWithoutPaging) {
        setFilters((prevState) => {
            const newState = {
                ...prevState,
                ...values,
            }

            if (_.isEqual(prevState, newState)) return newState
            return { ...newState, page: 0 }
        })
    }

    const {
        data: response,
        isFetching,
        refetch,
    } = useQuery(['getListOfDocuments', filters], () =>
        getDocumentList(filters)
    )

    function convertResponseData() {
        return response?.data?.content?.map((element: IDocument) => {
            return { ...element, id: element.documentId }
        })
    }

    function shouldDisableClearButton() {
        const { page, size, sort, ...rest } = filters
        return Object.values(rest).every((element) => element == null)
    }

    function handlePageChange(e: React.ChangeEvent<unknown>, p: number) {
        if (filters.page === 0 && p == 1) return

        setFilters((prevState: IDocumentsFilters) => {
            return {
                ...prevState,
                page: p - 1,
            }
        })
    }

    const columns = [
        {
            field: 'documentName',
            headerName: 'Name',
            flex: 1,
            sortable: false,
            renderHeader: () => (
                <SortingTableCell
                    title="Name"
                    sortingValue={sort as string}
                    setSortingValue={setSortingValue}
                    value="name"
                />
            ),
        },
        {
            field: 'documentType',
            headerName: 'Type',
            flex: 1,
            sortable: false,
            renderHeader: () => (
                <SortingTableCell
                    title="Type"
                    sortingValue={sort as string}
                    setSortingValue={setSortingValue}
                    value="type"
                />
            ),
        },
        {
            field: 'documentCategory',
            headerName: 'Category',
            hide: true,
            flex: 1,
            sortable: false,
            renderHeader: () => (
                <SortingTableCell
                    title="Category"
                    sortingValue={sort as string}
                    setSortingValue={setSortingValue}
                    value="category"
                />
            ),
        },
        {
            field: 'isGlobal',
            headerName: 'Is Global',
            flex: 1,
            sortable: false,
            hide: true,
            renderHeader: () => (
                <SortingTableCell
                    title="Is Global"
                    sortingValue={sort as string}
                    setSortingValue={setSortingValue}
                    value="isGlobal"
                />
            ),
        },
        {
            field: 'documentDescription',
            headerName: 'Description',
            flex: 1,
            sortable: false,
            renderHeader: () => (
                <SortingTableCell
                    title="Description"
                    sortingValue={sort as string}
                    setSortingValue={setSortingValue}
                    value="description"
                />
            ),
        },

        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            width: 290,
            renderCell: (params: GridCellParams) => (
                <Box display="flex" gap="0.5rem">
                    <Button
                        text="Delete"
                        error
                        padding=".5rem"
                        onClick={() => {
                            setIsDeleteDocumentModalOpen(true)
                            setRemovedDocumentId(params.id as string)
                        }}
                    />
                    <Button
                        isLoading={
                            mutation.isLoading &&
                            mutation.variables?.documentId === params.id
                        }
                        text="Download"
                        onClick={() => {
                            mutation.mutate({
                                documentId: params.id as string,
                                documentName: params.row.documentName,
                            })
                        }}
                    />

                    <Button
                        text="Preview"
                        colorPrimary
                        outlined
                        padding=".5rem"
                        onClick={() => {
                            setIsPreviewModalOpen(true)
                            setDocumentToPreview({
                                url: params?.row.url,
                                type: params?.row.documentType,
                            })
                        }}
                    />
                </Box>
            ),
        },
    ]

    return {
        columns,
        isFilteringModalOpen,
        setIsFilteringModalOpen,
        clearFilters,
        shouldDisableClearButton,
        response,
        convertResponseData,
        isFetching,
        filters,
        handlePageChange,
        applyFilters,
        isDeleteDocumentModalOpen,
        setIsDeleteDocumentModalOpen,
        isAddDocumentModalOpen,
        setIsAddDocumentModalOpen,
        removedDocumentId,
        refetch,
        isPreviewModalOpen,
        setIsPreviewModalOpen,
        documentToPreview,
        setDocumentToPreview,
    }
}

export default useEvansAdminDocumentsPage
