import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import Button from 'components/Button'
import { UseMutationResult } from 'react-query/types/react'
import { AxiosError, AxiosResponse } from 'axios'

interface IProps {
    title: string
    paragraph: string
    buttonText: string
    buttonErrorColor?: boolean
    onClick?: () => void
    mutation?: UseMutationResult<AxiosResponse, AxiosError, void, unknown>
    isButtonDisabled?: boolean
}

const UpdateCompanyAction: FC<IProps> = ({
    title,
    paragraph,
    buttonText,
    buttonErrorColor,
    onClick,
    mutation,
    isButtonDisabled,
}) => {
    const handleClick = () => {
        if (onClick) return onClick()
        if (mutation) return mutation.mutate()
    }

    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <div>
                <Typography variant="h6">{title}</Typography>
                <Typography>{paragraph}</Typography>
            </div>
            <Button
                error={buttonErrorColor}
                isLoading={mutation?.isLoading}
                text={buttonText}
                disabled={isButtonDisabled}
                onClick={handleClick}
            />
        </Box>
    )
}

export default UpdateCompanyAction
