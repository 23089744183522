import React, { FC, useRef } from 'react'
import { Editor as TinyEditor } from '@tinymce/tinymce-react'
import { Editor as TinyMCEEditor } from 'tinymce'

interface Init {
    plugins: string[]
    toolbar: string
    color_map: string[]
}

interface IProps {
    onChange: (text: string) => void
    value?: string
    minHeight?: number
    init?: Init
}

const Editor: FC<IProps> = ({ onChange, value, minHeight, init }) => {
    const editorRef = useRef<TinyMCEEditor | null>(null)

    return (
        <TinyEditor
            onEditorChange={(e: string) => {
                onChange(e)
            }}
            value={value}
            onInit={(evt, editor) => (editorRef.current = editor)}
            apiKey={process.env.REACT_APP_EDITOR_API_KEYS}
            init={{
                branding: false,
                min_height: minHeight,
                ...init,
                convert_urls: false,
            }}
        />
    )
}

export default Editor
