import { FC } from 'react'
import { AddCircleOutline } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import Table from 'components/Table'
import Modal from 'components/Modal/Modal'
import CreateAgreementTemplateModal from './CreateAgreementTemplateModal'
import useEvansAdminTemplatePage from './useEvansAdminTemplatePage'
import useStore from 'store/Store'
import usePageTitle from 'hooks/usePageTitle'
import CompaniesWhichUseTemplate from './CompaniesWhichUseTemplate'
import DeleteTemplate from './DeleteTemplate'

const EvansAdminTemplatesPage: FC = () => {
    usePageTitle('Templates')
    const { openNotification } = useStore()
    const {
        columns,
        isModalOpen,
        setIsModalOpen,
        currentPage,
        pageSize,
        isFetching,
        response,
        formatResponse,
        handlePageChange,
        refetch,
        templateIdToPreviewCompanies,
        setTemplateIdToPreviewCompanies,
        isDeleteTemplateModalOpen,
        setIsDeleteTemplateModalOpen,
        removedTemplateId,
    } = useEvansAdminTemplatePage()

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginTop="2rem"
                marginBottom="1rem"
            >
                <Typography color="primary" variant="h4">
                    Manage Templates
                </Typography>
                <AddCircleOutline
                    onClick={() => setIsModalOpen(true)}
                    color="primary"
                    sx={{ cursor: 'pointer', transform: 'scale(1.1)' }}
                />
            </Box>
            <Modal
                title="Delete Confirmation"
                isOpen={isDeleteTemplateModalOpen}
                onClose={() => setIsDeleteTemplateModalOpen(false)}
            >
                <DeleteTemplate
                    templateId={removedTemplateId}
                    successCallback={refetch}
                    closeModal={() => setIsDeleteTemplateModalOpen(false)}
                />
            </Modal>
            <Modal
                isOpen={Boolean(templateIdToPreviewCompanies.id)}
                title={templateIdToPreviewCompanies.name}
                onClose={() => {
                    setTemplateIdToPreviewCompanies({ name: '', id: '' })
                }}
            >
                <Box padding="1rem" maxHeight="350px">
                    <CompaniesWhichUseTemplate
                        templateId={templateIdToPreviewCompanies.id}
                    />
                </Box>
            </Modal>
            <Modal
                isOpen={isModalOpen}
                title="Create New Agreement Template"
                onClose={() => {
                    setIsModalOpen(false)
                }}
            >
                <CreateAgreementTemplateModal
                    successCallback={() => {
                        setIsModalOpen(false)
                        openNotification('Template has been created', 'success')
                        refetch()
                    }}
                    errorCallback={(message: string) => {
                        openNotification(message, 'error')
                    }}
                    closeModal={() => setIsModalOpen(false)}
                />
            </Modal>
            <Table
                disableSelect
                pageSize={pageSize}
                rows={formatResponse()}
                columns={columns}
                loading={isFetching}
                paginate
                currentPage={currentPage + 1}
                onPageChange={handlePageChange}
                totalElementsLength={response?.data.totalElements}
            />
        </>
    )
}

export default EvansAdminTemplatesPage
