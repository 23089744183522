import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import Modal, { IProps as ModalProps } from './Modal'

interface IProps extends ModalProps {
    data: Array<{ name: string; description: string }>
}

const CertificatesDescriptionModal: FC<IProps> = ({ data, ...rest }) => {
    return (
        <Modal scrollable {...rest}>
            <Box
                sx={{
                    maxHeight: '500px',
                    overflow: 'auto',
                    padding: '1.3rem',
                }}
            >
                {data?.map((element: { name: string; description: string }) => (
                    <Typography variant="body1" key={element.name} mb={2}>
                        <span style={{ fontWeight: 'bold' }}>
                            {element.name}
                        </span>{' '}
                        - {element.description}
                    </Typography>
                ))}
            </Box>
        </Modal>
    )
}

export default CertificatesDescriptionModal
