import { FC, useState, useRef, ReactNode } from 'react'
import Modal from 'components/Modal/Modal'
import { Formik, FormikProps } from 'formik'
import useAuth from 'auth/useAuth'
import Step1 from './components/Step1'
import Step2 from './components/Step2'
import * as yup from 'yup'

interface IProps {
    isOpen: boolean
    setIsOpen: () => void
    title?: string
    children?: ReactNode
}

const ForgotPasswordModal: FC<IProps> = ({ isOpen, setIsOpen, title }) => {
    const [currentStep, setCurrentStep] = useState(1)
    const { resetPassword, resetPasswordError } = useAuth()

    const initialValues = {
        email: '',
        code: '',
        password: '',
        confirmPassword: '',
    }

    type IForm = typeof initialValues

    const formRef = useRef<FormikProps<IForm>>(null)

    if (resetPasswordError) {
        formRef.current?.setStatus({ email: resetPasswordError })
    }

    const firstStepValidator = yup.object({
        email: yup
            .string()
            .email(
                'Please verify that you are entering a valid email address format e.g. name@somewhere.com'
            )
            .required(
                'Please verify that you are entering a valid email address format e.g. name@somewhere.com'
            ),
    })

    function handleSubmit(values: IForm) {
        const { email } = values

        resetPassword(email, () => {
            setCurrentStep(2)
        })
    }

    return (
        <Modal isOpen={isOpen} onClose={setIsOpen} title={title}>
            <Formik
                innerRef={formRef}
                validationSchema={firstStepValidator}
                initialValues={initialValues}
                onSubmit={(values) => handleSubmit(values)}
            >
                {() => (
                    <>
                        {currentStep === 1 && <Step1 />}
                        {currentStep === 2 && <Step2 />}
                    </>
                )}
            </Formik>
        </Modal>
    )
}

export default ForgotPasswordModal
