import React, { FC } from 'react'
import { Typography, Box } from '@mui/material'
import { Check } from '@mui/icons-material'

interface IProps {
    text: string
    children?: React.ReactNode
}

const boxStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    marginBottom: '.3rem',
}

const IconText: FC<IProps> = ({ text, children }) => {
    return (
        <Box sx={boxStyle}>
            {children || <Check />}
            <Typography sx={{ fontWeight: '500' }} variant="body2">
                {text}
            </Typography>
        </Box>
    )
}

export default IconText
