import { GridCellParams } from '@mui/x-data-grid'
import { IFilters } from 'Interfaces/Admin'
import { getSchemeAdminCompaniesList } from 'api/evansAdmin'
import Button from 'components/Button'
import Table from 'components/Table'
import SortingTableCell from 'components/sorting/SortingTableCell'
import { FC, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

const CompaniesList: FC<{ adminId: string }> = ({ adminId }) => {
    const navigate = useNavigate()
    const initialFilters = {
        page: 0,
        size: 11,
        sort: null,
    }
    const [filters, setFilters] = useState<IFilters>(initialFilters)
    const { data: response, isFetching } = useQuery(
        ['getCompanies', adminId, filters],
        () => getSchemeAdminCompaniesList(adminId, filters)
    )
    function setSortingValue(value: string | null) {
        setFilters((prevState) => ({ ...prevState, sort: value }))
    }

    function handlePageChange(e: React.ChangeEvent<unknown>, p: number) {
        if (filters.page === 0 && p == 1) {
            return
        } else {
            setFilters((prevState: IFilters) => {
                return { ...prevState, page: p - 1 }
            })
        }
    }

    const rows = response?.data.content || []
    const columns = [
        {
            field: 'companyName',
            flex: 1,
            headerName: 'Company name',
            sortable: false,
            renderHeader: () => (
                <SortingTableCell
                    title="Company name"
                    sortingValue={filters.sort}
                    setSortingValue={setSortingValue}
                    value="companyName"
                />
            ),
        },
        {
            field: 'tradingName',
            flex: 1,
            headerName: 'Trading name',
            sortable: false,
            renderHeader: () => (
                <SortingTableCell
                    title="Trading name"
                    sortingValue={filters.sort}
                    setSortingValue={setSortingValue}
                    value="tradingName"
                />
            ),
        },
        {
            field: 'companyRegistrationNumber',
            flex: 1,
            headerName: 'Registration number',
            sortable: false,
            renderHeader: () => (
                <SortingTableCell
                    title="Registration number"
                    sortingValue={filters.sort}
                    setSortingValue={setSortingValue}
                    value="companyRegistrationNumber"
                />
            ),
        },
        {
            field: 'View',
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <Button
                    text="View"
                    onClick={() =>
                        navigate(
                            `/ride-to-work-apply/evans-admin/companies/${params.row.id}`
                        )
                    }
                />
            ),
        },
    ]

    return (
        <Table
            currentPage={(filters.page as number) + 1}
            pageSize={filters.size}
            paginate
            loading={isFetching}
            rows={rows}
            columns={columns}
            onPageChange={handlePageChange}
            numberOfPages={Math.ceil(
                response?.data.totalElements / filters.size
            )}
        />
    )
}

export default CompaniesList
