import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
    palette: {
        primary: {
            main: '#313c49',
            contrastText: '#fff',
            light: '#7db993',
        },

        secondary: {
            main: '#f4e402',
            contrastText: '#313c49',
            dark: '#e9d900',
        },
        error: {
            main: '#b11b36',
            // light: '#f5dde0',
        },
    },

    zIndex: {
        modal: 7,
        appBar: 6,
        drawer: 5,
    },

    typography: {
        fontFamily: `Poppins, Helvetica, Arial,'sans-serif'`,
    },

    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#fff',
                    color: '#000',
                    border: '1px solid #dadde9',
                    padding: '1rem',
                    fontSize: '1rem',
                    lineHeight: '1.4',
                    boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.5) ',
                },
            },
        },
    },
})
