import { ReactElement } from 'react'
import create from 'zustand'

export interface Store {
    isSchemeAdmin: boolean
    isEvansAdmin: boolean
    isEvansAdminNavigationOpen: boolean
    isSchemeAdminNavigationOpen: boolean
    hasCmsPermission: boolean
    evansAdminEmail: string
    isNotificationOpen: boolean
    notificationMessage: string | ReactElement
    notificationType: string
    isModalOpen: boolean
    modalTitle: string
    modalContent: ReactElement
    handleSchemeAdminState(isLoggedIn: boolean): void
    handleEvansAdminState(isLoggedIn?: boolean, email?: string): void
    handleEvansAdminNavigationState(isOpen?: boolean): void
    handleSchemeAdminNavigationState(isOpen?: boolean): void
    handleCmsPermission(hasCmsPermission: boolean): void
    openNotification(message: string | ReactElement, type: string): void
    closeNotification(): void
    openModal(title: string, content: ReactElement): void
    closeModal(): void
}

const useStore = create<Store>((set) => ({
    isSchemeAdmin: false,
    isEvansAdmin: false,
    hasCmsPermission: false,
    isEvansAdminNavigationOpen: false,
    notificationMessage: '',
    evansAdminEmail: '',
    isNotificationOpen: false,
    isSchemeAdminNavigationOpen: false,
    notificationType: '',
    isModalOpen: false,
    modalContent: <></>,
    modalTitle: '',
    openNotification: (message: string | ReactElement, type: string) => {
        set((state: Store) => ({
            ...state,
            isNotificationOpen: true,
            notificationMessage: message,
            notificationType: type,
        }))
    },

    closeNotification: () => {
        set((state: Store) => ({
            ...state,
            isNotificationOpen: false,
        }))
    },

    openModal: (title: string, content: ReactElement) => {
        set((state: Store) => {
            return {
                ...state,
                isModalOpen: true,
                modalTitle: title,
                modalContent: content,
            }
        })
    },

    closeModal: () => {
        set((state: Store) => ({
            ...state,
            isModalOpen: false,
        }))
    },

    handleSchemeAdminState: (isLoggedIn: boolean) => {
        set((state: Store) => ({
            ...state,
            isSchemeAdmin: isLoggedIn,
        }))
    },

    handleEvansAdminState: (isLoggedIn: boolean, email: string) => {
        set((state: Store) => ({
            ...state,
            isEvansAdmin: isLoggedIn,
            evansAdminEmail: email,
        }))
    },

    handleCmsPermission: (hasCmsPermission: boolean) => {
        set((state: Store) => ({
            ...state,
            hasCmsPermission,
        }))
    },

    handleEvansAdminNavigationState: (open?: boolean) => {
        set((state: Store) => ({
            ...state,
            isEvansAdminNavigationOpen:
                open || !state.isEvansAdminNavigationOpen,
        }))
    },
    handleSchemeAdminNavigationState: (isOpen: boolean) => {
        set((state: Store) => ({
            ...state,
            isSchemeAdminNavigationOpen: isOpen,
        }))
    },
}))

export default useStore
