import { FC } from 'react'
import { Formik } from 'formik'
import Button from 'components/Button'
import Input from 'components/Input'
import Editor from 'components/Editor'
import {
    Alert,
    AlertTitle,
    Box,
    Grid,
    Tab,
    Tabs,
    Typography,
} from '@mui/material'
import LoadingSpinner from 'components/LoadingSpinner'
import useEvansAdminTemplateCreate from './useEvansAdminTemplateEdit'
import usePageTitle from 'hooks/usePageTitle'
import ErrorMessage from 'components/ErrorMessage'

const EvansAdminTemplatesEditPage: FC = () => {
    usePageTitle('Edit Agreement Template')
    const {
        initialValues,
        submitHandler,
        isLoading,
        currentAgreement,
        setCurrentAgreement,
        updateAgreementMutation,
        errors,
        errorMsg,
    } = useEvansAdminTemplateCreate()

    if (isLoading) {
        return <LoadingSpinner />
    }

    if (errorMsg) {
        return <ErrorMessage text={errorMsg as string} />
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => submitHandler(values)}
        >
            {(formik) => (
                <Box sx={{ padding: '1rem' }}>
                    <Typography color="primary" variant="h4" mb={3} mt={2}>
                        Edit Agreement Template
                    </Typography>

                    {updateAgreementMutation.isError && (
                        <Alert severity="error">
                            <AlertTitle>
                                {
                                    updateAgreementMutation.error?.response
                                        ?.data.message
                                }
                                {errors?.map((element) => (
                                    <p key={element}>{element}</p>
                                ))}
                            </AlertTitle>
                        </Alert>
                    )}
                    <Grid item xs={12} spacing={2} container mb={4}>
                        <Grid item xs={12}>
                            <Tabs
                                value={currentAgreement}
                                onChange={(
                                    event: React.SyntheticEvent,
                                    newValue: string
                                ) => {
                                    setCurrentAgreement(newValue)
                                }}
                            >
                                <Tab
                                    label="Hire Agreement"
                                    value="hireStepAgreement"
                                />
                                <Tab
                                    label={
                                        initialValues.thirdStepAgreementType ===
                                        'PRE_CONTRACT'
                                            ? 'Precontract'
                                            : 'Salary Sacrifice Agreement'
                                    }
                                    value="thirdStepAgreement"
                                />
                            </Tabs>
                        </Grid>
                        <Grid item container md={8}>
                            <Grid item xs={12}>
                                <Editor
                                    minHeight={700}
                                    value={
                                        currentAgreement ===
                                        'thirdStepAgreement'
                                            ? formik.values
                                                  .thirdStepAgreementTemplateContent
                                            : formik.values
                                                  .hireAgreementTemplateContent
                                    }
                                    onChange={(text) => {
                                        currentAgreement ===
                                        'thirdStepAgreement'
                                            ? formik.setFieldValue(
                                                  'thirdStepAgreementTemplateContent',
                                                  text
                                              )
                                            : formik.setFieldValue(
                                                  'hireAgreementTemplateContent',
                                                  text
                                              )
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            md={4}
                            rowSpacing={0}
                            mt={1}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                visibility:
                                    currentAgreement === 'hireStepAgreement'
                                        ? 'visible'
                                        : 'hidden',
                            }}
                        >
                            <Input
                                fullWidth
                                name="restrictions.showLogoOnAgreement"
                                label="Logo On Agreement {COMPANY_LOGO}"
                                checkBox
                            />

                            <Input
                                name="restrictions.showEvansCyclesName"
                                label="Evans Cycles Name {COMPANY_NAME}"
                                checkBox
                                fullWidth
                            />

                            <Input
                                name="restrictions.showEvansCyclesAddress"
                                label="Evans Cycles Address {COMPANY_ADDRESS}"
                                checkBox
                                fullWidth
                            />

                            <Input
                                name="restrictions.showEmployeeName"
                                label="Employee Name {EMPLOYEE_NAME}"
                                checkBox
                                apiErrors={formik.status}
                                fullWidth
                            />

                            <Input
                                name="restrictions.showEmployeeAddress"
                                label="Employee Address {EMPLOYEE_ADDRESS}"
                                checkBox
                                fullWidth
                            />

                            <Input
                                name="restrictions.showHireAgreementTotalAmount"
                                label="Hire Agreement Total Amount {HIRE_AGREEMENT_AMOUNT}"
                                checkBox
                                fullWidth
                            />

                            <Input
                                name="restrictions.showHireAgreementMonthlyPayment"
                                label="Hire Agreement Monthly Payment {HIRE_AGREEMENT_MONTHLY}"
                                checkBox
                                fullWidth
                            />

                            <Input
                                name="restrictions.showHireAgreementLength"
                                label="Hire Agreement Length {HIRE_AGREEMENT_TERM}"
                                checkBox
                                fullWidth
                            />

                            <Input
                                name="restrictions.showSalarySacrificeTerm"
                                label="Salary Sacrifice Term {SALARY_SACRIFICE_TERM}"
                                checkBox
                                fullWidth
                            />

                            <Input
                                name="restrictions.showDateOfSchemeAdministratorSignature"
                                label="Date Of Scheme Administrator Signature {DATE_OF_EMPLOYER_SIGNATURE}"
                                checkBox
                                fullWidth
                            />

                            <Input
                                name="restrictions.showDateOfEmployeeSignature"
                                label="Date Of Employee Signature {DATE_OF_EMPLOYEE_SIGNATURE}"
                                checkBox
                                fullWidth
                            />

                            <Input
                                name="restrictions.showNumberOfSacrifices"
                                label="Number Of Sacrifices {NUMBER_OF_SACRIFICES}"
                                checkBox
                                fullWidth
                            />

                            <Input
                                name="restrictions.showSalarySacrificeDeductionFrequency"
                                label="Salary Sacrifice Deduction Frequency {SALARY_SACRIFICE_DEDUCTION_FREQUENCY}"
                                checkBox
                                fullWidth
                            />

                            <Input
                                name="restrictions.showEmployeePayrollNumber"
                                label="Employee Payroll Number {EMPLOYEE_PAYROLL}"
                                checkBox
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                text="Submit"
                                onClick={formik.handleSubmit}
                                isLoading={updateAgreementMutation.isLoading}
                            />
                        </Grid>
                    </Grid>
                </Box>
            )}
        </Formik>
    )
}

export default EvansAdminTemplatesEditPage
