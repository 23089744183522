import { FC, useState } from 'react'
import {
    Box,
    Divider,
    Grid,
    Tab,
    Tabs,
    Theme,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { useQuery } from 'react-query'
import { getCompanyDetails } from 'api/schemeAdmin'
import { useParams } from 'react-router-dom'
import LoadingSpinner from 'components/LoadingSpinner'
import usePageTitle from 'hooks/usePageTitle'
import EmployerDetails from './EmployerDetails'
import AccountSettings from './AccountSettings'
import DescriptiveText from './DescriptiveText'
import CompanyLogo from './CompanyLogo'
import { AxiosError, AxiosResponse } from 'axios'
import ErrorMessage from 'components/ErrorMessage'

const CompanySettings: FC = () => {
    usePageTitle('Company Settings')
    const [currentPage, setCurrentPage] = useState('employerDetails')
    const { companyId } = useParams()
    const {
        data: response,
        isLoading,
        refetch,
        error,
    } = useQuery<AxiosResponse, AxiosError<{ message: string }>>(
        ['getCompanyDetails', companyId],
        () => getCompanyDetails(companyId as string)
    )

    const isMobileResolution = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    )

    const { address, companyName, descriptiveText, tradingName } =
        response?.data || {}

    const { message: errorMsg } = error?.response?.data || {}

    function displayCurrentPage() {
        switch (currentPage) {
            case 'employerDetails':
                return (
                    <EmployerDetails
                        successCallback={refetch}
                        data={{ companyName, tradingName, address }}
                    />
                )
            case 'accountSettings':
                return <AccountSettings />
            case 'companyDescription':
                return (
                    <DescriptiveText
                        description={descriptiveText}
                        refetch={refetch}
                    />
                )
            case 'companyLogo':
                return <CompanyLogo />
        }
    }

    if (isLoading) {
        return <LoadingSpinner />
    }

    if (errorMsg) {
        return <ErrorMessage text={errorMsg as string} />
    }

    return (
        <Box mt={4}>
            <Divider>
                <Typography variant="h4" color="primary" fontWeight="400">
                    Company Settings
                </Typography>
            </Divider>
            <Grid container spacing={4}>
                <Grid item xs={12} md={3}>
                    <Tabs
                        onChange={(
                            event: React.SyntheticEvent,
                            newValue: string
                        ) => {
                            setCurrentPage(newValue)
                        }}
                        value={currentPage}
                        sx={{
                            marginTop: '1rem',
                            '& .MuiTabs-flexContainer': {
                                flexWrap: 'wrap',
                            },
                        }}
                        TabIndicatorProps={{
                            sx: {
                                display: isMobileResolution ? 'none' : 'inline',
                            },
                        }}
                        orientation={
                            isMobileResolution ? 'horizontal' : 'vertical'
                        }
                    >
                        <Tab value="employerDetails" label="Employer Details" />
                        <Tab value="accountSettings" label="Account Settings" />
                        <Tab
                            value="companyDescription"
                            label="Company Description"
                        />
                        <Tab value="companyLogo" label="Company Logo" />
                    </Tabs>
                </Grid>
                <Grid item xs={12} md={9} mt={4}>
                    {displayCurrentPage()}
                </Grid>
            </Grid>
        </Box>
    )
}

export default CompanySettings
