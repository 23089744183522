import { FC, useRef } from 'react'
import { Grid, Divider, Box } from '@mui/material'
import { Formik, FormikProps } from 'formik'
import Input from 'components/Input'
import Button from 'components/Button'
import { updateCompanyDetails } from 'api/schemeAdmin'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import useStore from 'store/Store'
import { AxiosError } from 'axios'
import { IUpdateCompanyDetails } from 'Interfaces/Company'
import LoqateSearch from 'components/Loqate/LoqateSearch'
import { SectionHeader } from 'components/SectionHeader'
import replaceNullWithEmptyString from 'utils/replaceNullWithEmptyString'

interface IProps {
    data: IUpdateCompanyDetails
    successCallback: () => void
}

const EmployerDetails: FC<IProps> = ({ data, successCallback }) => {
    const { companyId } = useParams()
    const { openNotification } = useStore()
    const formRef = useRef<FormikProps<IUpdateCompanyDetails>>(null)

    const updateCompanyDetailsMutation = useMutation(
        (details: IUpdateCompanyDetails) =>
            updateCompanyDetails(companyId as string, details),
        {
            onSuccess: () => {
                openNotification('Data has been saved', 'success')
                successCallback()
            },

            onError: (
                error: AxiosError<{
                    message: string
                    fieldErrors: Record<string, string>
                }>
            ) => {
                formRef.current?.setStatus(error?.response?.data.fieldErrors)

                openNotification(
                    error.response?.data.message || 'Something went wrong',
                    'error'
                )
            },
        }
    )

    const employerDetailsValues = replaceNullWithEmptyString(data)

    return (
        <Formik
            innerRef={formRef}
            initialValues={employerDetailsValues}
            onSubmit={(values) => updateCompanyDetailsMutation.mutate(values)}
        >
            {(formik) => (
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <SectionHeader variant="h5">
                            Current Employer Details
                        </SectionHeader>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            name="companyName"
                            label="Company Name"
                            fullWidth
                            required
                            apiErrors={formik.status}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            name="tradingName"
                            label="Trading Name"
                            fullWidth
                            required
                            apiErrors={formik.status}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <LoqateSearch
                                label="Employer Address Finder"
                                formik={formik}
                                addressFieldsNames={{
                                    addressLine1: 'address.addressLine1',
                                    addressLine2: 'address.addressLine2',
                                    town: 'address.town',
                                    county: 'address.county',
                                    postcode: 'address.postcode',
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            name="address.addressLine2"
                            label="Employer Address 2"
                            fullWidth
                            apiErrors={formik.status}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            name="address.town"
                            label="Town"
                            fullWidth
                            required
                            apiErrors={formik.status}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            name="address.county"
                            label="County"
                            fullWidth
                            apiErrors={formik.status}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            name="address.postcode"
                            label="Postcode"
                            fullWidth
                            required
                            apiErrors={formik.status}
                        />
                    </Grid>
                    <Divider />
                    <Grid item xs={12}>
                        <Button
                            disabled={updateCompanyDetailsMutation.isLoading}
                            backgroundColor="secondary"
                            text="Update address"
                            onClick={formik.handleSubmit}
                            colorPrimary
                        />
                    </Grid>
                </Grid>
            )}
        </Formik>
    )
}

export default EmployerDetails
