import { useState } from 'react'
import useStore from 'store/Store'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from 'react-query'
import { updateTemplate, getTemplateById } from 'api/evansAdmin'
import { AxiosError, AxiosResponse } from 'axios'
import { ITemplateAgreement } from 'Interfaces/Templates'
const useEvansAdminTemplateEdit = () => {
    const { id } = useParams()
    const { openNotification } = useStore()
    const [errors, setErrors] = useState<string[]>([])
    const navigate = useNavigate()
    const [currentAgreement, setCurrentAgreement] =
        useState('hireStepAgreement')

    const {
        data: response,
        isLoading,
        error,
    } = useQuery<AxiosResponse, AxiosError<{ message: string }>>(
        ['getTemplateById', id],
        () => getTemplateById(id as string)
    )

    const initialValues = {
        ...response?.data,
    }

    const { message: errorMsg } = error?.response?.data || {}

    const updateAgreementMutation = useMutation(
        (data: ITemplateAgreement) => updateTemplate(id as string, data),
        {
            onSuccess: () => {
                openNotification('Template has been edited', 'success')
                setErrors([])
            },

            onError: (
                error: AxiosError<{
                    fieldErrors: Record<string, string>
                    message: string
                }>
            ) => {
                if (error?.response?.data?.fieldErrors) {
                    const { fieldErrors } = error.response.data
                    setErrors(Object.values(fieldErrors).flat() as string[])
                }
            },
        }
    )

    function submitHandler(values: ITemplateAgreement) {
        updateAgreementMutation.mutate({
            ...values,
            thirdStepAgreementType: values.thirdStepAgreementType.replace(
                ' ',
                '_'
            ),
        })
    }

    return {
        id,
        openNotification,
        response,
        isLoading,
        errors,
        submitHandler,
        initialValues,
        navigate,
        currentAgreement,
        setCurrentAgreement,
        updateAgreementMutation,
        errorMsg,
    }
}

export default useEvansAdminTemplateEdit
