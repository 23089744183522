import { FC } from 'react'
import { Grid } from '@mui/material'
import Button from 'components/Button'
import { Formik } from 'formik'
import Input from 'components/Input'
import { IButtonProps } from 'Interfaces/CMS'
import { contentFormButtonValidator } from 'utils/ValidationSchema'

interface ButtonFormProps {
    initialValues: IButtonProps
    onSave: (value: IButtonProps) => void
}
const ButtonForm: FC<ButtonFormProps> = ({ initialValues, onSave }) => {
    const handleSubmit = (values: IButtonProps) => {
        onSave(values)
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={contentFormButtonValidator}
        >
            {({ handleSubmit }) => (
                <Grid container rowSpacing={2} marginTop={2}>
                    <Grid item sm={12}>
                        <Input fullWidth label="Text" name="text" required />
                    </Grid>
                    <Grid item sm={12}>
                        <Input fullWidth label="Link" name="link" required />
                    </Grid>
                    <Grid item sm={8} md={6} paddingRight={1}>
                        <Input
                            fullWidth
                            label="Color"
                            name="color"
                            select
                            options={['SECONDARY', 'PRIMARY', 'ERROR']}
                            required
                        />
                    </Grid>
                    <Grid item sm={4} md={6}>
                        <Input
                            fullWidth
                            label="Width"
                            name="width"
                            required
                            suffix=" /12"
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <Button
                            text="Save"
                            md={12}
                            fullWidth
                            padding="0.9rem"
                            onClick={handleSubmit}
                            grid
                        />
                    </Grid>
                </Grid>
            )}
        </Formik>
    )
}

export default ButtonForm
