import { IDocumentsFilters, ICreateDocument } from './../Interfaces/Documents'
import axios, { axiosBinary } from './axios'
import { ICertificateFilters } from 'Interfaces/Certificate'
import { IAdminRole, IFilters, ISchemeAdminDetails } from 'Interfaces/Admin'
import {
    IAssignTemplateToCompany,
    ITemplateAgreement,
} from 'Interfaces/Templates'
import {
    ICompaniesFilters,
    ICompanyDetailsUpdate,
    ICompanyRestrictions,
} from 'Interfaces/Company'
import { IContent } from 'Interfaces/CMS'

const ALL = 99999

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
const removeEmptyFilters = (filters: any) => {
    return Object.fromEntries(
        /* eslint-disable-next-line  @typescript-eslint/no-unused-vars */
        Object.entries(filters).filter(([_, v]) => v !== null && v !== '')
    )
}

export const getAllCertificates = async (filterData: ICertificateFilters) => {
    return await axios.get(`evans-admin/certificates`, {
        params: filterData,
    })
}

export const getCertificatesForCompany = async (
    companyId: string,
    filterData: ICertificateFilters
) => {
    return await axios.get(`evans-admin/certificates/${companyId}`, {
        params: filterData,
    })
}

export const getCertificateApplicantDetails = async (processId: string) => {
    return await axios.get(`evans-admin/certificates/processes/${processId}`)
}

export const getCertificatesToCsv = async (filters: ICertificateFilters) => {
    const { page, size, ...rest } = filters
    return await axios.get(`evans-admin/certificates/csv`, {
        params: { ...rest, size: ALL },
    })
}

export const updateSchemeAdminDetails = async (
    schemeAdminId: string,
    data: ISchemeAdminDetails
) => {
    return await axios.put(`evans-admin/scheme-admins/${schemeAdminId}`, data)
}

export const getSchemeAdminDetails = async (schemeAdminId: string) => {
    return await axios.get(`evans-admin/scheme-admins/${schemeAdminId}`)
}

export const getSchemeAdminsForCompany = async (companyId: string) => {
    return await axios.get(`evans-admin/companies/${companyId}/scheme-admins`)
}
export const addAdminToCompany = async (
    companyId: string,
    body: { schemeAdminEmail: string }
) => {
    return await axios.patch(
        `evans-admin/companies/${companyId}/scheme-admins`,
        body
    )
}

export const removeAdminFromCompany = async (
    companyId: string | undefined,
    schemeAdminId: number
) => {
    return await axios.delete(
        `evans-admin/companies/${companyId}/scheme-admins/${schemeAdminId}`
    )
}

export const removeSchemeAdmin = async (schemeAdminId: number) => {
    return await axios.delete(`evans-admin/scheme-admins/${schemeAdminId}`)
}

export const setAdminAsMain = async (
    companyId: string | undefined,
    schemeAdminId: number
) => {
    return await axios.patch(
        `evans-admin/companies/${companyId}/scheme-admins/${schemeAdminId}`
    )
}

export const createNewAgreementTemplate = async (data: ITemplateAgreement) => {
    return await axios.post(`evans-admin/templates`, data)
}

export const createNewDocument = async (obj: ICreateDocument) => {
    const { file, documentForm } = obj
    return await axiosBinary.post(`evans-admin/documents`, file, {
        params: {
            documentForm,
        },
    })
}

export const getCompanyRestrictions = async (companyId: string) => {
    return await axios.get(`evans-admin/companies/${companyId}/restrictions`)
}

export const updateCompanyRestrictions = async (
    companyId: string,
    data: ICompanyRestrictions
) => {
    return await axios.patch(
        `evans-admin/companies/${companyId}/restrictions`,
        data
    )
}

export const getCompanyDetails = async (companyId: string) => {
    return await axios.get(`evans-admin/companies/${companyId}/details`)
}

export const getUnconfirmedAdmins = async () => {
    return await axios.get(`evans-admin/cognito-users/unconfirmed-admin`)
}

export const deleteUnconfirmedAdmin = async (
    schemeAdminEmail: string | undefined
) => {
    return await axios.delete(`/evans-admin/cognito-users/${schemeAdminEmail}`)
}

export const confirmAdmin = async (schemeAdminEmail: string | undefined) => {
    return await axios.patch(
        `evans-admin/cognito-users/confirmation/${schemeAdminEmail}`
    )
}

export const updateCompanyDetails = async (
    companyId: string,
    data: ICompanyDetailsUpdate
) => {
    return await axios.put(`evans-admin/companies/${companyId}/details`, data)
}

export const getCompanyCustomSettings = async (companyId: string) => {
    return await axios.get(`evans-admin/companies/${companyId}/custom/settings`)
}

export const updateCompanyCustomSettings = async (
    companyId: string,
    data: { discountPercentage: number; customVatRate: number }
) => {
    return await axios.patch(
        `evans-admin/companies/${companyId}/custom/settings`,
        data
    )
}

export const getCompanyList = async (filters: ICompaniesFilters) => {
    const filtersWithoutEmptyProperties = removeEmptyFilters(filters)
    return await axios.get(`evans-admin/companies`, {
        params: filtersWithoutEmptyProperties,
    })
}

export const getSchemeAdminCompaniesList = async (
    schemeAdminId: string,
    filters: IFilters
) => {
    return await axios.get(`evans-admin/companies/${schemeAdminId}/companies`, {
        params: filters,
    })
}

export const getAdminRoles = async (adminId: string) => {
    return await axios.get(`evans-admin/role/${adminId}`)
}

export const changeAdminRole = async (data: IAdminRole) => {
    return await axios.patch(`evans-admin/role`, data)
}

export const getCompaniesToCSV = async (filters: ICompaniesFilters) => {
    const { page, size, ...rest } = filters
    return await axios.get(`evans-admin/companies/csv`, {
        params: { ...rest, size: ALL },
    })
}

export const uploadNewCompanyLogo = async (
    companyId: string,
    image: File | string | Blob | FormData
) => {
    return await axiosBinary.post(`evans-admin/companies/${companyId}/logo`, {
        image,
    })
}

export const deleteCompanyLogo = async (companyId: string) => {
    return await axios.delete(`evans-admin/companies/${companyId}/logo`)
}

export const getTemplatesList = async (filters: {
    page?: number | null
    size?: number | null
    sort?: string | null
}) => {
    return await axios.get(`evans-admin/templates`, {
        params: filters,
    })
}

export const getAllTemplatesList = async () => {
    return await axios.get(`evans-admin/templates/list`)
}

export const updateCompanyRecordStatus = async (
    companyId: string,
    status: string
) => {
    return await axios.patch(
        `evans-admin/companies/${companyId}/status`,
        status
    )
}

export const getDocumentList = async (filters: IDocumentsFilters) => {
    return await axios.get(`evans-admin/documents`, {
        params: filters,
    })
}

export const assignAgreementToCompany = async (
    companyId: string,
    data: IAssignTemplateToCompany
) => {
    return await axios.put(`evans-admin/templates/companies/${companyId}`, data)
}

export const getTemplateById = async (templateId: string) => {
    return await axios.get(`evans-admin/templates/${templateId}`)
}

export const updateTemplate = async (
    templateId: string,
    data: ITemplateAgreement
) => {
    return await axios.put(`evans-admin/templates/${templateId}`, data)
}

export const getCompanyStatus = async (companyId: string) => {
    return await axios.get(`evans-admin/companies/${companyId}/status`)
}

export const deleteDocument = async (documentId: string) => {
    return await axios.delete(`evans-admin/documents/${documentId}`)
}

export const downloadDocument = async (documentId: string) => {
    return await axios.get(`evans-admin/documents/${documentId}`, {
        responseType: 'blob',
    })
}

export const getAllContentElements = async () => {
    return await axios.get(`evans-admin/content-elements`)
}

export const updateContentElement = async (data: Partial<IContent>) => {
    return await axios.put(`evans-admin/content-elements/${data.id}`, data)
}

export const assignCustomDocumentToCompany = async (data: {
    documentId: string
    companyId: string
}) => {
    const { documentId, companyId } = data

    return await axios.patch(
        `evans-admin/documents/${documentId}/company`,
        companyId
    )
}

export const getCustomDocuments = async () => {
    return await axios.get(`evans-admin/documents/custom`)
}

export const getAllDocumentsForCompany = async (companyId: string) => {
    return await axios.get(`evans-admin/documents/companies/${companyId}`)
}

export const removeCustomDocumentFromCompany = async (obj: {
    documentId: string
    companyId: string
}) => {
    const { documentId, companyId } = obj
    return await axios.patch(
        `evans-admin/documents/${documentId}/company/remove`,
        companyId
    )
}

export const updateSchemeAdminEmailAddress = async (data: {
    emailAddress: string
    confirmEmailAddress: string
    schemeAdminId: string
}) => {
    const { schemeAdminId, ...rest } = data

    return await axios.patch(
        `evans-admin/scheme-admins/${schemeAdminId}/email`,
        rest
    )
}

export const getCompanyLogo = async (companyId: string) => {
    return await axios.get(`evans-admin/companies/${companyId}/logo`)
}

export const updateSchemeAdminPassword = async (
    schemeAdminId: string,
    data: {
        temporaryPassword: string
        confirmPassword: string
    }
) => {
    return await axios.patch(
        `evans-admin/scheme-admins/${schemeAdminId}/password`,
        data
    )
}

export const getCertificatesStatuses = async () => {
    return await axios.get(`evans-admin/certificates/statuses`)
}

export const getAllowedActionsForCompanies = async () => {
    return await axios.get(`evans-admin/companies/allowed-actions`)
}

export const getAllowedActionsForCertificates = async () => {
    return await axios.get(`evans-admin/certificates/allowed-actions`)
}

export const performCompanyAction = async (
    companyId: string,
    action: string
) => {
    return await axios.patch(
        `/evans-admin/companies/${companyId}/actions/${action}`
    )
}

export const performCertificateAction = async (
    certificateId: string,
    action: string
) => {
    return await axios.patch(
        `/evans-admin/certificates/${certificateId}/actions/${action}`
    )
}

export const getSchemeAdminByEmail = async (params: {
    email: string
    limit: number
}) => {
    return await axios.get(`evans-admin/scheme-admins`, {
        params,
    })
}

export const getCompaniesWhichUseTemplate = async (templateId: string) => {
    return await axios.get(`evans-admin/templates/${templateId}/companies`)
}

export const deleteTemplate = async (templateId: string) => {
    return await axios.delete(`evans-admin/templates/${templateId}`)
}

export const getTerms = async () => {
    return await axios.get('evans-admin/companies/available-terms')
}

export const getCertificateErrors = async (processId: string) => {
    return await axios.get(`evans-admin/certificates/errors/${processId}`)
}
