import { FC, useState } from 'react'
import { Box, Divider, Grid, Typography, useTheme } from '@mui/material'
import Input from 'components/Input'
import { Formik } from 'formik'
import { adminChangePasswordSchemaValidator } from 'utils/ValidationSchema'
import Button from 'components/Button'
import usePageTitle from 'hooks/usePageTitle'
import useAuth from 'auth/useAuth'
import useStore from 'store/Store'
import ErrorMessage from 'components/ErrorMessage'

const AdminPanelChangePasswordPage: FC = () => {
    usePageTitle('Change Password')
    const theme = useTheme()
    const { changePassword } = useAuth()
    const { openNotification } = useStore()
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const initialValues = {
        currentPassword: '',
        newPassword: '',
        checkNewPassword: '',
    }

    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    function submitHandler(data: typeof initialValues, resetForm: any): void {
        const { currentPassword, newPassword } = data
        setIsLoading(true)
        changePassword(currentPassword, newPassword, (error, data) => {
            if (error) {
                setErrorMessage(error.message)
            }

            if (data) {
                setErrorMessage('')
                openNotification(
                    'Password has been changed successfully',
                    'success'
                )
                resetForm()
            }
            setIsLoading(false)
        })
    }

    return (
        <>
            <Box mt={1} mb={3} width="50%">
                <Typography
                    variant="body2"
                    align="justify"
                    sx={{ lineHeight: '1.8', fontWeight: '600' }}
                >
                    You can change the password to your Ride-to-Work scheme
                    below. Please remember passwords must be: 8 characters or
                    more and include 1 UPPERCASE character, 1 lowercase
                    character, 1 special character, 1 numeric character.
                </Typography>
            </Box>

            <Formik
                initialValues={initialValues}
                onSubmit={(values, { resetForm }) => {
                    submitHandler(values, resetForm)
                }}
                validationSchema={adminChangePasswordSchemaValidator}
            >
                {(formik) => (
                    <Grid container spacing={3}>
                        {errorMessage && (
                            <Grid item xs={12}>
                                <ErrorMessage text={errorMessage} />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Typography
                                color="primary"
                                variant="body1"
                                fontWeight="bold"
                            >
                                Enter your current password
                            </Typography>
                            <Divider
                                sx={{
                                    color: 'primary',
                                    borderBottomWidth: '2px',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                name="currentPassword"
                                label="Current Password"
                                required
                                fullWidth
                                type="password"
                                passwordToggle
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                color="primary"
                                variant="body1"
                                fontWeight="bold"
                            >
                                Now enter your new password
                            </Typography>
                            <Divider
                                sx={{
                                    color: 'primary',
                                    borderBottomWidth: '2px',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                name="newPassword"
                                label="Enter new password"
                                type="password"
                                passwordToggle
                                required
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Input
                                name="checkNewPassword"
                                label="Confirm new password"
                                type="password"
                                passwordToggle
                                required
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Divider
                                sx={{
                                    marginTop: '1rem',
                                    borderBottom: '2px solid',
                                    borderColor: theme.palette.secondary.main,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                isLoading={isLoading}
                                text="Save new password"
                                onClick={() => formik.handleSubmit()}
                            />
                        </Grid>
                    </Grid>
                )}
            </Formik>
        </>
    )
}

export default AdminPanelChangePasswordPage
