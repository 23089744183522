import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import AdminSearchBar, { IOption } from '../../../components/AdminSearchBar'
import { useNavigate } from 'react-router-dom'

const SearchSchemeAdmin: FC = () => {
    const navigate = useNavigate()

    const goToAdmin = (options: IOption[], input: string) => {
        const id = options?.find(
            (element) => element.emailAddress === input
        )?.id
        if (id) navigate(`/ride-to-work-apply/evans-admin/admins/${id}`)
    }

    return (
        <>
            <Typography mb={2} mt={1}>
                You can search for a specific administrator using their email
                address.
            </Typography>
            <Box sx={{ width: { xs: '100%', sm: '50%', md: '25%' } }}>
                <AdminSearchBar onChoose={goToAdmin} />
            </Box>
        </>
    )
}

export default SearchSchemeAdmin
