import { FC, useState } from 'react'
import usePageTitle from 'hooks/usePageTitle'
import { Box, MenuItem, Typography } from '@mui/material'
import Table from 'components/Table'
import Button from 'components/Button'
import Modal from 'components/Modal/Modal'
import { Formik } from 'formik'
import SelectInput from 'components/SelectInput'
import {
    assignCustomDocumentToCompany,
    getCustomDocuments,
    getAllDocumentsForCompany,
    removeCustomDocumentFromCompany,
} from 'api/evansAdmin'
import { useMutation, useQuery } from 'react-query'
import useStore from 'store/Store'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'
import { assignCustomDocumentToCompanyValidator } from 'utils/ValidationSchema'
import { AddCircleOutline } from '@mui/icons-material'
import { GridCellParams } from '@mui/x-data-grid'

const CompanyDocuments: FC = () => {
    usePageTitle('Company Documents')
    const { companyId } = useParams()
    const { openNotification } = useStore()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const initialValues = {
        documentId: '',
    }

    const columns = [
        {
            headerName: 'Name',
            flex: 2,
            field: 'name',
        },
        {
            headerName: 'Category',
            flex: 2,
            field: 'category',
        },
        {
            headerName: 'Remove From Company',
            width: 160,
            field: 'remove',
            renderCell: (params: GridCellParams) => (
                <Button
                    text="Remove"
                    error
                    fullWidth
                    isLoading={
                        unlinkDocumentFromCompanyMutation.isLoading &&
                        unlinkDocumentFromCompanyMutation.variables == params.id
                    }
                    onClick={() =>
                        unlinkDocumentFromCompanyMutation.mutate(
                            params.id as string
                        )
                    }
                />
            ),
        },
    ]

    const {
        data: companyDocumentsResponse,
        refetch,
        isLoading: areDocumentsLoading,
    } = useQuery('getAllDocumentsForCompany', () =>
        getAllDocumentsForCompany(companyId as string)
    )

    const unlinkDocumentFromCompanyMutation = useMutation(
        (documentId: string) =>
            removeCustomDocumentFromCompany({
                documentId,
                companyId: companyId as string,
            }),
        {
            onSuccess: () => {
                refetch()
            },

            onError: (error: AxiosError<{ message: string }>) => {
                openNotification(
                    error?.response?.data.message || 'Something went wrong',
                    'error'
                )
            },
        }
    )

    const { data: response } = useQuery(
        'getCustomDocuments',
        getCustomDocuments
    )

    const mutation = useMutation(
        (data: { documentId: string; companyId: string }) =>
            assignCustomDocumentToCompany(data),
        {
            onSuccess: () => {
                openNotification(
                    'Document has been assigned to company',
                    'success'
                )
                setIsModalOpen(false)
                refetch()
            },
            onError: (error: AxiosError<{ message: string }>) => {
                openNotification(
                    error?.response?.data.message || 'Something went wrong',
                    'error'
                )
            },
        }
    )

    function submitHandler(documentId: string) {
        mutation.mutate({
            companyId: companyId as string,
            documentId: documentId,
        })
    }

    function displayDocumentsToAssign() {
        type Document = Record<string, string>
        const { data } = response || {}
        const { data: companyDocuments } = companyDocumentsResponse || {}
        const assignedDocumentsId = companyDocuments.map(
            (element: Document) => element.id
        )

        return data
            .filter(
                (element: Document) => !assignedDocumentsId.includes(element.id)
            )
            .map((element: Document) => (
                <MenuItem key={element.id} value={element.id}>
                    {element.name}
                </MenuItem>
            ))
    }

    return (
        <>
            <Modal
                title="Assign Custom Document To Company"
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            >
                <Formik
                    validationSchema={assignCustomDocumentToCompanyValidator}
                    initialValues={initialValues}
                    onSubmit={(values) => submitHandler(values.documentId)}
                >
                    {(formik) => (
                        <Box padding="2rem 1rem" display="grid" gap="1.5rem">
                            <SelectInput
                                fullWidth
                                name="documentId"
                                label="Document Name"
                                options={() => displayDocumentsToAssign()}
                            />
                            <Button
                                onClick={formik.handleSubmit}
                                isLoading={mutation.isLoading}
                                text="Submit"
                                fullWidth
                            />
                        </Box>
                    )}
                </Formik>
            </Modal>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="h4" color="primary">
                    Company Documents
                </Typography>
                <AddCircleOutline
                    onClick={() => setIsModalOpen(true)}
                    color="primary"
                    sx={{ cursor: 'pointer', transform: 'scale(1.2)' }}
                />
            </Box>
            <Table
                disableSelect
                rows={companyDocumentsResponse?.data}
                columns={columns}
                loading={areDocumentsLoading}
            />
        </>
    )
}

export default CompanyDocuments
