import { useEffect } from 'react'
import useStore from 'store/Store'

const useBroadcast = () => {
    const broadcast = new BroadcastChannel('user')
    const { handleEvansAdminState, handleSchemeAdminState } = useStore()
    useEffect(() => {
        broadcast.onmessage = (event) => {
            if (event.data.type === 'logoutEvansAdmin') {
                handleEvansAdminState(false)
            }

            if (event.data.type === 'logoutSchemeAdmin') {
                handleSchemeAdminState(false)
            }

            if (event.data.type === 'loginSchemeAdmin') {
                handleSchemeAdminState(true)
            }

            if (event.data.type === 'loginEvansAdmin') {
                handleEvansAdminState(true, event.data.message)
            }
        }
    }, [])

    function handleBroadcastState(message: string, email?: string) {
        broadcast.postMessage({
            type: message,
            message: email,
        })
    }

    return { handleBroadcastState }
}

export default useBroadcast
