import { FC } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { OLD_PATHS } from 'utils/constants'

const NotFound: FC = () => {
    const { pathname } = useLocation()
    const paths = pathname.split('/')
    const oldPath = OLD_PATHS.find((element: string) => paths.includes(element))

    if (oldPath) {
        return (
            <Navigate
                to={`/ride-to-work-apply/certificate/get-certificate?UUID=${
                    paths[paths.length - 1]
                }`}
            />
        )
    }

    if (paths[2] === 'schemeadmin') {
        return <Navigate to="/ride-to-work-apply/schemeadmin/companies" />
    }

    if (paths[2] === 'evans-admin') {
        return <Navigate to="/ride-to-work-apply/evans-admin/companies" />
    }

    return <Navigate to="/ride-to-work-apply/certificate/get-certificate" />
}

export default NotFound
