import { FC, useEffect } from 'react'
import { Box } from '@mui/system'
import Table from 'components/Table'
import useManageCertificates from './useManageCertificates'
import Button from 'components/Button'
import Modal from 'components/Modal/Modal'
import { Formik } from 'formik'
import { useParams } from 'react-router-dom'
import {
    ICertificateSchemeAdminList,
    ICertificateFiltersSchemeAdmin,
} from 'Interfaces/Certificate'
import {
    DownloadOutlined,
    ExpandMore,
    NotificationsOutlined,
    ThumbUpOffAlt,
} from '@mui/icons-material'
import { GridSelectionModel } from '@mui/x-data-grid'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Chip,
    Divider,
    Grid,
    Typography,
} from '@mui/material'
import usePageTitle from 'hooks/usePageTitle'
import ErrorMessage from 'components/ErrorMessage'
import { schemeAdminFilterCertificatesValidator } from 'utils/ValidationSchema'
import LoadingSpinner from 'components/LoadingSpinner'
import CertificatesDescriptionModal from 'components/Modal/CertificatesDescriptionModal'
import DatePicker from 'components/DatePicker'
import { convertToPounds } from 'utils/convertToPounds'

const SchemeAdminCertificates: FC = () => {
    usePageTitle('Manage Certificates')

    const { companyId } = useParams()
    const {
        columns,
        filters,
        response,
        isLoading,
        applyFilters,
        clearFilters,
        shouldDisableFilterButton,
        exportCertificateToCsv,
        mutation,
        certificates,
        isApproveModalOpen,
        setIsApproveModalOpen,
        isRejectModalOpen,
        setIsRejectModalOpen,
        selectedCertificates,
        setSelectedCertificates,
        handlePageChange,
        errorMsg,
        selectedStatuses,
        setSelectedStatuses,
        isAccordionFilterExpanend,
        setIsAccordionFilterExpanend,
        certificatesStatuses,
        showLoadingSpinner,
        isDescriptionModalOpen,
        isFetching,
        setIsDescriptionModalOpen,
        formRef,
        isInitialLoading,
    } = useManageCertificates(companyId as string)

    const { page, size, ...restFilters } = filters

    const flexContainer = {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
    }

    useEffect(() => {
        if (!isInitialLoading)
            applyFilters(
                formRef?.current?.values as Omit<
                    ICertificateFiltersSchemeAdmin,
                    'page' | 'size'
                >
            )
    }, [selectedStatuses])

    if (showLoadingSpinner) {
        return <LoadingSpinner />
    }

    if (errorMsg) {
        return <ErrorMessage text={errorMsg as string} />
    }

    return (
        <Box mt={2}>
            <Modal
                hideHeader
                isOpen={isApproveModalOpen}
                onClose={() => setIsApproveModalOpen(false)}
            >
                <Typography variant="h6">
                    Approving these certificates impilies that you acknowledge
                    you have received the Hire Agreement from all the employess
                    involved. Do you want to proceed?
                </Typography>
                <Box
                    marginTop="1rem"
                    display="flex"
                    justifyContent="flex-end"
                    gap="0.5rem"
                >
                    <Button
                        outlined
                        colorPrimary
                        text="No, cancel"
                        onClick={() => setIsApproveModalOpen(false)}
                    />
                    <Button
                        text="Yes, approve"
                        isLoading={mutation.isLoading}
                        onClick={() => mutation.mutate('ACCEPT')}
                    />
                </Box>
            </Modal>
            <Modal
                hideHeader
                isOpen={isRejectModalOpen}
                onClose={() => setIsRejectModalOpen(false)}
            >
                <Box>
                    <Typography variant="h6" mb={2}>
                        Are you sure you want to reject these applications?
                    </Typography>

                    <Box display="flex" gap="0.5rem" justifyContent="flex-end">
                        <Button
                            outlined
                            colorPrimary
                            text="No, cancel"
                            onClick={() => {
                                setIsRejectModalOpen(false)
                                setSelectedCertificates([])
                            }}
                        />
                        <Button
                            isLoading={mutation.isLoading}
                            error
                            text="Yes, reject"
                            onClick={() => mutation.mutate('DECLINE')}
                        />
                    </Box>
                </Box>
            </Modal>

            <CertificatesDescriptionModal
                isOpen={isDescriptionModalOpen}
                title="Statuses Description"
                onClose={() => setIsDescriptionModalOpen(false)}
                maxWidth="md"
                data={certificatesStatuses}
            />

            <Formik
                innerRef={formRef}
                validationSchema={schemeAdminFilterCertificatesValidator}
                initialValues={restFilters}
                onSubmit={(values) => applyFilters(values)}
            >
                {(formik) => (
                    <Grid container>
                        <Grid item container spacing={2}>
                            <Grid item xs={12}>
                                <Divider>
                                    <Typography
                                        fontWeight="bold"
                                        color="primary"
                                        variant="h4"
                                    >
                                        Manage certificate applications
                                    </Typography>
                                </Divider>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                    color="primary"
                                    variant="h6"
                                    fontWeight="bold"
                                    mb={1}
                                >
                                    Notifications:
                                </Typography>

                                <Box sx={flexContainer} mb={1}>
                                    <NotificationsOutlined />
                                    <Typography>
                                        You have{' '}
                                        {certificates?.pendingEmployerApproval}{' '}
                                        Ride-to-Work certificates pending to
                                        approve
                                    </Typography>
                                </Box>
                                <Box sx={flexContainer} mb={1}>
                                    <ThumbUpOffAlt color="success" />
                                    <Typography>
                                        {certificates?.approved} certificates
                                        have been approved in total
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion
                                disableGutters
                                expanded={isAccordionFilterExpanend}
                                onChange={() =>
                                    setIsAccordionFilterExpanend(
                                        (prevState: boolean) => !prevState
                                    )
                                }
                                elevation={0}
                            >
                                <AccordionSummary
                                    sx={{ padding: 0 }}
                                    expandIcon={
                                        <ExpandMore
                                            sx={{ transform: 'scale(1.5)' }}
                                        />
                                    }
                                >
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        color="primary"
                                    >
                                        {isAccordionFilterExpanend
                                            ? 'Hide '
                                            : 'Show '}
                                        Filters:
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: 0 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <Typography
                                                color="primary"
                                                fontWeight="bold"
                                                mb={1}
                                            >
                                                Filter by status
                                            </Typography>

                                            <Divider />
                                            <Box
                                                marginTop="1rem"
                                                display="flex"
                                                flexWrap="wrap"
                                                gap="1rem"
                                            >
                                                {certificatesStatuses.map(
                                                    (element: {
                                                        name: string
                                                        description: string
                                                    }) => (
                                                        <Chip
                                                            key={element.name}
                                                            label={element.name.replaceAll(
                                                                '_',
                                                                ' '
                                                            )}
                                                            onClick={() => {
                                                                if (
                                                                    !selectedStatuses.includes(
                                                                        element.name
                                                                    )
                                                                ) {
                                                                    setSelectedStatuses(
                                                                        (
                                                                            prevState
                                                                        ) => [
                                                                            ...(prevState ||
                                                                                []),
                                                                            element.name,
                                                                        ]
                                                                    )
                                                                } else {
                                                                    setSelectedStatuses(
                                                                        (
                                                                            prevState
                                                                        ) =>
                                                                            prevState?.filter(
                                                                                (
                                                                                    item
                                                                                ) =>
                                                                                    item !==
                                                                                    element.name
                                                                            )
                                                                    )
                                                                }
                                                            }}
                                                            color={
                                                                selectedStatuses.includes(
                                                                    element.name
                                                                )
                                                                    ? 'primary'
                                                                    : 'default'
                                                            }
                                                        />
                                                    )
                                                )}
                                                <Typography
                                                    fontWeight="bold"
                                                    color="primary"
                                                    onClick={() =>
                                                        setIsDescriptionModalOpen(
                                                            true
                                                        )
                                                    }
                                                    sx={{
                                                        textDecoration:
                                                            'underline',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    Status Explanation
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            container
                                            spacing={2}
                                        >
                                            <Grid item xs={12}>
                                                <Typography
                                                    color="primary"
                                                    fontWeight="bold"
                                                    mb={1}
                                                >
                                                    Filter by dates
                                                </Typography>
                                                <Divider />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <DatePicker
                                                    fullWidth
                                                    apiErrors={formik.status}
                                                    value={
                                                        formik.values
                                                            .applicationDateFrom as string
                                                    }
                                                    onBlur={() => {
                                                        formik.setFieldTouched(
                                                            'applicationDateFrom',
                                                            true
                                                        )
                                                    }}
                                                    onChange={(
                                                        value: string | null
                                                    ) => {
                                                        formik.setFieldValue(
                                                            'applicationDateFrom',
                                                            value
                                                        )
                                                    }}
                                                    label="Application Date From"
                                                    name="applicationDateFrom"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <DatePicker
                                                    fullWidth
                                                    apiErrors={formik.status}
                                                    value={
                                                        formik.values
                                                            .applicationDateTo as string
                                                    }
                                                    onBlur={() => {
                                                        formik.setFieldTouched(
                                                            'applicationDateTo',
                                                            true
                                                        )
                                                    }}
                                                    onChange={(
                                                        value: string | null
                                                    ) => {
                                                        formik.setFieldValue(
                                                            'applicationDateTo',
                                                            value
                                                        )
                                                    }}
                                                    label="Application Date To"
                                                    name="applicationDateTo"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Button
                                                    isLoading={isFetching}
                                                    fullWidth
                                                    text="Search"
                                                    onClick={
                                                        formik.handleSubmit
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Button
                                                    fullWidth
                                                    colorPrimary
                                                    disabled={shouldDisableFilterButton()}
                                                    onClick={() => {
                                                        clearFilters()
                                                        formRef.current?.setFieldValue(
                                                            'applicationDateFrom',
                                                            null
                                                        )
                                                        formRef.current?.setFieldValue(
                                                            'applicationDateTo',
                                                            null
                                                        )

                                                        formRef.current?.setFieldTouched(
                                                            'applicationDateFrom',
                                                            false
                                                        )
                                                        formRef.current?.setFieldTouched(
                                                            'applicationDateTo',
                                                            false
                                                        )
                                                    }}
                                                    text="Clear Filters"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                )}
            </Formik>
            <Box
                display="flex"
                justifyContent="space-between"
                marginBottom=".2rem"
                gap="1rem"
                alignItems="center"
                marginTop="1rem"
            >
                <DownloadOutlined
                    color="primary"
                    onClick={() => exportCertificateToCsv.mutate()}
                    sx={{ cursor: 'pointer', transform: 'scale(1.1)' }}
                />
                <Box display="flex" alignItems="center" gap="0.5rem">
                    {selectedCertificates.length > 1 && (
                        <>
                            <Button
                                text="Approve selected"
                                onClick={() => setIsApproveModalOpen(true)}
                            />
                            <Button
                                text="Reject selected"
                                error
                                onClick={() => setIsRejectModalOpen(true)}
                            />
                        </>
                    )}
                </Box>
            </Box>

            <Table
                checkBox
                pageSize={filters.size}
                disableSelect
                height={!isAccordionFilterExpanend ? '500px' : '350px'}
                onPageChange={handlePageChange}
                isRowSelectable={(params) => params.row.status == 'PENDING'}
                loading={isLoading}
                totalElementsLength={response?.data?.totalElements}
                columns={columns}
                onSelect={(certificatesId: GridSelectionModel) => {
                    const selectedElements = response?.data.content
                        .filter((element: Record<string, string>) =>
                            certificatesId.includes(element.id)
                        )
                        .map((element: Record<string, string>) => element.id)
                    setSelectedCertificates(selectedElements)
                }}
                rows={response?.data.content.map(
                    (element: ICertificateSchemeAdminList) => {
                        const {
                            firstName,
                            lastName,
                            applicationDate,
                            totalAmount,
                            salarySacrifice,
                        } = element
                        const employeeName = `${firstName} ${lastName}`
                        if (element.id) {
                            return {
                                ...element,
                                salarySacrifice:
                                    convertToPounds(salarySacrifice),
                                totalAmount: convertToPounds(totalAmount),
                                employeeName,
                            }
                        }

                        return {
                            ...element,
                            employeeName,
                            salarySacrifice: convertToPounds(salarySacrifice),
                            totalAmount: convertToPounds(totalAmount),
                            id:
                                firstName +
                                lastName +
                                applicationDate +
                                totalAmount,
                        }
                    }
                )}
                paginate
                currentPage={filters.page + 1}
            />
        </Box>
    )
}

export default SchemeAdminCertificates
