import { Box, Tab, Tabs, Typography } from '@mui/material'
import { useState } from 'react'
import SearchSchemeAdmin from './SearchSchemeAdmin'
import UnconfirmedSchemeAdmins from './UnconfirmedSchemeAdmins'

const EvansAdminMenageAdmins = () => {
    const [currentScreen, setCurrentScreen] = useState('scheme admins')

    return (
        <Box padding="1rem">
            <Typography color="primary" variant="h4">
                Admin Search
            </Typography>
            <Tabs
                sx={{ marginTop: '1rem' }}
                value={currentScreen}
                onChange={(event, value: string) => setCurrentScreen(value)}
            >
                <Tab value="scheme admins" label="Scheme Admins" />
                <Tab value="unconfirmed admins" label="Unconfirmed Admins" />
            </Tabs>

            {currentScreen === 'scheme admins' && <SearchSchemeAdmin />}
            {currentScreen === 'unconfirmed admins' && (
                <UnconfirmedSchemeAdmins />
            )}
        </Box>
    )
}

export default EvansAdminMenageAdmins
