import { FC, useState } from 'react'
import { Formik } from 'formik'
import Button from 'components/Button'
import { IAssignTemplateToCompany } from 'Interfaces/Templates'
import { useMutation, useQuery } from 'react-query'
import {
    assignAgreementToCompany,
    getCompanyDetails,
    getAllTemplatesList,
} from 'api/evansAdmin'
import { Box, MenuItem } from '@mui/material'
import LoadingSpinner from 'components/LoadingSpinner'
import SelectInput from 'components/SelectInput'
import { ITemplateList } from 'Interfaces/Templates'
import useStore from 'store/Store'
import { AxiosError } from 'axios'

interface IProps {
    companyId: string
    successCallback: () => void
}

const AssignTemplate: FC<IProps> = ({ companyId, successCallback }) => {
    const [templates, setTemplates] = useState([])
    const { data, isFetching: isCompanyTemplatesFetching } = useQuery(
        'getCompanyTemplates',
        () => getCompanyDetails(companyId as string),
        {}
    )

    const { isFetching } = useQuery(
        'getAllTemplatesList',
        () => getAllTemplatesList(),
        {
            enabled: !isCompanyTemplatesFetching,
            onSuccess: (response) => {
                setTemplates(response?.data)
            },
        }
    )

    const {
        under18AgreementTemplateId,
        adultAgreementTemplateId,
        ownershipOption,
    } = data?.data || {}

    const initialValues = {
        adultAgreementTemplateId: String(adultAgreementTemplateId),
        under18AgreementTemplateId: String(under18AgreementTemplateId),
    }

    const { openNotification } = useStore()

    const assignAgreementMutation = useMutation(
        (data: IAssignTemplateToCompany) =>
            assignAgreementToCompany(companyId, data),
        {
            onSuccess: () => {
                openNotification(
                    'Template has been assigned to company',
                    'success'
                )
                successCallback()
            },
            onError: (error: AxiosError<{ message: string }>) => {
                openNotification(
                    error?.response?.data.message || 'Something went wrong',
                    'error'
                )
            },
        }
    )

    function submitHandler(values: IAssignTemplateToCompany) {
        assignAgreementMutation.mutate(values)
    }

    function displayAgreements(isAdult: boolean) {
        return templates
            ?.filter((element: ITemplateList) =>
                isAdult ? !element.isUnder18 : element.isUnder18
            )
            .filter((element: ITemplateList) =>
                ownershipOption !== 'Managed by Evans Cycles'
                    ? element.thirdStepAgreementType === 'PRE_CONTRACT'
                    : element.thirdStepAgreementType === 'SALARY_SACRIFICE'
            )
            .map((element: ITemplateList) => {
                return (
                    <MenuItem key={element.id} value={element.id}>
                        {element.title.replaceAll('_', ' ')}
                    </MenuItem>
                )
            })
    }

    const boxStyle = {
        padding: '2rem 0 0',
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
    }

    if (isFetching || isCompanyTemplatesFetching) {
        return (
            <Box sx={boxStyle}>
                <LoadingSpinner />
            </Box>
        )
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => submitHandler(values)}
        >
            {(formik) => (
                <Box sx={boxStyle}>
                    <SelectInput
                        options={() => displayAgreements(true)}
                        name="adultAgreementTemplateId"
                        label="Adult Agreement Template"
                        required
                    />
                    <SelectInput
                        options={() => displayAgreements(false)}
                        name="under18AgreementTemplateId"
                        label="Under 18 Agreement Template"
                        required
                    />

                    <Button
                        isLoading={assignAgreementMutation.isLoading}
                        text="Save"
                        onClick={formik.handleSubmit}
                    />
                </Box>
            )}
        </Formik>
    )
}

export default AssignTemplate
