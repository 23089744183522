import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useMutation, useQuery } from 'react-query'
import { Formik } from 'formik'
import {
    Box,
    Stack,
    Button as MuiButton,
    Grid,
    Paper,
    Typography,
    Divider,
} from '@mui/material'
import { Visibility } from '@mui/icons-material'
import Editor from 'components/Editor'
import FormSubtitle from 'components/FormSubtitle'
import ButtonsSection from './components/ButtonsSection'
import Button from 'components/Button'
import CustomContentBox from './components/CustomContentBox'
import Modal from 'components/Modal/Modal'
import { updateContentElement } from 'api/evansAdmin'
import { editorInitSettings } from './editorInitSettings'
import LoadingSpinner from 'components/LoadingSpinner'
import useStore from 'store/Store'
import Input from 'components/Input'
import { contentFormValidator } from 'utils/ValidationSchema'
import { IContent } from 'Interfaces/CMS'
import { getContentElement } from 'api/public'
import { AxiosError, AxiosResponse } from 'axios'
import ErrorMessage from 'components/ErrorMessage'

const EvansAdminCMSEditPage: FC = () => {
    const { contentId } = useParams()
    const { openNotification } = useStore()

    const [showPreview, setShowPreview] = useState<boolean>(false)

    const {
        data: response,
        isFetching,
        error,
    } = useQuery<AxiosResponse, AxiosError<{ message: string }>>(
        ['getContentElement', contentId],
        () => getContentElement(Number(contentId))
    )

    const initialValues = response?.data

    const mutation = useMutation(
        (data: IContent) => updateContentElement(data),
        {
            onSuccess: () => {
                openNotification('Data has been saved', 'success')
            },
            onError: () => {
                openNotification('Something went wrong', 'error')
            },
        }
    )

    const handleSubmit = (values: IContent) => {
        mutation.mutate(values)
    }

    if (!response?.data && isFetching) {
        return <LoadingSpinner />
    }

    const { message: errorMsg } = error?.response?.data || {}

    if (errorMsg) {
        return <ErrorMessage text={errorMsg} />
    }

    return (
        <Stack spacing={2}>
            <Helmet>
                <title>{`Content Management - Edit ${response?.data.name}`}</title>
            </Helmet>
            <FormSubtitle text={`Editing content: ${response?.data.name}`} />
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={contentFormValidator}
            >
                {({ values, setFieldValue }) => (
                    <>
                        <Grid container>
                            <Grid
                                display="flex"
                                flexDirection="column"
                                gap={2}
                                item
                                sm={12}
                                md={8}
                            >
                                <Input
                                    fullWidth
                                    maxWidth="100%"
                                    label="Title"
                                    name="title"
                                />
                                <Editor
                                    minHeight={600}
                                    value={values.content || ''}
                                    onChange={(text: unknown) =>
                                        setFieldValue('content', text)
                                    }
                                    init={editorInitSettings}
                                />
                            </Grid>
                            <Grid
                                item
                                sm={12}
                                md={4}
                                sx={{
                                    paddingLeft: { md: 2 },
                                    paddingTop: { sm: 2, md: 0 },
                                }}
                                gap={2}
                            >
                                {values.content?.includes('<ul>') && (
                                    <Paper sx={{ marginBottom: 2 }}>
                                        <Box padding={2}>
                                            <Typography
                                                variant="h6"
                                                color="primary"
                                            >
                                                Custom settings
                                            </Typography>
                                        </Box>
                                        <Divider />
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            padding={2}
                                        >
                                            <Input
                                                checkBox
                                                name="customBullets"
                                                label={`Use "checkmark" bullets`}
                                            />
                                        </Box>
                                    </Paper>
                                )}
                                <ButtonsSection
                                    buttons={values.buttons ?? []}
                                    onButtonsChange={(buttons) => {
                                        setFieldValue('buttons', buttons)
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="start"
                            gap={1}
                        >
                            <MuiButton
                                disableElevation
                                startIcon={<Visibility />}
                                onClick={() => setShowPreview(true)}
                                color="secondary"
                                variant="contained"
                            >
                                Preview
                            </MuiButton>
                            <Button
                                text="Save"
                                onClick={() => {
                                    handleSubmit(values)
                                }}
                                colorPrimary
                            />
                        </Box>

                        <Modal
                            isOpen={showPreview}
                            title="Preview"
                            onClose={() => setShowPreview(false)}
                        >
                            <Paper sx={{ padding: 2, marginTop: 2 }}>
                                <CustomContentBox contentElement={values} />
                            </Paper>
                        </Modal>
                    </>
                )}
            </Formik>
        </Stack>
    )
}

export default EvansAdminCMSEditPage
