import { FC } from 'react'
import { AccountCircle } from '@mui/icons-material'
import {
    Divider,
    Paper,
    Typography,
    Box,
    useTheme,
    useMediaQuery,
} from '@mui/material'
import Button from 'components/Button'
import Input from 'components/Input'
import { Formik } from 'formik'
import useAuth from 'auth/useAuth'
import usePageTitle from 'hooks/usePageTitle'

const EvansAdminLoginPage: FC = () => {
    usePageTitle('Evans Admin Login')
    const theme = useTheme()
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'))
    const { login, loginEvansAdminError, isLoading, userRoleError } = useAuth()
    const initialValues = {
        email: process.env.REACT_APP_SCHEME_ADMIN_EMAIL as string,
        password: process.env.REACT_APP_SCHEME_ADMIN_PASSWORD as string,
    }

    function submitHandler(values: { email: string; password: string }) {
        const { email, password } = values
        login('Evans-admin', email, password)
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => submitHandler(values)}
        >
            {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                    <Paper
                        elevation={4}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '1.5rem',
                            padding: '4rem 3rem',
                            maxWidth: '600px',
                            margin: '0 auto',
                            justifyContent: 'center',
                            textAlign: 'center',
                            marginTop: '4rem',
                        }}
                    >
                        <Box mb={3}>
                            <AccountCircle
                                color="primary"
                                sx={{ transform: 'scale(3)' }}
                            />
                        </Box>
                        <Typography
                            color="primary"
                            variant={isMobileScreen ? 'h5' : 'h4'}
                            mb={2}
                            fontWeight="bold"
                        >
                            Ride-to-Work Administrator
                        </Typography>
                        {loginEvansAdminError && (
                            <Typography color="error">
                                {loginEvansAdminError}
                            </Typography>
                        )}
                        {userRoleError && (
                            <Typography color="error">
                                {userRoleError}
                            </Typography>
                        )}
                        <Input name="email" required label="Email" fullWidth />
                        <Input
                            name="password"
                            required
                            label="Password"
                            fullWidth
                            type="password"
                            passwordToggle
                        />

                        <Button
                            isLoading={isLoading}
                            fullWidth
                            submit
                            text="Login"
                        />

                        <Divider
                            sx={{ width: '100%', borderBottomWidth: '2px' }}
                        />
                        <Typography variant="caption" sx={{}}>
                            If you have forgotten your username or password{' '}
                            please contact your administrator
                        </Typography>
                    </Paper>
                </form>
            )}
        </Formik>
    )
}

export default EvansAdminLoginPage
