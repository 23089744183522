import React, { FC } from 'react'
import { Tooltip } from '@mui/material'
import { Info } from '@mui/icons-material'

interface IProps {
    children?: JSX.Element
    text: string | JSX.Element
    color?: 'primary' | 'secondary'
}

const CustomTooltip: FC<IProps> = ({ children, text, color }) => {
    return (
        <Tooltip sx={{ transform: 'scale(0.9)' }} title={text}>
            {children || <Info color={color ? color : 'primary'} />}
        </Tooltip>
    )
}

export default CustomTooltip
