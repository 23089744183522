import { FC, ReactNode } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import {
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material'

interface IProps {
    children: ReactNode
    link?: string
    text: string
    onClick?: () => void
}

const SidebarListItem: FC<IProps> = ({ children, link, text, onClick }) => {
    const navigate = useNavigate()
    const location = useLocation()
    async function navigateUser() {
        if (onClick) onClick()
        if (link) navigate(link)
    }
    const isLinkActive = () => {
        let correctLink = link
        if (link === '/ride-to-work-apply/schemeadmin')
            correctLink = '/ride-to-work-apply/schemeadmin/companies'
        return link && location.pathname.includes(correctLink ?? '')
    }

    return (
        <ListItem
            sx={{
                color: isLinkActive()
                    ? 'secondary.main'
                    : 'primary.contrastText',
                '&:hover .MuiListItemIcon-root': {
                    color: 'secondary.main',
                },
                '&:hover .MuiListItemText-root': {
                    color: 'secondary.main',
                },
            }}
        >
            <ListItemButton onClick={() => navigateUser()}>
                <ListItemIcon
                    sx={{
                        color: isLinkActive()
                            ? 'secondary.main'
                            : 'primary.contrastText',
                        transform: {
                            xs: '1rem',
                            sm: 'scale(1.3)',
                            lg: 'scale(1)',
                        },
                    }}
                >
                    {children}
                </ListItemIcon>
                <ListItemText>
                    <Typography
                        sx={{
                            fontSize: { xs: '1rem', sm: '1.2rem', lg: '1rem' },
                        }}
                    >
                        {text}
                    </Typography>
                </ListItemText>
            </ListItemButton>
        </ListItem>
    )
}

export default SidebarListItem
