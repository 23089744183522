import { FC } from 'react'
import { ArrowUpward, ArrowDownward } from '@mui/icons-material'

interface ISortingIcon {
    isVisible: boolean
    value: string
    ascending: boolean
    setValue: (value: string) => void
}

const SortingIcon: FC<ISortingIcon> = ({
    isVisible,
    value,
    ascending,
    setValue,
}) => {
    if (isVisible && ascending) {
        return (
            <ArrowUpward
                sx={{ cursor: 'pointer', transform: 'scale(0.6)' }}
                onClick={() => setValue(value)}
            />
        )
    }

    if (isVisible && !ascending) {
        return (
            <ArrowDownward
                sx={{ cursor: 'pointer', transform: 'scale(0.6)' }}
                onClick={() => setValue(value)}
            />
        )
    }

    return null
}

export default SortingIcon
