import { FC } from 'react'
import EvansLogo from 'assets/Evans-Cycles-logo.png'
import { Box } from '@mui/material'
import { useLocation } from 'react-router-dom'
import EvansAdminHeader from 'layout/EvansAdminHeader'
import SchemeAdminHeader from 'layout/SchemeAdminHeader'

const boxStyle = {
    position: 'relative',
    borderBottom: '1px solid',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px',
}

const Header: FC = () => {
    const location = useLocation()

    if (location.pathname.includes('savings-calculator')) {
        return null
    }

    if (location.pathname.includes('evans-admin')) {
        return <EvansAdminHeader />
    }

    if (
        location.pathname.includes('schemeadmin') ||
        location.pathname.includes('sign-up/confirm')
    ) {
        return <SchemeAdminHeader />
    }

    return (
        <Box sx={boxStyle}>
            <img width="145px" height="33px" src={EvansLogo} />
        </Box>
    )
}

export default Header
