export const container = {
    display: 'grid',

    gap: '1rem',
    padding: '3rem 0',
}

export const submitButtonStyle = {
    color: 'primary.contrastText',

    padding: '.7rem 7rem',

    fontWeight: 'bold',
}

export const stepperIconStyle = {
    fontSize: '4rem',
}

export const flexContainer = {
    display: 'flex',
    gap: '1rem',
    flexWrap: 'wrap',
}
