import React, { FC } from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Breakpoint,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import {
    DialogTitleStyle,
    dialogCloseIconStyle,
    DialogContentStyle,
} from './ModalStyle'

export interface IProps {
    isOpen: boolean
    onClose?: () => void
    title?: string
    children?: React.ReactNode
    height?: string
    hideHeader?: boolean
    maxWidth?: Breakpoint
    scrollable?: boolean
}

const Modal: FC<IProps> = ({
    isOpen,
    onClose,
    title,
    children,
    hideHeader,
    maxWidth,
    scrollable,
}) => {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth={maxWidth || 'sm'}
            fullWidth
        >
            {!hideHeader && (
                <DialogTitle sx={DialogTitleStyle}>
                    <span>{title}</span>
                    <IconButton sx={dialogCloseIconStyle} onClick={onClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
            )}
            <DialogContent
                sx={{ ...DialogContentStyle, padding: scrollable ? 0 : '1rem' }}
            >
                {children}
            </DialogContent>
        </Dialog>
    )
}

export default Modal
