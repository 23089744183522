import { FC, useState } from 'react'
import Modal, { IProps as ModalProps } from 'components/Modal/Modal'
import useAuth from 'auth/useAuth'
import { Box } from '@mui/material'
import { Formik } from 'formik'
import Input from 'components/Input'
import Button from 'components/Button'
import { completeNewPasswordSchemaValidator } from 'utils/ValidationSchema'
import ErrorMessage from 'components/ErrorMessage'

interface IProps extends ModalProps {
    email: string
    password: string
}

const ChangePasswordModal: FC<IProps> = ({ email, password, ...props }) => {
    const { completeNewPassword, isCompletePasswordLoading } = useAuth()
    const [error, setError] = useState('')
    const initialValues = {
        newPassword: '',
        checkNewPassword: '',
    }

    function submitHandler(values: typeof initialValues) {
        const { newPassword } = values
        completeNewPassword(email, password, newPassword, (message) => {
            setError(message)
        })
    }

    return (
        <Modal {...props}>
            <Box
                sx={{
                    padding: '1.3rem',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                }}
            >
                <Formik
                    validationSchema={completeNewPasswordSchemaValidator}
                    initialValues={initialValues}
                    onSubmit={(values) => submitHandler(values)}
                >
                    {(formik) => (
                        <>
                            {error && <ErrorMessage text={error} />}

                            <Input
                                passwordToggle
                                fullWidth
                                required
                                name="newPassword"
                                label="New  Password"
                                type="password"
                            />
                            <Input
                                passwordToggle
                                fullWidth
                                required
                                name="checkNewPassword"
                                label="Confirm  Password"
                                type="password"
                            />
                            <Button
                                isLoading={isCompletePasswordLoading}
                                fullWidth
                                text="Submit"
                                onClick={formik.handleSubmit}
                            />
                        </>
                    )}
                </Formik>
            </Box>
        </Modal>
    )
}

export default ChangePasswordModal
