import { FC } from 'react'
import {
    Box,
    Typography,
    Container,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { Menu } from '@mui/icons-material'
import logo from 'assets/Evans_logo.svg'
import useStore from 'store/Store'

const navbarInner = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '1rem',
}

const EvansAdminHeader: FC = () => {
    const theme = useTheme()
    const isMobileScreen = useMediaQuery('(max-width:620px)')
    const isLargeScreen = useMediaQuery('(min-width:2000px)')
    const { handleEvansAdminNavigationState, isEvansAdmin } = useStore()
    const evansAdminNavigationStyle = {
        backgroundColor: 'primary.main',
        height: '100px',
        color: 'secondary.main',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        left: 0,
        width: '100%',
        right: 0,
        position: 'relative',
        zIndex: theme.zIndex.appBar,
    }
    return (
        <>
            <Box sx={evansAdminNavigationStyle}>
                <Container maxWidth="xl" sx={navbarInner}>
                    <Box display="flex" gap="2rem" alignItems="center">
                        {!isLargeScreen && isEvansAdmin && (
                            <Menu
                                onClick={() =>
                                    handleEvansAdminNavigationState()
                                }
                                sx={{
                                    cursor: 'pointer',
                                    fontSize: isMobileScreen
                                        ? '1.5rem'
                                        : '2rem',
                                }}
                            />
                        )}

                        <img
                            src={logo}
                            width={isMobileScreen ? '60px' : '145px'}
                            height="33px"
                        />
                    </Box>

                    <Typography variant={isMobileScreen ? 'caption' : 'h6'}>
                        Ride-to-Work Administrator Pages
                    </Typography>
                </Container>
            </Box>
        </>
    )
}

export default EvansAdminHeader
