import { FC, useRef, useState } from 'react'
import { PersonOutline } from '@mui/icons-material'
import {
    Typography,
    useTheme,
    Divider,
    Grid,
    Paper,
    Box,
    Tabs,
    Tab,
    Tooltip,
} from '@mui/material'
import Button from 'components/Button'
import { useParams, useNavigate } from 'react-router-dom'
import Tile from './components/Tile'
import { useQuery, useMutation } from 'react-query'
import { getCertificateDetails, getCertificateErrors } from 'api/schemeAdmin'
import LoadingSpinner from 'components/LoadingSpinner'
import { AxiosError, AxiosResponse } from 'axios'
import useStore from 'store/Store'
import {
    acceptCertificateProcess,
    rejectCertificateProcess,
} from 'api/schemeAdmin'
import Input from 'components/Input'
import { Formik, FormikProps } from 'formik'
import ErrorMessage from 'components/ErrorMessage'
import Modal from 'components/Modal/Modal'
import { convertToPounds } from 'utils/convertToPounds'

const AdminPanelApplicantDetailsPage: FC = () => {
    const { certificateId, companyId } = useParams()
    const { openNotification } = useStore()
    const formikRef = useRef<FormikProps<{ purchaseOrder: string }>>(null)
    const theme = useTheme()
    const navigate = useNavigate()
    const [isAgreementPreviewModalOpen, setIsAgreementPreviewModalOpen] =
        useState(false)

    const [errors, setErrors] = useState([])
    useQuery<
        AxiosResponse,
        AxiosError<{ response: Record<string, string>; message: string }>
    >(
        ['getCertificateErrors', certificateId],
        () => getCertificateErrors(certificateId as string),
        {
            enabled: Boolean(certificateId),
            onSuccess: (response) => {
                setErrors(response.data)
            },
        }
    )

    const [currentAgreement, setCurrentAgreement] = useState('thirdStep')

    const {
        data: response,
        isFetching,
        error,
    } = useQuery<AxiosResponse, AxiosError<{ message: string }>>(
        ['getCertificateDetails', certificateId],
        () => getCertificateDetails(certificateId as string),
        {
            onSuccess: (response) => {
                if (!response.data.thirdStepAgreementURL) {
                    setCurrentAgreement('hireAgreement')
                }
            },
        }
    )

    const { message: errorMsg } = error?.response?.data || {}

    const mutation = useMutation(
        (data: { listId: string; action: string; purchaseOrder?: string }) =>
            data.action === 'DECLINE'
                ? rejectCertificateProcess([data.listId])
                : acceptCertificateProcess([
                      {
                          certificateId: data.listId,
                          purchaseOrderNumber: data.purchaseOrder || '',
                      },
                  ]),
        {
            onSuccess: () => {
                navigate(
                    `/ride-to-work-apply/schemeadmin/companies/${companyId}/certificates`
                )
                openNotification(
                    'Status of certificate has been changed successfully ',
                    'success'
                )
            },

            onError: (error: AxiosError<{ message: string }>) => {
                openNotification(
                    error.response?.data.message || 'Something went wrong ',
                    'error'
                )
            },
        }
    )

    const {
        employee,
        applicationDate,
        approvalDate,
        certificateAmount,
        salarySacrificeAmount,
        status,
        purchaseOrder,
        thirdStepAgreementURL,
        hireAgreementURL,
        salarySacrificeDeductionFrequency,
        salarySacrificeTermMonths,
        hireAgreementTermMonths,
        singleSalarySacrificeAmount,
    } = response?.data || {}

    const {
        title,
        firstName,
        lastName,
        personalEmail,
        workEmail,
        payrollEmployeeNumber,
        mobileNumber,
        address,
        landline,
    } = employee || {}

    const { addressLine1, addressLine2, county, postcode, town } = address || {}

    const shouldDisableDeclineButtons = status != 'PENDING'

    const shouldDisableApproveButtons = status != 'PENDING' || errors.length > 0

    const shouldDisablePreviewButton =
        !thirdStepAgreementURL && !hireAgreementURL

    if (isFetching) {
        return <LoadingSpinner />
    }

    if (errorMsg) {
        return <ErrorMessage text={errorMsg} />
    }

    return (
        <>
            <Modal
                hideHeader
                maxWidth="md"
                isOpen={isAgreementPreviewModalOpen}
                onClose={() => setIsAgreementPreviewModalOpen(false)}
            >
                <Box sx={{ height: '600px' }}>
                    {Boolean(thirdStepAgreementURL && hireAgreementURL) && (
                        <Tabs
                            value={currentAgreement}
                            onChange={(event, value: string) =>
                                setCurrentAgreement(value)
                            }
                        >
                            <Tab
                                value="thirdStep"
                                label={
                                    thirdStepAgreementURL.includes(
                                        'salary_sacrifice_agreement.pdf'
                                    )
                                        ? 'Salary Sacrifice Agreement'
                                        : 'Pre Contract Agreement'
                                }
                            />
                            <Tab value="hireAgreement" label="Hire Agreement" />
                        </Tabs>
                    )}
                    <embed
                        src={
                            currentAgreement === 'thirdStep'
                                ? thirdStepAgreementURL
                                : hireAgreementURL
                        }
                        width="100%"
                        height="100%"
                    />
                </Box>
            </Modal>

            <Box mt={4}>
                <Box
                    sx={{
                        borderBottom: '2px solid',
                        borderColor: theme.palette.secondary.main,
                        paddingBottom: '0.5rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        gap: '1rem',
                    }}
                >
                    <Typography color="primary" variant="h4">
                        Applicant: {title + ' ' + firstName + ' ' + lastName}
                    </Typography>
                    <Button
                        disabled={shouldDisablePreviewButton}
                        colorPrimary
                        text="Preview Agreement"
                        onClick={() => setIsAgreementPreviewModalOpen(true)}
                    />
                </Box>
                <Paper
                    elevation={0}
                    sx={{
                        backgroundColor:
                            status === 'ACCEPTED'
                                ? theme.palette.success.light
                                : 'error.main',
                        maxWidth: '300px',
                        padding: '.7rem',
                        display: 'flex',
                        gap: '1rem',
                        marginTop: '1rem',
                    }}
                >
                    <PersonOutline
                        sx={{
                            color: 'primary.contrastText',
                            transform: 'scale(1.2)',
                        }}
                    />
                    <Typography color="primary.contrastText">
                        This application is {status}
                    </Typography>
                </Paper>

                <Typography
                    color="primary"
                    variant="h5"
                    mt={4}
                    mb={2}
                    sx={{
                        borderBottom: '2px solid',
                        borderColor: theme.palette.primary.main,
                    }}
                >
                    Personal Details
                </Typography>
                <Grid container spacing={1} sx={{ padding: '1rem 0' }}>
                    <Tile info={title} label="Title" />
                    <Tile info={workEmail} label="Email (work)" />
                    <Tile info={firstName} label="First Name" />
                    <Tile info={personalEmail} label="Email (personal)" />
                    <Tile info={lastName} label="Last Name" />
                    <Tile info={landline} label="Landline" />
                    <Tile info={addressLine1} label="Address 1" />
                    <Tile info={mobileNumber} label="Mobile" />
                    <Tile info={addressLine2} label="Address 2" />
                    <Tile
                        info={payrollEmployeeNumber}
                        label="Payroll number / Employee number"
                    />
                    <Tile info={town} label="Town" />
                    <Tile info={county} label="County" />
                    <Tile info={postcode} label="Postcode" />
                </Grid>
                <Typography
                    color="primary"
                    variant="h5"
                    mt={4}
                    mb={2}
                    sx={{
                        borderBottom: '2px solid',
                        borderColor: theme.palette.primary.main,
                    }}
                >
                    Scheme Details
                </Typography>
                <Grid container spacing={1} sx={{ padding: '1rem 0' }}>
                    <Tile
                        label="Application date"
                        info={applicationDate.join('-')}
                    />
                    <Tile label="Approve date" info={approvalDate?.join('-')} />

                    <Tile
                        label="Certificate amount"
                        info={convertToPounds(certificateAmount)}
                    />
                    <Tile
                        label="Salary sacrifice amount"
                        info={convertToPounds(salarySacrificeAmount)}
                    />
                    {/* Currently  we dont know exactly what it is  */}
                    {/* <Tile label="End of scheme option" info="No" /> */}
                    <Tile
                        label="Term of hire agreement"
                        info={hireAgreementTermMonths}
                    />

                    <Tile
                        label="Deducation frequency"
                        info={salarySacrificeDeductionFrequency}
                    />
                    <Tile
                        label="Salary sacrifice term (months)"
                        info={salarySacrificeTermMonths}
                    />

                    <Tile
                        label="Monthly Amount"
                        info={convertToPounds(singleSalarySacrificeAmount)}
                    />
                    <Formik
                        innerRef={formikRef}
                        initialValues={{ purchaseOrder: purchaseOrder || '' }}
                        onSubmit={() => {
                            // do nothing
                        }}
                    >
                        <Tile
                            label="Purchase order number (if required)"
                            info={purchaseOrder}
                        >
                            {!purchaseOrder && <Input name="purchaseOrder" />}
                        </Tile>
                    </Formik>
                </Grid>
                <Divider
                    color={theme.palette.secondary.main}
                    sx={{
                        borderBottom: '2px solid',
                        marginTop: '2rem',
                    }}
                />

                <Box
                    mt={3}
                    display="flex"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    gap="1rem"
                >
                    <Button
                        isLoading={mutation.isLoading}
                        disabled={shouldDisableDeclineButtons}
                        backgroundColor={theme.palette.error.main}
                        text="Reject application"
                        error
                        onClick={() =>
                            mutation.mutate({
                                listId: certificateId as string,
                                action: 'DECLINE',
                            })
                        }
                    />
                    <Tooltip
                        title="You cannot approve this certificate due to errors. Please contact the admin to resolve these issues"
                        disableHoverListener={!shouldDisableApproveButtons}
                    >
                        <span>
                            <Button
                                onClick={() =>
                                    mutation.mutate({
                                        listId: certificateId as string,
                                        purchaseOrder:
                                            formikRef.current?.values
                                                .purchaseOrder,
                                        action: 'ACCEPT',
                                    })
                                }
                                isLoading={mutation.isLoading}
                                disabled={shouldDisableApproveButtons}
                                text="Approve application"
                            />
                        </span>
                    </Tooltip>
                </Box>
            </Box>
        </>
    )
}

export default AdminPanelApplicantDetailsPage
