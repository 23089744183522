import { FC, useRef, useState } from 'react'
import {
    Divider,
    Grid,
    Typography,
    useTheme,
    Tabs,
    Tab,
    Box,
    Tooltip,
} from '@mui/material'
import Button from 'components/Button'
import Input from 'components/Input'
import { Formik, FormikProps } from 'formik'
import { useQuery, useMutation } from 'react-query'
import {
    updateSchemeAdminDetails,
    getSchemeAdminDetails,
    updateSchemeAdminEmailAddress,
    updateSchemeAdminPassword,
    removeSchemeAdmin,
} from 'api/evansAdmin'
import LoadingSpinner from 'components/LoadingSpinner'
import {
    updateSchemaAdminDetailsValidator,
    updateEmailValidator,
    updatePasswordForSchemeAdminByEvansAdminValidator,
} from 'utils/ValidationSchema'
import { ISchemeAdminDetails } from 'Interfaces/Admin'
import useStore from 'store/Store'
import { useNavigate, useParams } from 'react-router-dom'
import {
    HOW_DID_YOU_HEAR_OPTIONS_WITH_VALUES,
    TITLE_OPTIONS,
} from 'utils/constants'
import { AxiosError, AxiosResponse } from 'axios'
import usePageTitle from 'hooks/usePageTitle'
import generator from 'generate-password-browser'
import ErrorMessage from 'components/ErrorMessage'
import CompaniesList from './CompaniesList'
import DeleteModal from '../../../../components/DeleteModal'
import Modal from '../../../../components/Modal/Modal'
import AdminRoles from './AdminRoles'

type SchemeAdminData = {
    id: number
    email: string
}
const SchemeAdministrator: FC = () => {
    usePageTitle('Edit Scheme Admin')
    const { openNotification, hasCmsPermission } = useStore()
    const navigate = useNavigate()
    const [isDeleteSchemeAdminModalOpen, setIsDeleteSchemeAdminModalOpen] =
        useState<SchemeAdminData>()
    const [currentScreen, setCurrentScreen] = useState('details')
    const theme = useTheme()
    const { adminId } = useParams()
    const {
        data: response,
        isFetching,
        error,
        refetch,
    } = useQuery<AxiosResponse, AxiosError<{ message: string }>>(
        ['getSchemeAdminDetails', adminId],
        () => getSchemeAdminDetails(adminId as string)
    )

    const detailsFormRef = useRef<FormikProps<ISchemeAdminDetails>>(null)
    const updateEmailFormRef =
        useRef<
            FormikProps<{ emailAddress: string; confirmEmailAddress: string }>
        >(null)

    const updatePasswordFormRef = useRef<
        FormikProps<{
            temporaryPassword: string
            confirmPassword: string
        }>
    >(null)

    const initialValues = {
        ...response?.data,
        confirmEmailAddress: response?.data.emailAddress,
    }

    const emailFormValues = {
        emailAddress: response?.data.emailAddress,
        confirmEmailAddress: response?.data.emailAddress,
    }

    const updatePasswordValues = {
        temporaryPassword: '',
        confirmPassword: '',
    }

    const mutation = useMutation(
        (data: ISchemeAdminDetails) =>
            updateSchemeAdminDetails(adminId as string, data),
        {
            onSuccess: () => {
                openNotification('Data has been saved', 'success')
                refetch()
            },
            onError: (
                error: AxiosError<{
                    fieldErrors: Record<string, string>
                    message: string
                }>
            ) => {
                detailsFormRef.current?.setStatus({
                    ...error.response?.data.fieldErrors,
                })
                openNotification(
                    error?.response?.data.message || 'Something went wrong',
                    'error'
                )
            },
        }
    )

    const updateEmailMutation = useMutation(
        (data: { emailAddress: string; confirmEmailAddress: string }) =>
            updateSchemeAdminEmailAddress({
                ...data,
                schemeAdminId: adminId as string,
            }),
        {
            onSuccess: () => {
                openNotification('Email has been updated', 'success')
            },
            onError: (
                error: AxiosError<{
                    fieldErrors: Record<string, string>
                    message: string
                }>
            ) => {
                updateEmailFormRef.current?.setStatus({
                    ...error.response?.data.fieldErrors,
                })
                openNotification(
                    error?.response?.data.message || 'Something went wrong',
                    'error'
                )
            },
        }
    )

    const updateSchemeAdminPasswordMutation = useMutation(
        (data: { temporaryPassword: string; confirmPassword: string }) =>
            updateSchemeAdminPassword(adminId as string, data),
        {
            onSuccess: () => {
                openNotification('Data has been saved', 'success')
            },
            onError: (
                error: AxiosError<{
                    fieldErrors: Record<string, string>
                    message: string
                }>
            ) => {
                updatePasswordFormRef.current?.setStatus({
                    ...error.response?.data.fieldErrors,
                })
            },
        }
    )

    function submitHandler(values: ISchemeAdminDetails) {
        const { howDidYouHearOption, ...rest } = values

        mutation.mutate({
            ...rest,
            howDidYouHearOption: HOW_DID_YOU_HEAR_OPTIONS_WITH_VALUES.find(
                (element: { label: string; value: string }) =>
                    element.value === howDidYouHearOption
            )?.label as string,
        })
    }

    function generatePassword() {
        const password = generator.generate({
            uppercase: true,
            lowercase: true,
            numbers: true,
            excludeSimilarCharacters: true,
            strict: true,
            length: 8,
            symbols: '!@#$%^&*',
        })

        updatePasswordFormRef.current?.setFieldValue(
            'temporaryPassword',
            password
        )
        updatePasswordFormRef.current?.setFieldValue(
            'confirmPassword',
            password
        )

        updatePasswordFormRef.current?.setFieldTouched(
            'temporaryPassword',
            false
        )
        updatePasswordFormRef.current?.setFieldTouched('confirmPassword', false)
    }

    if (isFetching) {
        return <LoadingSpinner />
    }

    if (error) {
        return <ErrorMessage text={error.message as string} />
    }

    return (
        <Box padding="0 1rem">
            <Typography color="primary" variant="h4" mt={2}>
                Edit Scheme Administrator
            </Typography>
            <Tabs
                sx={{ marginBottom: '2rem', marginTop: '1rem' }}
                value={currentScreen}
                onChange={(event, value: string) => setCurrentScreen(value)}
            >
                <Tab value="details" label="Edit Details" />
                <Tab value="password" label="Edit Password" />
                <Tab value="companies" label="Companies" />
                {hasCmsPermission && <Tab value="roles" label="Admin Roles" />}
            </Tabs>
            {currentScreen === 'details' && (
                <Formik
                    validationSchema={updateSchemaAdminDetailsValidator}
                    initialValues={initialValues}
                    onSubmit={(data) => submitHandler(data)}
                    innerRef={detailsFormRef}
                >
                    {(formik) => (
                        <Grid rowSpacing={4} columnSpacing={2} container>
                            <Grid item xs={12}>
                                <Typography
                                    color="primary"
                                    variant="h6"
                                    fontWeight="bold"
                                >
                                    Personal Details
                                </Typography>
                                <Divider />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Input
                                    name="title"
                                    label="Title"
                                    fullWidth
                                    required
                                    select
                                    options={TITLE_OPTIONS}
                                    maxWidth="600px"
                                    apiErrors={formik.status}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Input
                                    name="firstName"
                                    label="First name"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Input
                                    name="lastName"
                                    label="Last name"
                                    fullWidth
                                    required
                                    apiErrors={formik.status}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Input
                                    name="jobTitle"
                                    label="Job Title"
                                    fullWidth
                                    required
                                    apiErrors={formik.status}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Input
                                    name="phoneNumber"
                                    label="Phone number"
                                    fullWidth
                                    required
                                    apiErrors={formik.status}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Input
                                    name="howDidYouHearOption"
                                    label="How did organiser hear about Evans Cycles Ride-to-Work"
                                    fullWidth
                                    required
                                    select
                                    optionsWithValues={
                                        HOW_DID_YOU_HEAR_OPTIONS_WITH_VALUES
                                    }
                                    maxWidth="600px"
                                    apiErrors={formik.status}
                                />
                            </Grid>
                            <Formik
                                initialValues={emailFormValues}
                                validationSchema={updateEmailValidator}
                                innerRef={updateEmailFormRef}
                                onSubmit={(values) =>
                                    updateEmailMutation.mutate(values)
                                }
                            >
                                {(formik) => (
                                    <>
                                        <Grid item xs={12}>
                                            <Typography
                                                color="primary"
                                                variant="h6"
                                                fontWeight="bold"
                                            >
                                                Email Address
                                            </Typography>
                                            <Divider />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <Input
                                                name="emailAddress"
                                                label="Email"
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Input
                                                name="confirmEmailAddress"
                                                label="Confirm Email"
                                                fullWidth
                                                required
                                                disablePasting
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <Button
                                                onClick={formik.handleSubmit}
                                                text="Update Email"
                                                colorPrimary
                                                isLoading={
                                                    updateEmailMutation.isLoading
                                                }
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Formik>

                            <Grid item xs={12}>
                                <Divider
                                    sx={{
                                        borderColor:
                                            theme.palette.secondary.main,
                                        borderWidth: '1px',
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Input
                                    name="signUpForNewsletter"
                                    checkBox
                                    label="Sign up for Newsletter"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button
                                    text="Submit"
                                    onClick={() => formik.handleSubmit()}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Tooltip
                                    title="Cannot remove scheme admin because it is assigned to company"
                                    disableHoverListener={
                                        !response?.data.isAssignedToCompany
                                    }
                                >
                                    <span>
                                        <Button
                                            error
                                            text="Delete scheme admin"
                                            disabled={
                                                response?.data
                                                    .isAssignedToCompany
                                            }
                                            onClick={() =>
                                                setIsDeleteSchemeAdminModalOpen(
                                                    {
                                                        id: response?.data
                                                            .id as number,
                                                        email: response?.data
                                                            .emailAddress,
                                                    }
                                                )
                                            }
                                        />
                                    </span>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    )}
                </Formik>
            )}

            {currentScreen === 'password' && (
                <Formik
                    validationSchema={
                        updatePasswordForSchemeAdminByEvansAdminValidator
                    }
                    innerRef={updatePasswordFormRef}
                    initialValues={updatePasswordValues}
                    onSubmit={(values) => {
                        updateSchemeAdminPasswordMutation.mutate(values)
                        updatePasswordFormRef.current?.setFieldTouched(
                            'temporaryPassword',
                            false
                        )
                        updatePasswordFormRef.current?.setFieldTouched(
                            'confirmPassword',
                            false
                        )
                    }}
                >
                    {(formik) => (
                        <>
                            {updateSchemeAdminPasswordMutation.error && (
                                <ErrorMessage
                                    text={
                                        updateSchemeAdminPasswordMutation.error
                                            .response?.data.message as string
                                    }
                                />
                            )}

                            <Typography
                                color="primary"
                                variant="h6"
                                fontWeight="bold"
                                mt={2}
                            >
                                Change Password
                            </Typography>
                            <Divider sx={{ marginBottom: '2rem' }} />
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Button
                                        onClick={generatePassword}
                                        maxWidth="600px"
                                        text="Generate Password"
                                        colorPrimary
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        apiErrors={formik.status}
                                        name="temporaryPassword"
                                        fullWidth
                                        label="New Password"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        apiErrors={formik.status}
                                        fullWidth
                                        required
                                        name="confirmPassword"
                                        label="Confirm New Password"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Divider
                                        sx={{
                                            backgroundColor:
                                                theme.palette.secondary.main,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Button
                                        maxWidth="600px"
                                        isLoading={
                                            updateSchemeAdminPasswordMutation.isLoading
                                        }
                                        text="Save Password"
                                        onClick={formik.handleSubmit}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Formik>
            )}
            {currentScreen === 'companies' && adminId && (
                <CompaniesList adminId={adminId} />
            )}
            {currentScreen === 'roles' && adminId && (
                <AdminRoles adminId={adminId} />
            )}

            <Modal
                title="Delete Confirmation"
                isOpen={isDeleteSchemeAdminModalOpen !== undefined}
                onClose={() => setIsDeleteSchemeAdminModalOpen(undefined)}
            >
                {isDeleteSchemeAdminModalOpen && (
                    <DeleteModal
                        successCallback={() => {
                            refetch()
                            setIsDeleteSchemeAdminModalOpen(undefined)
                            navigate('/ride-to-work-apply/evans-admin')
                        }}
                        closeModal={() =>
                            setIsDeleteSchemeAdminModalOpen(undefined)
                        }
                        deleteFunction={() =>
                            removeSchemeAdmin(isDeleteSchemeAdminModalOpen.id)
                        }
                        message={`Are you sure you want to remove scheme admin with email: ${isDeleteSchemeAdminModalOpen.email}?`}
                    />
                )}
            </Modal>
        </Box>
    )
}

export default SchemeAdministrator
