import { FC, useState } from 'react'
import { useFormikContext } from 'formik'
import {
    Paper,
    Grid,
    Typography,
    Checkbox,
    Box,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { IRideToWorkCertificate } from 'Interfaces/Certificate'
import { useQuery } from 'react-query'
import { getThirdStepAgreement } from 'api/public'
import Button from 'components/Button'
import { AxiosResponse } from 'axios'
import ErrorMessage from 'components/ErrorMessage'
import LoadingSpinner from 'components/LoadingSpinner'

interface IProps {
    id: string
    error: string
    isLoadingData: boolean
    descriptiveText: string
    isCancelAgreementButtonLoading: boolean
    cancelCertificateApplication: () => void
    updateStep: (step: string) => void
}

const Step3: FC<IProps> = ({
    id,
    error,
    isLoadingData,
    descriptiveText,
    isCancelAgreementButtonLoading,
    cancelCertificateApplication: cancelCertificateApplication,
    updateStep,
}) => {
    const formik = useFormikContext<IRideToWorkCertificate>()
    const [isSigned, setIsSigned] = useState(false)
    const theme = useTheme()
    const isDesktopResolution = useMediaQuery(theme.breakpoints.up('md'))

    const { isLoading, data } = useQuery(
        'getThirdStepAgreement',
        () => getThirdStepAgreement(id),
        {
            onSuccess: (response: AxiosResponse) => {
                updateStep(response.data.nextStep)
            },
        }
    )

    if (isLoading) {
        return <LoadingSpinner />
    }

    return (
        <>
            <Grid container spacing={4} sx={{ overflow: 'auto' }}>
                {error && (
                    <Grid item xs={12}>
                        <ErrorMessage text={error} />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Typography
                        sx={{
                            wordWrap: 'break-word',
                            lineHeight: '1.8',
                        }}
                    >
                        {descriptiveText}
                    </Typography>
                </Grid>
                <Grid xs={12} md={9} item>
                    <Paper elevation={2} sx={{ padding: '1rem' }}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: data?.data.htmlContent,
                            }}
                        ></div>

                        <Box
                            sx={{
                                backgroundColor: 'primary.main',
                                color: 'primary.contrastText',
                                padding: '.4rem',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1rem',
                            }}
                        >
                            <Checkbox
                                sx={{
                                    color: 'primary.contrastText',
                                    transform: 'scale(1.4)',
                                    '&.Mui-checked': {
                                        color: 'primary.contrastText',
                                    },
                                }}
                                size="medium"
                                checked={isSigned}
                                onChange={() => {
                                    if (!isSigned) {
                                        window.scrollTo({
                                            top: 0,
                                            behavior: 'smooth',
                                        })
                                    }

                                    setIsSigned(
                                        (prevState: boolean) => !prevState
                                    )
                                }}
                            />
                            <Typography fontWeight="bold" variant="h6">
                                {'< <'} Tick here to sign your agreement
                            </Typography>
                        </Box>
                        <Typography fontWeight="bold" variant="body2" mt={2}>
                            I understand, that by ticking this box, I have read
                            the Salary Sacrifice Agreement regulated by the
                            Financial Services and Markets Act 2000 and the
                            Consumer Credit Act 1974
                        </Typography>
                    </Paper>
                </Grid>
                <Grid
                    xs={12}
                    md={3}
                    item
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem',
                    }}
                >
                    {!isSigned && isDesktopResolution && (
                        <Typography variant="subtitle1" fontWeight={600}>
                            First you have to sign agreement at the bottom of
                            the page.
                        </Typography>
                    )}
                    <Button
                        isLoading={isLoadingData}
                        text="Accept & Continue"
                        onClick={() => formik.handleSubmit()}
                        disabled={!isSigned}
                    />
                    <Button
                        text="Cancel my application"
                        onClick={() => cancelCertificateApplication()}
                        outlined
                        colorPrimary
                        disabled={isCancelAgreementButtonLoading}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default Step3
