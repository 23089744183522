import { CircularProgress } from '@mui/material'
import React, { FC } from 'react'

const LoadingSpinner: FC = () => {
    return (
        <CircularProgress
            size={50}
            sx={{
                position: 'fixed',
                left: '50%',
                top: '50%',
                zIndex: 30000000,
                transform: 'translate(-50%, -50%)',
            }}
        />
    )
}

export default LoadingSpinner
