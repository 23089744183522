import { forwardRef } from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void
    name: string
    decimals?: boolean
    thousandSeparator?: boolean
    prefix?: string
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const { onChange, thousandSeparator, prefix, decimals, ...other } =
            props

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    })
                }}
                thousandSeparator={thousandSeparator}
                fixedDecimalScale={decimals}
                decimalScale={decimals ? 2 : undefined}
                prefix={prefix}
                valueIsNumericString
            />
        )
    }
)

export default NumericFormatCustom
