export const capitalizeEnum = (text: string, splitter = '_') => {
    return text
        .split(splitter)
        .map((word) => {
            const firstLetter = word[0].toLocaleUpperCase()
            const rest = word.toLocaleLowerCase().slice(1)
            return firstLetter + rest
        })
        .join(' ')
}
