import { FC, useState } from 'react'
import { useFormikContext } from 'formik'
import { Typography, Box, Divider, Grid } from '@mui/material'
import Input from 'components/Input'
import Button from 'components/Button'
import useAuth from 'auth/useAuth'
import ErrorMessage from 'components/ErrorMessage'

interface IRegisterAdmin {
    emailAddress: string
    confirmEmailAddress: string
    password: string
    checkPassword: string
}

interface IProps {
    error: string
    isLoading: boolean
}

const Step1: FC<IProps> = ({ error, isLoading }) => {
    const formik = useFormikContext<IRegisterAdmin>()
    const [resendConfirmationSuccess, setResendConfirmationSuccess] =
        useState(false)
    const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(false)
    const [hasResendFailed, setHasResendFailed] = useState(false)
    const { resendConfirmationMail, isConfirmationResendLinkLoading } =
        useAuth()

    async function resendConfirmationLink() {
        const mail = formik.values.emailAddress
        await resendConfirmationMail(
            mail,
            () => {
                setResendConfirmationSuccess(true)
                setIsResendButtonDisabled(true)
            },
            () => setHasResendFailed(true)
        )
    }

    return (
        <>
            <div>
                <Typography
                    variant="h4"
                    color="primary"
                    mb={3}
                    mt={2}
                    fontWeight="bold"
                    textAlign="center"
                >
                    Getting started with the Evans Ride-to-Work Scheme!
                </Typography>
            </div>

            <Grid sx={{ marginBottom: '2rem' }} container spacing={6}>
                <Grid item md={6}>
                    <Typography variant="body1">
                        It’s easy to get on board with Evans Cycles
                        Ride-to-Work. <br /> The scheme is open to any employer
                        big or small in the private or public sector operating a
                        PAYE system.
                    </Typography>
                </Grid>
                <Grid item md={6}>
                    <Typography variant="body1">
                        We’ll send you an email which provides further details
                        about how to manage and promote your Ride-to-Work
                        scheme, and our helpdesk is on hand to answer any
                        queries you or your employees may have.
                    </Typography>
                </Grid>
            </Grid>

            <Typography
                paddingBottom=".1rem"
                borderBottom="3px solid"
                variant="h5"
                color="primary"
                mb={2}
                fontWeight="bold"
            >
                Set up a scheme administrator
            </Typography>
            {error ? (
                <ErrorMessage
                    text={
                        <>
                            <Typography>{error}</Typography>
                            {error ===
                                'An account with the given email already exists.' &&
                                !resendConfirmationSuccess && (
                                    <>
                                        <Typography my={1}>
                                            If you have not received
                                            confirmation email click here:
                                        </Typography>
                                        <Button
                                            isLoading={
                                                isConfirmationResendLinkLoading
                                            }
                                            disabled={
                                                isResendButtonDisabled ||
                                                hasResendFailed
                                            }
                                            text="Resend confirmation link"
                                            onClick={resendConfirmationLink}
                                            error
                                        />
                                        {hasResendFailed && (
                                            <Typography fontSize="small">
                                                Resending email failed. Probably
                                                this user is already confirmed
                                            </Typography>
                                        )}
                                    </>
                                )}
                        </>
                    }
                />
            ) : null}

            <div>
                <Typography fontWeight="bold" variant="h6" color="primary">
                    Contact details
                </Typography>
                <Divider sx={{ borderBottomWidth: '2px' }} />
            </div>
            <Typography variant="caption" mb={2}>
                Enter the contact information of the nominated scheme
                administrator
            </Typography>

            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Input
                        apiErrors={formik.status}
                        required
                        label="Email"
                        name="emailAddress"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        fullWidth
                        apiErrors={formik.status}
                        required
                        label="Confirm Email"
                        name="confirmEmailAddress"
                        disablePasting
                    />
                </Grid>
            </Grid>

            <div>
                <Typography fontWeight="bold" variant="h6" color="primary">
                    Create password
                </Typography>
                <Divider sx={{ borderBottomWidth: '2px' }} />
            </div>
            <Typography variant="caption" mb={2}>
                Set a password to access the account - min. 8 characters,
                including 1 upper, lower, special and numeric character
            </Typography>

            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Input
                        apiErrors={formik.status}
                        required
                        label="Password"
                        name="password"
                        type="password"
                        fullWidth
                        passwordToggle
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        apiErrors={formik.status}
                        required
                        label="Confirm password"
                        name="checkPassword"
                        type="password"
                        fullWidth
                        passwordToggle
                    />
                </Grid>
            </Grid>
            <Divider />
            <Box display="flex" justifyContent="center" marginY="1rem">
                <Button
                    maxWidth="450px"
                    fullWidth
                    text="Continue"
                    isLoading={isLoading}
                    onClick={() => {
                        formik.handleSubmit()
                    }}
                />
            </Box>
        </>
    )
}

export default Step1
