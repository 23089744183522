import { FC } from 'react'
import { Grid } from '@mui/material'
import Button from 'components/Button'
import Input from 'components/Input'
import { Formik } from 'formik'
import { ITemplateAgreement } from 'Interfaces/Templates'
import { useMutation } from 'react-query'
import { createNewAgreementTemplate } from 'api/evansAdmin'
import { AxiosError } from 'axios'

interface IProps {
    closeModal: () => void
    successCallback: () => void
    errorCallback: (message: string) => void
}

const CreateAgreementTemplateModal: FC<IProps> = ({
    successCallback,
    errorCallback,
}) => {
    const initialValues = {
        title: '',
        thirdStepAgreementType: 'PRE CONTRACT',
        isUnder18: false,
        restrictions: {
            showLogoOnAgreement: true,
            showEvansCyclesName: true,
            showEvansCyclesAddress: true,
            showEmployeeName: true,
            showEmployeeAddress: true,
            showHireAgreementTotalAmount: true,
            showHireAgreementMonthlyPayment: true,
            showHireAgreementLength: true,
            showSalarySacrificeTerm: true,
            showDateOfSchemeAdministratorSignature: true,
            showDateOfEmployeeSignature: true,
            showNumberOfSacrifices: true,
            showSalarySacrificeDeductionFrequency: true,
            showEmployeePayrollNumber: true,
        },
    }

    const mutation = useMutation(
        (data: ITemplateAgreement) => createNewAgreementTemplate(data),
        {
            onSuccess: () => {
                successCallback()
            },

            onError: (error: AxiosError<{ message: string }>) => {
                errorCallback(error.response?.data.message as string)
            },
        }
    )

    function submitHandler(values: ITemplateAgreement) {
        mutation.mutate({
            ...values,
            thirdStepAgreementType: values.thirdStepAgreementType.replaceAll(
                ' ',
                '_'
            ),
        })
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => submitHandler(values)}
        >
            {(formik) => (
                <Grid container spacing={3} paddingTop="2rem">
                    <Grid item xs={12}>
                        <Input name="title" label="Title" required fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            name="thirdStepAgreementType"
                            label="Third Step Agreement Type"
                            required
                            fullWidth
                            select
                            maxWidth="1200px"
                            options={['PRE CONTRACT', 'SALARY SACRIFICE']}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            name="isUnder18"
                            label="Is Under 18"
                            checkBox
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            isLoading={mutation.isLoading}
                            fullWidth
                            text="Create Template"
                            onClick={formik.handleSubmit}
                        />
                    </Grid>
                </Grid>
            )}
        </Formik>
    )
}

export default CreateAgreementTemplateModal
