import Modal from 'components/Modal/Modal'
import { FC, useState } from 'react'
import { useSearchParams, Link, Navigate } from 'react-router-dom'
import * as yup from 'yup'
import { passwordPattern } from 'utils/dataPatterns'
import { Formik } from 'formik'
import Input from 'components/Input'
import Button from 'components/Button'
import useAuth from 'auth/useAuth'
import ErrorMessage from 'components/ErrorMessage'
import { Box, Typography } from '@mui/material'

const SignUpResetPage: FC = () => {
    const [params] = useSearchParams()
    const email = params.get('mail')
    const code = params.get('code')
    const {
        confirmResetPassword,
        confirmPasswordError,
        confirmResetPasswordIsLoading,
    } = useAuth()
    const [currentStep, setCurrentStep] = useState(1)

    const initialValues = {
        email,
        code: code?.split('?')[0],
        password: '',
        confirmPassword: '',
    }

    const validator = yup.object({
        password: yup
            .string()
            .required('You must provide a password')
            .matches(
                passwordPattern,
                'Password must be at least 8 characters including one upper, lower, special and numeric character.'
            ),
        confirmPassword: yup
            .string()
            .required('You must confirm your password')
            .oneOf([yup.ref('password')], 'The passwords fields do not match.'),
    })

    function submitHandler(values: typeof initialValues) {
        const { email, code, password } = values
        confirmResetPassword(email as string, code as string, password, () => {
            setCurrentStep(2)
        })
    }

    const boxStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
        padding: currentStep === 1 ? '2rem 1rem' : '1rem',
    }

    if (!email || !code) {
        return <Navigate to="/ride-to-work-apply/schemeadmin/login" />
    }

    return (
        <>
            <Modal title="Reset Password" isOpen={true}>
                {currentStep === 1 ? (
                    <Formik
                        onSubmit={(values) => submitHandler(values)}
                        initialValues={initialValues}
                        validationSchema={validator}
                    >
                        {(formik) => (
                            <form onSubmit={formik.handleSubmit}>
                                <Box sx={boxStyle}>
                                    {confirmPasswordError && (
                                        <ErrorMessage
                                            text={confirmPasswordError}
                                        />
                                    )}
                                    <Typography
                                        variant="h6"
                                        color="primary"
                                        fontWeight={'bold'}
                                    >
                                        Password reset for {email}
                                    </Typography>
                                    <Input
                                        label="Enter new password"
                                        type="password"
                                        name="password"
                                        passwordToggle
                                    />
                                    <Input
                                        label="Confirm new password"
                                        type="password"
                                        name="confirmPassword"
                                        passwordToggle
                                    />
                                    <Button
                                        isLoading={
                                            confirmResetPasswordIsLoading
                                        }
                                        text="Submit"
                                        submit
                                    />
                                </Box>
                            </form>
                        )}
                    </Formik>
                ) : (
                    <Box sx={boxStyle}>
                        <Typography fontWeight={900} variant="h6">
                            Your password has been changed successfully!
                        </Typography>
                        <Link
                            style={{ fontSize: '1.2rem' }}
                            to="/ride-to-work-apply/schemeadmin/login"
                        >
                            Click here to login.
                        </Link>
                    </Box>
                )}
            </Modal>
        </>
    )
}

export default SignUpResetPage
