import { FC, useState } from 'react'
import { getCertificateErrors } from 'api/evansAdmin'
import { useQuery } from 'react-query'
import LoadingSpinner from 'components/LoadingSpinner'
import ErrorMessage from 'components/ErrorMessage'
import { Typography } from '@mui/material'
import { AxiosError, AxiosResponse } from 'axios'

interface ICompany {
    errorMessage: string
    requiredAction: string
    dateOfOccurrence: string
}

interface IProps {
    certificateId: string
}

const CertificateErrors: FC<IProps> = ({ certificateId }) => {
    const [errors, setErrors] = useState([])
    const { isLoading, isFetching, isError, error } = useQuery<
        AxiosResponse,
        AxiosError<{ response: Record<string, string>; message: string }>
    >(
        ['getCertificateErrors', certificateId],
        () => getCertificateErrors(certificateId as string),
        {
            enabled: Boolean(certificateId),
            onSuccess: (response) => {
                setErrors(response.data)
            },
        }
    )

    if (isLoading || isFetching) {
        return <LoadingSpinner />
    }

    if (isError) {
        return <ErrorMessage text={error?.response?.data.message as string} />
    }

    return (
        <>
            <Typography variant="h6">
                Errors which occurred during certification process:
            </Typography>
            <br />
            {errors.map((element: ICompany) => (
                <>
                    <br />
                    <Typography variant="subtitle1" key={element.errorMessage}>
                        - An error with message: {'"'}
                        {element.errorMessage}
                        {'"'} occurred on {element.dateOfOccurrence} please
                        execute {element.requiredAction} action
                    </Typography>
                </>
            ))}
        </>
    )
}

export default CertificateErrors
