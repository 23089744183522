import { EmailRequest } from 'pages/SavingsCalculator/EmailForm'
import axios, { axiosInstanceLoqate } from './axios'
import { IAddress, IRideToWorkCertificate } from 'Interfaces/Certificate'

export const createCertificateProcess = async (
    data: IRideToWorkCertificate
) => {
    return await axios.post('pub/certificates/processes', data)
}

export const getThirdStepAgreement = async (processUUID: string | number) => {
    return await axios.get(`pub/certificates/${processUUID}/third-step`)
}

export const getHireAgreement = async (processUUID: string) => {
    return await axios.get(`pub/certificates/${processUUID}/hire`)
}

export const getCertificateCalculation = async (
    companyId: string | number,
    certificateAmount: string | number
) => {
    return await axios.get(
        `pub/certificates/companies/${companyId}/calculations`,
        {
            params: {
                certificateAmount,
            },
        }
    )
}

export const getCompanyByNavisionNumber = async (companyNumber: string) => {
    return await axios.get(`pub/companies/${companyNumber}`)
}

export const getCompanyNamesList = async (partialCompanyName: string) => {
    return await axios.get(`pub/companies/company-names`, {
        params: { partialCompanyName },
    })
}

export const updateCertificateProcessStatus = async (
    processId: string,
    action: string
) => {
    return await axios.patch(`pub/certificates/${processId}`, action)
}

export const getContentElement = async (elementId: number) => {
    return await axios.get(`pub/content-elements/${elementId}`)
}

export const confirmRegistration = async (obj: {
    uid: string
    code: string
}) => {
    return await axios.get(`pub/confirmation`, {
        params: obj,
    })
}

export const getCurrentStepInCertificateProcess = async (
    processUUID: string
) => {
    return await axios.get(`pub/certificates/processes/${processUUID}`)
}

export const getT4UCertificate = async (processUUID: string) => {
    return await axios.get(`pub/certificates/t4u/${processUUID}/employee`)
}

export const applyT4UCertificate = async (body: IRideToWorkCertificate) => {
    return await axios.post(`pub/certificates/t4u/processes`, body)
}

export const sendSavingsCalculatorEmail = async (body: EmailRequest) => {
    return await axios.post(`pub/saving-calculator/email`, body)
}

export const verifyAddress = async (address: IAddress) => {
    return await axiosInstanceLoqate.post('', {
        key: process.env.REACT_APP_LOQATE_KEY,
        Addresses: [address],
    })
}
