export const passwordPattern =
    /^(?=.*[0-9])(?=.*[!@#$%^&*_?#^*+=-></.:()-;])(?=.*[a-z])(?=.*[A-Z]).{8,}$/

export const phoneNumberPattern = /\+?(\d{1,3})?((-|\s)?\d{3}){3}/

export const inputNumericValuePattern = /\d+/

export const certificateAmountNumberPattern = /^\d+(\.\d+)?$/

export const datePattern =
    /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]/

export const yearMonthDayPattern =
    /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])/

export const postcodePattern =
    /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/
