import React, { FC } from 'react'
import { Button as MuiButton, Grid } from '@mui/material'

interface IProps {
    text: string
    onClick?: () => void
    to?: string
    id?: string
    outlined?: boolean
    colorPrimary?: boolean
    grid?: boolean
    fullWidth?: boolean
    maxWidth?: string
    md?: number
    padding?: string
    isLoading?: boolean
    backgroundColor?: string
    error?: boolean
    disabled?: boolean
    submit?: boolean
    rtwButton?: boolean
}

const Button: FC<IProps> = ({
    text,
    onClick,
    to,
    id,
    outlined,
    colorPrimary,
    fullWidth,
    maxWidth,
    grid,
    md,
    padding,
    isLoading,
    backgroundColor,
    error,
    disabled,
    submit,
    rtwButton,
}) => {
    const rtwButtonStyles = rtwButton
        ? {
              borderRadius: '0',
              border: '3px solid transparent',

              '&:hover': {
                  backgroundColor: 'primary.contrastText',
                  border: '3px solid',
                  borderColor: 'primary.main',
                  color: 'primary.main',
                  transition: 'all 0.2s ease-in',
              },
          }
        : {}

    const btn = (
        <MuiButton
            id={id}
            variant={outlined ? 'outlined' : 'contained'}
            onClick={onClick}
            href={to}
            disableElevation
            type={submit ? 'submit' : 'button'}
            disabled={isLoading || disabled}
            fullWidth={fullWidth}
            color={
                rtwButton || colorPrimary
                    ? 'primary'
                    : error
                    ? 'error'
                    : 'secondary'
            }
            sx={{
                maxWidth: maxWidth,
                padding: padding || '.5rem 1rem',
                backgroundColor: backgroundColor || '',
                fontWeight: colorPrimary || error ? 'regular' : 'bold',
                ...rtwButtonStyles,
            }}
        >
            {isLoading ? 'Loading...' : text}
        </MuiButton>
    )

    if (grid) {
        return (
            <Grid item xs={12} md={md}>
                {btn}
            </Grid>
        )
    }

    return btn
}

export default Button
