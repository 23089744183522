import { FC, useState, useRef } from 'react'
import { Box } from '@mui/material'
import { Formik, FormikProps } from 'formik'
import Step1 from './Components/Step1'
import Step2 from './Components/Step2'
import { registerAdminSchemaValidator } from 'utils/ValidationSchema'
import { container } from './AdminRegisterStyle'
import useAuth from 'auth/useAuth'
import usePageTitle from 'hooks/usePageTitle'

const AdminRegisterPage: FC = () => {
    usePageTitle('Scheme Administrator Registration')
    const [activeStep, setActiveStep] = useState(0)
    const { error, isLoading, registerAccount } = useAuth()

    const initialValues = {
        emailAddress: '',
        confirmEmailAddress: '',
        password: '',
        checkPassword: '',
    }

    type IRegisterAdmin = typeof initialValues

    const formRef = useRef<FormikProps<IRegisterAdmin>>(null)

    function submitHandler(values: IRegisterAdmin) {
        registerAccount(values.emailAddress, values.password, () =>
            setActiveStep(1)
        )
    }

    return (
        <>
            <Box sx={container}>
                <Formik<IRegisterAdmin>
                    enableReinitialize
                    innerRef={formRef}
                    validationSchema={registerAdminSchemaValidator}
                    initialValues={initialValues}
                    onSubmit={(values) => submitHandler(values)}
                >
                    {(formik) => (
                        <>
                            {activeStep === 0 && (
                                <Step1 error={error} isLoading={isLoading} />
                            )}
                            {activeStep === 1 && (
                                <Step2 email={formik.values.emailAddress} />
                            )}
                        </>
                    )}
                </Formik>
            </Box>
        </>
    )
}

export default AdminRegisterPage
