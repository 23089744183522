import { useState, useRef, useEffect } from 'react'
import { ICertificateFiltersSchemeAdmin } from 'Interfaces/Certificate'
import Button from 'components/Button'
import { useQuery, useMutation } from 'react-query'
import {
    getCertificateListing,
    getCertificateListingToCsv,
    getCertificatesNumberForCompany,
    rejectCertificateProcess,
    acceptCertificateProcess,
    getCertificatesDescription,
} from 'api/schemeAdmin'
import { GridCellParams, GridRowId } from '@mui/x-data-grid'
import generateCsvReport from 'utils/generateCsvReport'
import useStore from 'store/Store'
import { AxiosError, AxiosResponse } from 'axios'
import { Box, TextField, Tooltip } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Edit } from '@mui/icons-material'
import CustomTooltip from 'components/CustomTooltip'
import { FormikProps } from 'formik'
import format from 'date-fns/format'
import SortingTableCell from 'components/sorting/SortingTableCell'
import useLocalStorage from 'use-local-storage'

const useManageCertificates = (companyId: string) => {
    const [selectedCertificates, setSelectedCertificates] = useState<string[]>(
        []
    )
    const [certificatesStatuses, setCertificatesStatuses] = useState([])
    const [isAccordionFilterExpanend, setIsAccordionFilterExpanend] =
        useState(true)
    const [selectedStatuses, setSelectedStatuses] = useLocalStorage<string[]>(
        'selectedStatuses',
        []
    )
    const [poNumbers, setPoNumbers] = useState<Record<string, string>>({})
    const [isApproveModalOpen, setIsApproveModalOpen] = useState(false)
    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false)
    const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false)
    const [isEditPurchaseNoOpened, setIsEditPurchaseNoOpened] =
        useState<GridRowId>()
    const [companyIdStorage, setCompanyIdStorage] = useLocalStorage<string>(
        'companyId',
        ''
    )
    const [filters, setFilters] =
        useLocalStorage<ICertificateFiltersSchemeAdmin>('filters', {
            page: 0,
            size: 10,
            sort: null,
            processStatus: null,
            applicationDateFrom: null,
            applicationDateTo: null,
        })

    useEffect(() => {
        if (companyIdStorage !== companyId) {
            setCompanyIdStorage(companyId)
            clearFilters()
        }
    }, [companyId])

    const { sort } = filters

    const formRef =
        useRef<FormikProps<Omit<typeof filters, 'page' | 'size'>>>(null)

    const { isFetching: isDescriptionLoading } = useQuery<
        AxiosResponse,
        AxiosError<{ message: string }>
    >('getCertificatesDescription', () => getCertificatesDescription(), {
        onSuccess: (response) => {
            setCertificatesStatuses(response.data)
        },
    })

    const {
        data: response,
        isFetching,
        isLoading: isInitialLoading,
        refetch,
        error,
    } = useQuery<AxiosResponse, AxiosError<{ message: string }>>(
        ['getCertificates', { ...filters, companyId }],
        () => getCertificateListing(companyId, filters)
    )

    const {
        data,
        isFetching: isNumberOfCertificatesFetching,
        refetch: refetchNumberOfCertificates,
    } = useQuery(['getNumberOfCertificates', companyId], () =>
        getCertificatesNumberForCompany(companyId)
    )

    const showLoadingSpinner =
        isNumberOfCertificatesFetching && isFetching && isDescriptionLoading

    const { data: certificates } = data || {}

    const isLoading = isFetching || isNumberOfCertificatesFetching

    const { message: errorMsg } = error?.response?.data || {}

    const navigate = useNavigate()
    const { openNotification } = useStore()
    const mutation = useMutation(
        (action: string) =>
            action === 'DECLINE'
                ? rejectCertificateProcess(selectedCertificates)
                : acceptCertificateProcess(
                      selectedCertificates.map((certificateId) => ({
                          certificateId,
                          purchaseOrderNumber: poNumbers[certificateId] || '',
                      }))
                  ),

        {
            onSuccess: () => {
                refetch()
                refetchNumberOfCertificates()
                setSelectedCertificates([])
                setIsApproveModalOpen(false)
                setIsRejectModalOpen(false)
                setIsEditPurchaseNoOpened(undefined)
                setFilters({ ...filters, page: 0 })
            },

            onError: (error: AxiosError<{ message: string }>) => {
                openNotification(
                    error.response?.data.message || 'Something went wrong ',
                    'error'
                )
            },
        }
    )

    const columns = [
        {
            field: 'employeeName',
            headerName: 'Employee Name',
            sortable: false,
            flex: 1,
            minWidth: 160,
            renderHeader: () => (
                <SortingTableCell
                    title="Employee Name"
                    sortingValue={sort as string}
                    setSortingValue={setSortingValue}
                    value="employee.firstName"
                />
            ),
        },

        {
            field: 'applicationDate',
            headerName: 'Application Date',
            sortable: false,
            flex: 1,

            minWidth: 150,
            renderHeader: () => (
                <SortingTableCell
                    title="Application Date"
                    sortingValue={sort as string}
                    setSortingValue={setSortingValue}
                    value="applicationDate"
                />
            ),
        },
        {
            field: 'totalAmount',
            flex: 1,
            headerName: 'Total Amount',
            sortable: false,
            renderHeader: () => (
                <SortingTableCell
                    title="Total Amount"
                    sortingValue={sort as string}
                    setSortingValue={setSortingValue}
                    value="certificateAmount"
                />
            ),
        },
        {
            field: 'salarySacrifice',
            flex: 1,
            headerName: 'Salary Sacrifice',
            sortable: false,
            minWidth: 150,
            renderHeader: () => (
                <SortingTableCell
                    title="Salary Sacrifice"
                    sortingValue={sort as string}
                    setSortingValue={setSortingValue}
                    value="singleSalarySacrificeAmount"
                />
            ),
        },
        {
            field: 'purchaseOrder',
            flex: 1,
            sortable: false,
            renderHeader: () => (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <SortingTableCell
                        title="PO Number"
                        sortingValue={sort as string}
                        setSortingValue={setSortingValue}
                        value="purchaseOrder"
                    />
                    <CustomTooltip
                        color="secondary"
                        text="If you require a purchase order on your invoice, enter it below before approving the application"
                    />{' '}
                </Box>
            ),
            renderCell: (params: GridCellParams) => (
                <>
                    {isEditPurchaseNoOpened === params.id ? (
                        <TextField
                            value={poNumbers[params.id] || ''}
                            onChange={(v) =>
                                setPoNumbers((poN) => ({
                                    ...poN,
                                    [params.id]: v.target.value,
                                }))
                            }
                        />
                    ) : params.row.status === 'PENDING' ? (
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                        >
                            <p>{poNumbers[params.id] || ''}</p>
                            <Edit
                                onClick={() =>
                                    setIsEditPurchaseNoOpened(params.id)
                                }
                            />
                        </Box>
                    ) : (
                        params.row.purchaseOrder
                    )}
                </>
            ),
        },
        {
            field: 'approve',
            flex: 1.5,
            headerName: 'Status',
            sortable: false,
            width: 100,
            renderCell: (params: GridCellParams) =>
                params.row.status === 'PENDING' ? (
                    <Box display="flex" gap=".5rem" width="100%">
                        <Tooltip
                            title="You cannot approve this certificate due to errors. Please contact the admin to resolve these issues"
                            disableHoverListener={
                                !(params.row.certificateErrorDTO.length > 0)
                            }
                        >
                            <span>
                                <Button
                                    fullWidth
                                    onClick={() => {
                                        setIsApproveModalOpen(true)
                                        setSelectedCertificates([
                                            params.id as string,
                                        ])
                                    }}
                                    text="Approve"
                                    disabled={
                                        params.row.certificateErrorDTO.length >
                                        0
                                    }
                                />
                            </span>
                        </Tooltip>
                        <Button
                            error
                            fullWidth
                            onClick={() => {
                                setSelectedCertificates([params.id as string])
                                setIsRejectModalOpen(true)
                            }}
                            text="Decline"
                        />
                    </Box>
                ) : (
                    params.row.status
                ),
        },
        {
            field: 'preview',
            sortable: false,
            flex: 1,
            headerName: 'Preview',
            width: 70,
            renderCell: (params: GridCellParams) => (
                <Button
                    text="Preview"
                    outlined
                    colorPrimary
                    fullWidth
                    onClick={() =>
                        navigate(
                            `/ride-to-work-apply/schemeadmin/companies/${companyId}/certificates/${params.id}`
                        )
                    }
                />
            ),
        },
    ]

    function clearFilters() {
        setFilters({
            ...filters,
            page: 0,
            size: 10,
            sort: null,
            processStatus: null,
            applicationDateFrom: null,
            applicationDateTo: null,
        })

        setSelectedStatuses([])
    }

    function setSortingValue(value: string | null) {
        setFilters({ ...filters, page: 0, sort: value })
    }

    function applyFilters(
        data: Omit<ICertificateFiltersSchemeAdmin, 'page' | 'size'>
    ) {
        setFilters({
            ...filters,
            ...data,
            page: 0,
            applicationDateFrom: data?.applicationDateFrom
                ? format(
                      new Date(data.applicationDateFrom as string),
                      'yyyy-MM-dd'
                  )
                : filters.applicationDateFrom,
            applicationDateTo: data?.applicationDateTo
                ? format(
                      new Date(data.applicationDateTo as string),
                      'yyyy-MM-dd'
                  )
                : filters.applicationDateTo,
            processStatus: selectedStatuses,
        })
    }

    function handlePageChange(e: React.ChangeEvent<unknown>, p: number) {
        if (filters.page === 0 && p == 1) return

        setFilters({ ...filters, page: p - 1 })
    }

    function shouldDisableFilterButton() {
        const { page, size, sort, processStatus, ...rest } = filters

        const startInput = formRef.current?.values.applicationDateFrom
        const endInput = formRef.current?.values.applicationDateTo

        const val1 =
            Object.values(rest).every((element) => element === null) &&
            selectedStatuses.length == 0

        const val = Boolean(!startInput && !endInput)

        return Boolean(val && val1)
    }

    const exportCertificateToCsv = useMutation(
        () => getCertificateListingToCsv(companyId, filters),
        {
            onSuccess: (response) => {
                generateCsvReport(response.data, 'Certificates_Report')
            },
            onError: (error: AxiosError<{ message: string }>) => {
                openNotification(
                    (error.response?.data?.message as string) ||
                        'Something went wrong ',
                    'error'
                )
            },
        }
    )

    return {
        columns,
        filters,
        response,
        isLoading,
        clearFilters,
        applyFilters,
        exportCertificateToCsv,
        shouldDisableFilterButton,
        mutation,
        certificates,
        isApproveModalOpen,
        setIsApproveModalOpen,
        isRejectModalOpen,
        setIsRejectModalOpen,
        selectedCertificates,
        setSelectedCertificates,
        handlePageChange,
        isFetching,
        errorMsg,
        selectedStatuses,
        setSelectedStatuses,
        isAccordionFilterExpanend,
        setIsAccordionFilterExpanend,
        certificatesStatuses,
        showLoadingSpinner,
        isDescriptionModalOpen,
        setIsDescriptionModalOpen,
        formRef,
        refetch,
        isInitialLoading,
    }
}

export default useManageCertificates
