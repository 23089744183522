import { FC, useRef } from 'react'
import { Box, Divider, Grid, Typography, useTheme } from '@mui/material'
import Button from 'components/Button'
import Input from 'components/Input'
import { Formik, FormikProps } from 'formik'
import {
    getCompanyRestrictions,
    updateCompanyRestrictions,
} from 'api/schemeAdmin'
import { useQuery, useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import LoadingSpinner from 'components/LoadingSpinner'
import {
    HIRE_AGREEMENT_OPTIONS,
    TRANSFER_OPTIONS_WITH_VALUES,
    SALARY_SACRIFICE_DEDUCTION_FREQUENCY,
    NOTIFICATION_OPTIONS_WITH_VALUES,
} from 'utils/constants'
import useStore from 'store/Store'
import { ICompanyRestrictions } from 'Interfaces/Company'
import CustomTooltip from 'components/CustomTooltip'
import { updateCompanyRestrictionsAsSchemaAdminValidator } from 'utils/ValidationSchema'
import DateTimeInput from 'components/DateTimeInput'
import format from 'date-fns/format'
import { AxiosError } from 'axios'
import { SectionHeader } from 'components/SectionHeader'
import { getTerms } from 'api/schemeAdmin'
import { OwnershipSelect } from 'pages/SchemeAdmin/CompanyRegister/OwnershipSelect'

const AccountSettings: FC = () => {
    const { companyId } = useParams()
    const { openNotification } = useStore()
    const theme = useTheme()
    const formRef = useRef<FormikProps<ICompanyRestrictions>>(null)

    const mutation = useMutation(
        (data: ICompanyRestrictions) =>
            updateCompanyRestrictions(companyId as string, data),
        {
            onSuccess: () => {
                openNotification('Data has been updated', 'success')
            },

            onError: (
                error: AxiosError<{ fieldErrors: Record<string, string> }>
            ) => {
                formRef.current?.setStatus(error)
                openNotification('Something went wrong', 'error')
            },
        }
    )

    const { data: response, isFetching } = useQuery(
        ['getCompanyRestrictions', companyId],
        () => getCompanyRestrictions(companyId as string)
    )

    const { data: terms, isFetching: isFetchingTerms } = useQuery(
        ['getTerms'],
        () => getTerms()
    )

    function resetDates() {
        formRef.current?.setFieldValue('schemeOpeningDate', null)
        formRef.current?.setFieldValue('schemeClosingDate', null)

        formRef.current?.setFieldTouched('schemeClosingDate', false)
        formRef.current?.setFieldTouched('schemeOpeningDate', false)
    }

    if (isFetching || isFetchingTerms) {
        return <LoadingSpinner />
    }

    const accountSettingsValues = {
        ...response?.data,
    }

    const ownershipOptions = TRANSFER_OPTIONS_WITH_VALUES.filter(
        (element: { label: string; value: string }) =>
            accountSettingsValues.ownershipOption === 'T4U'
                ? true
                : element.value !== 'T4U'
    )

    return (
        <Formik
            innerRef={formRef}
            validationSchema={updateCompanyRestrictionsAsSchemaAdminValidator}
            onSubmit={(values) => {
                const { schemeOpeningDate, schemeClosingDate } = values

                mutation.mutate({
                    ...values,
                    schemeOpeningDate: !schemeOpeningDate
                        ? null
                        : format(
                              new Date(schemeOpeningDate),
                              'yyyy-MM-dd HH:mm'
                          ),

                    schemeClosingDate: !schemeClosingDate
                        ? null
                        : format(
                              new Date(schemeClosingDate),
                              'yyyy-MM-dd HH:mm'
                          ),
                })
            }}
            initialValues={accountSettingsValues}
        >
            {(formik) => (
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <SectionHeader variant="h5">
                            Account Settings
                        </SectionHeader>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            color="primary"
                            variant="h6"
                            fontWeight="bold"
                        >
                            Notifications
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            apiErrors={formik.status}
                            fullWidth
                            name="notification"
                            radio
                            radioRow
                            radioOptions={NOTIFICATION_OPTIONS_WITH_VALUES}
                            label="How often should we send you email notifications of new certificate requests?"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" gap="0.5rem" alignItems="center">
                            <Typography
                                variant="h6"
                                color="primary"
                                fontWeight="bold"
                            >
                                Certificate application date period
                            </Typography>
                            <CustomTooltip text="Only accepting application for a limited period? You can set a date range below. If you do not set a date, the scheme will always be active" />
                        </Box>
                        <Divider />
                    </Grid>
                    <Grid item container rowSpacing={2} columnSpacing={5}>
                        <Grid item xs={12} md={6}>
                            <DateTimeInput
                                fullWidth
                                apiErrors={formik.status}
                                value={formik.values.schemeOpeningDate}
                                onBlur={() => {
                                    formRef.current?.setFieldTouched(
                                        'schemeOpeningDate',
                                        true
                                    )
                                }}
                                onChange={(value: string | null) => {
                                    formik.setFieldValue(
                                        'schemeOpeningDate',
                                        value
                                    )
                                }}
                                label="Start Date"
                                name="schemeOpeningDate"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateTimeInput
                                fullWidth
                                apiErrors={formik.status}
                                value={formik.values.schemeClosingDate}
                                onBlur={() => {
                                    formRef.current?.setFieldTouched(
                                        'schemeClosingDate',
                                        true
                                    )
                                }}
                                onChange={(value: string | null) => {
                                    formik.setFieldValue(
                                        'schemeClosingDate',
                                        value
                                    )
                                }}
                                label="End Date"
                                name="schemeClosingDate"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                onClick={resetDates}
                                colorPrimary
                                text="Reset Dates"
                                disabled={
                                    !formik.values.schemeClosingDate &&
                                    !formik.values.schemeOpeningDate
                                }
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography
                            variant="h6"
                            color="primary"
                            fontWeight="bold"
                        >
                            Ownership options
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        display="flex"
                        gap="1rem"
                        alignItems="center"
                    >
                        <OwnershipSelect
                            optionsWithValues={ownershipOptions}
                            maxWidth="600px"
                            label="Which ownership do you wish to offer"
                            defaultValues={accountSettingsValues}
                        />
                        <CustomTooltip text="At the end of the Salary Sacrifice term, Government guidelines require that any transfer of ownership to the employee takes place in accordance with the Fair Market Value. There are different options for managing the end of scheme. Please click" />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography
                            color="primary"
                            variant="h6"
                            fontWeight="bold"
                        >
                            Salary Sacrifice Preferences
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} md={6} display="flex">
                        <Input
                            type="number"
                            apiErrors={formik.status}
                            name="minCertificateAmount"
                            label="Minimum Certificate Amount"
                            fullWidth
                            required
                            prefix="£"
                            maxWidth="600px"
                            tooltip={`The minimum certificate value is ${formik.values.minCertificateAmount}`}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} display="flex">
                        <Input
                            prefix="£"
                            type="number"
                            apiErrors={formik.status}
                            name="maxCertificateAmount"
                            label="Maximum Certificate Amount"
                            fullWidth
                            required
                            maxWidth="600px"
                            tooltip={`The maximum certificate value is ${formik.values.maxCertificateAmount}`}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Input
                            apiErrors={formik.status}
                            required
                            width="300px"
                            name="salarySacrificeTermInMonths"
                            label="Set salary sacrifice term in months"
                            select
                            options={
                                terms?.data?.allAvailableSalarySacrificeTerms?.[
                                    formik.values?.ownershipOption
                                ] ?? ['']
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            color="primary"
                            variant="h6"
                            fontWeight="bold"
                        >
                            Hire agreement
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            apiErrors={formik.status}
                            required
                            name="hireAgreementType"
                            label="Require signature Online or Offline"
                            fullWidth
                            select
                            options={HIRE_AGREEMENT_OPTIONS}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} display="flex">
                        {formik.values.hireAgreementType === 'ONLINE' && (
                            <Input
                                apiErrors={formik.status}
                                required
                                name="hireAgreementTermInMonths"
                                select
                                fullWidth
                                options={
                                    terms?.data
                                        ?.allAvailableHireAgreementTerms?.[
                                        formik.values?.ownershipOption
                                    ] ?? ['']
                                }
                                label="Set term of online hire agreement in months"
                                tooltip="The hire agreement is between employee and Evans Cycles and covers the full Fair Market Value depreciation term, to the point where the hired goods are of negligible value and ownership transfers to the employee for free. During this term, the salary sacrifice period will complete.
                                    There is no extra charge for choosing this option."
                            />
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <Typography
                            variant="h6"
                            color="primary"
                            fontWeight="bold"
                        >
                            Payment frequency
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            apiErrors={formik.status}
                            name="salarySacrificeDeductionFrequency"
                            label="Set the payment frequency"
                            select
                            fullWidth
                            options={SALARY_SACRIFICE_DEDUCTION_FREQUENCY}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="h6"
                            color="primary"
                            fontWeight="bold"
                        >
                            Others
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid item container gap={1}>
                        <Grid item xs={12}>
                            <Input
                                apiErrors={formik.status}
                                name="requirePayrollNumber"
                                label="Required payroll or employee number"
                                fullWidth
                                checkBox
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Input
                                apiErrors={formik.status}
                                name="isTemporaryDisabled"
                                label="Temporary disable company from certification process"
                                fullWidth
                                checkBox
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider
                            color="secondary"
                            sx={{
                                borderBottomWidth: '2px',
                                backgroundColor: theme.palette.secondary.main,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button
                            isLoading={mutation.isLoading}
                            onClick={formik.handleSubmit}
                            text="Update Account Settings"
                        />
                    </Grid>
                </Grid>
            )}
        </Formik>
    )
}

export default AccountSettings
