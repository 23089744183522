import { Autocomplete, TextField, useTheme } from '@mui/material'
import { FC, useCallback, useState } from 'react'
import { getSchemeAdminByEmail } from 'api/evansAdmin'
import { useMutation } from 'react-query'
import debounce from 'lodash/debounce'

export interface IOption {
    emailAddress: string
    firstName: string
    id: string
    lastName: string
}

interface IProps {
    onChoose: (options: IOption[], input: string) => void
}

const AdminSearchBar: FC<IProps> = ({ onChoose }) => {
    const [email, setEmail] = useState('')
    const [options, setOptions] = useState<IOption[]>([])
    const theme = useTheme()

    const mutation = useMutation(
        (email: string) => getSchemeAdminByEmail({ email, limit: 10 }),
        {
            onSuccess: (response) => {
                setOptions(response.data)
            },
        }
    )

    const debounceFn = useCallback(
        debounce((email) => {
            if (email.trim().length > 0) {
                mutation.mutate(email.trim())
                onChoose(options, email)
            }
        }, 250),
        [mutation.mutate]
    )

    return (
        <Autocomplete
            inputValue={email}
            loading={mutation.isLoading}
            loadingText="Loading..."
            noOptionsText="No options..."
            autoHighlight
            sx={{
                background: theme.palette.primary.contrastText,
            }}
            onKeyUp={(e) => {
                if (e.key === 'Enter') {
                    onChoose(options, email)
                }
            }}
            onInputChange={(
                event: React.SyntheticEvent,
                newInputValue: string
            ) => {
                if (event.type === 'click' && newInputValue) {
                    onChoose(options, newInputValue)
                }
                if (event.type === 'change') {
                    debounceFn(newInputValue)
                }
                setEmail(newInputValue.trim())
            }}
            clearOnBlur={false}
            options={options}
            getOptionLabel={(option: IOption | string) => {
                if (typeof option === 'string') return option

                return option.emailAddress
            }}
            renderOption={(props, option: IOption) => {
                const label = `${option.emailAddress} - ${option.firstName} ${option.lastName}`
                return <li {...props}>{label}</li>
            }}
            renderInput={(params) => (
                <TextField {...params} placeholder="e.g. user@gmail.com" />
            )}
        />
    )
}

export default AdminSearchBar
