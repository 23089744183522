import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import EvansAdminSidebar from 'layout/EvansAdminSidebar'

const EvansAdminIndexPage: FC = () => {
    return (
        <>
            <EvansAdminSidebar />
            <Outlet />
        </>
    )
}

export default EvansAdminIndexPage
