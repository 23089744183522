import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    GridActionsCellItem,
    GridCellParams,
    GridRowParams,
} from '@mui/x-data-grid'
import { ICompaniesFilters } from 'Interfaces/Company'
import { useQuery, useMutation } from 'react-query'
import {
    getCompanyList,
    getCompaniesToCSV,
    getAllowedActionsForCompanies,
    performCompanyAction,
} from 'api/evansAdmin'
import generateCsvReport from 'utils/generateCsvReport'
import useStore from 'store/Store'
import Button from 'components/Button'
import { capitalizeEnum } from 'utils/capitalize'
import SortingTableCell from 'components/sorting/SortingTableCell'
import { AxiosError } from 'axios'
import useLocalStorage from 'use-local-storage'

const useEvansAdminCompaniesPage = () => {
    const navigate = useNavigate()

    const [filters, setFilters] = useLocalStorage<ICompaniesFilters>(
        'admin-companies-filters',
        {
            page: 0,
            size: 11,
            companyName: null,
            sort: null,
            companyStatus: null,
            navisionNumber: null,
            vatNumber: null,
            dateFrom: null,
            dateTo: null,
            firstName: null,
            lastName: null,
        }
    )

    const { sort } = filters

    const columns = [
        {
            field: 'companyName',
            flex: 1,
            headerName: 'Company Name',
            sortable: false,
            renderHeader: () => (
                <SortingTableCell
                    title="Company Name"
                    sortingValue={sort as string}
                    setSortingValue={setSortingValue}
                    value="companyName"
                />
            ),
        },
        {
            field: 'creationDate',
            flex: 1,
            headerName: 'Creation Date',
            sortable: false,
            renderHeader: () => (
                <SortingTableCell
                    title="Creation Date"
                    sortingValue={sort as string}
                    setSortingValue={setSortingValue}
                    value="creationDate"
                />
            ),
        },
        {
            field: 'status',
            flex: 1,
            headerName: 'Status',
            sortable: false,
            renderHeader: () => (
                <SortingTableCell
                    title="Status"
                    sortingValue={sort as string}
                    setSortingValue={setSortingValue}
                    value="status"
                />
            ),
        },
        {
            field: 'navisionNumber',
            flex: 1,
            headerName: 'Navision Number',
            sortable: false,
            renderHeader: () => (
                <SortingTableCell
                    title="Navision Number"
                    sortingValue={sort as string}
                    setSortingValue={setSortingValue}
                    value="navisionNumber"
                />
            ),
        },

        {
            field: 'Preview',
            sortable: false,
            width: 100,
            headerName: 'Preview',
            renderCell: (params: GridCellParams) => (
                <Button
                    text="Preview"
                    onClick={() =>
                        navigate(
                            `/ride-to-work-apply/evans-admin/companies/${params.row.id}`
                        )
                    }
                />
            ),
        },
        {
            field: 'actions',
            width: 70,
            sortable: false,
            headerName: 'Actions',
            type: 'actions',
            getActions: (params: GridRowParams) =>
                renderActions(params.row.status, params.row.id),
        },
    ]

    const renderActions = (status: string, id: string) => {
        const companyActions = actions?.data?.allowedCompanyActions[status]
        return companyActions?.map((action: string) => (
            <GridActionsCellItem
                key={action + id}
                showInMenu
                onClick={() => performAction.mutate({ action, companyId: id })}
                label={capitalizeEnum(action)
                    ?.replaceAll('Xml', 'XML')
                    ?.replaceAll('Re Activate', 'Reactivate')}
            />
        ))
    }

    const { openNotification } = useStore()
    const [isFilteringModalOpen, setIsFilteringModalOpen] = useState(false)

    function setSortingValue(value: string | null) {
        setFilters({ ...filters, sort: value })
    }

    const getSuccessMessage = (action: string): string => {
        switch (action) {
            case 'DISABLE':
                return 'Company disabled successfully'
            case 'RESEND_EMAIL':
            case 'SEND_EMARSYS_COMPANY_DECLINED_EMAIL':
                return 'Email resent successfully'
            case 'ACTIVATE':
            case 'RE_ACTIVATE':
                return 'Company activated successfully'
            case 'RESEND_NAV_XML':
                return 'XML resent successfully'
            case 'APPROVE':
                return 'Company approved successfully'
            case 'DECLINE':
                return 'Company declined successfully'
            default:
                return 'Action performed successfully'
        }
    }

    const performAction = useMutation(
        ({ action, companyId }: { action: string; companyId: string }) =>
            performCompanyAction(companyId, action),
        {
            onSuccess: (data) => {
                const action = data.config.url?.split('/').pop()
                openNotification(getSuccessMessage(action ?? ''), 'success')
                refetch()
            },

            onError: () => {
                openNotification(`Couldn't perform the action`, 'error')
            },
        }
    )

    const {
        data: actions,
        isFetching: isActionsFetching,
        isLoading: isActionsLoading,
    } = useQuery(
        'getAllowedCompaniesActions',

        () => getAllowedActionsForCompanies(),
        { keepPreviousData: true }
    )

    const { isFetching, data, refetch, isLoading } = useQuery(
        ['getCompaniesList', filters],

        () => getCompanyList(filters),
        { keepPreviousData: true }
    )

    const exportCompaniesToCsv = useMutation(() => getCompaniesToCSV(filters), {
        onSuccess: (response) => {
            generateCsvReport(response.data, 'Companies_Report')
        },
        onError: (error: AxiosError<{ message: string }>) => {
            openNotification(
                error?.response?.data.message || 'Something went wrong',
                'error'
            )
        },
    })

    function handleFilteringModalState() {
        setIsFilteringModalOpen((prevState: boolean) => !prevState)
    }

    function applyFilters(
        filtersData: Omit<ICompaniesFilters, 'page' | 'size'>
    ) {
        setFilters({ ...filters, page: 0, ...filtersData })
    }

    function shouldDisableFilterButton() {
        const { page, size, sort, ...rest } = filters
        const filtersValues = Object.values(rest)

        return filtersValues.every((element: string | null) => !element)
    }

    function clearFilters() {
        setFilters({
            size: filters.size,
            page: 0,
            sort: filters.sort,
            companyName: null,
            companyStatus: null,
            navisionNumber: null,
            firstName: null,
            lastName: null,
            vatNumber: null,
            dateFrom: null,
            dateTo: null,
        })
    }

    function convertCompanyData() {
        return data?.data.content.map((element: Record<string, string>) => {
            return {
                ...element,
                creationDate: element.creationDate
                    .toString()
                    .replaceAll(',', '-'),
            }
        })
    }

    function handlePageChange(e: React.ChangeEvent<unknown>, p: number) {
        if (filters.page === 0 && p == 1) return
        setFilters({ ...filters, page: p - 1 })
    }

    return {
        columns,
        filters,
        setFilters,
        isFilteringModalOpen,
        setIsFilteringModalOpen,
        handleFilteringModalState,
        applyFilters,
        shouldDisableFilterButton,
        clearFilters,
        isFetching,
        data,
        convertCompanyData,
        handlePageChange,
        exportCompaniesToCsv,
        isActionsFetching,
        isActionsLoading,
        isLoading,
    }
}

export default useEvansAdminCompaniesPage
