import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { DialogContentStyle } from 'components/Modal/ModalStyle'

const Step2: FC = () => {
    return (
        <Box sx={DialogContentStyle}>
            <Typography fontWeight="bold" variant="body1" mb={2}>
                Now, check your email. Remember to look in your spam folder,
                where automated messages sometimes filter.
            </Typography>
        </Box>
    )
}

export default Step2
