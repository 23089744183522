import { useState } from 'react'
import axiosInstance from 'api/axios'
import useStore from 'store/Store'
import UserPool from './UserPool'
import {
    CognitoUser,
    AuthenticationDetails,
    CognitoUserSession,
    NodeCallback,
} from 'amazon-cognito-identity-js'
import useBroadcast from './useBroadcast'

const useAuth = () => {
    const {
        handleEvansAdminState,
        handleSchemeAdminState,
        handleCmsPermission,
    } = useStore()
    const [loginEvansAdminError, setLoginEvansAdminError] = useState('')
    const [loginSchemeAdminError, setLoginSchemeAdminError] = useState('')
    const [error, setError] = useState('')
    const [confirmationError, setConfirmationError] = useState('')
    const [resetPasswordError, setResetPasswordError] = useState('')
    const [confirmPasswordError, setConfirmPasswordError] = useState('')
    const [confirmResetPasswordIsLoading, setConfirmResetPasswordIsLoading] =
        useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [userRoleError, setUserRoleError] = useState('')
    const [isCompletePasswordLoading, setIsCompletePasswordLoading] =
        useState(false)

    const [
        isConfirmationResendLinkLoading,
        setIsConfirmationResendLinkLoading,
    ] = useState(false)

    const { handleBroadcastState } = useBroadcast()

    function logout() {
        const user = UserPool.getCurrentUser()

        user?.getSession((err: null, data: CognitoUserSession) => {
            if (data) {
                const roles = data.getAccessToken().payload['cognito:groups']
                user.signOut(() => {
                    const isEvansAdmin = roles.includes('Evans-admin')
                    const isSchemeAdmin = roles.includes('Scheme-admin')

                    if (isSchemeAdmin) {
                        handleSchemeAdminState(false)
                        handleBroadcastState('logoutSchemeAdmin')
                    }

                    if (isEvansAdmin) {
                        handleEvansAdminState(false, '')
                        handleBroadcastState('logoutEvansAdmin')
                    }

                    handleCmsPermission(false)

                    delete axiosInstance.defaults.headers.common[
                        'Authorization'
                    ]
                    localStorage.clear()
                })
            }
        })
    }

    function login(
        role: string,
        email: string,
        password: string,
        resetPasswordCallback?: () => void
    ) {
        setIsLoading(true)
        const user = new CognitoUser({
            Username: email,
            Pool: UserPool,
        })

        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        })

        // If other user is already logged in
        logout()

        user.authenticateUser(authDetails, {
            onSuccess: (data) => {
                const roles = data.getAccessToken().payload['cognito:groups']
                setIsLoading(false)
                setLoginEvansAdminError('')
                setLoginSchemeAdminError('')

                if (!roles?.includes(role)) {
                    setUserRoleError('User does not exist.')
                    localStorage.clear()
                    return
                }

                assignPermissions(roles, email)
            },

            newPasswordRequired: () => {
                setIsLoading(false)
                if (resetPasswordCallback) resetPasswordCallback()
            },

            onFailure: (error) => {
                setIsLoading(false)
                if (role === 'Evans-admin') {
                    setLoginEvansAdminError(error.message)
                } else {
                    setLoginSchemeAdminError(error.message)
                }
            },
        })
    }

    function assignPermissions(roles: string[], email?: string) {
        if (roles?.includes('Evans-admin')) {
            handleEvansAdminState(true, email)
            handleBroadcastState('loginEvansAdmin', email)
        }

        if (roles?.includes('Scheme-admin')) {
            handleSchemeAdminState(true)
            handleBroadcastState('loginSchemeAdmin')
        }

        if (roles?.includes('Evans-CMS-admin')) {
            handleCmsPermission(true)
        }
    }

    function registerAccount(
        email: string,
        password: string,
        callback: () => void
    ) {
        setIsLoading(true)
        UserPool.signUp(email, password, [], [], (err, data) => {
            if (err) {
                setError(err.message)
            }

            if (data) {
                setError('')
                callback()
            }

            setIsLoading(false)
        })
    }

    function confirmRegistration(
        email: string,
        code: string,
        callback: () => void
    ) {
        setIsLoading(true)
        const user = new CognitoUser({
            Username: email,
            Pool: UserPool,
        })

        user.confirmRegistration(code, false, (err, result) => {
            if (err) {
                setConfirmationError(err.message)
            }

            if (result) {
                callback()
            }
        })

        setIsLoading(false)
    }

    function resetPassword(
        email: string,
        callback: () => void,
        errorCallback?: () => void
    ) {
        setIsLoading(true)
        const user = new CognitoUser({
            Username: email,
            Pool: UserPool,
        })

        user.forgotPassword({
            onSuccess() {
                callback()
                setResetPasswordError('')
            },

            onFailure(err) {
                setResetPasswordError(err.message)
                if (errorCallback) {
                    errorCallback()
                }
            },
        })
        setIsLoading(false)
    }

    function confirmResetPassword(
        email: string,
        code: string,
        password: string,
        callback: () => void
    ) {
        setConfirmResetPasswordIsLoading(true)
        const user = new CognitoUser({
            Username: email,
            Pool: UserPool,
        })

        user.confirmPassword(code, password, {
            onSuccess() {
                callback()
                setConfirmResetPasswordIsLoading(false)
            },
            onFailure(err) {
                setConfirmPasswordError(err.message)
                setConfirmResetPasswordIsLoading(false)
            },
        })
    }

    function getCurrentUserEmail() {
        let email = ''
        UserPool.getCurrentUser()?.getSession(
            (err: Error, user: CognitoUserSession | null) => {
                if (user) {
                    email = user.getIdToken().decodePayload().email
                }
            }
        )

        return email
    }

    function changePassword(
        oldPassword: string,
        newPassword: string,
        callback: NodeCallback<Error, string>
    ) {
        return new Promise((resolve, reject) => {
            const currentUser = UserPool.getCurrentUser()
            if (currentUser) {
                currentUser.getSession((err: Error) => {
                    if (err) {
                        reject(err)
                    } else {
                        currentUser.changePassword(
                            oldPassword,
                            newPassword,
                            callback
                        )
                    }
                })
            } else {
                reject('User is not authenticated')
            }
        })
    }

    function completeNewPassword(
        email: string,
        password: string,
        newPassword: string,
        errorCallback: (message: string) => void
    ) {
        const user = new CognitoUser({
            Username: email,
            Pool: UserPool,
        })

        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        })
        setIsCompletePasswordLoading(true)

        user.authenticateUser(authDetails, {
            onFailure: () => {
                return
            },
            onSuccess: () => {
                return
            },
            newPasswordRequired: (userAttributes) => {
                delete userAttributes.email_verified
                delete userAttributes.email
                delete userAttributes.phone_number_verified

                user.completeNewPasswordChallenge(newPassword, userAttributes, {
                    onFailure: (err) => {
                        errorCallback(err.message)
                        setIsCompletePasswordLoading(false)
                    },
                    onSuccess: (result) => {
                        const roles =
                            result.getAccessToken().payload['cognito:groups']
                        assignPermissions(roles)
                        setIsCompletePasswordLoading(false)
                    },
                })
            },
        })
    }

    function resendConfirmationMail(
        email: string,
        sucessCallback: () => void,
        errorCallback?: () => void
    ) {
        const user = new CognitoUser({
            Username: email,
            Pool: UserPool,
        })

        setIsConfirmationResendLinkLoading(true)

        return new Promise((resolve, reject) => {
            user.resendConfirmationCode((err, result) => {
                if (err) {
                    errorCallback && errorCallback()
                    setIsConfirmationResendLinkLoading(false)
                    reject(err)
                } else {
                    sucessCallback()
                    setIsConfirmationResendLinkLoading(false)
                    resolve(result)
                }
            })
        })
    }

    return {
        assignPermissions,
        logout,
        login,
        loginEvansAdminError,
        loginSchemeAdminError,
        setLoginSchemeAdminError,
        isLoading,
        registerAccount,
        error,
        confirmRegistration,
        confirmationError,
        resetPassword,
        resetPasswordError,
        setConfirmPasswordError,
        confirmResetPassword,
        confirmPasswordError,
        getCurrentUserEmail,
        changePassword,
        confirmResetPasswordIsLoading,
        userRoleError,
        completeNewPassword,
        isCompletePasswordLoading,
        resendConfirmationMail,
        isConfirmationResendLinkLoading,
    }
}

export default useAuth
