import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    GridActionsCellItem,
    GridCellParams,
    GridRowParams,
} from '@mui/x-data-grid'
import { ICertificateFilters } from 'Interfaces/Certificate'
import { useQuery, useMutation } from 'react-query'
import {
    getAllCertificates,
    getCertificatesStatuses,
    getAllowedActionsForCertificates,
    getCertificatesToCsv,
    performCertificateAction,
} from 'api/evansAdmin'
import generateCsvReport from 'utils/generateCsvReport'
import useStore from 'store/Store'
import Button from 'components/Button'
import { AxiosError, AxiosResponse } from 'axios'
import { capitalizeEnum } from 'utils/capitalize'
import SortingTableCell from 'components/sorting/SortingTableCell'
import { convertToPounds } from 'utils/convertToPounds'
import useLocalStorage from 'use-local-storage'
import CustomTooltip from '../../../components/CustomTooltip'

const useEvansAdminCertificatesPage = () => {
    const navigate = useNavigate()
    const [companyIdToPreviewErrors, setCompanyIdToPreviewErrors] = useState({
        id: '',
    })
    const [filters, setFilters] = useLocalStorage<ICertificateFilters>(
        'admin-certificate-filters',
        {
            page: 0,
            size: 11,
            sort: null,
            companyName: null,
            navisionNumber: null,
            processStatus: null,
            applicationDateFrom: null,
            applicationDateTo: null,
            approvalDateFrom: null,
            approvalDateTo: null,
            certificateId: null,
            postcode: null,
            emailAddress: null,
            firstName: null,
            lastName: null,
            certificateNumber: null,
            hasCertificateErrors: null,
        }
    )

    const { page, size, sort, ...rest } = filters

    const columns = [
        {
            field: 'hasCertificateErrors',
            flex: 0.5,
            width: 90,
            headerName: '...',
            sortable: false,
            renderHeader: () => (
                <CustomTooltip
                    color="secondary"
                    text="Errors that occurred during the certification process"
                />
            ),
            renderCell: (params: GridCellParams) => {
                const { hasErrors } = params.row
                return (
                    <>
                        {hasErrors && (
                            <Button
                                text="Errors"
                                error
                                onClick={() =>
                                    setCompanyIdToPreviewErrors({
                                        id: params.row.id,
                                    })
                                }
                            />
                        )}
                    </>
                )
            },
        },
        {
            field: 'navisionNumber',
            flex: 1.7,
            headerName: 'Company Navision Number',
            sortable: false,
            renderHeader: () => (
                <SortingTableCell
                    title="Company Navision Number"
                    sortingValue={sort as string}
                    setSortingValue={setSortingValue}
                    value="navisionNumber"
                />
            ),
        },
        {
            field: 'companyName',
            flex: 1.5,
            headerName: 'Company Name',
            sortable: false,
            renderHeader: () => (
                <SortingTableCell
                    title="Company Name"
                    sortingValue={sort as string}
                    setSortingValue={setSortingValue}
                    value="companyName"
                />
            ),
        },
        {
            field: 'employeeName',
            flex: 1.5,
            headerName: 'Employee Name',
            sortable: false,
            renderHeader: () => (
                <SortingTableCell
                    title="Employee Name"
                    sortingValue={sort as string}
                    setSortingValue={setSortingValue}
                    value="firstName"
                />
            ),
        },
        {
            field: 'certificateAmount',
            flex: 1,
            headerName: 'Total Amount',
            sortable: false,
            renderHeader: () => (
                <SortingTableCell
                    title="Total Amount"
                    sortingValue={sort as string}
                    setSortingValue={setSortingValue}
                    value="certificateAmount"
                />
            ),
        },
        {
            field: 'singleSalarySacrificeAmount',
            flex: 1,
            headerName: 'Salary Sacrifice',
            sortable: false,
            renderHeader: () => (
                <SortingTableCell
                    title="Salary Sacrifice"
                    sortingValue={sort as string}
                    setSortingValue={setSortingValue}
                    value="singleSalarySacrificeAmount"
                />
            ),
        },
        {
            field: 'approvalDate',
            flex: 1,
            headerName: 'Approval Date',
            sortable: false,
            renderHeader: () => (
                <SortingTableCell
                    title="Approval Date"
                    sortingValue={sort as string}
                    setSortingValue={setSortingValue}
                    value="approvalDate"
                />
            ),
        },
        {
            field: 'processStatus',
            flex: 1,
            headerName: 'Status',
            sortable: false,
            renderHeader: () => (
                <SortingTableCell
                    title="Status"
                    sortingValue={sort as string}
                    setSortingValue={setSortingValue}
                    value="processStatus"
                />
            ),
        },
        {
            field: 'preview',
            headerName: 'Preview',
            sortable: false,
            width: 100,
            renderCell: (params: GridCellParams) => (
                <Button
                    text="Preview"
                    onClick={() =>
                        navigate(
                            `/ride-to-work-apply/evans-admin/certificates/${params.row.id}`
                        )
                    }
                />
            ),
        },
        {
            field: 'actions',
            sortable: false,
            width: 70,
            headerName: 'Actions',
            type: 'actions',
            getActions: (params: GridRowParams) =>
                params.row.isCompanyDisabled
                    ? []
                    : renderActions(params.row.processStatus, params.row.id),
        },
    ]

    const renderActions = (status: string, id: string) => {
        const companyActions =
            actions?.data?.allowedCertificateActions[status] ?? []
        return (
            companyActions?.map((action: string) => (
                <GridActionsCellItem
                    key={action + id}
                    showInMenu
                    onClick={() =>
                        performAction.mutate({
                            action,
                            certificateId: id,
                        })
                    }
                    label={capitalizeEnum(action as string)}
                />
            )) ?? []
        )
    }

    const getSuccessMessage = (action: string): string => {
        switch (action) {
            case 'NOTIFY_NAVISION':
                return 'Navision notified successfully'
            case 'RESEND_EMAIL':
                return 'Email resent successfully'
            case 'RESEND_REMINDER_EMAIL':
                return 'Reminder email resent successfully'
            case 'CANCEL':
                return 'Certificate cancelled successfully'
            case 'REUPLOAD_AGREEMENTS':
                return 'Agreements reuploaded successfully'
            default:
                return 'Action performed successfully'
        }
    }

    const getErrorMessage = (action: string): string => {
        switch (action) {
            case 'RESEND_EMAIL':
                return 'Email can only be resend for manual or under 18 agreement'
            default:
                return `Couldn't perform the action`
        }
    }

    const performAction = useMutation(
        ({
            action,
            certificateId,
        }: {
            action: string
            certificateId: string
        }) => performCertificateAction(certificateId, action),
        {
            onSuccess: (data) => {
                const action = data.config.url?.split('/').pop()
                openNotification(getSuccessMessage(action ?? ''), 'success')
                refetch()
            },

            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            onError: (error: any) => {
                const action = error.config.url?.split('/').pop()
                openNotification(getErrorMessage(action ?? ''), 'error')
            },
        }
    )

    const { openNotification } = useStore()
    const [isFilteringModalOpen, setIsFilteringModalOpen] = useState(false)
    const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false)
    const [certificatesStatuses, setCertificatesStatuses] = useState([])

    const {
        data: response,
        isFetching,
        error,
        refetch,
    } = useQuery(['getCertificates', filters], () =>
        getAllCertificates(filters)
    )

    const { isFetching: isDescriptionLoading } = useQuery<
        AxiosResponse,
        AxiosError<{ message: string }>
    >('getCertificatesDescription', () => getCertificatesStatuses(), {
        onSuccess: (response) => {
            setCertificatesStatuses(response.data)
        },
    })

    const { data: actions } = useQuery(
        'getAllowedCertificatesActions',
        () => getAllowedActionsForCertificates(),
        {
            keepPreviousData: true,
        }
    )

    const exportCertificatesToCsv = useMutation(
        () => getCertificatesToCsv(filters),
        {
            onSuccess: (response) => {
                generateCsvReport(response.data, 'Certificates_Report')
            },
            onError: (error: AxiosError<{ message: string }>) => {
                openNotification(
                    error?.response?.data.message || 'Something went wrong',
                    'error'
                )
            },
        }
    )

    function applyFilters(data: Omit<ICertificateFilters, 'page' | 'size'>) {
        setFilters({ ...data, page: 0, size: filters.size })
    }

    function setSortingValue(value: string | null) {
        setFilters({ ...filters, sort: value })
    }

    function clearFilters() {
        setFilters({
            page: 0,
            size: filters.size,
            companyName: null,
            sort: filters.sort,
            navisionNumber: null,
            processStatus: null,
            applicationDateFrom: null,
            applicationDateTo: null,
            approvalDateFrom: null,
            approvalDateTo: null,
            certificateId: null,
            postcode: null,
            emailAddress: null,
            firstName: null,
            lastName: null,
            certificateNumber: null,
            hasCertificateErrors: null,
        })
    }

    function convertResponseDisplayFormat() {
        const rows = response?.data.content.map(
            (element: Record<string, string>) => {
                const {
                    certificateId,
                    firstName,
                    lastName,
                    certificateAmount,
                    totalSalarySacrificeAmount,
                    singleSalarySacrificeAmount,
                } = element
                return {
                    ...element,
                    id: certificateId,
                    employeeName: `${firstName} ${lastName}`,
                    certificateAmount: convertToPounds(certificateAmount),
                    totalSalarySacrificeAmount: convertToPounds(
                        totalSalarySacrificeAmount
                    ),
                    singleSalarySacrificeAmount: convertToPounds(
                        singleSalarySacrificeAmount
                    ),
                }
            }
        )

        return rows
    }

    function handleModalState() {
        setIsFilteringModalOpen((prevState: boolean) => !prevState)
    }

    function handlePageChange(e: React.ChangeEvent<unknown>, p: number) {
        if (page === 0 && p == 1) {
            return
        } else {
            setFilters({ ...filters, page: p - 1 })
        }
    }

    return {
        columns,
        isFilteringModalOpen,
        filters,
        clearFilters,
        applyFilters,
        convertResponseDisplayFormat,
        handleModalState,
        handlePageChange,
        error,
        isFetching,
        response,
        rest,
        setFilters,
        page,
        size,
        exportCertificatesToCsv,
        isDescriptionModalOpen,
        setIsDescriptionModalOpen,
        certificatesStatuses,
        isDescriptionLoading,
        companyIdToPreviewErrors,
        setCompanyIdToPreviewErrors,
    }
}

export default useEvansAdminCertificatesPage
