import { FC } from 'react'
import SortingIcon from './SortingIcon'
import RemoveSorting from './RemoveSorting'
import { Box } from '@mui/material'

const headerCellStyle = {
    display: 'flex',
    alignItems: 'center',
}

interface ISortingTableCell {
    title: string
    value: string
    setSortingValue(value: string | null): void
    sortingValue: string | null
}

const SortingTabellCell: FC<ISortingTableCell> = ({
    title,
    value,
    setSortingValue,
    sortingValue,
}) => {
    return (
        <Box sx={headerCellStyle}>
            <div>{title}</div>
            <div style={{ display: 'flex' }}>
                <SortingIcon
                    value={value + ',asc'}
                    isVisible={sortingValue !== `${value},asc`}
                    ascending
                    setValue={setSortingValue}
                />
                <SortingIcon
                    value={value + ',desc'}
                    isVisible={sortingValue === `${value},asc`}
                    ascending={false}
                    setValue={setSortingValue}
                />

                <RemoveSorting
                    currentValue={value}
                    setValue={setSortingValue}
                    sortValue={sortingValue as string}
                />
            </div>
        </Box>
    )
}

export default SortingTabellCell
