import React, { FC } from 'react'
import { TextField } from '@mui/material'
import { useField } from 'formik'

interface IProps {
    name: string
    label: string
    required?: boolean
    fullWidth?: boolean
    options: () => React.ReactNode
    width?: string
    maxWidthInput?: string
}

const SelectInput: FC<IProps> = ({
    name,
    label,
    required,
    fullWidth,
    options,
    width,
    maxWidthInput,
}) => {
    const [field, meta] = useField(name)
    return (
        <TextField
            label={label}
            fullWidth={fullWidth}
            InputLabelProps={{
                shrink: true,
                sx: {
                    color: 'black',
                    '& .MuiFormLabel-asterisk': {
                        color: '#FF0000',
                        fontSize: '1.2rem',
                    },
                },
            }}
            select
            error={Boolean(meta.touched && meta.error)}
            sx={{
                maxWidth: maxWidthInput,
                width: width,
            }}
            {...field}
            helperText={meta.touched && meta.error}
            required={required}
        >
            {options()}
        </TextField>
    )
}

export default SelectInput
