import { FC } from 'react'
import { Routes, Route } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import EvansAdminGuard from 'guards/EvansAdminGuard'
import SchemaAdminGuard from 'guards/SchemaAdminGuard'
import NotSchemaAdminGuard from 'guards/NotSchemaAdminGuard'
import NotEvansAdminGuard from 'guards/NotEvansAdminGuard'
import NotFound from 'pages/NotFound'
import EvansAdminCompaniesPage from 'pages/EvansAdmin/EvansAdminCompaniesPage/EvansAdminCompaniesPage'
import EvansAdminCertificatesPage from 'pages/EvansAdmin/EvansAdminCertificatesPage/EvansAdminCertificatesPage'
import AdminLoginPage from 'pages/SchemeAdmin/AdminLogin/AdminLoginPage'
import RideToWorkCertificatePage from 'pages/RideToWorkCertificate/RideToWorkCertificatePage'
import AdminRegisterPage from 'pages/SchemeAdmin/AdminRegister/AdminRegisterPage'
import EvansAdminCompanyPage from 'pages/EvansAdmin/EvansAdminCompanyPage/EvansAdminCompanyPage'
import EvansAdminLoginPage from 'pages/EvansAdmin/EvansAdminLoginPage/EvansAdminLoginPage'
import EvansAdminIndexPage from 'pages/EvansAdmin/EvansAdminIndexPage/EvansAdminIndexPage'
import AdminSettings from 'pages/SchemeAdmin/AdminSettings/AdminSettings'
import EvansAdminCertificateDetailsPage from 'pages/EvansAdmin/EvansAdminCertificateDetailsPage/EvansAdminCertificateDetailsPage'
import EvansAdminTemplatesPage from 'pages/EvansAdmin/EvansAdminTemplates/EvansAdminTemplatesPage'
import EvansAdminTemplatesEditPage from 'pages/EvansAdmin/EvansAdminTemplates/EvansAdminTemplateEdit/EvansAdminTemplatesEditPage'
import EvansAdminDocumentsPage from 'pages/EvansAdmin/EvansAdminDocuments/EvansAdminDocumentsPage'
import SchemeAdminCompanyRegister from 'pages/SchemeAdmin/CompanyRegister/SchemeAdminCompanyRegister'
import EvansAdminCMSPage from 'pages/EvansAdmin/EvansAdminCMSPage/EvansAdminCMSPage'
import EvansAdminCMSEditPage from 'pages/EvansAdmin/EvansAdminCMSPage/EvansAdminCMSEditPage'
import CompanyDetails from 'pages/EvansAdmin/EvansAdminCompanyPage/components/CompanyDetails'
import SchemeAdminList from 'pages/EvansAdmin/EvansAdminCompanyPage/components/SchemeAdminList'
import CompanyRestrictions from 'pages/EvansAdmin/EvansAdminCompanyPage/components/CompanyRestrictions'
import CompanyDocuments from 'pages/EvansAdmin/EvansAdminCompanyPage/components/CompanyDocuments'
import CompanySettings from 'pages/EvansAdmin/EvansAdminCompanyPage/components/CompanySettings'
import SchemeAdministrator from 'pages/EvansAdmin/EvansAdminCompanyPage/components/SchemeAdministrator'
import SchemeAdminCompanyDetailsPage from 'pages/SchemeAdmin/SchemeAdminCompanyPage/SchemeAdminCompanyDetailsPage'
import SchemeAdminDocuments from 'pages/SchemeAdmin/SchemeAdminCompanyPage/components/Documents/SchemeAdminDocuments'
import SchemeAdminCertificates from 'pages/SchemeAdmin/SchemeAdminCompanyPage/components/Certificates/SchemeAdminCertificates'
import SavingsCalculatorPage from 'pages/SavingsCalculator/SavingsCalculatorPage'
import CompanyDetailsPage from 'pages/SchemeAdmin/SchemeAdminCompanyPage/components/Settings/CompanySettings'
import AgreementDeclinedPage from 'pages/RideToWorkCertificate/AgreementCancelledPage'
import EvansAdminSettingsPage from 'pages/EvansAdmin/EvansAdminSettingsPage/EvansAdminSettingsPage'
import CmsGuard from 'guards/CmsGuard'
import EvansAdminMenageAdmins from 'pages/EvansAdmin/AdminsPage/EvansAdminMenageAdmins'
import SignUpResetPage from 'pages/SchemeAdmin/SignUpReset/SignUpResetPage'
import AdminPanelHomePage from 'pages/SchemeAdmin/AdminPanelHome/AdminPanelHomePage'
import AdminPanelApplicantDetailsPage from 'pages/SchemeAdmin/AdminPanelApplicantDetails/AdminPanelApplicantDetailsPage'

const Routing: FC = () => {
    return (
        <Routes>
            <Route
                path="/ride-to-work-apply/savings-calculator"
                element={<SavingsCalculatorPage />}
            />
            <Route
                path="/ride-to-work-apply/certificate/get-certificate"
                element={<RideToWorkCertificatePage />}
            />
            <Route
                path="/ride-to-work-apply/certificate/agreement-declined"
                element={<AgreementDeclinedPage />}
            />

            <Route
                path="/ride-to-work-apply/sign-up/confirm"
                element={
                    <NotSchemaAdminGuard>
                        <AdminLoginPage />
                    </NotSchemaAdminGuard>
                }
            />
            <Route
                path="/ride-to-work-apply/schemeadmin/login"
                element={
                    <NotSchemaAdminGuard>
                        <AdminLoginPage />
                    </NotSchemaAdminGuard>
                }
            />
            <Route
                path="/ride-to-work-apply/sign-up/reset"
                element={
                    <NotSchemaAdminGuard>
                        <SignUpResetPage />
                    </NotSchemaAdminGuard>
                }
            />
            <Route
                path="/ride-to-work-apply/schemeadmin/register"
                element={
                    <NotSchemaAdminGuard>
                        <AdminRegisterPage />
                    </NotSchemaAdminGuard>
                }
            />

            <Route
                path="ride-to-work-apply/schemeadmin/settings"
                element={
                    <SchemaAdminGuard>
                        <AdminSettings />
                    </SchemaAdminGuard>
                }
            />

            <Route
                path="ride-to-work-apply/schemeadmin/companies"
                element={
                    <SchemaAdminGuard>
                        <AdminPanelHomePage />
                    </SchemaAdminGuard>
                }
            />

            <Route
                path="ride-to-work-apply/schemeadmin/companies/register"
                element={
                    <SchemaAdminGuard>
                        <SchemeAdminCompanyRegister />
                    </SchemaAdminGuard>
                }
            />

            <Route
                path="ride-to-work-apply/schemeadmin/companies/:companyId"
                element={
                    <SchemaAdminGuard>
                        <SchemeAdminCompanyDetailsPage />
                    </SchemaAdminGuard>
                }
            />

            <Route
                path="ride-to-work-apply/schemeadmin/companies/:companyId/documents"
                element={
                    <SchemaAdminGuard>
                        <SchemeAdminDocuments />
                    </SchemaAdminGuard>
                }
            />

            <Route
                path="ride-to-work-apply/schemeadmin/companies/:companyId/details"
                element={
                    <SchemaAdminGuard>
                        <CompanyDetailsPage />
                    </SchemaAdminGuard>
                }
            />

            <Route
                path="ride-to-work-apply/schemeadmin/companies/:companyId/certificates"
                element={
                    <SchemaAdminGuard>
                        <SchemeAdminCertificates />
                    </SchemaAdminGuard>
                }
            />

            <Route
                path="ride-to-work-apply/schemeadmin/companies/:companyId/certificates/:certificateId"
                element={
                    <SchemaAdminGuard>
                        <AdminPanelApplicantDetailsPage />
                    </SchemaAdminGuard>
                }
            />

            <Route
                path="/ride-to-work-apply/evans-admin/login"
                element={
                    <NotEvansAdminGuard>
                        <EvansAdminLoginPage />
                    </NotEvansAdminGuard>
                }
            />

            <Route
                path="/ride-to-work-apply/evans-admin/"
                element={
                    <EvansAdminGuard>
                        <EvansAdminIndexPage />
                    </EvansAdminGuard>
                }
            >
                <Route path="companies" element={<EvansAdminCompaniesPage />} />

                <Route
                    path="templates"
                    element={
                        <CmsGuard>
                            <EvansAdminTemplatesPage />
                        </CmsGuard>
                    }
                />

                <Route path="settings" element={<EvansAdminSettingsPage />} />

                <Route
                    path="templates/:id"
                    element={
                        <CmsGuard>
                            <EvansAdminTemplatesEditPage />
                        </CmsGuard>
                    }
                />

                <Route path="documents" element={<EvansAdminDocumentsPage />} />

                <Route
                    path="certificates"
                    element={<EvansAdminCertificatesPage />}
                />

                <Route
                    path="certificates/:certificateId"
                    element={<EvansAdminCertificateDetailsPage />}
                />

                <Route
                    path="companies/:companyId"
                    element={<EvansAdminCompanyPage />}
                />

                <Route
                    path="companies/:companyId/details"
                    element={<CompanyDetails />}
                />

                <Route
                    path="companies/:companyId/admins"
                    element={<SchemeAdminList />}
                />

                <Route
                    path="companies/:companyId/admins/:adminId"
                    element={<SchemeAdministrator />}
                />

                <Route path="admins" element={<EvansAdminMenageAdmins />} />

                <Route
                    path="admins/:adminId"
                    element={<SchemeAdministrator />}
                />

                <Route
                    path="companies/:companyId/settings"
                    element={<CompanySettings />}
                />

                <Route
                    path="companies/:companyId/restrictions"
                    element={<CompanyRestrictions />}
                />

                <Route
                    path="companies/:companyId/documents"
                    element={<CompanyDocuments />}
                />

                <Route
                    path="cms"
                    element={
                        <CmsGuard>
                            <EvansAdminCMSPage />
                        </CmsGuard>
                    }
                />
                <Route
                    path="cms/:contentId"
                    element={
                        <CmsGuard>
                            <EvansAdminCMSEditPage />
                        </CmsGuard>
                    }
                />
                <Route index element={<Navigate to="companies" />} />
            </Route>
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}

export default Routing
