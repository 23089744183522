import { FC, useRef, useState } from 'react'
import Button from 'components/Button'
import { Formik, FormikProps } from 'formik'
import DragDropFile from 'components/DragDropFile'
import { Box, Grid, Stack, Typography } from '@mui/material'
import {
    removeCompanyLogo,
    uploadNewCompanyLogo,
    getCompanyLogo,
} from 'api/schemeAdmin'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import useStore from 'store/Store'
import { SectionHeader } from 'components/SectionHeader'
import { ISchemeAdminDetails } from 'Interfaces/Admin'
import Modal from 'components/Modal/Modal'
import LoadingSpinner from 'components/LoadingSpinner'
import { AxiosError } from 'axios'

const CompanyLogo: FC = () => {
    const { openNotification } = useStore()
    const { companyId } = useParams()
    const formikRef = useRef<FormikProps<ISchemeAdminDetails>>(null)
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false)
    const [shouldResetFile, setShouldResetFile] = useState(false)

    const { data, isFetching } = useQuery(['getCompanyLogo', companyId], () =>
        getCompanyLogo(companyId as string)
    )

    const initialValues = {
        ...data?.data,
        logo: null,
    }

    const uploadLogo = useMutation(
        (image: File | string | Blob | FormData) =>
            uploadNewCompanyLogo(companyId as string, image),
        {
            onSuccess: () => {
                openNotification('Logo has been updated', 'success')
                setShouldResetFile(true)
            },
            onError: (error: AxiosError<{ message: string }>) => {
                openNotification(
                    error?.response?.data.message || 'Something went wrong',
                    'error'
                )
            },
        }
    )

    const deleteLogo = useMutation(
        () => removeCompanyLogo(companyId as string),
        {
            onSuccess: () => {
                openNotification('Logo has been deleted', 'success')
                setShouldResetFile(true)
                formikRef.current?.setFieldValue('url', '')
                formikRef.current?.setFieldValue('logo', 'null')
            },
            onError: (error: AxiosError<{ message: string }>) => {
                openNotification(
                    error?.response?.data.message || 'Something went wrong',
                    'error'
                )
            },
        }
    )

    if (isFetching) {
        return <LoadingSpinner />
    }

    return (
        <>
            <SectionHeader variant="h5">Upload a company logo</SectionHeader>
            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                onSubmit={({ logo }) => uploadLogo.mutate(logo)}
            >
                {(formik) => {
                    return (
                        <>
                            <Modal
                                isOpen={isPreviewModalOpen}
                                onClose={() => setIsPreviewModalOpen(false)}
                                title="Company logo"
                            >
                                <Box p=".5rem">
                                    <img
                                        src={formik.values.url}
                                        width="100%"
                                        height="100%"
                                    />
                                </Box>
                            </Modal>
                            <Grid container spacing="2rem">
                                <Grid item xs={12} md={4}>
                                    <Stack spacing="0.5rem">
                                        <DragDropFile
                                            shouldResetFile={shouldResetFile}
                                            maxSize={
                                                parseInt(
                                                    process.env
                                                        .REACT_APP_MAXIMUM_LOGO_SIZE as string
                                                ) || 2
                                            }
                                            label="Choose file"
                                            fileTypes={['JPG', 'PNG']}
                                            isLoading={false}
                                            name="logo"
                                            onChange={(file: File) => {
                                                setShouldResetFile(false)
                                                formik.setFieldValue(
                                                    'logo',
                                                    file
                                                )
                                                formik.setFieldValue(
                                                    'url',
                                                    URL.createObjectURL(file)
                                                )
                                            }}
                                        />
                                        <Button
                                            disabled={!formik.values.url}
                                            outlined
                                            colorPrimary
                                            fullWidth
                                            text="Logo Preview"
                                            onClick={() =>
                                                setIsPreviewModalOpen(true)
                                            }
                                        />
                                        <Button
                                            onClick={() => deleteLogo.mutate()}
                                            isLoading={deleteLogo.isLoading}
                                            text="Remove logo"
                                            disabled={!formik.values.url}
                                            fullWidth
                                            error
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Typography>
                                        Upload company logo to personalise the
                                        company Ride-to-Work page
                                    </Typography>
                                    <Typography
                                        color="primary"
                                        fontWeight="bold"
                                        marginY="1rem"
                                    >
                                        Image Guidelines
                                    </Typography>
                                    <Box marginBottom="1rem">
                                        <Typography>
                                            Formats: JPG, PNG
                                        </Typography>
                                        <Typography>
                                            Dimensions: 150 x 150 px
                                        </Typography>
                                        <Typography>Size: Max 2MB</Typography>
                                    </Box>
                                    <Button
                                        isLoading={uploadLogo.isLoading}
                                        backgroundColor="secondary"
                                        onClick={() => formik.handleSubmit()}
                                        text="Add image"
                                        colorPrimary
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )
                }}
            </Formik>
        </>
    )
}

export default CompanyLogo
