import { FC } from 'react'
import Button from 'components/Button'
import Input from 'components/Input'
import { Formik } from 'formik'
import { Grid } from '@mui/material'
import { companyFilteringSchemaValidator } from 'utils/ValidationSchema'
import { ICompaniesFilters } from 'Interfaces/Company'

interface IProps {
    applyFilters: (obj: Omit<ICompaniesFilters, 'page' | 'size'>) => void
    filteringValues: Omit<ICompaniesFilters, 'page' | 'size'>
    closeModal: () => void
}

const CompaniesFiltering: FC<IProps> = ({
    applyFilters,
    filteringValues,
    closeModal,
}) => {
    function submitHandler(values: Omit<ICompaniesFilters, 'page' | 'size'>) {
        applyFilters(values)
        closeModal()
    }

    return (
        <Formik
            initialValues={filteringValues}
            onSubmit={(values) => submitHandler(values)}
            validationSchema={companyFilteringSchemaValidator}
        >
            {(formik) => (
                <Grid container spacing={4} sx={{ padding: '2rem 0 0' }}>
                    <Grid item xs={12} md={6}>
                        <Input
                            name="companyName"
                            label="Company Name"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            name="companyStatus"
                            label="Company Status"
                            fullWidth
                            maxWidth="600px"
                            select
                            options={[
                                'PENDING',
                                'DECLINED',
                                'APPROVED',
                                'ACTIVE',
                                'DISABLED',
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            name="navisionNumber"
                            label="Navision Number"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input name="vatNumber" label="Vat Number" fullWidth />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            name="dateFrom"
                            label="Date From"
                            fullWidth
                            placeholder="YYYY-MM-DD"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            name="dateTo"
                            label="Date To"
                            fullWidth
                            placeholder="YYYY-MM-DD"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            name="firstName"
                            label="Scheme Admin First Name"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            name="lastName"
                            label="Scheme Admin Last Name"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button
                            fullWidth
                            text="Search"
                            onClick={formik.handleSubmit}
                        />
                    </Grid>
                </Grid>
            )}
        </Formik>
    )
}

export default CompaniesFiltering
