import { FC, useState } from 'react'
import { Form, Formik } from 'formik'
import { Stack } from '@mui/system'
import Input from 'components/Input'
import { savingsCalculatorEmailFormValidator } from 'utils/ValidationSchema'
import { ICalculatorResults } from './SavingsCalculatorPage'
import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'
import { sendSavingsCalculatorEmail } from 'api/public'
import { useMutation } from 'react-query'
import { Box, Typography } from '@mui/material'

interface EmailFormProps {
    close: () => void
    calculatorResults?: ICalculatorResults
}

export interface EmailRequest {
    firstName: string
    lastName: string
    emailAddress: string
    confirmEmailAddress: string
    savingCalculatorResultDTO: ICalculatorResults
}

const EmailForm: FC<EmailFormProps> = ({ close, calculatorResults }) => {
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    // should not occur
    if (!calculatorResults) {
        return <ErrorMessage text="Error getting results calculated" />
    }

    const submitHandler = (v: {
        firstName: string
        lastName: string
        emailAddress: string
        confirmEmailAddress: string
    }) => {
        const body = { ...v, savingCalculatorResultDTO: calculatorResults }
        sendEmail.mutate(body)
    }

    const sendEmail = useMutation(
        (body: EmailRequest) => sendSavingsCalculatorEmail(body),
        {
            onSuccess: () => {
                setSuccess(true)
            },
            onError: () => {
                setError(true)
            },
        }
    )

    if (success)
        return (
            <Box marginTop={3}>
                <Typography>The email has been sent.</Typography>
                <Box display="flex" justifyContent="end" marginTop={3}>
                    <Button
                        text="OK"
                        onClick={() => {
                            close()
                        }}
                    />
                </Box>
            </Box>
        )
    if (error)
        return (
            <Box marginTop={3}>
                <ErrorMessage text="The email has not been sent. Please try again." />
                <Box display="flex" justifyContent="end" marginTop={3}>
                    <Button
                        text="OK"
                        onClick={() => {
                            close()
                        }}
                    />
                </Box>
            </Box>
        )

    return (
        <Formik
            initialValues={{
                firstName: '',
                lastName: '',
                emailAddress: '',
                confirmEmailAddress: '',
            }}
            validationSchema={savingsCalculatorEmailFormValidator}
            onSubmit={(values) => {
                submitHandler(values)
            }}
        >
            {({ values, handleSubmit, isValid }) => (
                <Form
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit(e)
                    }}
                >
                    <Stack spacing={2} marginTop={3}>
                        <Input name="firstName" label="First name" required />
                        <Input name="lastName" label="Last name" required />
                        <Input
                            name="emailAddress"
                            label="Email address"
                            required
                        />
                        <Input
                            name="confirmEmailAddress"
                            label="Confirm email address"
                            required
                            disablePasting
                        />

                        <Button
                            disabled={isValid}
                            text="Email me"
                            onClick={() => submitHandler(values)}
                        />
                    </Stack>
                </Form>
            )}
        </Formik>
    )
}

export default EmailForm
