import { FC, useEffect, useState } from 'react'
import Table from 'components/Table'
import useSchemeAdminCompanyPage from './useSchemeAdminCompanyPage'
import Button from 'components/Button'
import { useNavigate } from 'react-router-dom'
import { Box, Grid, Typography, Divider } from '@mui/material'
import { FileIcon, defaultStyles } from 'react-file-icon'
import LoadingSpinner from 'components/LoadingSpinner'
import CustomContentBox from 'pages/EvansAdmin/EvansAdminCMSPage/components/CustomContentBox'
import { theme } from 'utils/Theme'
import Modal from 'components/Modal/Modal'

interface IProps {
    name: string
}

const SchemeAdminCompanyPage: FC<IProps> = ({ name }) => {
    const {
        columns,
        isLoading,
        response,
        documentsResponse,
        helloElementResponse,
        helloBoxElementResponse,
        isDocumentsLoading,
        downloadDocumentMutation,
    } = useSchemeAdminCompanyPage()

    const navigate = useNavigate()

    const [isFirstLogin, setIsFirstLogin] = useState(
        !response?.data || response?.data?.length == 0
    )

    useEffect(() => {
        setIsFirstLogin(response?.data?.length == 0)
    }, [response])

    if (isLoading || isDocumentsLoading) {
        return <LoadingSpinner />
    }

    const headerStyle = {
        fontWeight: 'bold',
        borderBottom: '2px solid',
        borderColor: 'primary.main',
    }

    return (
        <>
            <Modal
                isOpen={isFirstLogin}
                title="Welcome to Evans Ride-to-Work Employers Portal!"
                onClose={() => setIsFirstLogin(false)}
            >
                <Typography mt={1}>
                    To get started and unlock the full potential of our
                    platform, you need to register a company.
                </Typography>
                <Box display="flex" justifyContent="flex-end" gap={1}>
                    <Button
                        outlined
                        colorPrimary
                        text="Later"
                        onClick={() => setIsFirstLogin(false)}
                    />
                    <Button
                        colorPrimary
                        text="Register a company"
                        onClick={() =>
                            navigate(
                                '/ride-to-work-apply/schemeadmin/companies/register'
                            )
                        }
                    />
                </Box>
            </Modal>
            <Divider color="primary">
                <Typography color="primary" variant="h4" fontWeight="bold">
                    Hi, {name}!
                </Typography>
            </Divider>
            <Grid container columnSpacing="2rem" marginY="1rem">
                {helloElementResponse && (
                    <Grid item xs={12} md={helloBoxElementResponse ? 6 : 12}>
                        <CustomContentBox
                            contentElement={helloElementResponse.data}
                        />
                    </Grid>
                )}
                {helloBoxElementResponse && (
                    <Grid item xs={12} md={helloElementResponse ? 6 : 12}>
                        <Box
                            paddingX="1rem"
                            border="1px solid"
                            borderColor={theme.palette.grey[300]}
                        >
                            <CustomContentBox
                                contentElement={helloBoxElementResponse.data}
                            />
                        </Box>
                    </Grid>
                )}
            </Grid>

            <Typography mt={2} variant="h5" color="primary" sx={headerStyle}>
                Your Accounts
            </Typography>
            <Table
                loading={isLoading}
                columns={columns}
                height="350px"
                rows={response?.data.map((element: Record<string, string>) => {
                    return {
                        ...element,
                        id: element.companyId,
                    }
                })}
                disableSelect
            />
            <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                    <Typography
                        mt={2}
                        variant="h6"
                        color="primary"
                        sx={headerStyle}
                    >
                        Promote your scheme
                    </Typography>
                    <Typography mt={1}>
                        We have a loads of great marketing material to help your
                        employees become aware of Ride-to-Work. Use these to
                        spread the word and encourage people to join!{' '}
                    </Typography>
                    <Box marginTop="1rem">
                        {documentsResponse?.data.map(
                            (element: Record<string, string>) => {
                                const format = element.name.split('.')[1]
                                return (
                                    <div
                                        onClick={() =>
                                            downloadDocumentMutation.mutate({
                                                documentId: element.id,
                                                documentName: element.name,
                                            })
                                        }
                                        key={element.name}
                                        style={{
                                            width: 'max-content',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '1rem',
                                            marginTop: '1rem',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <div style={{ width: '25px' }}>
                                            <FileIcon
                                                extension={format}
                                                {...defaultStyles}
                                            />
                                        </div>
                                        <Typography
                                            variant="body2"
                                            sx={{ fontWeight: '600' }}
                                        >
                                            {element.name}
                                        </Typography>
                                    </div>
                                )
                            }
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography
                        mt={2}
                        variant="h6"
                        color="primary"
                        sx={headerStyle}
                    >
                        Register a new company
                    </Typography>
                    <Typography mt={1} mb={2}>
                        Need to register a new company? It&apos;s easy! You can
                        do it below:
                    </Typography>
                    <Button
                        colorPrimary
                        text="Register Company"
                        onClick={() =>
                            navigate(
                                '/ride-to-work-apply/schemeadmin/companies/register'
                            )
                        }
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default SchemeAdminCompanyPage
