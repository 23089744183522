import * as yup from 'yup'
import {
    passwordPattern,
    phoneNumberPattern,
    certificateAmountNumberPattern,
    yearMonthDayPattern,
    postcodePattern,
} from './dataPatterns'

import {
    MIN_CERTIFICATE_AMOUNT,
    MAX_SALARY_SACRIFICE_CERTIFICATE_AMOUNT,
    MAX_ELIGIBLE_EMPLOYEE_NUMBER,
    MAX_LENGTH_DESCRIPTION_TEXT,
    MINIMUM_YEAR_OF_BIRTH,
    MAX_PRE_CONTRACT_CERTIFICATE_AMOUNT,
} from 'utils/constants'

export const loginSchemaValidator = yup.object({
    email: yup
        .string()
        .email('You must enter a valid email adress.')
        .required('You must enter an email adress.'),

    password: yup.string().required('You must enter a password'),
})

export const forgotPasswordSchemaValidator = yup.object({
    email: yup
        .string()
        .email(
            'Please verify that you are entering a valid email address format e.g. name@somewhere.com '
        )
        .required('You must provide an email adress'),
})

export const registerAdminSchemaValidator = yup.object().shape({
    emailAddress: yup
        .string()
        .email('You must enter a valid email adress')
        .required('You must enter an email adress'),
    confirmEmailAddress: yup
        .string()
        .required('You must confirm your email')
        .oneOf([yup.ref('emailAddress')], 'The emails fields do not match.'),

    password: yup
        .string()
        .required('You must enter a password')
        .matches(
            passwordPattern,
            'Password must be at least 8 characters including one upper, lower, special and numeric character.'
        ),
    checkPassword: yup
        .string()
        .required('You must confirm your password')
        .oneOf([yup.ref('password')], 'The passwords fields do not match.'),
})

export const registerCompanyValidator = yup.object({
    companyName: yup
        .string()
        .required('You must provide a company name')
        .max(255, 'Maximum length for company name is 255 characters'),

    tradingName: yup
        .string()
        .required('You must provide a trading name')
        .max(255, 'Maximum length for trading name is 255 characters'),

    eligibleEmployeesNumber: yup
        .number()
        .max(
            MAX_ELIGIBLE_EMPLOYEE_NUMBER,
            `Maximum number for eligible employees equals ${MAX_ELIGIBLE_EMPLOYEE_NUMBER}`
        )
        .required('You must enter a number of eligble employees'),

    registrationNumber: yup.string().when('eligibleEmployeesNumber', {
        is: (val: number) => val >= 200,
        then: yup
            .string()
            .matches(
                /^[a-zA-Z0-9_-]*$/,
                'Registration number may only contain alphanumeric characters'
            )
            .required('You must provide a registration number'),
        otherwise: yup.string(),
    }),

    companyCounty: yup.string(),

    addressLine1: yup.string().required('You must provide an address'),

    companyTown: yup.string().required('You must provide a town'),

    companyPostcode: yup.string().required('You must provide a post code'),

    salarySacrificeTermInMonths: yup
        .number()
        .max(
            yup.ref('hireAgreementTermInMonths'),
            'Salary Sacrifice term should be less than or equal to the Hire Agreement term'
        )
        .required('You must provide an end of term'),

    minCertificateAmount: yup
        .number()
        .integer()
        .min(
            MIN_CERTIFICATE_AMOUNT,
            `Minimum certificate amount equals ${MIN_CERTIFICATE_AMOUNT}.`
        )
        .required('You must provide a minimum value'),

    maxCertificateAmount: yup.number().when('ownershipOption', {
        is: 'MANAGED_BY_EVANS_CYCLES',
        then: yup
            .number()
            .moreThan(
                yup.ref('minCertificateAmount'),
                'Maximum value should be greater than the minimum value'
            )
            .max(
                MAX_SALARY_SACRIFICE_CERTIFICATE_AMOUNT,
                `Maximum certificate amount equals ${MAX_SALARY_SACRIFICE_CERTIFICATE_AMOUNT}`
            )
            .required('You must provide a maximum value'),
        otherwise: yup
            .number()
            .moreThan(
                yup.ref('minCertificateAmount'),
                'Maximum value should be greater than the minimum value'
            )
            .max(
                MAX_PRE_CONTRACT_CERTIFICATE_AMOUNT,
                `Maximum certificate amount equals ${MAX_PRE_CONTRACT_CERTIFICATE_AMOUNT}`
            )
            .required('You must provide a maximum value'),
    }),
})

const rideToWorkCertificateStep0 = yup.object({
    companyNavisionNumber: yup
        .string()
        .required('Please enter an account number'),
})

const rideToWorkCertificateStep1 = yup.object({
    employeeForm: yup.object({
        title: yup.string().required('Please select a title.'),
        firstName: yup
            .string()
            .required('Please enter a first name.')
            .min(2, 'First name is too short')
            .max(255, 'First name is too long'),
        lastName: yup
            .string()
            .required('Please enter a last name.')
            .min(2, 'Last name is too short')
            .max(255, 'Last name is too long'),
        primaryEmailAddress: yup
            .string()
            .required('Please enter your email address.')
            .email('Invalid email address'),
        secondaryEmailAddress: yup.string().email('Invalid email address'),
        confirmPrimaryEmailAddress: yup
            .string()
            .required('Please  confirm your email address')
            .email('Invalid email address')
            .oneOf(
                [yup.ref('primaryEmailAddress')],
                `Email address doesn't match`
            ),
        primaryTelephoneNumber: yup
            .string()
            .required('Please enter a primary telephone number')
            .matches(phoneNumberPattern, 'Invalid phone number pattern'),
        addressLine1: yup.string().required('Please enter an address line.'),
        companyTown: yup.string().required('Please enter a town.'),
        companyPostcode: yup
            .string()
            .required('Please enter a postcode.')
            .matches(postcodePattern, 'Please enter a correct postcode'),
    }),
    payrollEmployeeNumber: yup.string().when('requiredPayroll', {
        is: true,
        then: yup.string().required('Must enter a payroll number'),
        otherwise: yup.string().notRequired(),
    }),
    dateOfBirth: yup
        .string()
        .required('Please enter a date of birth')
        .test(
            'Check Minimum year of birth',
            `Minimum year of birth is ${MINIMUM_YEAR_OF_BIRTH}`,
            (value) => {
                const yearOfBirth = value?.split('-')[0]
                return Number(yearOfBirth) >= MINIMUM_YEAR_OF_BIRTH
            }
        )
        .test(
            'Is year of birth in the future',
            'This date is in the future',
            (value) => {
                const today = new Date()
                const dobElements = value?.split('-').map(Number) || []

                if (!dobElements || dobElements.length === 0) return true

                const dateOfBirth = new Date(
                    dobElements[0],
                    dobElements[1],
                    dobElements[2]
                )

                return today >= dateOfBirth
            }
        ),
    certificateAmount: yup
        .number()
        .test(
            'Numeric Pattern',
            'You need to put correct amount value.',
            (certificateAmount) =>
                Boolean(
                    String(certificateAmount).match(
                        certificateAmountNumberPattern
                    )
                )
        )
        .min(
            yup.ref('minCertificateAmount'),
            'Amount entered is outside your company limits'
        )
        .max(
            yup.ref('maxCertificateAmount'),
            'Amount entered is outside your company limits'
        ),
})

export const rideToWorkCertificateValidator = [
    rideToWorkCertificateStep0,
    rideToWorkCertificateStep1,
]

export const administratorSchemaDetailsValidator = yup.object({
    title: yup.string().required('You must provide a title'),
    firstName: yup
        .string()
        .required('You must provide a first name')
        .min(2, 'Minimum length for first name is 2 characters')
        .max(255, 'Maximum length for first name  exceeded'),
    lastName: yup
        .string()
        .required('You must provide a last name')
        .min(2, 'Minimum length for last name is 2 characters')
        .max(255, 'Maximum length for last name  exceeded'),
    jobTitle: yup
        .string()
        .required('You must provide a job title')
        .min(2, 'Minimum length for job title is 2 characters')
        .max(255, 'Maximum length for job title is 255 characters'),
    phoneNumber: yup
        .string()
        .required('You must provide a phone number')
        .matches(phoneNumberPattern, 'Please enter a valid phone number'),
})

export const adminChangePasswordSchemaValidator = yup.object({
    currentPassword: yup
        .string()
        .required('You must provide a current password')
        .matches(
            passwordPattern,
            'Password must be at least 8 characters including one upper, lower, special and numeric character.'
        ),
    newPassword: yup
        .string()
        .required('You must provide a new password')
        .matches(
            passwordPattern,
            'Password must be at least 8 characters including one upper, lower, special and numeric character.'
        ),
    checkNewPassword: yup
        .string()
        .required('You must confirm your new password')
        .oneOf([yup.ref('newPassword')], "Password doesn't match"),
})

export const updateCompanyDetailsSchemaValidator = yup.object({
    companyRegistrationNumber: yup.string().when('eligibleEmployeesNumber', {
        is: (val: number) => val >= 200,
        then: yup
            .string()
            .matches(
                /^[a-zA-Z0-9_-]*$/,
                'Registration number may only contain alphanumeric characters'
            )
            .required('You must provide a registration number'),
        otherwise: yup.string(),
    }),
    companyName: yup
        .string()
        .required('Company name is required')
        .max(255, 'Maximum length 255 characters'),
    tradingName: yup
        .string()
        .required('Trading name is required ')
        .max(255, 'Maximum length 255 characters'),
    navisionNumber: yup
        .string()
        .nullable()
        .required('Navision number is required')
        .max(255, 'Maximum length 255 characters'),
    descriptiveText: yup
        .string()
        .nullable()
        .max(
            MAX_LENGTH_DESCRIPTION_TEXT,
            `Maximum length ${MAX_LENGTH_DESCRIPTION_TEXT} characters exceeded`
        ),
    address: yup.object({
        addressLine1: yup
            .string()
            .required('Address is requred')
            .max(255, 'Maximum length 255 characters'),

        town: yup
            .string()
            .required('Town is required')
            .max(255, 'Maximum length 255 characters'),

        postcode: yup
            .string()
            .required('Postcode is required')
            .max(255, 'Maximum length 255 characters'),
    }),
})

export const updateCompanyRestrictionsSchemaValidator = yup.object({
    minCertificateAmount: yup
        .number()
        .required('You must provide a minimum certificate amount')
        .lessThan(
            yup.ref('maxCertificateAmount'),
            'Minimum certificate amount should be lower than maximum'
        )
        .min(
            yup.ref('minCertThreshold'),
            'Minimum amount should be greater or equal than minimum threshold'
        ),
    maxCertificateAmount: yup
        .number()
        .required('You must provide a maximum certificate amount'),

    maxCertThreshold: yup
        .number()
        .required('You must provide a maximum certificate threshold')
        .min(
            yup.ref('maxCertificateAmount'),
            'Maximum threshold should be greater or equal than maximum amount'
        ),
    minCertThreshold: yup
        .number()
        .required('You must provide a minimum certificate threshold')
        .lessThan(
            yup.ref('maxCertThreshold'),
            'Minimum certificate threshold should be lower than maximum'
        ),

    financeNumber: yup.number().when('financeHireAgreement', {
        is: true,
        then: yup.number().min(1, 'Finance amount must be greater than 0'),
        otherwise: yup.number().nullable(),
    }),
    schemeOpeningDate: yup
        .string()
        .nullable()
        .test('check if its valid pattern', 'Invalid Date Pattern', (value) => {
            return value !== 'Invalid Date'
        })
        .test(
            'check if required',
            'Scheme opening date is required',
            (value, testContent) => {
                const { schemeClosingDate } = testContent.parent

                if (!value && schemeClosingDate) {
                    return false
                }
                return true
            }
        ),

    schemeClosingDate: yup
        .string()
        .nullable()
        .test('check if its valid pattern', 'Invalid Date Pattern', (value) => {
            return value !== 'Invalid Date'
        })
        .test(
            'Check if dates are valid',
            'Closing date must be higher than opening date',
            (value, testContext) => {
                const { schemeOpeningDate } = testContext.parent
                if (
                    value &&
                    schemeOpeningDate &&
                    schemeOpeningDate !== 'Invalid Date'
                ) {
                    return (
                        new Date(value as string) >
                        new Date(testContext.parent.schemeOpeningDate)
                    )
                }
                return true
            }
        )
        .test(
            'check if required',
            'Scheme closing date is required',
            (value, testContent) => {
                const { schemeOpeningDate } = testContent.parent
                if (!value && schemeOpeningDate) {
                    return false
                }
                return true
            }
        ),

    salarySacrificeTermInMonths: yup
        .number()
        .min(1, 'Salary sacrifice term must be greater than 0')
        .max(
            yup.ref('hireAgreementTermInMonths'),
            'Salary Sacrifice term should be less than or equal to the Hire Agreement term'
        ),
})

export const companyFilteringSchemaValidator = yup.object({
    dateFrom: yup
        .string()
        .nullable()
        .matches(yearMonthDayPattern, 'Correct date format is YYYY-MM-DD ')
        .test(
            'data lower',
            'Starting date must be lower than ending date',
            (value, content) => {
                if ((value as string) > content.parent.dateTo) {
                    return false
                }

                return true
            }
        ),
    dateTo: yup
        .string()
        .nullable()
        .matches(yearMonthDayPattern, 'Correct date format is YYYY-MM-DD'),
})

export const updateSchemaAdminDetailsValidator = yup.object({
    firstName: yup
        .string()
        .required('You must provide a first name')
        .max(255, 'First name cannot be greater than 255'),
    lastName: yup
        .string()
        .required('You must provide a last name')
        .max(255, 'Last name cannot be greater than 255'),
    jobTitle: yup
        .string()
        .required('You must provide a job title')
        .max(255, 'Job title cannot be greater than 255'),
    phoneNumber: yup.string().required('You must provide a phone number'),
})

export const certificateFilteringEvansAdminSchemaValidator = yup.object({
    emailAddress: yup.string().nullable().email('Invalid email address'),
    applicationDateFrom: yup
        .string()
        .nullable()
        .matches(yearMonthDayPattern, 'Correct date format is YYYY-MM-DD'),
    applicationDateTo: yup
        .string()
        .nullable()
        .matches(yearMonthDayPattern, 'Correct date format is YYYY-MM-DD')
        .test(
            'data lower',
            'Starting date must be lower than ending date',
            (value, content) => {
                if ((value as string) < content.parent.applicationDateFrom) {
                    return false
                }

                return true
            }
        ),
    approvalDateFrom: yup
        .string()
        .nullable()
        .matches(yearMonthDayPattern, 'Correct date format is YYYY-MM-DD'),
    approvalDateTo: yup
        .string()
        .nullable()
        .matches(yearMonthDayPattern, 'Correct date format is YYYY-MM-DD')
        .test(
            'data lower',
            'Starting date must be lower than ending date',
            (value, content) => {
                if ((value as string) < content.parent.approvalDateFrom) {
                    return false
                }

                return true
            }
        ),
})

export const registerSchemaAdminValidator = yup.object({
    title: yup.string().required('You must provide a title'),
    firstName: yup
        .string()
        .required('You must provide a first name')
        .max(255, 'Maximum length exceeded'),
    lastName: yup
        .string()
        .required('You must provide a last name')
        .max(255, 'Maximum length exceeded'),
    jobTitle: yup
        .string()
        .required('You must provide a job title')
        .max(255, 'Maximum length exceeded'),
    phoneNumber: yup
        .string()
        .required('You must provide a phone number')
        .matches(phoneNumberPattern, 'You must provide a valid phone number'),
    howDidYouHearOption: yup
        .string()
        .required('You must provide a how did you hear option'),
})

export const createDocumentValidator = yup.object({
    category: yup.string().required('You must provide a category'),
    description: yup.string().required('You must provide a description'),
})

export const contentFormButtonValidator = yup.object({
    text: yup.string().required('You must provide a text'),
    link: yup.string().required('You must provide a link'),
    color: yup.string().required('You must provide a color'),
    width: yup
        .number()
        .required('You must provide a width')
        .lessThan(13, 'Width cannot be more than 12')
        .moreThan(0, 'Width has to be more than 0'),
})

export const contentFormValidator = yup.object({
    title: yup.string().max(259),
    buttons: yup.array().of(contentFormButtonValidator.clone()),
})

export const savingsCalculatorFormValidator = yup.object({
    annualSalary: yup
        .number()
        .typeError('Annual salary must be a number')
        .required('You must enter annual salary')
        .lessThan(15_000_000_000, 'Annual salary is too high'),
    totalValue: yup
        .number()
        .typeError('Total value must be a number')
        .required('You must enter total value'),
})
export const savingsCalculatorEmailFormValidator = yup.object({
    firstName: yup.string().required('You must enter a first name'),
    lastName: yup.string().required('You must enter last name'),
    email: yup
        .string()
        .email('Email format is not correct')
        .required('You must enter an email address'),
    confirmEmail: yup
        .string()
        .email('Email format is not correct')
        .required('You must enter an email address')
        .test(
            'sameValue',
            'Confirm email address is incorrect.',
            function (value) {
                return value === this.parent.email
            }
        ),
})

export const assignCustomDocumentToCompanyValidator = yup.object({
    documentId: yup.string().required('You must provide a document '),
})

export const updateEmailValidator = yup.object({
    emailAddress: yup
        .string()
        .required('You must provide an email address')
        .email('Please enter a valid email address'),
    confirmEmailAddress: yup
        .string()
        .required('You must confirm your email address')
        .oneOf([yup.ref('emailAddress')], `Email address doesn't match`),
})

export const emailValidation = yup.object({
    schemeAdminEmail: yup
        .string()
        .required('You must provide an email address')
        .email('Please enter a valid email address'),
})

export const t4uCertificateValidator = yup.object({
    employeeForm: yup.object({
        title: yup.string().required('Please select a title.'),
        firstName: yup
            .string()
            .required('Please enter a first name.')
            .min(2, 'First name is too short')
            .max(255, 'First name is too long'),
        lastName: yup
            .string()
            .required('Please enter a last name.')
            .min(2, 'Last name is too short')
            .max(255, 'Last name is too long'),
        primaryEmailAddress: yup
            .string()
            .required('Please enter your email address.')
            .email('Invalid email address'),
        confirmPrimaryEmailAddress: yup
            .string()
            .required('Please confirm your email address')
            .oneOf(
                [yup.ref('primaryEmailAddress')],
                `Email address doesn't match`
            ),
        primaryTelephoneNumber: yup
            .string()
            .required('Please enter a primary telephone number')
            .matches(phoneNumberPattern, 'Invalid phone number pattern'),
        addressLine1: yup.string().required('Please enter an address line.'),
        companyTown: yup.string().required('Please enter a town.'),
        companyPostcode: yup.string().required('Please enter a postcode.'),
    }),
    payrollEmployeeNumber: yup.string().when('requiredPayroll', {
        is: true,
        then: yup.string().required('Must enter a payroll number'),
        otherwise: yup.string().notRequired(),
    }),
})

export const updateCompanyRestrictionsAsSchemaAdminValidator = yup.object({
    minCertificateAmount: yup
        .number()
        .required('You must provide a minimum certificate amount')
        .lessThan(
            yup.ref('maxCertificateAmount'),
            'Minimum certificate amount should be lower than maximum'
        )
        .min(
            yup.ref('minCertThreshold'),
            ({ min }) => `Minimum certificate amount equals ${min}`
        ),
    maxCertificateAmount: yup
        .number()
        .required('You must provide a maximum certificate amount')
        .max(
            yup.ref('maxCertThreshold'),
            ({ max }) => `Maximum certificate amount ${max}`
        ),
    schemeOpeningDate: yup
        .string()
        .nullable()
        .test('check if its valid pattern', 'Invalid Date Pattern', (value) => {
            return value !== 'Invalid Date'
        })
        .test(
            'check if required',
            'Scheme opening date is required',
            (value, testContent) => {
                const { schemeClosingDate } = testContent.parent
                return !(!value && schemeClosingDate)
            }
        ),

    schemeClosingDate: yup
        .string()
        .nullable()
        .test('check if its valid pattern', 'Invalid Date Pattern', (value) => {
            return value !== 'Invalid Date'
        })
        .test(
            'Check if dates are valid',
            'Closing date must be higher than opening date',
            (value, testContext) => {
                const { schemeOpeningDate } = testContext.parent
                if (
                    value &&
                    schemeOpeningDate &&
                    schemeOpeningDate !== 'Invalid Date'
                ) {
                    return (
                        new Date(value as string) >
                        new Date(testContext.parent.schemeOpeningDate)
                    )
                }
                return true
            }
        )
        .test(
            'check if required',
            'Scheme closing date is required',
            (value, testContent) => {
                const { schemeOpeningDate } = testContent.parent
                if (!value && schemeOpeningDate) {
                    return false
                }
                return true
            }
        ),

    salarySacrificeTermInMonths: yup
        .number()
        .max(
            yup.ref('hireAgreementTermInMonths'),
            'Salary Sacrifice term should be less than or equal to the Hire Agreement term'
        ),
})

export const confirmResetPasswordValidator = yup.object({
    code: yup.string().required('You must provide a verification code'),
    password: yup
        .string()
        .required('You must provide a password')
        .matches(
            passwordPattern,
            'Password must be at least 8 characters including one upper, lower, special and numeric character.'
        ),
    confirmPassword: yup
        .string()
        .required('You must confirm your password')
        .oneOf([yup.ref('password')], 'The passwords fields do not match.'),
})

export const companyDescriptionValidator = yup.object({
    description: yup
        .string()
        .max(
            MAX_LENGTH_DESCRIPTION_TEXT,
            `Maximum length ${MAX_LENGTH_DESCRIPTION_TEXT} characters exceeded`
        ),
})

export const schemeAdminFilterCertificatesValidator = yup.object({
    applicationDateFrom: yup
        .string()
        .test(
            'check if required',
            'Starting date is required',
            (value, testContent) => {
                const { applicationDateTo } = testContent.parent
                if (!value && applicationDateTo) {
                    return false
                }
                return true
            }
        )
        .test('check if date is valid', 'Invalid date', (value) => {
            return value !== 'Invalid Date'
        })
        .nullable(),
    applicationDateTo: yup
        .string()
        .nullable()
        .test(
            'check if required',
            'Closing date is required',
            (value, testContent) => {
                const { applicationDateFrom } = testContent.parent

                if (!value && applicationDateFrom) {
                    return false
                }
                return true
            }
        )
        .test('check if data is valid', 'Invalid date', (value) => {
            return value !== 'Invalid Date'
        })
        .test(
            'Check if dates are correct',
            'Ending date must be higher than starting date',
            (value, content) => {
                const { applicationDateFrom } = content.parent
                const isStartDate = value !== 'Invalid Date' && value !== null
                const isEndDate =
                    applicationDateFrom !== 'Invalid Date' &&
                    applicationDateFrom !== null

                if (!isStartDate || !isEndDate) return true

                const endingDate = new Date(value as string).getTime()
                const startingDate = new Date(
                    content.parent.applicationDateFrom as string
                ).getTime()

                return endingDate >= startingDate
            }
        ),
})

export const updatePasswordForSchemeAdminByEvansAdminValidator = yup.object({
    temporaryPassword: yup
        .string()
        .required('You must provide a new password')
        .matches(
            passwordPattern,
            'Password must be at least 8 characters including one upper, lower, special and numeric character.'
        ),
    confirmPassword: yup
        .string()
        .required('You must confirm your new password')
        .oneOf([yup.ref('temporaryPassword')], "Password doesn't match"),
})

export const completeNewPasswordSchemaValidator = yup.object({
    newPassword: yup
        .string()
        .required('You must provide a new password')
        .matches(
            passwordPattern,
            'Password must be at least 8 characters including one upper, lower, special and numeric character.'
        ),
    checkNewPassword: yup
        .string()
        .required('You must confirm your new password')
        .oneOf([yup.ref('newPassword')], "Password doesn't match"),
})
