import { MenuItem, Typography } from '@mui/material'
import { Item } from 'react-loqate'

export const AddressSearchMenuItem = (props: { suggestion: Item }) => {
    return (
        <MenuItem {...props}>
            <Typography fontFamily="body2" noWrap {...props} />
        </MenuItem>
    )
}
