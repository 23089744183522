import { FC } from 'react'
import {
    Avatar,
    Box,
    Drawer,
    List,
    Typography,
    useMediaQuery,
} from '@mui/material'
import {
    AdminPanelSettings,
    AutoAwesomeMosaic,
    Business,
    Handshake,
    Logout,
    Summarize,
    SupervisorAccountOutlined,
    WorkspacePremium,
} from '@mui/icons-material'
import SidebarListItem from 'pages/SchemeAdmin/AdminPanel/components/SidebarListItem'
import useStore from 'store/Store'
import useAuth from 'auth/useAuth'
import { useNavigate } from 'react-router-dom'

const EvansAdminSidebar: FC = () => {
    const isLargeScreen = useMediaQuery('(min-width:2000px)')
    const navigate = useNavigate()

    const {
        isEvansAdminNavigationOpen,
        handleEvansAdminNavigationState,
        evansAdminEmail,
        hasCmsPermission,
    } = useStore()

    const { logout, getCurrentUserEmail } = useAuth()

    getCurrentUserEmail()

    const elements = [
        {
            link: '/ride-to-work-apply/evans-admin/companies',
            text: 'Companies',
            onClick: () => handleEvansAdminNavigationState(false),
            icon: Business,
            cmsPermissionRequired: false,
        },
        {
            link: '/ride-to-work-apply/evans-admin/certificates',
            text: 'Certificates',
            onClick: () => handleEvansAdminNavigationState(false),
            icon: WorkspacePremium,
            cmsPermissionRequired: false,
        },
        {
            link: '/ride-to-work-apply/evans-admin/documents',
            text: 'Documents',
            onClick: () => handleEvansAdminNavigationState(false),
            icon: Summarize,
            cmsPermissionRequired: false,
        },
        {
            link: '/ride-to-work-apply/evans-admin/cms',
            text: 'CMS',
            onClick: () => handleEvansAdminNavigationState(false),
            icon: AutoAwesomeMosaic,
            cmsPermissionRequired: true,
        },
        {
            link: '/ride-to-work-apply/evans-admin/templates',
            text: 'Templates',
            onClick: () => handleEvansAdminNavigationState(false),
            icon: Handshake,
            cmsPermissionRequired: true,
        },
        {
            link: '/ride-to-work-apply/evans-admin/admins',
            text: 'Admins',
            onClick: () => {
                handleEvansAdminNavigationState(false)
            },
            icon: SupervisorAccountOutlined,
            cmsPermissionRequired: false,
        },
        {
            link: '/ride-to-work-apply/evans-admin/settings',
            text: 'Settings',
            onClick: () => {
                handleEvansAdminNavigationState(false)
            },
            icon: AdminPanelSettings,
            cmsPermissionRequired: false,
        },
        {
            text: 'Logout',
            onClick: () => {
                handleEvansAdminNavigationState(false)
                logout()
            },
            icon: Logout,
            cmsPermissionRequired: false,
        },
    ]
    return (
        <Drawer
            open={isEvansAdminNavigationOpen || isLargeScreen}
            onClose={() => {
                handleEvansAdminNavigationState()
            }}
            disableEnforceFocus
            disableScrollLock={true}
            ModalProps={{
                sx: {
                    width: '250px',
                },
            }}
            PaperProps={{
                sx: {
                    backgroundColor: '#212121',
                    width: '250px',
                    color: '#fff',
                    textAlign: 'center',
                    justifyContent: 'center',
                },
            }}
            sx={{
                width: '250px',
            }}
            hideBackdrop={isLargeScreen}
            anchor="left"
        >
            <List>
                <Box mb="1rem">
                    <Avatar
                        onClick={() =>
                            navigate('/ride-to-work-apply/evans-admin/settings')
                        }
                        sx={{
                            bgcolor: 'secondary.main',
                            margin: '0 auto',
                            transform: 'scale(1.3)',
                            cursor: 'pointer',
                            color: 'secondary.contrastText',
                        }}
                    >
                        {evansAdminEmail?.slice(0, 1)}
                    </Avatar>

                    <Typography
                        variant="body2"
                        sx={{ wordWrap: 'break-word', padding: '0 2rem' }}
                        mt={3}
                    >
                        {evansAdminEmail}
                    </Typography>
                </Box>

                {elements.map((el) => {
                    {
                        if (el.cmsPermissionRequired && !hasCmsPermission)
                            return
                        const Icon = el.icon

                        return (
                            <SidebarListItem
                                key={el.text}
                                link={el.link}
                                text={el.text}
                                onClick={el.onClick}
                            >
                                <Icon />
                            </SidebarListItem>
                        )
                    }
                })}
            </List>
        </Drawer>
    )
}

export default EvansAdminSidebar
