import { FC } from 'react'
import {
    Box,
    Grid,
    Paper,
    Theme,
    Typography,
    useMediaQuery,
} from '@mui/material'
import CustomContentBox from 'pages/EvansAdmin/EvansAdminCMSPage/components/CustomContentBox'
import { useQuery } from 'react-query'
import { getContentElement } from 'api/public'
import Bike from 'assets/bike.svg'
import RideToWorkLogo from 'assets/ride-to-work-form-logo.svg'

const flexContainer = {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1.3rem',
}

const successElementID = 1
const contactUsElementID = 2

const Step5: FC = () => {
    const { data: successElementResponse, isFetching: successElementFetching } =
        useQuery(['getSuccessElement'], () =>
            getContentElement(successElementID)
        )
    const {
        data: contactUsElementResponse,
        isFetching: contactUsElementFetching,
    } = useQuery(['getContactUsElement'], () =>
        getContentElement(contactUsElementID)
    )

    const isDesktopView = useMediaQuery((theme: Theme) =>
        theme.breakpoints.up('xl')
    )

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                gap="1rem"
                flexWrap="wrap"
                justifyContent="space-between"
                mb={3}
            >
                {isDesktopView && <img src={Bike} height="70px" />}
                <div>
                    <Typography
                        mt={isDesktopView ? 0 : 3}
                        color="secondary.main"
                        variant="h3"
                        fontWeight={900}
                    >
                        SUCCESS!
                    </Typography>
                    <Typography color="primary" variant="h5" fontWeight={900}>
                        Your application request has been submitted
                    </Typography>
                </div>
                <img src={RideToWorkLogo} width="250px" height="55px" />
            </Box>
            <Grid container spacing={5}>
                <Grid item xs={12} md={6}>
                    {!successElementFetching && (
                        <Paper elevation={3} sx={flexContainer}>
                            {successElementResponse?.data && (
                                <CustomContentBox
                                    contentElement={successElementResponse.data}
                                />
                            )}
                        </Paper>
                    )}
                </Grid>

                <Grid item xs={12} md={6}>
                    {!contactUsElementFetching && (
                        <Paper sx={flexContainer} elevation={3}>
                            {contactUsElementResponse && (
                                <CustomContentBox
                                    contentElement={
                                        contactUsElementResponse.data
                                    }
                                />
                            )}
                        </Paper>
                    )}
                </Grid>
            </Grid>
            <Typography>Happy Cycling!</Typography>
        </>
    )
}

export default Step5
