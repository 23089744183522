import { FC } from 'react'
import { Typography, Divider, Grid } from '@mui/material'
import { useParams } from 'react-router-dom'
import {
    DocumentScanner,
    Settings,
    WorkspacePremium,
} from '@mui/icons-material'
import { Box } from '@mui/system'
import SchemeAdminTile from './SchemeAdminTile'
import { useQuery } from 'react-query'
import { getCompanyDetails } from 'api/schemeAdmin'
import LoadingSpinner from 'components/LoadingSpinner'
import ErrorMessage from 'components/ErrorMessage'
import { AxiosError, AxiosResponse } from 'axios'
import { ICompanyDetails } from 'Interfaces/Company'

const SchemeAdminCompanyDetailsPage: FC = () => {
    const { companyId } = useParams()
    const iconStyle = { fontSize: '5rem', color: 'primary.main' }

    const {
        data: response,
        isLoading,
        isError,
        error,
    } = useQuery<
        AxiosResponse<ICompanyDetails>,
        AxiosError<{ message: string }>
    >(['getCompanyName', companyId], () =>
        getCompanyDetails(companyId as string)
    )

    const { companyName } = response?.data || {}
    const { message } = error?.response?.data || {}

    if (isLoading) {
        return <LoadingSpinner />
    }

    if (isError) {
        return <ErrorMessage text={message as string} />
    }

    return (
        <Box mt={3}>
            <Typography color="primary" variant="h4" mb={2}>
                {companyName}
            </Typography>
            <Divider />

            <Grid container spacing={4} marginTop="1rem">
                <Grid item xs={12} md={4}>
                    <SchemeAdminTile
                        title="Manage Certificates"
                        link={`/${companyId as string}/certificates`}
                    >
                        <WorkspacePremium sx={iconStyle} />
                    </SchemeAdminTile>
                </Grid>
                <Grid item xs={12} md={4}>
                    <SchemeAdminTile
                        title="Useful Documents"
                        link={`/${companyId}/documents`}
                    >
                        <DocumentScanner sx={iconStyle} />
                    </SchemeAdminTile>
                </Grid>
                <Grid item xs={12} md={4}>
                    <SchemeAdminTile
                        title="Company Settings"
                        link={`/${companyId}/details`}
                    >
                        <Settings sx={iconStyle} />
                    </SchemeAdminTile>
                </Grid>
            </Grid>
        </Box>
    )
}

export default SchemeAdminCompanyDetailsPage
