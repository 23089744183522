import styled from '@emotion/styled'
import { Typography, Grid } from '@mui/material'
import { FC } from 'react'
import { theme } from 'utils/Theme'
import { IOutput } from './SavingsCalculatorPage'

const TypographySubheader = styled(Typography)`
    color: ${theme.palette.primary.main};
    font-weight: 700;
`
interface OutputRowProps {
    output: IOutput
    isLast: boolean
}

export const formatValue = (value: number, type?: 'currency' | 'percent') => {
    if (type === 'percent') return `${value?.toFixed(2)}%`
    if (type === 'currency')
        return new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP',
        }).format(value)
    return value
}

const OutputRow: FC<OutputRowProps> = ({ output, isLast }) => {
    const { value, label, subheader, type } = output
    if (subheader)
        return (
            <Grid
                container
                key={label}
                borderBottom={
                    !isLast ? `1px solid ${theme.palette.grey[300]}` : 'none'
                }
            >
                <Grid
                    item
                    padding={1}
                    xs={8}
                    sx={{
                        backgroundColor: theme.palette.grey[300],
                    }}
                >
                    <TypographySubheader>{label}</TypographySubheader>
                </Grid>
                <Grid
                    item
                    padding={1}
                    xs={4}
                    borderLeft={`1px solid ${theme.palette.grey[300]}`}
                    sx={{
                        backgroundColor: theme.palette.grey[300],
                    }}
                >
                    <TypographySubheader>
                        {value ? formatValue(value, type) : ''}
                    </TypographySubheader>
                </Grid>
            </Grid>
        )
    return (
        <Grid
            container
            key={label}
            borderBottom={
                !isLast ? `1px solid ${theme.palette.grey[300]}` : 'none'
            }
        >
            <Grid item padding={1} xs={8}>
                <Typography>{label}</Typography>
            </Grid>
            <Grid
                item
                padding={1}
                xs={4}
                borderLeft={`1px solid ${theme.palette.grey[300]}`}
            >
                <Typography>{formatValue(value ?? 0, type)}</Typography>
            </Grid>
        </Grid>
    )
}

export default OutputRow
