import { Paper, Typography } from '@mui/material'
import { FC, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { theme } from 'utils/Theme'

interface IProps {
    title: string
    link: string
    children: ReactNode
}

const SchemeAdminTile: FC<IProps> = ({ title, link, children }) => {
    const navigate = useNavigate()
    return (
        <Paper
            onClick={() =>
                navigate('/ride-to-work-apply/schemeadmin/companies' + link)
            }
            sx={{
                cursor: 'pointer',
                padding: '2rem 1rem',
                marginBottom: '1rem',
                display: 'flex',
                gap: '1rem',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                transition: 'all 0.15s ease-in-out',

                '&:hover': {
                    boxShadow: theme.shadows[3],
                    transform: 'translateY(-5px)',
                },
            }}
        >
            {children}
            <Typography variant="h6" color="primary" marginTop={'1rem'}>
                {title}
            </Typography>
        </Paper>
    )
}

export default SchemeAdminTile
