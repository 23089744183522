import { FC, ReactNode } from 'react'
import useStore from 'store/Store'
import { Navigate } from 'react-router-dom'

interface IProps {
    children: ReactNode
}

const EvansAdminGuard: FC<IProps> = ({ children }) => {
    const { isEvansAdmin } = useStore()

    if (isEvansAdmin) {
        return <>{children}</>
    }

    return <Navigate to="/ride-to-work-apply/evans-admin/login" />
}

export default EvansAdminGuard
