import { FC, useState } from 'react'
import SchemeAdminRegistration from './components/SchemeAdminRegistration'
import { useQuery } from 'react-query'
import { getSchemeAdminDetails } from 'api/schemeAdmin'
import LoadingSpinner from 'components/LoadingSpinner'
import Modal from 'components/Modal/Modal'
import SchemeAdminCompanyPage from 'pages/SchemeAdmin/AdminPanelHome/SchemeAdminCompanyPage'
import usePageTitle from 'hooks/usePageTitle'
import { AxiosError, AxiosResponse } from 'axios'
import useAuth from '../../../auth/useAuth'

const AdminPanelHomePage: FC = () => {
    usePageTitle('Companies')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isAdminExists, setIsAdminExists] = useState(false)
    const { logout } = useAuth()
    const {
        data: response,
        isFetching,
        refetch,
    } = useQuery<AxiosResponse, AxiosError>(
        'getSchemeAdminDetails',
        () => getSchemeAdminDetails(),
        {
            onSuccess: () => {
                setIsAdminExists(true)
                setIsModalOpen(false)
            },
            onError: (error) => {
                if (error?.response?.status === 400) {
                    setIsModalOpen(true)
                }
            },

            retry: false,
        }
    )

    if (isFetching) {
        return <LoadingSpinner />
    }

    const { firstName } = response?.data || {}

    return (
        <>
            <Modal
                title="Complete Your Registration"
                isOpen={isModalOpen}
                onClose={() => {
                    logout()
                }}
            >
                <SchemeAdminRegistration refetch={refetch} />
            </Modal>

            {isAdminExists && (
                <SchemeAdminCompanyPage name={firstName as string} />
            )}
        </>
    )
}

export default AdminPanelHomePage
