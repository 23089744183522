import React, { FC } from 'react'
import { Divider, Typography, TypographyTypeMap } from '@mui/material'

interface IProps {
    text: string
    variant?: TypographyTypeMap['props']['variant']
}

const FormSubtitle: FC<IProps> = ({ text, variant }) => {
    return (
        <div>
            <Typography
                variant={variant || 'h6'}
                sx={{ color: 'primary.main' }}
            >
                {text}
            </Typography>
            <Divider />
        </div>
    )
}

export default FormSubtitle
