import { FC, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import {
    addAdminToCompany,
    getSchemeAdminsForCompany,
    removeAdminFromCompany,
    setAdminAsMain,
} from 'api/evansAdmin'
import { GridCellParams, GridValueFormatterParams } from '@mui/x-data-grid'
import Button from 'components/Button'
import Table from 'components/Table'
import { ISchemeAdminListDetails } from 'Interfaces/Admin'
import { useParams, useNavigate } from 'react-router-dom'
import usePageTitle from 'hooks/usePageTitle'
import { AddCircleOutline } from '@mui/icons-material'
import Modal from 'components/Modal/Modal'
import { Formik } from 'formik'
import { Box, Tooltip } from '@mui/material'
import { AxiosError, AxiosResponse } from 'axios'
import { emailValidation } from 'utils/ValidationSchema'
import { Typography } from '@mui/material'
import ErrorMessage from 'components/ErrorMessage'
import AdminSearchBar from 'components/AdminSearchBar'
import DeleteModal from '../../../../components/DeleteModal'

type SchemeAdminData = {
    id: number
    email: string
}
const SchemeAdminList: FC = () => {
    usePageTitle('Company Scheme Administrators')
    const { companyId } = useParams()
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isDeleteSchemeAdminModalOpen, setIsDeleteSchemeAdminModalOpen] =
        useState<SchemeAdminData>()
    const [apiError, setApiError] = useState({ schemeAdminEmail: '' })
    const {
        isFetching,
        data: response,
        refetch,
        error,
    } = useQuery<AxiosResponse, AxiosError<{ message: string }>>(
        'getSchemeAdmins',
        () => getSchemeAdminsForCompany(companyId as string)
    )

    const mutation = useMutation(
        (data: { schemeAdminEmail: string }) =>
            addAdminToCompany(companyId as string, data),
        {
            onSuccess: () => {
                setIsModalOpen(false)
                refetch()
            },
            onError: (error: AxiosError<{ message: string }>) => {
                setApiError({
                    schemeAdminEmail: error.response?.data.message ?? '',
                })
            },
        }
    )

    const handleSubmit = (values: { schemeAdminEmail: string }) => {
        mutation.mutate(values)
    }

    const columns = [
        { field: 'emailAddress', headerName: 'Email Address', flex: 1 },
        { field: 'firstName', headerName: 'First Name', flex: 1 },
        { field: 'lastName', headerName: 'Last Name', flex: 1 },
        {
            field: 'mainSchemeAdmin',
            headerName: 'Role',
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams<boolean>) =>
                params.value ? 'MAIN' : 'SECONDARY',
        },
        {
            field: 'details',
            headerName: 'Actions',
            width: 320,
            renderCell: (params: GridCellParams) => (
                <Box display="flex" gap="0.5rem">
                    <Button
                        text="Details"
                        onClick={() =>
                            navigate(
                                `/ride-to-work-apply/evans-admin/companies/${companyId}/admins/${params.id}`
                            )
                        }
                    />
                    <Tooltip
                        title="Cannot remove main scheme admin"
                        disableHoverListener={!params.row.mainSchemeAdmin}
                    >
                        <span>
                            <Button
                                disabled={params.row.mainSchemeAdmin}
                                text="Remove"
                                error
                                onClick={() =>
                                    setIsDeleteSchemeAdminModalOpen({
                                        id: params.id as number,
                                        email: params.row.emailAddress,
                                    })
                                }
                            />
                        </span>
                    </Tooltip>
                    <Tooltip
                        title="Cannot change to main scheme admin"
                        disableHoverListener={!params.row.mainSchemeAdmin}
                    >
                        <span>
                            <Button
                                disabled={params.row.mainSchemeAdmin}
                                text="Set as main"
                                colorPrimary
                                onClick={() =>
                                    setAdminAsMain(
                                        companyId,
                                        params.id as number
                                    ).then(() => refetch())
                                }
                            />
                        </span>
                    </Tooltip>
                </Box>
            ),
        },
    ]

    if (error) {
        return (
            <ErrorMessage
                text={
                    (error?.response?.data.message as string) ||
                    'Something went wrong'
                }
            />
        )
    }

    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                marginX="1rem"
                alignItems="center"
                flexWrap="wrap"
            >
                <Typography variant="h4" color="primary">
                    Company Admins
                </Typography>
                <AddCircleOutline
                    onClick={() => setIsModalOpen(true)}
                    color="primary"
                    sx={{ cursor: 'pointer', transform: 'scale(1.1)' }}
                />
            </Box>
            <Modal
                isOpen={isModalOpen}
                title="Add a new admin"
                onClose={() => setIsModalOpen(false)}
            >
                <Formik
                    initialValues={{ schemeAdminEmail: '' }}
                    onSubmit={handleSubmit}
                    validationSchema={emailValidation}
                >
                    {({ setFieldValue, handleSubmit }) => (
                        <Box
                            marginTop="2rem"
                            display="flex"
                            flexDirection="column"
                            gap="1rem"
                        >
                            <AdminSearchBar
                                onChoose={(options, email) => {
                                    setFieldValue('schemeAdminEmail', email)
                                    setApiError({ schemeAdminEmail: '' })
                                }}
                            />
                            {apiError.schemeAdminEmail && (
                                <Typography color="error" fontSize="small">
                                    {apiError.schemeAdminEmail}
                                </Typography>
                            )}
                            <Button
                                isLoading={mutation.isLoading}
                                text="Submit"
                                onClick={handleSubmit}
                            />
                        </Box>
                    )}
                </Formik>
            </Modal>

            <Modal
                title="Delete Confirmation"
                isOpen={isDeleteSchemeAdminModalOpen !== undefined}
                onClose={() => setIsDeleteSchemeAdminModalOpen(undefined)}
            >
                {isDeleteSchemeAdminModalOpen && (
                    <DeleteModal
                        successCallback={refetch}
                        closeModal={() =>
                            setIsDeleteSchemeAdminModalOpen(undefined)
                        }
                        deleteFunction={() =>
                            removeAdminFromCompany(
                                companyId,
                                isDeleteSchemeAdminModalOpen.id
                            )
                        }
                        message={`Are you sure you want to remove scheme admin with email: ${isDeleteSchemeAdminModalOpen.email}?`}
                    />
                )}
            </Modal>

            <Table
                loading={isFetching}
                columns={columns}
                pageSize={10}
                numberOfPages={Math.ceil(response?.data.length / 10)}
                rows={response?.data.map((element: ISchemeAdminListDetails) => {
                    const { emailAddress, firstName, lastName, id } =
                        element.schemeAdminDTO || {}
                    return {
                        emailAddress,
                        firstName,
                        lastName,
                        id,
                        mainSchemeAdmin: element.isMainSchemeAdmin,
                    }
                })}
            />
        </>
    )
}

export default SchemeAdminList
