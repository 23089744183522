import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import Button from 'components/Button'
import Input from 'components/Input'
import { Formik } from 'formik'
import { updateCompanyDescription } from 'api/schemeAdmin'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import useStore from 'store/Store'
import { AxiosError } from 'axios'
import { SectionHeader } from 'components/SectionHeader'
import { companyDescriptionValidator } from 'utils/ValidationSchema'

interface IProps {
    description: string
    refetch: () => void
}

const DescriptiveText: FC<IProps> = ({ description, refetch }) => {
    const { companyId } = useParams()
    const { openNotification } = useStore()
    const mutation = useMutation(
        (text: string) => updateCompanyDescription(companyId as string, text),
        {
            onSuccess: () => {
                openNotification('Descriptive text has been updated', 'success')
                refetch()
            },

            onError: (error: AxiosError<{ message: string }>) => {
                openNotification(
                    error?.response?.data.message || 'Something went wrong',
                    'error'
                )
            },
        }
    )

    function submitHandler(text: string) {
        mutation.mutate(text)
    }

    return (
        <Formik
            validationSchema={companyDescriptionValidator}
            initialValues={{ description: description || '' }}
            onSubmit={(values) => submitHandler(values.description)}
        >
            {({ handleSubmit }) => (
                <>
                    <SectionHeader variant="h5">
                        Company Description
                    </SectionHeader>
                    <Box display="grid" gap="1.5rem" mt={2}>
                        <Typography>
                            Add a short description (max 300 characters) that
                            applicants will see when they view <br /> the
                            company Ride-to-Work page
                        </Typography>

                        <Input
                            name="description"
                            textarea
                            fullWidth
                            placeholder="Write your description here..."
                            rows={6}
                            lengthLimit={300}
                            maxWidth="100%"
                        />

                        <Button
                            isLoading={mutation.isLoading}
                            maxWidth="250px"
                            colorPrimary
                            text="Add description"
                            backgroundColor="secondary"
                            onClick={handleSubmit}
                        />
                    </Box>
                </>
            )}
        </Formik>
    )
}

export default DescriptiveText
