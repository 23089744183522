import { FC, useRef } from 'react'
import { Typography, Grid, Divider, Box } from '@mui/material'
import Input from 'components/Input'
import { Formik, FormikProps } from 'formik'
import Button from 'components/Button'
import { registerCompanyValidator } from 'utils/ValidationSchema'

import { TRANSFER_OPTIONS_WITH_VALUES } from 'utils/constants'
import { registerCompany } from 'api/schemeAdmin'
import { useNavigate } from 'react-router-dom'
import useStore from 'store/Store'
import { useMutation, useQuery } from 'react-query'
import { ICompanyRegistration } from 'Interfaces/Company'
import { AxiosError } from 'axios'
import usePageTitle from 'hooks/usePageTitle'
import LoqateSearch from 'components/Loqate/LoqateSearch'
import ErrorMessage from 'components/ErrorMessage'
import { getContentElement } from 'api/public'
import LoadingSpinner from 'components/LoadingSpinner'
import CustomContentBox from 'pages/EvansAdmin/EvansAdminCMSPage/components/CustomContentBox'
import CustomTooltip from 'components/CustomTooltip'
import { getTerms } from 'api/schemeAdmin'
import { OwnershipSelect } from './OwnershipSelect'

const TypographySubheader = ({ children }: { children: React.ReactNode }) => (
    <Typography
        mt={1}
        variant="h6"
        fontWeight="bold"
        sx={{
            borderBottom: '2px solid',
            borderColor: '#f0efef',
        }}
        color="primary"
    >
        {children}
    </Typography>
)

const successModalContentID = 6

const SchemeAdminCompanyRegister: FC = () => {
    usePageTitle('Company Registration')
    const { openModal } = useStore()
    const navigate = useNavigate()
    const formRef = useRef<FormikProps<ICompanyRegistration>>(null)

    const { data: successModalContent, isFetching } = useQuery(
        ['getRegisterCompanySuccessElement'],
        () => getContentElement(successModalContentID)
    )

    const { data: terms, isFetching: isFetchingTerms } = useQuery(
        ['getTerms'],
        () => getTerms()
    )

    const modalContent = successModalContent ? (
        <Box marginTop="1rem">
            <CustomContentBox contentElement={successModalContent?.data} />
        </Box>
    ) : (
        <></>
    )

    const registerCompanyMutation = useMutation(
        (data: ICompanyRegistration) => registerCompany(data),
        {
            onSuccess: () => {
                openModal('Thanks!', modalContent)
                navigate('/ride-to-work-apply/schemeadmin/companies')
            },
            onError: (
                error: AxiosError<{
                    fieldErrors: Record<string, string>
                    message: string
                }>
            ) => {
                formRef.current?.setStatus({
                    ...error?.response?.data.fieldErrors,
                })
            },
        }
    )

    function submitHandler(values: ICompanyRegistration) {
        registerCompanyMutation.mutate(values)
    }

    const initialValues = {
        companyName: '',
        tradingName: '',
        companyVatNumber: '',
        eligibleEmployeesNumber: '',
        registrationNumber: '',
        addressLine1: '',
        addressLine2: '',
        companyTown: '',
        companyCounty: '',
        companyPostcode: '',
        ownershipOption: 'MANAGED_BY_EVANS_CYCLES',
        salarySacrificeTermInMonths: 12,
        hireAgreementTermInMonths: 72,
        minCertificateAmount: 100,
        maxCertificateAmount: 5000,
    }

    if (isFetching || isFetchingTerms) {
        return <LoadingSpinner />
    }

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={(values) => submitHandler(values)}
            validationSchema={registerCompanyValidator}
        >
            {(formik) => {
                const transferOptions = TRANSFER_OPTIONS_WITH_VALUES.filter(
                    (element: { value: string; label: string }) =>
                        formik.values.ownershipOption === 'T4U'
                            ? true
                            : element.label !== 'T4U'
                )

                return (
                    <Grid container spacing={3} sx={{ padding: '1rem 0' }}>
                        <Grid item xs={12}>
                            <Typography
                                fontWeight="bold"
                                variant="h4"
                                color="primary"
                            >
                                Register a new company
                            </Typography>
                            <Typography variant="body1" mt={1}>
                                Tell us about the company that you would like to
                                register for the Evans Ride-to-Work scheme.
                            </Typography>
                        </Grid>
                        {registerCompanyMutation.isError && (
                            <Grid item mt={2} xs={12}>
                                <ErrorMessage
                                    text={
                                        (registerCompanyMutation.error?.response
                                            ?.data.message as string) ||
                                        'Something went wrong'
                                    }
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Typography
                                color="primary"
                                variant="h6"
                                fontWeight="bold"
                                borderBottom="2px solid"
                            >
                                About the company
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TypographySubheader>
                                Company details
                            </TypographySubheader>
                            <Typography variant="body2" mt={1}>
                                Enter the details of the company that you would
                                like to register.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Input
                                apiErrors={formik.status}
                                required
                                label="Company Name"
                                name="companyName"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                apiErrors={formik.status}
                                required
                                label="Trading Name"
                                name="tradingName"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                apiErrors={formik.status}
                                label="Vat Number"
                                name="companyVatNumber"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                apiErrors={formik.status}
                                required
                                label="Number of eligible employees"
                                name="eligibleEmployeesNumber"
                                fullWidth
                            />
                        </Grid>
                        {parseInt(formik.values.eligibleEmployeesNumber) >=
                            200 && (
                            <Grid item xs={12} md={6}>
                                <Input
                                    apiErrors={formik.status}
                                    required
                                    label="Registration Number"
                                    name="registrationNumber"
                                    fullWidth
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <TypographySubheader>
                                Company adress
                            </TypographySubheader>
                            <Typography variant="body2" mt={1}>
                                Enter the company address details below
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Box>
                                <LoqateSearch
                                    label="Address 1"
                                    formik={formik}
                                    addressFieldsNames={{
                                        addressLine1: 'addressLine1',
                                        addressLine2: 'addressLine2',
                                        town: 'companyTown',
                                        county: 'companyCounty',
                                        postcode: 'companyPostcode',
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                fullWidth
                                apiErrors={formik.status}
                                label="Address 2"
                                name="addressLine2"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                apiErrors={formik.status}
                                required
                                label="Town"
                                name="companyTown"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                apiErrors={formik.status}
                                label="County"
                                name="companyCounty"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                apiErrors={formik.status}
                                required
                                label="Postcode"
                                name="companyPostcode"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TypographySubheader>
                                Ownership option
                            </TypographySubheader>
                            <Typography variant="body2" mt={1}>
                                Choose an end of term option - for more
                                information, view our employer pages End of term
                                options:
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center" gap=".5rem">
                                <OwnershipSelect
                                    optionsWithValues={transferOptions}
                                />
                                <CustomTooltip
                                    text={
                                        <Typography>
                                            At the end of the Salary Sacrifice
                                            term, Government guidelines require
                                            that any transfer of ownership to
                                            the employee takes place in
                                            accordance with the Fair Market
                                            Value. There are different options
                                            for managing the end of scheme.
                                            Please click{' '}
                                            <a href="https://www.evanscycles.com/b2b/ride-to-work-faqs#faq-end">
                                                here
                                            </a>{' '}
                                            to see details of each option
                                        </Typography>
                                    }
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <TypographySubheader>
                                Salary sacrifice preferences
                            </TypographySubheader>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography variant="body2" mb={1}>
                                Please set the salary sacrifice term (in months){' '}
                            </Typography>
                            <Box display="flex" alignItems="center" gap=".5rem">
                                <Input
                                    apiErrors={formik.status}
                                    select
                                    name="salarySacrificeTermInMonths"
                                    options={
                                        terms?.data
                                            ?.allAvailableSalarySacrificeTerms?.[
                                            formik.values?.ownershipOption ||
                                                'MANAGED_BY_EVANS_CYCLES'
                                        ] ?? ['']
                                    }
                                    fullWidth
                                />

                                <CustomTooltip
                                    text={`Your certificate repayment term is
                                     ${formik.values.salarySacrificeTermInMonths}
                                      months`}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body2" mb={1}>
                                Set the term of the online hire agreement
                                (months)
                            </Typography>
                            <Box display="flex" alignItems="center" gap=".5rem">
                                <Input
                                    apiErrors={formik.status}
                                    select
                                    fullWidth
                                    name="hireAgreementTermInMonths"
                                    options={
                                        terms?.data
                                            ?.allAvailableHireAgreementTerms?.[
                                            formRef?.current?.values
                                                ?.ownershipOption ||
                                                'MANAGED_BY_EVANS_CYCLES'
                                        ]
                                    }
                                />
                                <CustomTooltip
                                    text="The hire agreement is between employee and Evans Cycles and covers the 
                                    full Fair Market Value depreciation term, to the point where the hired goods are
                                     of negligible value and ownership transfers to the employee for free. During this
                                      term, the salary sacrifice period will complete. There is no extra charge 
                                      for choosing this option."
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center" gap=".5rem">
                                <Typography variant="body2">
                                    Please set the salary sacrifice limits (in
                                    GBP)
                                </Typography>
                                <CustomTooltip
                                    text={`You decide on a scheme limit between
                                     ${formik.values.minCertificateAmount} and 
                                      ${formik.values.maxCertificateAmount}`}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                apiErrors={formik.status}
                                required
                                name="minCertificateAmount"
                                label="Minimum certificate amount"
                                type="number"
                                fullWidth
                                maxWidth="400px"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                apiErrors={formik.status}
                                required
                                name="maxCertificateAmount"
                                label="Maximum certificate amount"
                                type="number"
                                fullWidth
                                maxWidth="400px"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{ borderColor: 'primary.main' }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button
                                text="Submit"
                                fullWidth
                                isLoading={registerCompanyMutation.isLoading}
                                maxWidth="400px"
                                onClick={formik.handleSubmit}
                            />
                        </Grid>
                    </Grid>
                )
            }}
        </Formik>
    )
}

export default SchemeAdminCompanyRegister
