import { FC } from 'react'
import { Grid } from '@mui/material'
import Button from 'components/Button'
import Input from 'components/Input'
import { Formik } from 'formik'
import { certificateFilteringEvansAdminSchemaValidator } from 'utils/ValidationSchema'
import { ICertificateFilters } from 'Interfaces/Certificate'

interface IProps {
    filteringValues: Omit<ICertificateFilters, 'page' | 'size'>
    applyFilters: (data: Omit<ICertificateFilters, 'page' | 'size'>) => void
    certificatesStatuses: Array<{ name: string; description: string }>
    closeModal: () => void
}

const FilteringCertificatesEvansModal: FC<IProps> = ({
    applyFilters,
    filteringValues,
    closeModal,
    certificatesStatuses,
}) => {
    function submitHandler(values: Omit<ICertificateFilters, 'page' | 'size'>) {
        applyFilters(values)
        closeModal()
    }

    return (
        <Formik
            validationSchema={certificateFilteringEvansAdminSchemaValidator}
            initialValues={filteringValues}
            onSubmit={(values) => submitHandler(values)}
        >
            {(formik) => (
                <Grid container spacing={4} padding="2rem 1rem 1rem">
                    <Grid item xs={6}>
                        <Input
                            name="companyName"
                            label="Company Name"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            name="navisionNumber"
                            label="Navision Number"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input name="firstName" label="First Name" fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <Input name="lastName" label="Last Name" fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            name="emailAddress"
                            label="Email Address"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input name="postcode" label="Postcode" fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            name="applicationDateFrom"
                            label="Application Date From"
                            fullWidth
                            placeholder="YYYY-MM-DD"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            name="applicationDateTo"
                            label="Application Date To"
                            fullWidth
                            placeholder="YYYY-MM-DD"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            name="approvalDateFrom"
                            label="Approval Date From"
                            fullWidth
                            placeholder="YYYY-MM-DD"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            name="approvalDateTo"
                            label="Approval Date To"
                            fullWidth
                            placeholder="YYYY-MM-DD"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            name="processStatus"
                            label="Process Status"
                            fullWidth
                            select
                            options={certificatesStatuses.map(
                                (element) => element.name
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            name="certificateId"
                            label="Certificate Id"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            name="certificateNumber"
                            label="Certificate Number"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            checkBox
                            name="hasCertificateErrors"
                            label="Certificate with errors"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={12}>
                        <Button
                            onClick={formik.handleSubmit}
                            text="Filter"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            )}
        </Formik>
    )
}

export default FilteringCertificatesEvansModal
