import { Grid, Paper, Typography } from '@mui/material'
import { FC } from 'react'
import { theme } from 'utils/Theme'

interface IProps {
    title: string
    children: React.ReactNode
    onClick?: () => void
}

const box = {
    color: 'primary.main',
    padding: '2.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    cursor: 'pointer',
    transition: 'all 0.15s ease-in-out',

    '&:hover': {
        boxShadow: theme.shadows[8],
        transform: 'translateY(-5px)',
    },
}

const PanelTile: FC<IProps> = ({ title, children, onClick }) => {
    return (
        <Grid item md={6} xs={12} lg={4} onClick={onClick}>
            <Paper sx={box} elevation={6}>
                {children}
                <Typography variant="h6" marginTop="1rem">
                    {title}
                </Typography>
            </Paper>
        </Grid>
    )
}

export default PanelTile
