import React, { FC, useState, useEffect } from 'react'
import { CloudUpload } from '@mui/icons-material'
import { Paper, Typography, useTheme } from '@mui/material'
import { FileUploader } from 'react-drag-drop-files'
import useStore from 'store/Store'

interface IProps {
    fileTypes: Array<string>
    onChange: (file: File) => void
    name: string
    maxSize: number
    label: string
    isLoading: boolean
    shouldResetFile?: boolean
}

const DragDropFile: FC<IProps> = ({
    fileTypes,
    name,
    onChange,
    maxSize,
    label,
    isLoading,
    shouldResetFile,
}) => {
    const [file, setFile] = useState<File>()
    const { openNotification } = useStore()
    const labelText = file?.name || label
    const theme = useTheme()

    useEffect(() => {
        if (shouldResetFile) {
            setFile(undefined)
        }
    }, [shouldResetFile])

    return (
        <FileUploader
            maxSize={maxSize}
            name={name}
            onTypeError={() => openNotification('Invalid file type', 'error')}
            onSizeError={() =>
                openNotification(`Maximum size ${maxSize}mb exceeded`, 'error')
            }
            types={fileTypes}
            handleChange={(file: File) => {
                onChange(file)
                setFile(file)
            }}
        >
            <Paper
                elevation={0}
                sx={{
                    cursor: 'pointer',
                    display: 'grid',
                    placeItems: 'center',
                    border: '2px dashed',
                    borderColor: theme.palette.primary.main,
                    padding: '1rem 0',
                    borderRadius: '5px',
                }}
            >
                <CloudUpload sx={{ fontSize: '2.5rem' }} />

                <Typography mt={1} variant="body1">
                    {isLoading ? 'Loading...' : labelText}
                </Typography>
            </Paper>
        </FileUploader>
    )
}

export default DragDropFile
