import { FC, useRef } from 'react'
import { Typography, Divider, Grid, useTheme } from '@mui/material'
import Input from 'components/Input'
import { Formik, FormikProps } from 'formik'
import {
    administratorSchemaDetailsValidator,
    updateEmailValidator,
} from 'utils/ValidationSchema'
import Button from 'components/Button'
import useEvansAdminDetailsPage from './useEvansAdminDetailsPage'
import LoadingSpinner from 'components/LoadingSpinner'
import { ISchemeAdminDetails } from 'Interfaces/Admin'
import { HOW_DID_YOU_HEAR_OPTIONS, TITLE_OPTIONS } from 'utils/constants'
import usePageTitle from 'hooks/usePageTitle'

const AdminPanelDetailsPage: FC = () => {
    usePageTitle('Account Details')
    const formikRef = useRef<FormikProps<ISchemeAdminDetails>>(null)
    const emailFormRef =
        useRef<
            FormikProps<{ emailAddress: string; confirmEmailAddress: string }>
        >(null)
    const theme = useTheme()

    const {
        initialValues,
        submitHandler,
        isFetching,
        mutation,
        updateEmailMutation,
        emailFormValues,
    } = useEvansAdminDetailsPage(formikRef, emailFormRef)

    if (isFetching) {
        return <LoadingSpinner />
    }

    return (
        <Formik
            innerRef={formikRef}
            onSubmit={submitHandler}
            initialValues={initialValues}
            validationSchema={administratorSchemaDetailsValidator}
        >
            {({ handleSubmit, status }) => (
                <>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography
                                color="primary"
                                variant="body1"
                                fontWeight="bold"
                            >
                                Personal Details
                            </Typography>

                            <Divider
                                sx={{
                                    backgroundColor: 'primary',
                                    borderBottomWidth: '2px',
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Input
                                label="Title"
                                name="title"
                                required
                                maxWidth="600px"
                                select
                                options={TITLE_OPTIONS}
                                fullWidth
                                apiErrors={status}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                label="First Name"
                                name="firstName"
                                required
                                fullWidth
                                apiErrors={status}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                label="Last Name"
                                name="lastName"
                                required
                                fullWidth
                                apiErrors={status}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                label="Job Title"
                                name="jobTitle"
                                required
                                fullWidth
                                apiErrors={status}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                label="Telephone number"
                                name="phoneNumber"
                                required
                                fullWidth
                                apiErrors={status?.phoneNumber}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                options={HOW_DID_YOU_HEAR_OPTIONS}
                                select
                                required
                                fullWidth
                                maxWidth="600px"
                                name="howDidYouHearOption"
                                label="How Did You Hear About Us"
                                apiErrors={status}
                            />
                        </Grid>

                        <Formik
                            validationSchema={updateEmailValidator}
                            initialValues={emailFormValues}
                            onSubmit={(values) =>
                                updateEmailMutation.mutate(values)
                            }
                        >
                            {(formik) => (
                                <>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body1"
                                            color="primary"
                                            fontWeight="bold"
                                        >
                                            Email Address
                                        </Typography>
                                        <Divider
                                            sx={{
                                                backgroundColor: 'primary',
                                                borderBottomWidth: '2px',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Input
                                            label="Email address"
                                            name="emailAddress"
                                            required
                                            fullWidth
                                            apiErrors={status}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Input
                                            label="Confirm your email address"
                                            name="confirmEmailAddress"
                                            apiErrors={status}
                                            required
                                            fullWidth
                                            disablePasting
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Button
                                            onClick={formik.handleSubmit}
                                            isLoading={
                                                updateEmailMutation.isLoading
                                            }
                                            maxWidth="600px"
                                            text="Update email"
                                            colorPrimary
                                            fullWidth
                                        />
                                    </Grid>
                                </>
                            )}
                        </Formik>

                        <Grid item xs={12}>
                            <Divider
                                sx={{
                                    color: 'primary.main',
                                    borderWidth: '1px',
                                    backgroundColor:
                                        theme.palette.secondary.main,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                checkBox
                                fullWidth
                                name="signUpForNewsletter"
                                label="Sign Up For Newsletter"
                                apiErrors={status}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Button
                                isLoading={mutation.isLoading}
                                text="Update details"
                                onClick={handleSubmit}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </Formik>
    )
}

export default AdminPanelDetailsPage
