import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import { theme } from 'utils/Theme'

export const SectionHeader = styled(Typography)`
    font-weight: bold;
    border-bottom: 2px solid;
    border-color: ${theme.palette.primary.main};
    color: ${theme.palette.primary.main};
    margin-bottom: 1rem;
`
