import {
    Step,
    StepConnector,
    stepConnectorClasses,
    StepLabel,
    Stepper,
    styled,
} from '@mui/material'
import React, { FC } from 'react'

interface IProps {
    options: Array<string>
    activeStep: number
}

const EvansConnector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 'clamp(5px, 3.25vw, 25px)',
        left: 'calc(-50% + 24px)',
        right: 'calc(50% + 24px)',
        zIndex: -100,
    },
}))

const FormStepper: FC<IProps> = ({ options, activeStep }) => {
    return (
        <Stepper
            sx={{ marginBottom: '1rem' }}
            alternativeLabel
            activeStep={activeStep}
            connector={<EvansConnector />}
        >
            {options.map((element: string) => (
                <Step key={element}>
                    <StepLabel
                        componentsProps={{
                            label: {
                                style: {
                                    fontSize: 'clamp(10px, 2.5vw, 18px)',
                                },
                            },
                        }}
                        StepIconProps={{
                            sx: { fontSize: 'clamp(10px, 7.5vw, 50px)' },
                        }}
                    >
                        {element}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    )
}

export default FormStepper
