import { useContext } from 'react'
import { TextFieldProps, TextField } from '@mui/material'
import { useField } from 'formik'
import { LoqateContext } from './LoqateContext'

export const AddressSearchInput = (
    props: JSX.IntrinsicAttributes & TextFieldProps
) => {
    const { label, name } = useContext(LoqateContext)
    const [field, meta] = useField(name || 'addressLine1')

    const error = meta.touched && meta.error

    return (
        <TextField
            label={label}
            autoComplete="chrome-off"
            required
            name={field.name}
            helperText={error}
            error={Boolean(error)}
            placeholder="Please enter your address and select"
            InputLabelProps={{
                shrink: true,
                sx: {
                    color: 'black',
                    '& .MuiFormLabel-asterisk': {
                        color: '#FF0000',
                        fontSize: '1.2rem',
                    },
                },
            }}
            {...props}
            value={field.value}
            fullWidth
            sx={{ maxWidth: '600px' }}
            onChange={(e) => {
                props.onChange?.(e)
                field.onChange(e)
            }}
        />
    )
}
