import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { FC, useEffect, useState } from 'react'
import UserPool from './UserPool'
import useAuth from './useAuth'
import LoadingSpinner from 'components/LoadingSpinner'

interface IProps {
    children: React.ReactNode
}

const AuthProvider: FC<IProps> = ({ children }) => {
    const { assignPermissions } = useAuth()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (UserPool.getCurrentUser()) {
            UserPool.getCurrentUser()?.getSession(
                (err: Error, session: CognitoUserSession | null) => {
                    if (!err) {
                        const email = session
                            ?.getIdToken()
                            .decodePayload().email
                        const roles =
                            session?.getIdToken().payload['cognito:groups']

                        if (Array.isArray(roles)) {
                            if (roles?.includes('Evans-admin')) {
                                assignPermissions(roles, email)
                            } else {
                                assignPermissions(roles)
                            }
                        }
                    }
                }
            )
        }
        setLoading(false)
    }, [])

    if (loading) {
        return <LoadingSpinner />
    }

    return <>{children}</>
}

export default AuthProvider
