import { FC, useState } from 'react'
import AdminPanelChangePasswordPage from '../AdminPanelChangePasswordPage/AdminPanelChangePasswordPage'
import AdminPanelDetailsPage from '../AdminPanelDetailsPage/AdminPanelDetailsPage'
import {
    Divider,
    Tab,
    Tabs,
    Typography,
    Grid,
    useMediaQuery,
    Theme,
} from '@mui/material'

const AdminSettings: FC = () => {
    const [currentPage, setCurrentPage] = useState('AdminSettings')

    const isMobileResolution = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    )

    return (
        <>
            <Divider>
                <Typography color="primary" variant="h5" fontWeight="bold">
                    Administrator Details
                </Typography>
            </Divider>
            <Grid container spacing={4}>
                <Grid item xs={12} md={3}>
                    <Tabs
                        sx={{ marginTop: '1rem' }}
                        orientation={
                            !isMobileResolution ? 'vertical' : 'horizontal'
                        }
                        value={currentPage}
                        onChange={(event, value: string) =>
                            setCurrentPage(value)
                        }
                    >
                        <Tab label="Admin Settings" value="AdminSettings" />
                        <Tab label="Change Password" value="ChangePassword" />
                    </Tabs>
                </Grid>
                <Grid item xs={12} md={9} mt={3} sx={{ padding: '2rem 0' }}>
                    {currentPage === 'ChangePassword' ? (
                        <AdminPanelChangePasswordPage />
                    ) : (
                        <AdminPanelDetailsPage />
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export default AdminSettings
