import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import Modal from 'components/Modal/Modal'
// import CustomFileReader from 'components/FileReader'

type DocumentPreviewModalProps = {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    document: { url: string; type: string }
}

const DocumentPreviewModal: FC<DocumentPreviewModalProps> = ({
    isOpen,
    setIsOpen,
    document,
}) => {
    const renderPreview = () => {
        switch (document.type) {
            case 'PDF':
                return <embed src={document.url} width="100%" height="100%" />
            case 'PNG':
            case 'JPG':
                return <img src={document.url} width="100%" height="100%" />

            case 'DOC':
            case 'DOCX':
                return (
                    <Box
                        width="100%"
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography display="block" textAlign="center">
                            Document preview is not available.
                        </Typography>
                    </Box>
                )
            // Not working because of CORS settings
            // Could be handled by backend in the future (parsing to pdf)
            // return <CustomFileReader filePath={document.url} hideFileName />
        }
    }

    return (
        <Modal
            hideHeader
            isOpen={isOpen}
            maxWidth="md"
            onClose={() => {
                setIsOpen(false)
            }}
        >
            <Box height={600}>{renderPreview()}</Box>
        </Modal>
    )
}

export default DocumentPreviewModal
