import { FC } from 'react'
import { List, Drawer, useTheme } from '@mui/material'
import { Logout, ManageAccounts, PedalBike } from '@mui/icons-material'
import SidebarListItem from '../pages/SchemeAdmin/AdminPanel/components/SidebarListItem'
import useStore from 'store/Store'
import useAuth from 'auth/useAuth'

const listStyle = {
    backgroundColor: 'primary.main',
    color: 'primary.main',
    width: '270px',
    position: 'relative',
    height: '100%',
}

const SchemeAdminSidebar: FC = () => {
    const theme = useTheme()
    const { isSchemeAdminNavigationOpen, handleSchemeAdminNavigationState } =
        useStore()

    const { logout } = useAuth()

    function closeSchemeAdminNavigation() {
        handleSchemeAdminNavigationState(false)
    }
    const elements = [
        {
            link: '/ride-to-work-apply/schemeadmin',
            text: 'Home',
            onClick: closeSchemeAdminNavigation,
            icon: PedalBike,
        },
        {
            link: '/ride-to-work-apply/schemeadmin/settings',
            text: 'Setting',
            onClick: closeSchemeAdminNavigation,
            icon: ManageAccounts,
        },
        {
            text: 'Logout',
            onClick: () => {
                logout()
                closeSchemeAdminNavigation()
            },
            icon: Logout,
        },
    ]

    return (
        <Drawer
            sx={{ zIndex: theme.zIndex.appBar + 1 }}
            open={isSchemeAdminNavigationOpen}
            onClose={() => handleSchemeAdminNavigationState(false)}
            variant="temporary"
        >
            <List sx={listStyle}>
                {elements.map((el) => {
                    {
                        const Icon = el.icon

                        return (
                            <SidebarListItem
                                key={el.text}
                                link={el.link}
                                text={el.text}
                                onClick={el.onClick}
                            >
                                <Icon />
                            </SidebarListItem>
                        )
                    }
                })}
            </List>
        </Drawer>
    )
}

export default SchemeAdminSidebar
