import { FC, ReactNode } from 'react'
import useStore from 'store/Store'
import { Navigate } from 'react-router-dom'

interface IProps {
    children: ReactNode
}

const NotSchemaAdminGuard: FC<IProps> = ({ children }) => {
    const { isSchemeAdmin } = useStore()

    if (!isSchemeAdmin) {
        return <>{children}</>
    }

    return <Navigate to="/ride-to-work-apply/schemeadmin" />
}

export default NotSchemaAdminGuard
