import { formatValue } from './OutputRow'
import { ICalculatorResults } from './SavingsCalculatorPage'

export const getTaxAndInsurance = (salary: number) => {
    if (salary <= Number(process.env.REACT_APP_SALARY_CEIL_1))
        return {
            taxRate: Number(process.env.REACT_APP_TAX_RATE_1),
            nationalInsuranceRate: Number(
                process.env.REACT_APP_INSURANCE_RATE_1
            ),
        }
    if (salary <= Number(process.env.REACT_APP_SALARY_CEIL_2))
        return {
            taxRate: Number(process.env.REACT_APP_TAX_RATE_2),
            nationalInsuranceRate: Number(
                process.env.REACT_APP_INSURANCE_RATE_2
            ),
        }
    if (salary <= Number(process.env.REACT_APP_SALARY_CEIL_3))
        return {
            taxRate: Number(process.env.REACT_APP_TAX_RATE_3),
            nationalInsuranceRate: Number(
                process.env.REACT_APP_INSURANCE_RATE_3
            ),
        }
    return {
        taxRate: Number(process.env.REACT_APP_TAX_RATE_4),
        nationalInsuranceRate: Number(process.env.REACT_APP_INSURANCE_RATE_4),
    }
}

export type Frequency = 'monthly' | 'weekly' | 'fortnightly' | 'fourweekly'

const calculateDeductionByFrequency = (value: number, frequency: Frequency) => {
    switch (frequency) {
        case 'monthly':
            return value / 12
        case 'weekly':
            return value / 52
        case 'fortnightly':
            return value / 26
        case 'fourweekly':
            return value / 13
        default:
            return -1
    }
}
export interface FormInput {
    annualSalary: number
    totalValue: number
    payFrequency: Frequency
    term: number
    calculatorResults?: ICalculatorResults
}

const calculationHelper = (hireCost: number, annualSalary: number) => {
    const { taxRate, nationalInsuranceRate } = getTaxAndInsurance(annualSalary)
    const incomeTaxSaving = hireCost * (taxRate / 100)
    const nationalInsuranceSaving = hireCost * (nationalInsuranceRate / 100)

    return {
        taxRate,
        nationalInsuranceRate,
        incomeTaxSaving,
        nationalInsuranceSaving,
    }
}

export const calculateTotals = (annualSalary: number, hireCost: number) => {
    const { incomeTaxSaving, nationalInsuranceSaving } = calculationHelper(
        hireCost,
        annualSalary
    )
    const totalCostOfHire = hireCost - incomeTaxSaving - nationalInsuranceSaving
    const totalSavingPercent = (1 - totalCostOfHire / hireCost) * 100
    const totalSavingValue = hireCost - totalCostOfHire

    return {
        totalSavingPercent,
        totalSavingValue,
        totalCostOfHire,
    }
}

export const calculateGrossAndNet = ({
    annualSalary,
    totalValue: hireCost,
    payFrequency,
    term,
}: FormInput) => {
    const { incomeTaxSaving, nationalInsuranceSaving } = calculationHelper(
        hireCost,
        annualSalary
    )

    const totalCostOfHire = hireCost - incomeTaxSaving - nationalInsuranceSaving
    const salarySacrificeTermMultiplier = 12 / term

    const grossDeduction = calculateDeductionByFrequency(
        hireCost * salarySacrificeTermMultiplier,
        payFrequency
    )
    const netDeduction = calculateDeductionByFrequency(
        totalCostOfHire * salarySacrificeTermMultiplier,
        payFrequency
    )

    return {
        grossDeduction,
        netDeduction,
    }
}

const toCurrency = (value: number) => {
    return formatValue(value, 'currency').toString()
}

export const createCalculatorResults = (values: FormInput) => {
    const fortnightlyResults = calculateGrossAndNet({
        ...values,
        payFrequency: 'fortnightly',
    })
    const fourweeklyResults = calculateGrossAndNet({
        ...values,
        payFrequency: 'fourweekly',
    })
    const monthlyResults = calculateGrossAndNet({
        ...values,
        payFrequency: 'monthly',
    })
    const weeklyResults = calculateGrossAndNet({
        ...values,
        payFrequency: 'weekly',
    })

    const { totalSavingPercent, totalSavingValue, totalCostOfHire } =
        calculateTotals(values.annualSalary, values.totalValue)

    return {
        fortnightlyGrossValue: toCurrency(fortnightlyResults.grossDeduction),
        fourweeklyGrossValue: toCurrency(fourweeklyResults.grossDeduction),
        monthlyGrossValue: toCurrency(monthlyResults.grossDeduction),
        weeklyGrossValue: toCurrency(weeklyResults.grossDeduction),
        fortnightlyNetValue: toCurrency(fortnightlyResults.netDeduction),
        fourweeklyNetValue: toCurrency(fourweeklyResults.netDeduction),
        monthlyNetValue: toCurrency(monthlyResults.netDeduction),
        weeklyNetValue: toCurrency(weeklyResults.netDeduction),
        totalCostOfHireValue: toCurrency(totalCostOfHire),
        totalSavingPercent: formatValue(
            totalSavingPercent,
            'percent'
        ).toString(),
        totalSavingValue: toCurrency(totalSavingValue),
    }
}
