import { useState } from 'react'
import Button from 'components/Button'
import { GridCellParams } from '@mui/x-data-grid'
import { useQuery } from 'react-query'
import { getTemplatesList } from 'api/evansAdmin'
import { ITemplateList } from 'Interfaces/Templates'
import { useNavigate } from 'react-router-dom'
import SortingTableCell from 'components/sorting/SortingTableCell'

const useEvansAdminTemplatePage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [removedTemplateId, setRemovedTemplateId] = useState('')
    const [isDeleteTemplateModalOpen, setIsDeleteTemplateModalOpen] =
        useState(false)
    const [templateIdToPreviewCompanies, setTemplateIdToPreviewCompanies] =
        useState({
            name: '',
            id: '',
        })

    const [errorMessage, setErrorMessage] = useState('')
    const [sortedValue, setSortedValue] = useState<string | null>(null)
    const pageSize = 12
    const navigate = useNavigate()

    const columns = [
        {
            field: 'title',
            headerName: 'Title',
            sortable: false,
            flex: 1,
            renderHeader: () => (
                <SortingTableCell
                    title="Title"
                    sortingValue={sortedValue as string}
                    setSortingValue={setSortingValue}
                    value="title"
                />
            ),
        },
        {
            field: 'isUnder18',
            headerName: 'Under 18',
            flex: 1,
            sortable: false,
            renderHeader: () => (
                <SortingTableCell
                    title="Under 18"
                    sortingValue={sortedValue as string}
                    setSortingValue={setSortingValue}
                    value="isUnder18"
                />
            ),
        },
        {
            field: 'isGlobal',
            headerName: 'Global',
            sortable: false,
            flex: 1,
            renderHeader: () => (
                <SortingTableCell
                    title="Global"
                    sortingValue={sortedValue as string}
                    setSortingValue={setSortingValue}
                    value="isGlobal"
                />
            ),
        },
        {
            field: 'thirdStepAgreementType',
            headerName: 'Third Step Agreement Type',
            sortable: false,
            flex: 1,
            renderHeader: () => (
                <SortingTableCell
                    title="Third Step Agreement Type"
                    sortingValue={sortedValue as string}
                    setSortingValue={setSortingValue}
                    value="thirdStepAgreementType"
                />
            ),
        },
        {
            field: 'edit',
            headerName: 'Edit',
            sortable: false,
            width: 80,
            renderCell: (params: GridCellParams) => {
                return (
                    <Button
                        fullWidth
                        text="Edit"
                        onClick={() => {
                            navigate(
                                `/ride-to-work-apply/evans-admin/templates/${params.id}`
                            )
                        }}
                    />
                )
            },
        },
        {
            field: 'companies',
            headerName: 'Used By',
            sortable: false,
            width: 80,
            renderCell: (params: GridCellParams) => {
                return (
                    <Button
                        // isGlobal is boolean but in string
                        disabled={params.row.isGlobal === 'TRUE'}
                        colorPrimary
                        fullWidth
                        text="View"
                        onClick={() => {
                            setTemplateIdToPreviewCompanies({
                                id: params.row.id,
                                name: params.row.title,
                            })
                        }}
                    />
                )
            },
        },
        {
            field: 'delete',
            headerName: 'Remove',
            sortable: false,
            width: 100,
            renderCell: (params: GridCellParams) => {
                return (
                    <Button
                        disabled={!params.row.canBeDeleted}
                        error
                        fullWidth
                        text="Delete"
                        onClick={() => {
                            setIsDeleteTemplateModalOpen(true)
                            setRemovedTemplateId(params.id as string)
                        }}
                    />
                )
            },
        },
    ]

    const {
        data: response,
        isFetching,
        refetch,
    } = useQuery(['getTemplates', currentPage, sortedValue], () =>
        getTemplatesList({
            page: currentPage,
            size: pageSize,
            sort: sortedValue,
        })
    )

    function setSortingValue(value: string | null) {
        setSortedValue(value)
    }

    function formatResponse() {
        return response?.data.content.map((element: ITemplateList) => {
            return {
                ...element,
                isUnder18: String(element.isUnder18).toUpperCase(),
                isGlobal: String(element.isGlobal).toUpperCase(),
                title: element.title.replaceAll('_', ' '),
                thirdStepAgreementType:
                    element.thirdStepAgreementType.replaceAll('_', ' '),
            }
        })
    }

    function handlePageChange(e: React.ChangeEvent<unknown>, p: number) {
        if (currentPage === 0 && p == 1) {
            return
        } else {
            setCurrentPage(p - 1)
        }
    }

    return {
        columns,
        isModalOpen,
        currentPage,
        pageSize,
        formatResponse,
        isFetching,
        response,
        templateIdToPreviewCompanies,
        setTemplateIdToPreviewCompanies,
        handlePageChange,
        setIsModalOpen,
        refetch,
        errorMessage,
        setErrorMessage,
        isDeleteTemplateModalOpen,
        setIsDeleteTemplateModalOpen,
        removedTemplateId,
    }
}

export default useEvansAdminTemplatePage
