import React, { FC } from 'react'
import { useMutation, useQuery } from 'react-query'
import { changeAdminRole, getAdminRoles } from '../../../../api/evansAdmin'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import {
    Box,
    Divider,
    Grid,
    MenuItem,
    Typography,
    useTheme,
} from '@mui/material'
import SelectInput from '../../../../components/SelectInput'
import Button from '../../../../components/Button'
import { Formik } from 'formik'
import useStore from '../../../../store/Store'
import { AxiosError } from 'axios'
import { IAdminRole } from '../../../../Interfaces/Admin'

const AdminRoles: FC<{ adminId: string }> = ({ adminId }) => {
    const { data, isFetching } = useQuery(['getCompanies', adminId], () =>
        getAdminRoles(adminId)
    )

    const changeAdminRoleMutation = useMutation(
        (data: IAdminRole) => changeAdminRole(data),
        {
            onSuccess: () => {
                openNotification('Admin role has been changed', 'success')
            },
            onError: (error: AxiosError<{ message: string }>) => {
                openNotification(
                    error?.response?.data.message || 'Something went wrong',
                    'error'
                )
            },
        }
    )
    const theme = useTheme()
    const { openNotification } = useStore()

    const availableRoles = ['Scheme Admin', 'Evans Admin', 'CMS Evans Admin']

    if (isFetching) {
        return <LoadingSpinner />
    }

    const initialValues = {
        adminRole: '',
    }

    if (data?.data) {
        if (data.data.includes('EVANS_CMS_ADMIN')) {
            initialValues.adminRole = 'CMS Evans Admin'
        } else if (data.data.includes('EVANS_ADMIN')) {
            initialValues.adminRole = 'Evans Admin'
        } else if (data.data.includes('SCHEME_ADMIN')) {
            initialValues.adminRole = 'Scheme Admin'
        }
    }

    const boxStyle = {
        padding: '2rem 0 0',
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
    }

    function displayRoles() {
        return availableRoles.map((role) => (
            <MenuItem key={role} value={role}>
                {role}
            </MenuItem>
        ))
    }

    function submitHandler(value: string) {
        const roleMappings: { [key: string]: string } = {
            'CMS Evans Admin': 'EVANS_CMS_ADMIN',
            'Evans Admin': 'EVANS_ADMIN',
            'Scheme Admin': 'SCHEME_ADMIN',
        }

        const adminRoleData: IAdminRole = {
            adminId: Number(adminId),
            adminRole: roleMappings[value] || '',
        }

        console.log(adminRoleData)
        changeAdminRoleMutation.mutate(adminRoleData)
    }

    return (
        <>
            <Typography color="primary" variant="h6" fontWeight="bold" mt={2}>
                Admin Roles
            </Typography>
            <Divider sx={{ marginBottom: '2rem' }} />

            <Formik
                initialValues={initialValues}
                onSubmit={(values) => submitHandler(values.adminRole)}
            >
                {(formik) => (
                    <Box sx={boxStyle}>
                        <SelectInput
                            options={() => displayRoles()}
                            name="adminRole"
                            label="Admin Role"
                            required
                        />
                        <Grid item xs={12}>
                            <Divider
                                sx={{
                                    backgroundColor:
                                        theme.palette.secondary.main,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                isLoading={changeAdminRoleMutation.isLoading}
                                text="Change Role"
                                maxWidth="600px"
                                onClick={formik.handleSubmit}
                            />
                        </Grid>
                    </Box>
                )}
            </Formik>
        </>
    )
}

export default AdminRoles
