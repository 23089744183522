import { Grid, Paper, Typography } from '@mui/material'
import { FC, ReactNode } from 'react'

interface IProps {
    label: string
    info?: string
    children?: ReactNode
}

const Tile: FC<IProps> = ({ label, info, children }) => {
    return (
        <Grid item xs={12} md={6}>
            <Paper
                elevation={0}
                sx={{
                    padding: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                    backgroundColor: '#efefef',
                }}
            >
                <Typography variant="body1" sx={{ fontWeight: '600' }}>
                    {label}:
                </Typography>
                {info && (
                    <Typography variant="body1" sx={{ fontWeight: '500' }}>
                        {info}
                    </Typography>
                )}
                {children}
            </Paper>
        </Grid>
    )
}

export default Tile
