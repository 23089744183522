import { FC, useRef } from 'react'
import { registerSchemeAdmin } from 'api/schemeAdmin'
import { Formik, FormikProps } from 'formik'
import Input from 'components/Input'
import Button from 'components/Button'
import { Grid } from '@mui/material'
import { useMutation } from 'react-query'
import { IRegisterSchemeAdmin } from 'Interfaces/Admin'
import { registerSchemaAdminValidator } from 'utils/ValidationSchema'
import { AxiosError } from 'axios'
import useStore from 'store/Store'
import { TITLE_OPTIONS, HOW_DID_YOU_HEAR_OPTIONS } from 'utils/constants'

interface IProps {
    refetch: () => void
}

const SchemeAdminRegistration: FC<IProps> = ({ refetch }) => {
    const { openNotification } = useStore()
    const formikRef = useRef<FormikProps<IRegisterSchemeAdmin>>(null)
    const initialValues = {
        title: '',
        firstName: '',
        lastName: '',
        jobTitle: '',
        phoneNumber: '',
        howDidYouHearOption: '',
        signUpForNewsletter: false,
    }

    const { mutate, isLoading } = useMutation(
        (data: IRegisterSchemeAdmin) => registerSchemeAdmin(data),
        {
            onSuccess: () => {
                openNotification('Accout has been registered', 'success')
                refetch()
            },
            onError: (
                error: AxiosError<{
                    message: string
                    fieldErrors: Record<string, string>
                }>
            ) => {
                formikRef.current?.setStatus({
                    ...error.response?.data.fieldErrors,
                })
                openNotification(
                    error?.response?.data.message as string,
                    'error'
                )
            },
        }
    )

    function submitHandler(values: IRegisterSchemeAdmin) {
        mutate(values)
    }

    return (
        <Formik
            innerRef={formikRef}
            validationSchema={registerSchemaAdminValidator}
            initialValues={initialValues}
            onSubmit={(values) => submitHandler(values)}
        >
            {({ handleSubmit, status }) => (
                <>
                    <Grid
                        container
                        spacing={4}
                        sx={{ padding: '2rem 0 0', maxHeight: '700px' }}
                    >
                        <Grid item xs={12} md={6}>
                            <Input
                                name="title"
                                label="Title"
                                required
                                select
                                maxWidth="600px"
                                options={TITLE_OPTIONS}
                                fullWidth
                                apiErrors={status}
                            />
                        </Grid>

                        <Grid item xs={12} container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <Input
                                    name="firstName"
                                    label="First Name"
                                    required
                                    fullWidth
                                    apiErrors={status}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Input
                                    name="lastName"
                                    label="Last Name"
                                    required
                                    fullWidth
                                    apiErrors={status}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                name="jobTitle"
                                label="Job Title"
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                name="phoneNumber"
                                label="Phone Number"
                                required
                                fullWidth
                                apiErrors={status}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                maxWidth="600px"
                                name="howDidYouHearOption"
                                label="How Did You Hear About Us"
                                required
                                select
                                options={HOW_DID_YOU_HEAR_OPTIONS}
                                fullWidth
                                apiErrors={status}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                name="signUpForNewsletter"
                                label="Sign Up For Newsletter"
                                checkBox
                                apiErrors={status}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button
                                maxWidth="600px"
                                isLoading={isLoading}
                                text="Submit"
                                fullWidth
                                onClick={handleSubmit}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </Formik>
    )
}

export default SchemeAdminRegistration
