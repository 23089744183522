import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import Button from 'components/Button'
import { useMutation } from 'react-query'
import useStore from 'store/Store'
import { AxiosError, AxiosResponse } from 'axios'

interface IProps {
    closeModal: () => void
    successCallback: () => void
    deleteFunction: () => Promise<AxiosResponse>
    message: string
}

const DeleteModal: FC<IProps> = ({
    closeModal,
    successCallback,
    deleteFunction,
    message,
}) => {
    const { openNotification } = useStore()

    const { mutate, isLoading } = useMutation(() => deleteFunction(), {
        onSuccess: () => {
            openNotification('Deleted successfully', 'success')
            successCallback()
            closeModal()
        },

        onError: (error: AxiosError<{ message: string }>) => {
            openNotification(error.response?.data.message as string, 'error')
        },
    })

    return (
        <Box padding="2rem 1rem">
            <Typography variant="h5" mb={1} color="primary">
                {message}
            </Typography>

            <Box
                display="flex"
                justifyContent="center"
                gap="1rem"
                marginTop="1rem"
            >
                <Button
                    text="No, Keep it."
                    outlined
                    colorPrimary
                    onClick={closeModal}
                />
                <Button
                    error
                    text="Yes, Delete"
                    isLoading={isLoading}
                    onClick={mutate}
                />
            </Box>
        </Box>
    )
}

export default DeleteModal
