import React, { FC } from 'react'
import { Autocomplete as MuiAutocomplete, TextField } from '@mui/material'
import { IOption } from 'Interfaces/Certificate'

interface IProps {
    values: Array<IOption>
    inputValue: string
    value: IOption | null
    onInputChange: (e: string) => void
    onValueChange: (e: IOption | null) => void
    label: string
    width?: string
    error?: string
}

const Autocomplete: FC<IProps> = ({
    values,
    width,
    label,
    value,
    inputValue,
    onInputChange,
    onValueChange,
    error,
}) => {
    return (
        <MuiAutocomplete
            freeSolo
            options={values}
            value={value}
            inputValue={inputValue}
            onChange={(
                event: React.SyntheticEvent,
                newValue: IOption | null | string
            ) => {
                if (typeof newValue === 'string')
                    // handling type mismatch, this shouldn't happen
                    onValueChange({
                        label: newValue,
                        value: newValue,
                        additionalData: {
                            name: newValue,
                            type: newValue,
                        },
                    })
                else {
                    onValueChange(newValue)
                }
            }}
            onInputChange={(
                event: React.SyntheticEvent,
                newInputValue: string
            ) => {
                onInputChange(newInputValue)
            }}
            renderInput={(params) => (
                <TextField
                    helperText={error}
                    error={Boolean(error)}
                    {...params}
                    label={label}
                    sx={{ width: width || 300, flex: 4 }}
                />
            )}
        />
    )
}

export default Autocomplete
