import { FC, useState } from 'react'
import { Box, Grid, Typography, Stack, Divider } from '@mui/material'
import Button from 'components/Button'
import DragDropFile from 'components/DragDropFile'
import { useMutation, useQuery } from 'react-query'
import {
    uploadNewCompanyLogo,
    deleteCompanyLogo,
    getCompanyLogo,
} from 'api/evansAdmin'
import { useParams } from 'react-router-dom'
import useStore from 'store/Store'
import { AxiosError, AxiosResponse } from 'axios'
import LoadingSpinner from 'components/LoadingSpinner'
import Logo from 'assets/LogoPlaceholder.svg'

interface IProps {
    setError: (message: string) => void
}

const CompanyLogo: FC<IProps> = ({ setError }) => {
    const [file, setFile] = useState<File | null>(null)
    const [shouldResetFile, setShouldResetFile] = useState(false)
    const [logoUrl, setLogoUrl] = useState('')
    const { companyId } = useParams()
    const { openNotification } = useStore()

    const { isFetching } = useQuery<
        AxiosResponse,
        AxiosError<{ message: string }>
    >(
        ['getCompanyLogo', companyId],
        () => getCompanyLogo(companyId as string),
        {
            onSuccess: (response) => {
                setLogoUrl(response.data.url)
            },
            onError: (error) => {
                setError(error.response?.data.message as string)
            },
        }
    )

    const uploadLogo = useMutation(
        (image: File | string | Blob | FormData) =>
            uploadNewCompanyLogo(companyId as string, image),
        {
            onSuccess: () => {
                openNotification('Logo has been updated', 'success')
                setLogoUrl(URL.createObjectURL(file as File))
                setShouldResetFile(true)
            },
            onError: (error: AxiosError<{ message: string }>) => {
                openNotification(
                    error?.response?.data.message || 'Something went wrong',
                    'error'
                )
            },
        }
    )

    const deleteCompanyLogoMutation = useMutation(
        () => deleteCompanyLogo(companyId as string),
        {
            onSuccess: () => {
                openNotification('Company logo has been deleted ', 'success')
                setLogoUrl('')
                setFile(null)
            },

            onError: (error: AxiosError<{ message: string }>) => {
                openNotification(
                    error?.response?.data.message as string,
                    'error'
                )
            },
        }
    )

    if (isFetching) {
        return <LoadingSpinner />
    }

    return (
        <>
            <Grid container spacing="2rem" mt={1}>
                <Grid item xs={12}>
                    <Typography variant="h6" color="primary" fontWeight="bold">
                        Company Logo
                    </Typography>
                    <Divider
                        sx={{
                            borderBottomWidth: '1.5px',
                            borderColor: 'primary.main',
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={5}>
                    <Box
                        sx={{
                            padding: '.5rem',
                            maxWidth: '500px',
                            width: '100%',
                        }}
                    >
                        {file || logoUrl ? (
                            <img
                                width="100%"
                                height="100%"
                                src={file ? URL.createObjectURL(file) : logoUrl}
                                alt="Logo of the company"
                            />
                        ) : (
                            <img
                                width="100%"
                                height="100%"
                                src={Logo}
                                alt="Logo placeholder"
                            />
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Stack spacing="0.5rem">
                        <DragDropFile
                            maxSize={
                                parseInt(
                                    process.env
                                        .REACT_APP_MAXIMUM_LOGO_SIZE as string
                                ) || 2
                            }
                            label="Choose file"
                            fileTypes={['JPG', 'PNG']}
                            isLoading={false}
                            name="logo"
                            shouldResetFile={shouldResetFile}
                            onChange={(file: File) => {
                                setFile(file)
                            }}
                        />
                        <Button
                            isLoading={uploadLogo.isLoading}
                            backgroundColor="secondary"
                            onClick={() => uploadLogo.mutate(file as File)}
                            text="Add image"
                            colorPrimary
                            fullWidth
                            disabled={!file}
                        />
                        <Button
                            onClick={deleteCompanyLogoMutation.mutate}
                            isLoading={deleteCompanyLogoMutation.isLoading}
                            text="Remove logo"
                            disabled={!logoUrl}
                            fullWidth
                            error
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Typography>
                        Upload company logo to personalise the company
                        Ride-to-Work page
                    </Typography>
                    <Typography
                        color="primary"
                        fontWeight="bold"
                        marginY="1rem"
                    >
                        Image Guidelines
                    </Typography>
                    <Box marginBottom="1rem">
                        <Typography>Formats: JPG, PNG</Typography>
                        <Typography>Dimensions: 150 x 150 px</Typography>
                        <Typography>Size: Max 2MB</Typography>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default CompanyLogo
