import { FC, useState } from 'react'
import { getCompaniesWhichUseTemplate } from 'api/evansAdmin'
import { useQuery } from 'react-query'
import LoadingSpinner from 'components/LoadingSpinner'
import ErrorMessage from 'components/ErrorMessage'
import { Typography } from '@mui/material'
import { AxiosError, AxiosResponse } from 'axios'

interface ICompany {
    companyId: string
    companyName: string
}

interface IProps {
    templateId: string
}

const CompaniesWhichUseTemplate: FC<IProps> = ({ templateId }) => {
    const [companies, setCompanies] = useState([])
    const { isLoading, isFetching, isError, error } = useQuery<
        AxiosResponse,
        AxiosError<{ response: Record<string, string>; message: string }>
    >(
        ['getCompaniesWhichUseGivenTemplate', templateId],
        () => getCompaniesWhichUseTemplate(templateId as string),
        {
            enabled: Boolean(templateId),
            onSuccess: (response) => {
                setCompanies(response.data)
            },
        }
    )

    if (isLoading || isFetching) {
        return <LoadingSpinner />
    }

    if (isError) {
        return <ErrorMessage text={error?.response?.data.message as string} />
    }

    if (companies.length === 0) {
        return (
            <Typography variant="h6">
                No one is using this template currently.
            </Typography>
        )
    }

    return (
        <>
            <Typography variant="h6">
                Companies which use this template:
            </Typography>
            {companies.map((element: ICompany) => (
                <Typography variant="subtitle1" key={element.companyId}>
                    {element.companyName}
                </Typography>
            ))}
        </>
    )
}

export default CompaniesWhichUseTemplate
