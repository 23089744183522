import { styled, Typography } from '@mui/material'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'

const EvansLink = styled(NavLink)`
    display: flex;
    text-decoration: none;
    color: ${(props) => props.theme.palette.primary.contrastText};
    padding: 0.2rem 0.3rem;
    transition: all 0.15s ease-in-out;
    border-bottom: 4px solid transparent;
    &.active {
        color: ${(props) => props.theme.palette.secondary.main};
        border-bottom: 4px solid
            ${(props) => props.theme.palette.secondary.main};
    }
    &:hover {
        color: ${(props) => props.theme.palette.secondary.main};
        border-bottom: 4px solid
            ${(props) => props.theme.palette.secondary.main};
    }
`

interface EvansNavLinkProps {
    to: string
    text: string
}
const EvansNavLink: FC<EvansNavLinkProps> = ({ to, text }) => {
    return (
        <li>
            <EvansLink to={to}>
                <Typography fontSize="1.2rem">{text}</Typography>
            </EvansLink>
        </li>
    )
}
export default EvansNavLink
