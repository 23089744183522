import Input from 'components/Input'
import { useFormikContext } from 'formik'
import { FC, useEffect } from 'react'

interface IProps {
    optionsWithValues?: Array<{
        label: string | number
        value: string | number
    }>
    maxWidth?: string
    displayTooltip?: boolean
    label?: string
    defaultValues?: {
        ownershipOption: string
        salarySacrificeTermInMonths: number
        hireAgreementTermInMonths: number
        minCertificateAmount: number
        maxCertificateAmount: number
        maxCertThreshold: number
    }
}

export const OwnershipSelect: FC<IProps> = ({
    optionsWithValues,
    maxWidth,
    label,
    defaultValues,
}) => {
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    const formik = useFormikContext<any>()

    useEffect(() => {
        if (formik.values?.ownershipOption === defaultValues?.ownershipOption) {
            formik.setFieldValue(
                'hireAgreementTermInMonths',
                defaultValues?.hireAgreementTermInMonths
            )
            formik.setFieldValue(
                'salarySacrificeTermInMonths',
                defaultValues?.salarySacrificeTermInMonths
            )
            formik.setFieldValue(
                'minCertificateAmount',
                defaultValues?.minCertificateAmount
            )
            formik.setFieldValue(
                'maxCertificateAmount',
                defaultValues?.maxCertificateAmount
            )
            formik.setFieldValue(
                'maxCertThreshold',
                defaultValues?.maxCertThreshold
            )
        } else {
            formik.setFieldValue(
                'hireAgreementTermInMonths',
                formik.values.ownershipOption == 'MANAGED_BY_EVANS_CYCLES'
                    ? 72
                    : 12
            )
            formik.setFieldValue('salarySacrificeTermInMonths', 12)

            formik.setFieldValue(
                'maxCertificateAmount',
                formik.values.ownershipOption == 'MANAGED_BY_EVANS_CYCLES'
                    ? 5000
                    : 1000
            )

            formik.setFieldValue(
                'maxCertThreshold',
                formik.values.ownershipOption == 'MANAGED_BY_EVANS_CYCLES'
                    ? 15000
                    : 1000
            )

            formik.setFieldValue('minCertificateAmount', 100)
        }
    }, [formik.values?.ownershipOption])

    return (
        <Input
            apiErrors={formik.status}
            required
            select
            fullWidth
            maxWidth={maxWidth ? maxWidth : ''}
            name="ownershipOption"
            optionsWithValues={optionsWithValues}
            label={label ? label : ''}
        />
    )
}
