import { FC } from 'react'
import { Grid } from '@mui/material'
import Button from 'components/Button'
import FormSubtitle from 'components/FormSubtitle'
import { theme } from 'utils/Theme'
import { IContent } from 'Interfaces/CMS'
import './customContentBox.css'

interface CustomContentBoxProps {
    contentElement: Partial<IContent>
}

const CustomContentBox: FC<CustomContentBoxProps> = ({ contentElement }) => {
    const { title, content, buttons, customBullets } = contentElement

    return (
        <>
            {title && <FormSubtitle text={title} />}
            {content && (
                <div
                    className={`contentStyles${
                        customBullets ? ' bullets' : ''
                    }`}
                    style={{ fontFamily: theme.typography.body1.fontFamily }}
                    dangerouslySetInnerHTML={{
                        __html: content,
                    }}
                />
            )}
            {(buttons?.length ?? 0) > 0 && (
                <Grid container spacing={1} marginTop={2}>
                    {buttons?.map(({ id, text, width, color, link }) => (
                        <Button
                            key={id}
                            text={text}
                            md={Number(width)}
                            fullWidth
                            error={color === 'ERROR'}
                            colorPrimary={color === 'PRIMARY'}
                            to={link}
                            grid
                        />
                    ))}
                </Grid>
            )}
        </>
    )
}
export default CustomContentBox
