import React, { FC, ReactElement } from 'react'
import { Alert } from '@mui/material'

interface IProps {
    text: string | ReactElement
}

const ErrorMessage: FC<IProps> = ({ text }) => {
    return <Alert severity="error">{text}</Alert>
}

export default ErrorMessage
