import React, { FC, ReactElement } from 'react'
import { Alert, Snackbar } from '@mui/material'

interface IProps {
    text: string | ReactElement
    error?: boolean
    isOpen: boolean
    onClose: () => void
}

const Notification: FC<IProps> = ({ text, error, isOpen, onClose }) => {
    return (
        <Snackbar
            onClose={onClose}
            open={isOpen}
            autoHideDuration={typeof text === 'string' ? 2000 : 4000}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <Alert
                onClose={onClose}
                sx={{ width: '100%', padding: '1rem' }}
                severity={error ? 'error' : 'success'}
            >
                {text}
            </Alert>
        </Snackbar>
    )
}

export default Notification
