import { FC, useRef } from 'react'
import { Grid, Typography } from '@mui/material'
import Input from 'components/Input'
import { Formik, FormikProps } from 'formik'
import {
    getCompanyRestrictions,
    getTerms,
    updateCompanyRestrictions,
} from 'api/evansAdmin'
import { useQuery, useMutation } from 'react-query'
import LoadingSpinner from 'components/LoadingSpinner'
import Button from 'components/Button'
import { updateCompanyRestrictionsSchemaValidator } from 'utils/ValidationSchema'
import { ICompanyRestrictions } from 'Interfaces/Company'
import useStore from 'store/Store'
import { useParams } from 'react-router-dom'
import usePageTitle from 'hooks/usePageTitle'
import { TRANSFER_OPTIONS_WITH_VALUES } from 'utils/constants'
import DateTimeInput from 'components/DateTimeInput'
import format from 'date-fns/format'
import { HIRE_AGREEMENT_OPTIONS } from 'utils/constants'
import { AxiosError } from 'axios'
import { OwnershipSelect } from 'pages/SchemeAdmin/CompanyRegister/OwnershipSelect'

const CompanyRestrictions: FC = () => {
    usePageTitle('Company Restrictions')
    const { openNotification } = useStore()
    const { companyId } = useParams()
    const formRef = useRef<FormikProps<ICompanyRestrictions>>(null)

    const { isFetching, data, error } = useQuery('getCompanyRestrictions', () =>
        getCompanyRestrictions(companyId as string)
    )

    const initialValues = {
        ...data?.data,
        clearDates: false,
    }

    const transferOptions = TRANSFER_OPTIONS_WITH_VALUES.filter(
        (element: { value: string; label: string }) =>
            initialValues.ownershipOption === 'T4U'
                ? true
                : element.label !== 'T4U'
    )

    const getFullErrorMessage = (
        error: AxiosError<{ message: string; globalErrors: string[] }>
    ) => {
        if (!error?.response?.data || !error?.response?.data.message)
            return 'Something went wrong'

        const { message, globalErrors } = error?.response?.data || {}

        if (!globalErrors || globalErrors.length === 0) return message
        return (
            <>
                <span>{message}:</span>
                <ul>
                    {globalErrors.map((err) => (
                        <li key={err}>{err}</li>
                    ))}
                </ul>
            </>
        )
    }

    const mutation = useMutation(
        (data: ICompanyRestrictions) =>
            updateCompanyRestrictions(companyId as string, data),
        {
            onSuccess: () => {
                openNotification('Data has been saved', 'success')
                formRef.current?.setTouched({})
            },
            onError: (
                error: AxiosError<{ message: string; globalErrors: string[] }>
            ) => {
                openNotification(getFullErrorMessage(error), 'error')
            },
        }
    )

    function resetDates() {
        formRef.current?.setFieldValue('schemeClosingDate', null)
        formRef.current?.setFieldValue('schemeOpeningDate', null)

        formRef.current?.setFieldTouched('schemeClosingDate', false)
        formRef.current?.setFieldTouched('schemeOpeningDate', false)
    }

    const { data: terms, isFetching: isFetchingTerms } = useQuery(
        ['getTerms'],
        () => getTerms()
    )

    if (isFetching || isFetchingTerms) {
        return <LoadingSpinner />
    }

    if (error) {
        return <div>error...</div>
    }

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={(values) => {
                const {
                    schemeOpeningDate,
                    schemeClosingDate,
                    clearDates,
                    ...rest
                } = values

                mutation.mutate({
                    ...rest,
                    schemeOpeningDate: schemeOpeningDate
                        ? format(
                              new Date(schemeOpeningDate),
                              'yyyy-MM-dd HH:mm'
                          )
                        : null,

                    schemeClosingDate: schemeClosingDate
                        ? format(
                              new Date(schemeClosingDate),
                              'yyyy-MM-dd HH:mm'
                          )
                        : null,
                })
            }}
            validationSchema={updateCompanyRestrictionsSchemaValidator}
        >
            {({ values, handleSubmit, setFieldValue }) => (
                <Grid
                    container
                    rowSpacing={4}
                    columnSpacing={2}
                    sx={{ padding: '1rem', marginTop: '1rem' }}
                >
                    <Grid item xs={12}>
                        <Typography color="primary" variant="h4">
                            Edit Company Restrictions
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            type="number"
                            fullWidth
                            name="minCertificateAmount"
                            label="Minimum Certificate Amount"
                            required
                            prefix="£"
                            maxWidth="600px"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            type="number"
                            fullWidth
                            name="maxCertificateAmount"
                            label="Maximum Certificate Amount"
                            required
                            prefix="£"
                            maxWidth="600px"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            type="number"
                            fullWidth
                            name="minCertThreshold"
                            label="Minimum Certificate Treshold"
                            required
                            prefix="£"
                            maxWidth="600px"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            type="number"
                            fullWidth
                            name="maxCertThreshold"
                            label="Maximum Certificate Treshold"
                            required
                            prefix="£"
                            maxWidth="600px"
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <DateTimeInput
                            fullWidth
                            label="Scheme Opening Date"
                            name="schemeOpeningDate"
                            maxWidth="600px"
                            value={values.schemeOpeningDate}
                            onBlur={() => {
                                formRef.current?.setFieldTouched(
                                    'schemeOpeningDate',
                                    true
                                )
                            }}
                            onChange={(value: string | null) => {
                                setFieldValue(
                                    'schemeOpeningDate',
                                    value as string
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DateTimeInput
                            fullWidth
                            maxWidth="600px"
                            label="Scheme Closing Date"
                            name="schemeClosingDate"
                            value={values.schemeClosingDate}
                            onBlur={() => {
                                formRef.current?.setFieldTouched(
                                    'schemeClosingDate',
                                    true
                                )
                            }}
                            onChange={(value: string | null) =>
                                setFieldValue(
                                    'schemeClosingDate',
                                    value as string
                                )
                            }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            disabled={
                                !values.schemeClosingDate &&
                                !values.schemeOpeningDate
                            }
                            text="Reset Dates"
                            colorPrimary
                            onClick={resetDates}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Input
                            fullWidth
                            maxWidth="600px"
                            options={HIRE_AGREEMENT_OPTIONS}
                            name="hireAgreementType"
                            select
                            label="Hire Agreement Type"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Input
                            name="financeHireAgreement"
                            label="Financed Hire Agreement"
                            checkBox
                            fullWidth
                        />
                    </Grid>

                    {values.financeHireAgreement && (
                        <>
                            <Grid item xs={12} md={6}>
                                <Input
                                    fullWidth
                                    name="financialProvider"
                                    label="Finance Provider"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Input
                                    type="number"
                                    fullWidth
                                    name="financeAmount"
                                    label="Finance Amount (in Pounds)"
                                    prefix="£"
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <Input
                            checkBox
                            fullWidth
                            name="requirePayrollNumber"
                            label="Require Payroll Number"
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <OwnershipSelect
                            optionsWithValues={transferOptions}
                            maxWidth="600px"
                            label="Transfer of ownership option"
                            defaultValues={data?.data}
                        />
                    </Grid>
                    <Grid item />
                    <Grid item xs={12} md={6}>
                        <Input
                            fullWidth
                            required
                            maxWidth="600px"
                            name="hireAgreementTermInMonths"
                            label="Please set the term of hire agreement (in months)"
                            select
                            options={
                                terms?.data?.allAvailableHireAgreementTerms?.[
                                    values?.ownershipOption
                                ] ?? ['']
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            required
                            fullWidth
                            maxWidth="600px"
                            name="salarySacrificeTermInMonths"
                            label="Please set the term of salary sacrifice term (in months)"
                            select
                            options={
                                terms?.data?.allAvailableSalarySacrificeTerms?.[
                                    values?.ownershipOption
                                ] ?? ['']
                            }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Input
                            radio
                            radioOptions={[
                                { label: 'monthly', value: 'MONTHLY' },
                                { label: 'weekly', value: 'WEEKLY' },
                                {
                                    label: 'fortnightly',
                                    value: 'FORTNIGHTLY',
                                },
                                {
                                    label: 'fourweekly',
                                    value: 'FOURWEEKLY',
                                },
                            ]}
                            fullWidth
                            maxWidth="600px"
                            name="salarySacrificeDeductionFrequency"
                            label="Salary sacrifice deductions will be made from payroll"
                        />
                    </Grid>

                    <Grid container item xs={12}>
                        <Input
                            checkBox
                            fullWidth
                            name="certificateRedemptionRestriction"
                            label=" Certificate Redemption Restrictions"
                        />

                        {values.certificateRedemptionRestriction && (
                            <>
                                <Grid item xs={12} sx={{ marginLeft: '2rem' }}>
                                    <Input
                                        checkBox
                                        fullWidth
                                        name="redeemableIBD"
                                        label="Redemption at IBD (IBD = independent bike dealership - non Evans store)"
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{ marginLeft: '2rem' }}>
                                    <Input
                                        checkBox
                                        fullWidth
                                        name="collectAtStore"
                                        label="Collect at store only"
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <Input
                            checkBox
                            fullWidth
                            name="isTemporaryDisabled"
                            label="Disable Company From Certificate Process"
                        />
                    </Grid>

                    <Button
                        isLoading={mutation.isLoading}
                        grid
                        md={6}
                        fullWidth
                        text="Save Changes"
                        maxWidth="600px"
                        onClick={() => {
                            if (!values.financeHireAgreement) {
                                setFieldValue('financeAmount', null)
                                setFieldValue('financialProvider', null)
                            }

                            if (!values.certificateRedemptionRestriction) {
                                setFieldValue('redeemableIBD', false)
                                setFieldValue('collectAtStore', false)
                            }

                            handleSubmit()
                        }}
                    />
                </Grid>
            )}
        </Formik>
    )
}

export default CompanyRestrictions
