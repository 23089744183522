import { Box, Typography } from '@mui/material'
import Button from 'components/Button'
import Modal from 'components/Modal/Modal'
import { IAddress } from 'Interfaces/Certificate'
import { FC } from 'react'

interface IUnverifiedAddressModal {
    isOpen: boolean
    close: () => void
    address: IAddress
    submit: () => void
}

const UnverifiedAddressModal: FC<IUnverifiedAddressModal> = ({
    isOpen,
    close,
    address,
    submit,
}) => {
    return (
        <Modal isOpen={isOpen} onClose={close} title="Address not verified">
            <Box marginTop="1rem">
                <Typography marginBottom="1rem">
                    We&apos;re sorry, we were unable to verify your address.
                    Please review and adjust your address if necessary. If
                    you&apos;re confident that your address is correct, you may
                    proceed with the address you&apos;ve entered.
                </Typography>
                {Object.values(address)
                    .filter(Boolean)
                    .map((a) => {
                        if (a !== 'GB')
                            return (
                                <Typography color="gray" key={a}>
                                    {a}
                                </Typography>
                            )
                    })}
                <Box
                    marginTop="1rem"
                    gap="1rem"
                    display="flex"
                    justifyContent="flex-end"
                >
                    <Button text="Cancel" onClick={close} />
                    <Button colorPrimary text="Proceed" onClick={submit} />
                </Box>
            </Box>
        </Modal>
    )
}
export default UnverifiedAddressModal
