import { Container } from '@mui/system'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from 'utils/Theme'
import { QueryClientProvider, QueryClient } from 'react-query'
import Header from 'layout/Header'
import Routing from 'Routing'
import Footer from 'layout/Footer'
import { BrowserRouter } from 'react-router-dom'
import Navigation from 'components/Navigation'
import { GlobalStyles } from '@mui/material'
import AuthProvider from 'auth/AuthProvider'
import Notification from 'components/Notification'
import useStore from 'store/Store'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import useBroadcast from 'auth/useBroadcast'
import useAuth from 'auth/useAuth'
import { AxiosError } from 'axios'
import Modal from 'components/Modal/Modal'

function App() {
    const {
        isNotificationOpen,
        closeNotification,
        notificationMessage,
        notificationType,
        isModalOpen,
        closeModal,
        modalTitle,
        modalContent,
        handleEvansAdminState,
        handleSchemeAdminState,
    } = useStore()
    const { logout } = useAuth()
    const client = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                retry(
                    failureCount: number,
                    error: AxiosError | unknown | undefined
                ) {
                    if (
                        failureCount > 1 &&
                        error instanceof AxiosError &&
                        (error.response?.status === 403 ||
                            error?.response?.status === 401)
                    ) {
                        logout()
                        handleEvansAdminState(false, '')
                        handleSchemeAdminState(false)
                        return false
                    }

                    if (failureCount > 1) {
                        return false
                    }

                    return true
                },
            },
        },
    })

    const globalStyles = {
        body: { margin: '0', overflow: 'visible' },
    }
    const containerStyle = {
        minHeight: 'calc(100vh - 190px)',
        padding: '2rem 1rem',
        margin: ' 0 auto',
    }

    useBroadcast()

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <QueryClientProvider client={client}>
                <BrowserRouter>
                    <ThemeProvider theme={theme}>
                        <AuthProvider>
                            <GlobalStyles styles={globalStyles} />
                            <Header />
                            <Container
                                sx={containerStyle}
                                maxWidth={
                                    location.pathname.includes('evans-admin')
                                        ? 'xl'
                                        : 'lg'
                                }
                            >
                                <Navigation />
                                <Routing />
                                <Notification
                                    isOpen={isNotificationOpen}
                                    error={notificationType === 'error'}
                                    text={notificationMessage}
                                    onClose={() => closeNotification()}
                                />
                                <Modal
                                    isOpen={isModalOpen}
                                    title={modalTitle}
                                    onClose={closeModal}
                                >
                                    {modalContent}
                                </Modal>
                            </Container>
                            <Footer />
                        </AuthProvider>
                    </ThemeProvider>
                </BrowserRouter>
            </QueryClientProvider>
        </LocalizationProvider>
    )
}

export default App
