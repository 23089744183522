import { FC } from 'react'
import Table from 'components/Table'
import { Box, Typography } from '@mui/material'
import Button from 'components/Button'
import { FileDownload } from '@mui/icons-material'
import Modal from 'components/Modal/Modal'
import FilteringCertificatesEvansModal from './components/FilteringCertificatesEvansModal'
import useEvansAdminCertificatesPage from './useEvansAdminCertificatesPage'
import usePageTitle from 'hooks/usePageTitle'
import LoadingSpinner from 'components/LoadingSpinner'
import ErrorMessage from 'components/ErrorMessage'
import CertificatesDescriptionModal from 'components/Modal/CertificatesDescriptionModal'
import replaceNullWithEmptyString from 'utils/replaceNullWithEmptyString'
import CertificateErrors from './CertificateErrors'

const EvansAdminCertificatesPage: FC = () => {
    usePageTitle('Certificates')
    const {
        columns,
        applyFilters,
        isFilteringModalOpen,
        handleModalState,
        page,
        size,
        rest,
        clearFilters,
        convertResponseDisplayFormat,
        handlePageChange,
        response,
        isFetching,
        error,
        exportCertificatesToCsv,
        isDescriptionModalOpen,
        setIsDescriptionModalOpen,
        certificatesStatuses,
        isDescriptionLoading,
        companyIdToPreviewErrors,
        setCompanyIdToPreviewErrors,
    } = useEvansAdminCertificatesPage()

    if (error) {
        return <ErrorMessage text={error as string} />
    }

    if (isFetching && isDescriptionLoading) {
        return <LoadingSpinner />
    }

    return (
        <>
            <Modal
                isOpen={isFilteringModalOpen}
                title="Filter Certificates"
                onClose={handleModalState}
            >
                <FilteringCertificatesEvansModal
                    applyFilters={applyFilters}
                    filteringValues={replaceNullWithEmptyString(rest)}
                    closeModal={handleModalState}
                    certificatesStatuses={certificatesStatuses}
                />
            </Modal>
            <Modal
                isOpen={Boolean(companyIdToPreviewErrors.id)}
                onClose={() => {
                    setCompanyIdToPreviewErrors({ id: '' })
                }}
            >
                <Box padding="1rem" maxHeight="350px">
                    <CertificateErrors
                        certificateId={companyIdToPreviewErrors.id}
                    />
                </Box>
            </Modal>
            <CertificatesDescriptionModal
                isOpen={isDescriptionModalOpen}
                title="Statuses Description"
                maxWidth="md"
                onClose={() => setIsDescriptionModalOpen(false)}
                data={certificatesStatuses}
            />

            <Typography color="primary" variant="h4" mt={4} mb={2}>
                Manage Certificates
            </Typography>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Box display="flex" gap="1rem" alignItems="center">
                    <Button
                        colorPrimary
                        text="Clear Filters"
                        onClick={clearFilters}
                        disabled={Object.values(rest).every(
                            (element: string | null | string[] | boolean) =>
                                element == null
                        )}
                    />
                    <Button text="Filters" onClick={handleModalState} />
                    <Typography
                        color="primary"
                        fontWeight="bold"
                        sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                        variant="body1"
                        onClick={() => setIsDescriptionModalOpen(true)}
                    >
                        Status Description
                    </Typography>
                </Box>

                <FileDownload
                    onClick={() => exportCertificatesToCsv.mutate()}
                    color="primary"
                    sx={{ cursor: 'pointer', transform: 'scale(1.2)' }}
                />
            </Box>
            <Table
                currentPage={(page as number) + 1}
                pageSize={size}
                paginate
                loading={isFetching}
                rows={convertResponseDisplayFormat()}
                columns={columns}
                onPageChange={handlePageChange}
                numberOfPages={Math.ceil(response?.data.totalElements / size)}
            />
        </>
    )
}

export default EvansAdminCertificatesPage
